import axios from "axios";
import { stringify } from "draft-js/lib/DraftStringKey";
import _ from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import bag from "../../Assets/bag.svg";
import styles from "./styles/OrganizerPage.module.css";
import { Link } from "react-router-dom";

const IntroShowcase = ({
  setCampignkey,
  handleAddtoMyCampaign,
  handleRemoveOtherContest,
  cartMap,
  count,
}) => {
  const [filterData, setFilterData] = useState([]);
  const [current, setCurrent] = useState("All");
  const [showCart, setShowCart] = useState(false);
  const navigate = useNavigate();
  const { category } = useParams();
  useEffect(() => {
    setCurrent(category);
    loadFilterData();
  }, []);
  const loadFilterData = async () => {
    try {
      const setdata = await axios.get(
        `https://storage.googleapis.com/campaign_templates/campaignMedia/filterChips.json`
      );

      setFilterData(setdata?.data?.data);
      // let campaignKey = setdata?.data?.data?.find(
      //   (el) => el?.json_path == category
      // );
      let campaignKey = {
        displayKey: "Online auditions",
        json_path: "Online_auditions",
        url: "https://storage.googleapis.com/campaign_templates/Festival/template.json",
      };
      setCampignkey(campaignKey);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div className="w-[100%]  mx-auto pt-[30px]  text-black">
        <div className="flex justify-between items-start relative px-[20px]">
          <div>
            <p className={` mb-[16px] ${styles.heading1}`}>Create campaign</p>
            <p className={`${styles.desciption1} max-w-[550px]`}>
              Choose from a variety of contest options including Photo, Video,
              Audio, and Poster and create engaging online contests that will
              captivate your audience.
            </p>
          </div>
          <Link to="/campaigns/create/cart">
            <div className="flex items-center min-w-[32px]">
              {count > 0 && (
                <p className="md:block hidden px-[16px] py-[6px] text-small text-[#4E04C6]">
                  {count == 1 ? count + " contest" : count + " contests"} in
                  cart{" "}
                </p>
              )}
              <div className={styles.bagButton}>
                <img src={bag} />
                {count > 0 && <div className={styles.dot} />}
              </div>
            </div>
          </Link>

          {showCart && (
            <div className="absolute top-[25px] right-[25px] text-right text-black bg-white p-[10px] rounded-[20px] drop-shadow-lg">
              {Array?.from(cartMap)?.map(([key, value]) => {
                return (
                  <div className="flex gap-[10px]">
                    <p onClick={() => handleRemoveOtherContest(value)}>
                      {value?.contestName}
                    </p>
                    {value?.count && (
                      <p>
                        <span onClick={() => handleRemoveOtherContest(value)}>
                          -
                        </span>
                        {value?.count}
                        <span onClick={() => handleAddtoMyCampaign(value)}>
                          +
                        </span>
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* <div className="flex overflow-x-auto gap-[10px] hideScrollBar mt-[20px] md:mt-[32px] pl-[20px]">
          {filterData?.map((el, index) => {
            return (
              <div
                className={`w-auto py-[8px] px-[16px] flex items-center border-[1px] border-[#4E04C6] rounded-[4px] text-small transition-colors duration-300 ease-in ${
                  current == el?.json_path
                    ? "bg-[#4E04C6] text-white"
                    : "bg-inherit text-[#4E04C6]"
                } hover:cursor-pointer`}
                key={index}
                onClick={() => {
                  setCurrent(el?.json_path);
                  setCampignkey(el);
                  navigate(`/campaigns/create/${el?.json_path}`);
                }}
              >
                {el?.displayKey}
              </div>
            );
          })}
        </div> */}
      </div>
    </>
  );
};

export default IntroShowcase;
