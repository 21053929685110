import React, { useEffect, useState } from "react";
import styles from "./CampaignSpecialGuests.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import LoadingAnimation from "../Loading/LoadingAnimation";
import CreateJudge from "../CreateJudge/CreateJudge";
import EditJudge from "../EditJudge/EditJudge";
import EditSpecialGuests from "../EditSpecialGuests/EditSpecialGuests";
import CreateSpecialGuests from "../CreateSpecialGuests/CreateSpecialGuests";

export default function CampaignSpecialGuests({
  setShowAddJudges,
  editCampaignData,
}) {
  const initialValue = editCampaignData?.specialGuests
    ? editCampaignData?.specialGuests
    : [];

  const [addedData, setAddedData] = useState(initialValue);
  const [createdData, setCreatedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const {
    organizationId,
    userIdToken,
    setCampign,
    setUpdateData,
    setCampaignPageNo,
    updateData,
    setNotAdmin,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  useEffect(() => {
    getSponsorData();
  }, [updateData]);
  const getSponsorData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_GUESTS}/${organizationId}?type=special_guests`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const apiData = await axios(config1);
      let filter = apiData?.data?.data?.specialGuests?.filter(
        (el) => !initialValue?.some((el2) => el2?._id === el?._id)
      );
      setCreatedData(filter);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const judgesDataFilter = () => {
    let sponsorFromApi = createdData;
    let filter = sponsorFromApi.filter(
      (el) => !addedData?.some((el2) => el2?._id === el?._id)
    );
    setCreatedData(filter);
  };

  const handleSelect = (obj) => {
    let newArray = addedData;
    newArray.push(obj);
    setAddedData(newArray);
    judgesDataFilter();
  };
  const handleRemove = (obj) => {
    let newArray = addedData;
    var index = newArray.findIndex((el) => el._id == obj._id);
    newArray.splice(index, 1);
    setAddedData(newArray);
    judgesDataFilter();
    setCreatedData([obj, ...createdData]);
  };
  const handleSave = async () => {
    setLoading(true);
    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_CAMPAIGN_DETAILS}/${editCampaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { specialGuests: addedData },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        setShowAddJudges(false);
        setUpdateData((prevState) => prevState + 1);
        setCampign([]);
        setCampaignPageNo(1);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handlePublish = async () => {
    setLoading(true);
    try {
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_CAMPAIGN_DETAILS}/${editCampaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { specialGuests: addedData },
      };
      const response = await axios(config1);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        let config2 = {
          method: "PATCH",
          url: `${process.env.REACT_APP_PUBLISH_ORGANISER_CAMPAIGN}/${editCampaignData?._id}`,
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
        };
        const response2 = await axios(config2);
        if (response2.status === 201) {
          if (response2?.data?.admin == false) {
            setNotAdmin(true);
            setLoading(false);
            return;
          }
          setShowAddJudges(false);
          setUpdateData((prevState) => prevState + 1);
          setCampign([]);
          setCampaignPageNo(1);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleEditJudge = (event, data) => {
    event.stopPropagation();
    setEdit(true);
    setEditData(data);
  };
  return (
    <div className={styles.popup}>
      {createNew && (
        <CreateSpecialGuests
          showPopup={createNew}
          setShowPopup={setCreateNew}
        />
      )}
      {edit && (
        <EditSpecialGuests
          showPopup={edit}
          setShowPopup={setEdit}
          editData={editData}
          type="campaign"
          campaignId={editCampaignData?._id}
          setAddedData={setAddedData}
        />
      )}
      <div className={styles.selectAdsPopup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Special Guests</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowAddJudges(false)} />
          </div>
          <div className={styles.body}>
            {loading ? (
              <LoadingAnimation />
            ) : (
              <>
                <p>Special Guests</p>
                <div className={styles.promotionListGrid}>
                  {createdData?.map((obj) => {
                    return (
                      <div
                        className={styles.entry}
                        onClick={() => handleSelect(obj)}
                      >
                        <img src={obj?.dop} />
                        <div className="p-[10px]">
                          <p>{obj?.promotorName}</p>
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className={styles.entry}
                    onClick={() => setCreateNew(true)}
                  >
                    {/* <img src={obj?.fileUrl} /> */}
                    <p>Create Special Guest</p>
                  </div>
                </div>
                <p className="mt-[20px]">Selected Special Guests</p>

                <div className={styles.promotionListGrid}>
                  {addedData?.map((obj) => {
                    return (
                      <div
                        className={styles.entry}
                        onClick={() => handleRemove(obj)}
                      >
                        <img src={obj?.dop} />
                        <div className="p-[10px] flex flex-col gap-[5px]">
                          <p>{obj?.promotorName}</p>
                          <button
                            className={styles.editButton}
                            onClick={(e) => handleEditJudge(e, obj)}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.save}
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </button>
            <button
              className={styles.save}
              onClick={handlePublish}
              disabled={loading}
            >
              Save and publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
