import React from "react";
import underReviewImage from '../../Assets/underReview.svg'

function UnderReviewPopup() {
  return (
    <>
      <div className=" h-[100%] w-[100%] flex  bg-white text-black justify-center items-end">
        <div className="h-[418px] w-[100%] rounded-t-[30px] underReviewCard px-[30px] py-[20px] flex flex-col items-center">
          <div className="bg-[#252525] h-[61px] w-[61px] rounded-full flex items-center justify-center">
            <img src={underReviewImage} alt="" />
          </div>
          <p className="six text-[21px] mt-[20px]">
            Campaign creation is in progress
          </p>
          <p className="text-center four text-[17px] mt-[10px]">
            Your campaign creation is in progress and will be published after
            admin approval. Once approved, you will be notified through email.
          </p>
          {/* <p className="five text-[17px] text-[#5073EF] mt-[20px] hover:cursor-pointer">
                    Go to Dashboard
                  </p> */}
        </div>
      </div>
    </>
  );
}

export default UnderReviewPopup;
