import styles from "./styles/LuckDrw.module.css";
import Timer from "./Timer";
import Cup from "../../Assets/cup.svg";
import Star from "../../Assets/star.svg";
import Remove from "../../Assets/remove.svg";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import ContestHeadData from "./ContestHeadData";
import filterJson from "../../Json/prizeAlgorithm.json";
import * as _ from "lodash";
import TopBanner from "../TopBanner/TopBanner";
import RightPanel from "../RightPanel/RightPanel";
import { ExportCSV } from "../../utils/ExportCSV";
import WinnerSelectionList from "../WinnerSelectionList/WinnerSelectionList";
import winnerSelectionJson from "../../Json/winnerSelection.json";
import { useRef } from "react";
import LoadingAnimation from "../Loading/LoadingAnimation";
import SelectedWinners from "../SelectedWinners/SelectedWinners";
import RefreshButton from "../RefreshButton/RefreshButton";
import WinnerPopup from "../SelectedWinners/WinnerPopup";
import AlertPopup from "../AlertPopups/AlertPopup";
import Filter from "../Filters/Filter";

function Luckydraw() {
  const { currentUser } = useUserAuth();
  const [textValue, setTextValue] = useState();
  const [cutOffMark, setCutOffMark] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingWinner, setLoadingWinner] = useState(false);
  const [randomLoading, setRandomLoading] = useState(false);
  const [errorValue, setError] = useState("");
  const [filter, setFilter] = useState({
    filter: "ALL_CUTOFF",
  });
  const [clickedObj, setClickedObj] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [declarePopup, setDeclarePopup] = useState(false);
  const handleShowPopup = (obj) => {
    setShowPopup(true);
    setClickedObj(obj);
  };
  const {
    verifyUser,
    userIdToken,
    orgId,
    campaignId,
    contestId,
    setUpdateData,
    updateData,
    setCampaignIdToPersist,
  } = useContext(Context);
  const [singleContestData, setSingleContest] = useState({});
  const [selectedWinnersList, setSelectedWinnersList] = useState([]);
  const [noWinnerText, setNotWinnerText] = useState("");
  const [winnersPrepared, setWinnersPrepared] = useState(false);
  const [winnersUrl, setWinnersUrl] = useState("");
  const [randomSelectionNumber, setRandomSelectionNmber] = useState();
  const [status, setStatus] = useState("crowdWinner");
  const [searchLoading, setSearchLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const selectedWinners = useRef(null);

  useEffect(() => {
    verifyUser();
    getCampaignContestData();
  }, [updateData]);
  const tokenData = userIdToken || currentUser?.accessToken;

  const getCampaignContestData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CONTEST_DATA}/${orgId}/${campaignId}/${contestId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const contestDetails = setApi?.data?.data;
      setCampaignIdToPersist(contestDetails?.campaignId);
      setSingleContest(contestDetails);
      const winnersPrepared = _.get(
        contestDetails && contestDetails,
        "winnersPrepared",
        false
      );
      setWinnersPrepared(winnersPrepared);

      const winnerList = _.get(
        contestDetails && contestDetails,
        "winnersUrl",
        ""
      );
      setWinnersUrl(winnerList);

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const declareOperations = (obj) => {
    setError("");
    setTextValue("");

    if (selectedWinnersList?.some((el) => el?.id == obj.id)) {
      return setError("User id already added");
    }
    setSelectedWinnersList((prevState) => [...prevState, obj]);
  };
  const searchEntriesWithAlgorithm = async () => {
    try {
      setSearchData([]);

      setNotWinnerText("");
      setSearchLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_WINNER_SEARCH_WITH_ALGORITHM}/${orgId}/${campaignId}/${contestId}?algorithmType=${filter?.filter}&cutOffMarks=${cutOffMark}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const result = await axios(config);
      setSearchData(result?.data?.data);
      setSearchLoading(false);
    } catch (error) {
      setSearchLoading(false);
    }
  };
  const searchEntries = async (text) => {
    try {
      setSearchData([]);
      setNotWinnerText("");
      setSearchLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_WINNER_SEARCH}/${orgId}/${campaignId}/${contestId}?search=${text}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const result = await axios(config);
      setSearchData(result?.data?.data);
      setSearchLoading(false);
    } catch (error) {
      setSearchLoading(false);
    }
  };
  const removeWinners = (obj) => {
    setSelectedWinnersList((prevList) => {
      const newList = [...prevList];
      let index = newList?.findIndex((el) => el?.id == obj?.id);
      newList.splice(index, 1);
      return newList;
    });
  };

  const handleEdit = (text) => {
    setEdit(status);
    setNotWinnerText(text);
  };

  const winnerSelection = async () => {
    let type = "";
    if (status == "judgeWinner") {
      type = "judge";
    } else if (status == "specialWinner") {
      type = "special_recognition";
    }
    setDeclarePopup(false);
    try {
      setLoadingWinner(true);
      let resultToPublish = {};
      if (noWinnerText) {
        resultToPublish = {
          noWinner: true,
          reason: noWinnerText,
        };
      } else {
        let trimmdWinnerlist = selectedWinnersList?.map((obj) => ({
          id: obj?.id,
          position: obj?.position,
        }));
        const sortedData = trimmdWinnerlist?.sort((a, b) => {
          if (a.position === "general" && b.position !== "general") {
            return 1; // "general" comes last
          } else if (a.position !== "general" && b.position === "general") {
            return -1; // "general" comes last
          } else {
            // Sort based on the numerical value of the position
            return a.position - b.position;
          }
        });
        resultToPublish = {
          idArray: sortedData,
        };
      }
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_WINNER_SELECTION}/${orgId}/${campaignId}/${contestId}?type=${type}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: resultToPublish,
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        setLoadingWinner(false);
        const params = {
          orgId: orgId,
          cmpId: campaignId,
          conId: contestId,
        };
        setDeclarePopup(false);
        setUpdateData((prevState) => prevState + 1);
        setEdit("");
        // navigate({
        //   pathname: `/campaigns/contests/contest`,
        //   search: `?${createSearchParams(params)}`,
        // });
      }
    } catch (err) {
      setLoadingWinner(false);
      console.log(err.message);
    }
  };
  const randomSelection = async () => {
    try {
      setRandomLoading(true);
      setNotWinnerText("");
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_WINNER_RANDOM_SELECTION}/${orgId}/${campaignId}/${contestId}?limit=${randomSelectionNumber}&contestType=${singleContestData.contestType}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        const winnersLists = setApi?.data?.data;
        let winnersListsToDisplay = [];
        winnersLists.forEach((element, index) => {
          let position = "";
          if (index > 2) {
            position = "general";
          } else {
            position = index + 1;
          }
          winnersListsToDisplay.push({
            ...element,
            position,
            id:
              "ACGUS" +
              element.userId.toString() +
              "EN" +
              element._id.toString(),
          });
        });
        setSelectedWinnersList(winnersListsToDisplay);
        setRandomLoading(false);
        selectedWinners.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    } catch (err) {
      setRandomLoading(false);
      console.log(err.message);
    }
  };
  const handleRedeclareWinner = (type) => {
    setSingleContest((prevState) => {
      return {
        ...prevState,
        [type]: [],
      };
    });
  };
  const handleDeclareWinner = () => {
    setDeclarePopup((prevState) => !prevState);
  };
  const handleSearchEntries = () => {
    if (filter?.filter == "NAME_OR_ID") {
      searchEntries(textValue);
    } else {
      searchEntriesWithAlgorithm();
    }
  };
  const approveValues = {
    Icon: "",
    Heading: "Declare winners",
    Para: "Are you sure you want to confirm these entries as winners? Please note that this action cannot be undone ",
    Button1: "Cancel",
    Button2: "Declare",
    Button2Color: "#06B818",
  };
  return (
    <div className="content-wrapper ">
      {declarePopup && (
        <AlertPopup
          visible={declarePopup}
          setpopUp={setDeclarePopup}
          button1Function={handleDeclareWinner}
          button2Function={winnerSelection}
          value={approveValues}
        />
      )}
      {showPopup && (
        <WinnerPopup
          setpopUp={setShowPopup}
          popUp={showPopup}
          popupData={clickedObj}
        />
      )}
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="center">
            <TopBanner
              backgroundImage={singleContestData?.contestPoster}
              heading={singleContestData?.contestName}
              data={singleContestData}
              breadCrumbItem={[
                { label: "Campaigns", url: "/campaigns" },
                {
                  label: "Contests",
                  url: "/campaigns/contests",
                  params: {
                    orgId: singleContestData?.orgId,
                    cmpId: singleContestData?.campaignId,
                  },
                },
                {
                  label: singleContestData?.contestName,
                  url: "/campaigns/contests/contest",
                  params: {
                    orgId: singleContestData?.orgId,
                    cmpId: singleContestData?.campaignId,
                    conId: singleContestData?.contestId,
                  },
                },
                {
                  label: "Winner Selection",
                  url: "/campaigns/contests/contest/winner-selection",
                  params: {
                    orgId: singleContestData?.orgId,
                    cmpId: singleContestData?.campaignId,
                    conId: singleContestData?.contestId,
                  },
                },
              ]}
            />
            <div className="px-[20px]">
              <div className={styles.filterChips}>
                {winnerSelectionJson?.options?.map((obj) => {
                  return (
                    <div
                      onClick={() => {
                        setStatus(obj?.key);
                        setSelectedWinnersList([]);
                        setSearchData([]);
                        setNotWinnerText("");
                      }}
                      className={
                        status == obj?.key
                          ? styles.eachChips
                          : styles.eachChipsDark
                      }
                    >
                      <p>{obj?.optionName}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            {!winnersPrepared && (
              <div className={styles.winnerNotPrepared}>
                <p className={styles.inTxt}>
                  Participation list preparation is in progress
                </p>
              </div>
            )}
            {winnersPrepared && (
              <div className="px-[20px]">
                {status == "crowdWinner" && (
                  <>
                    {!singleContestData?.crowdWinnerSelected ? (
                      <div className={styles.winnerSelection}>
                        <div className={styles.winBocx}>
                          <h3>Pick Winners</h3>
                          <p className={styles.inTxt}>
                            If you want automated winner selection, click the
                            Pick winners button.
                          </p>
                          <div className="flexico mt-[30px]">
                            <p
                              className={`  ${styles.inTxt} whitespace-nowrap`}
                            >
                              Number of winners
                            </p>
                            <input
                              placeholder="0"
                              type="number"
                              className={styles.winnerInput}
                              onChange={(e) =>
                                setRandomSelectionNmber(e.target.value)
                              }
                            />
                          </div>
                          <button
                            className={styles.winern}
                            onClick={randomSelection}
                            disabled={randomLoading}
                          >
                            <p>{randomLoading ? "Loading" : "Pick winners"}</p>
                          </button>
                        </div>
                        <div className={styles.outOfbOxc}>
                          <div>
                            <h3>Declare the Winners</h3>
                            <p className={styles.inTxt}>
                              If you have already selected crowd winners,
                              declare the winners from the participants by
                              adding the{" "}
                              <span className="font-semibold">
                                Participant’s ID
                              </span>{" "}
                              to list.
                            </p>
                            <div className="flexico mt-[20px]">
                              <input
                                placeholder="Search Id or Name"
                                type="text"
                                value={textValue}
                                onChange={(e) => {
                                  setError("");
                                  setTextValue(e.target.value);
                                }}
                                className={styles.search}
                              />
                              <button
                                className={styles.addme}
                                onClick={() => {
                                  searchEntries(textValue);
                                }}
                                disabled={searchLoading}
                              >
                                <p>{searchLoading ? "Searching" : "Search"}</p>
                              </button>
                            </div>
                            {errorValue && <p>{errorValue}</p>}
                            <table className=" border-collapse    border border-[#E5EAFF]  w-[100%] min-w-full mt-[10px]">
                              {_.isEmpty(searchData) ? (
                                <p className="w-full p-[20px] flex items-center justify-center">
                                  {searchLoading ? "Loading" : "No Data"}
                                </p>
                              ) : (
                                searchData?.map((obj) => {
                                  return (
                                    <WinnerSelectionList
                                      obj={obj}
                                      declareOperations={declareOperations}
                                      removeWinners={removeWinners}
                                      selectedWinnersList={selectedWinnersList}
                                      handleShowPopup={handleShowPopup}
                                      singleContestData={singleContestData}
                                      status={status}
                                    />
                                  );
                                })
                              )}
                            </table>
                          </div>
                          <div className={styles.tropy} ref={selectedWinners}>
                            {/* <img src={Cup} className={styles.trophyPic} alt="" /> */}
                            <h3>Selected Winners</h3>
                          </div>
                          {selectedWinnersList?.map((obj, index) => {
                            return (
                              <div className={styles.choosed} key={obj?.id}>
                                <div
                                  className={styles.flexme}
                                  style={{ gap: "8px" }}
                                >
                                  <p>{obj?.position}</p>
                                  <img
                                    src={Star}
                                    className={styles.proPikIfNo}
                                    alt=""
                                  />
                                  <p>{obj?.id}</p>
                                </div>
                                <div
                                  className={styles.flexme}
                                  style={{ gap: "8px" }}
                                  onClick={() => {
                                    removeWinners(obj);
                                  }}
                                >
                                  <p className={styles.rmv}>Remove</p>
                                  <img
                                    src={Remove}
                                    className={styles.rmvC}
                                    alt=""
                                  />
                                </div>
                              </div>
                            );
                          })}

                          {selectedWinnersList?.length === 0 && (
                            <div className="mx-auto w-full flex items-center flex-col gap-[20px] my-[30px]">
                              <p className={styles.result}>
                                Add winners and click declare winners
                              </p>{" "}
                              <p className={styles.result}>or</p>
                              <p className={styles.result}>
                                Enter a text and click declare winners if there
                                is no winners
                              </p>
                              <input
                                type="text"
                                onChange={(e) =>
                                  setNotWinnerText(e.target.value)
                                }
                                className={styles.input}
                                placeholder="Enter a message why there is no winners"
                                value={noWinnerText}
                              />
                            </div>
                          )}

                          {selectedWinnersList?.length === 0 &&
                            !noWinnerText && (
                              <div
                                className={styles.declwine}
                                style={{ opacity: "0.2" }}
                              >
                                <p>Declare winners</p>
                              </div>
                            )}
                          {((selectedWinnersList?.length !== 0 &&
                            _.isEmpty(errorValue)) ||
                            noWinnerText) && (
                            <button
                              className={styles.declbutn}
                              onClick={handleDeclareWinner}
                              disabled={loadingWinner}
                            >
                              <p>
                                {loadingWinner ? "Loading" : "Declare Winners"}
                              </p>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <SelectedWinners
                          data={singleContestData?.crowdWinners}
                          type="Crowd Winners"
                        />

                        {edit == status ? (
                          <>
                            <input
                              type="text"
                              onChange={(e) => setNotWinnerText(e.target.value)}
                              className={styles.input}
                              placeholder="Enter a message why there is no winners"
                              value={noWinnerText}
                            />
                            <button
                              className={styles.declbutn}
                              onClick={handleDeclareWinner}
                              disabled={loadingWinner}
                            >
                              <p>{loadingWinner ? "Loading" : "Save"}</p>
                            </button>
                          </>
                        ) : (
                          <>
                            <p>
                              {singleContestData?.crowdWinnerNotSelectReason}
                            </p>
                            <button
                              className={styles.declbutn}
                              onClick={() =>
                                handleEdit(
                                  singleContestData?.crowdWinnerNotSelectReason
                                )
                              }
                            >
                              <p>Edit</p>
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {status == "judgeWinner" && (
                  <>
                    {!singleContestData?.judgeWinnerSelected ? (
                      <div className={styles.winnerSelection}>
                        <div className={styles.outOfbOxc}>
                          <div>
                            <h3>Filter Contestants</h3>
                            <p className={styles.inTxt}>
                              Filter contestants using diffrent criterias
                            </p>
                            <div className="flexico mt-[20px] gap-[20px]">
                              <Filter
                                sorting={filter}
                                setSorting={setFilter}
                                options={filterJson.options}
                                setPageNumber={setSearchData}
                              />
                              {filter?.filter == "ALL_CUTOFF" && (
                                <input
                                  placeholder="Cut off mark"
                                  type="number"
                                  value={cutOffMark}
                                  onChange={(e) => {
                                    setError("");
                                    setCutOffMark(e.target.value);
                                  }}
                                  className={styles.search}
                                />
                              )}
                              {filter?.filter == "NAME_OR_ID" && (
                                <input
                                  placeholder="Search Id or Name"
                                  type="text"
                                  value={textValue}
                                  onChange={(e) => {
                                    setError("");
                                    setTextValue(e.target.value);
                                  }}
                                  className={styles.search}
                                />
                              )}
                              <button
                                className={styles.addme}
                                onClick={handleSearchEntries}
                                disabled={searchLoading}
                              >
                                <p>{searchLoading ? "Searching" : "Search"}</p>
                              </button>
                            </div>
                            {errorValue && <p>{errorValue}</p>}
                            {searchData && (
                              <p
                                className="text-right w-full underline text-[#3075FC] mt-[15px]"
                                onClick={() => setSearchData("")}
                              >
                                Clear Data
                              </p>
                            )}
                            <table className=" border-collapse    border border-[#E5EAFF]  w-[100%] min-w-full mt-[15px]">
                              {_.isEmpty(searchData) ? (
                                <p className="w-full p-[20px] flex items-center justify-center">
                                  {searchLoading ? "Loading" : "No Data"}
                                </p>
                              ) : (
                                searchData?.map((obj) => {
                                  return (
                                    <WinnerSelectionList
                                      obj={obj}
                                      declareOperations={declareOperations}
                                      removeWinners={removeWinners}
                                      selectedWinnersList={selectedWinnersList}
                                      handleShowPopup={handleShowPopup}
                                      singleContestData={singleContestData}
                                      status={status}
                                    />
                                  );
                                })
                              )}
                            </table>
                          </div>

                          <div className={styles.tropy} ref={selectedWinners}>
                            {/* <img src={Cup} className={styles.trophyPic} alt="" /> */}
                            <h3>Selected Winners</h3>
                          </div>
                          {selectedWinnersList?.map((obj) => {
                            return (
                              <>
                                <div className={styles.choosed}>
                                  <div
                                    className={styles.flexme}
                                    style={{ gap: "8px" }}
                                  >
                                    <p>{obj?.position}</p>
                                    <img
                                      src={Star}
                                      className={styles.proPikIfNo}
                                      alt=""
                                    />
                                    <p>{obj?.id}</p>
                                  </div>
                                  <div
                                    className={styles.flexme}
                                    style={{ gap: "8px" }}
                                    onClick={() => {
                                      removeWinners(obj);
                                    }}
                                  >
                                    <p className={styles.rmv}>Remove</p>
                                    <img
                                      src={Remove}
                                      className={styles.rmvC}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          {selectedWinnersList?.length === 0 && (
                            <div className="mx-auto w-full flex items-center flex-col gap-[20px] my-[30px]">
                              <p className={styles.result}>
                                Add winners and click declare winners
                              </p>{" "}
                              <p className={styles.result}>or</p>
                              <p className={styles.result}>
                                Enter a text and click declare winners if there
                                is no winners
                              </p>
                              <input
                                type="text"
                                onChange={(e) =>
                                  setNotWinnerText(e.target.value)
                                }
                                className={styles.input}
                                placeholder="Enter a message why there is no winners"
                                value={noWinnerText}
                              />
                            </div>
                          )}

                          {selectedWinnersList?.length === 0 &&
                            !noWinnerText && (
                              <div
                                className={styles.declwine}
                                style={{ opacity: "0.2" }}
                              >
                                <p>Declare winners</p>
                              </div>
                            )}
                          {((selectedWinnersList?.length !== 0 &&
                            _.isEmpty(errorValue)) ||
                            noWinnerText) && (
                            <button
                              className={styles.declbutn}
                              onClick={handleDeclareWinner}
                              disabled={loadingWinner}
                            >
                              <p>
                                {loadingWinner ? "Loading" : "Declare Winners"}
                              </p>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <SelectedWinners
                          data={singleContestData?.judgeWinners}
                          type="Judge Winners"
                        />
                        {edit == status ? (
                          <>
                            <input
                              type="text"
                              onChange={(e) => setNotWinnerText(e.target.value)}
                              className={styles.input}
                              placeholder="Enter a message why there is no winners"
                              value={noWinnerText}
                            />
                            <button
                              className={styles.declbutn}
                              onClick={handleDeclareWinner}
                              disabled={loadingWinner}
                            >
                              <p>{loadingWinner ? "Loading" : "Save"}</p>
                            </button>
                          </>
                        ) : (
                          <>
                            <p>
                              {singleContestData?.judgeWinnerNotSelectReason}
                            </p>

                            <button
                              className={styles.declbutn}
                              onClick={() =>
                                handleEdit(
                                  singleContestData?.judgeWinnerNotSelectReason
                                )
                              }
                            >
                              <p>Edit</p>
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {status == "specialWinner" && (
                  <>
                    {!singleContestData?.specialWinnerSelected ? (
                      <div className={styles.winnerSelection}>
                        <div className={styles.outOfbOxc}>
                          <div>
                            <h3>Declare the Winners</h3>
                            <p className={styles.inTxt}>
                              Search using name or id and Select Winner
                            </p>
                            <div className="flexico mt-[20px]">
                              <input
                                placeholder="Search Id or Name"
                                type="text"
                                value={textValue}
                                onChange={(e) => {
                                  setError("");
                                  setTextValue(e.target.value);
                                }}
                                className={styles.search}
                              />
                              <button
                                className={styles.addme}
                                onClick={() => {
                                  searchEntries(textValue);
                                }}
                                disabled={searchLoading}
                              >
                                <p>{searchLoading ? "Searching" : "Search"}</p>
                              </button>
                            </div>
                            {errorValue && <p>{errorValue}</p>}
                            <table className=" border-collapse    border border-[#E5EAFF]  w-[100%] min-w-full mt-[10px]">
                              {_.isEmpty(searchData) ? (
                                <p className="w-full p-[20px] flex items-center justify-center">
                                  {searchLoading ? "Loading" : "No Data"}
                                </p>
                              ) : (
                                searchData?.map((obj) => {
                                  return (
                                    <WinnerSelectionList
                                      obj={obj}
                                      declareOperations={declareOperations}
                                      removeWinners={removeWinners}
                                      selectedWinnersList={selectedWinnersList}
                                      handleShowPopup={handleShowPopup}
                                      singleContestData={singleContestData}
                                      status={status}
                                    />
                                  );
                                })
                              )}
                            </table>
                          </div>
                          <div className={styles.tropy} ref={selectedWinners}>
                            {/* <img src={Cup} className={styles.trophyPic} alt="" /> */}
                            <h3>Selected Winners</h3>
                          </div>
                          {selectedWinnersList?.map((obj) => {
                            return (
                              <>
                                <div className={styles.choosed}>
                                  <div
                                    className={styles.flexme}
                                    style={{ gap: "8px" }}
                                  >
                                    <p>{obj?.position}</p>
                                    <img
                                      src={Star}
                                      className={styles.proPikIfNo}
                                      alt=""
                                    />
                                    <p>{obj?.id}</p>
                                  </div>
                                  <div
                                    className={styles.flexme}
                                    style={{ gap: "8px" }}
                                    onClick={() => {
                                      removeWinners(obj);
                                    }}
                                  >
                                    <p className={styles.rmv}>Remove</p>
                                    <img
                                      src={Remove}
                                      className={styles.rmvC}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          {selectedWinnersList?.length === 0 && (
                            <div className="mx-auto w-full flex items-center flex-col gap-[20px] my-[30px]">
                              <p className={styles.result}>
                                Add winners and click declare winners
                              </p>{" "}
                              <p className={styles.result}>or</p>
                              <p className={styles.result}>
                                Enter a text and click declare winners if there
                                is no winners
                              </p>
                              <input
                                type="text"
                                onChange={(e) =>
                                  setNotWinnerText(e.target.value)
                                }
                                className={styles.input}
                                placeholder="Enter a message why there is no winners"
                                value={noWinnerText}
                              />
                            </div>
                          )}

                          {selectedWinnersList?.length === 0 &&
                            !noWinnerText && (
                              <div
                                className={styles.declwine}
                                style={{ opacity: "0.2" }}
                              >
                                <p>Declare winners</p>
                              </div>
                            )}
                          {((selectedWinnersList?.length !== 0 &&
                            _.isEmpty(errorValue)) ||
                            noWinnerText) && (
                            <button
                              className={styles.declbutn}
                              onClick={handleDeclareWinner}
                              disabled={loadingWinner}
                            >
                              <p>
                                {loadingWinner ? "Loading" : "Declare Winners"}
                              </p>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <SelectedWinners
                          data={singleContestData?.specialWinners}
                          type="Special Winners"
                        />
                        {edit == status ? (
                          <>
                            <input
                              type="text"
                              onChange={(e) => setNotWinnerText(e.target.value)}
                              className={styles.input}
                              placeholder="Enter a message why there is no winners"
                              value={noWinnerText}
                            />
                            <button
                              className={styles.declbutn}
                              onClick={handleDeclareWinner}
                              disabled={loadingWinner}
                            >
                              <p>{loadingWinner ? "Loading" : "Save"}</p>
                            </button>
                          </>
                        ) : (
                          <>
                            <p>
                              {singleContestData?.specialWinnerNotSelectReason}
                            </p>

                            <button
                              className={styles.declbutn}
                              onClick={() =>
                                handleEdit(
                                  singleContestData?.specialWinnerNotSelectReason
                                )
                              }
                            >
                              <p>Edit</p>
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {status == "lucky_draw" && (
                  <>
                    {!singleContestData?.crowdWinnerSelected ? (
                      <div className={styles.winnerSelection}>
                        <div className="mt-[45px]">
                          <ExportCSV
                            winnersPrepared={winnersPrepared}
                            fileName={"participantDetails"}
                            found={singleContestData}
                            winnersUrl={winnersUrl}
                          />
                        </div>
                        <div className={styles.winBocx}>
                          <h3>Pick Winners</h3>
                          <p className={styles.inTxt}>
                            If you want automated winner selection, click the
                            Pick winners button.
                          </p>
                          <div className="flexico mt-[30px]">
                            <p
                              className={`  ${styles.inTxt} whitespace-nowrap`}
                            >
                              Number of winners
                            </p>
                            <input
                              placeholder="0"
                              type="number"
                              className={styles.winnerInput}
                              onChange={(e) =>
                                setRandomSelectionNmber(e.target.value)
                              }
                            />
                          </div>
                          <button
                            className={styles.winern}
                            onClick={randomSelection}
                            disabled={randomLoading}
                          >
                            <p>{randomLoading ? "Loading" : "Pick winners"}</p>
                          </button>
                        </div>
                        <div className={styles.outOfbOxc}>
                          <div className={styles.tropy} ref={selectedWinners}>
                            <h3>Selected Winners</h3>
                          </div>
                          {selectedWinnersList?.map((obj) => {
                            return (
                              <>
                                <div className={styles.choosed}>
                                  <div
                                    className={styles.flexme}
                                    style={{ gap: "8px" }}
                                  >
                                    <p>{obj?.position}</p>
                                    <img
                                      src={Star}
                                      className={styles.proPikIfNo}
                                      alt=""
                                    />
                                    <p>{obj?.id}</p>
                                  </div>
                                  <div
                                    className={styles.flexme}
                                    style={{ gap: "8px" }}
                                    onClick={() => {
                                      removeWinners(obj);
                                    }}
                                  >
                                    <p className={styles.rmv}>Remove</p>
                                    <img
                                      src={Remove}
                                      className={styles.rmvC}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          {selectedWinnersList?.length === 0 && (
                            <div className="mx-auto w-full flex items-center flex-col gap-[20px] my-[30px]">
                              <p className={styles.result}>
                                Add winners and click declare winners
                              </p>{" "}
                              <p className={styles.result}>or</p>
                              <p className={styles.result}>
                                Enter a text and click declare winners if there
                                is no winners
                              </p>
                              <input
                                type="text"
                                onChange={(e) =>
                                  setNotWinnerText(e.target.value)
                                }
                                className={styles.input}
                                placeholder="Enter a message why there is no winners"
                                value={noWinnerText}
                              />
                            </div>
                          )}

                          {selectedWinnersList?.length === 0 &&
                            !noWinnerText && (
                              <div
                                className={styles.declwine}
                                style={{ opacity: "0.2" }}
                              >
                                <p>Declare winners</p>
                              </div>
                            )}
                          {((selectedWinnersList?.length !== 0 &&
                            _.isEmpty(errorValue)) ||
                            noWinnerText) && (
                            <button
                              className={styles.declbutn}
                              onClick={handleDeclareWinner}
                              disabled={loadingWinner}
                            >
                              <p>
                                {loadingWinner ? "Loading" : "Declare Winners"}
                              </p>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <SelectedWinners
                          data={singleContestData?.crowdWinners}
                          type="Crowd Winners Using Lucky Draw"
                        />
                        <p>{singleContestData?.crowdWinnerNotSelectReason}</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className=" right-sidebar">
            <RightPanel
              cardTitile="contest details"
              title={singleContestData.contestName}
              type="Contest"
              description={singleContestData.description}
              rightPanelData={singleContestData}
              status={singleContestData.contestStatus}
              likeCount={singleContestData?.contestEntryLikeCount}
              viewCount={singleContestData?.contestEntryViewCount}
              userCount={singleContestData?.contestVisitCount}
              participantCounter={singleContestData?.participantCounter}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Luckydraw;
