import { useContext } from "react";
import Context from "../../Context/Context";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import Close from "../../Assets/close.svg";
import styles from "./PopUp.module.css";

const AlertPopup = ({
  visible,
  setpopUp,
  button1Function,
  button2Function,
  value,
  type,
  handleReasonChange,
}) => {
  const { modalRef } = useContext(Context);

  return (
    <PopupWrapper visible={visible} setpopUp={setpopUp} zIndex="100">
      <div className={styles.popUpContainer} ref={modalRef}>
        <div>
          <img
            src={Close}
            className="p-[20px] ml-auto cursor-pointer"
            onClick={() => setpopUp(false)}
          />
        </div>
        <div className={styles.headerContainer}>
          <img src={value.Icon} alt="" className={styles.popUpImg} />
          <div>
            <p className={styles.popUpHeader}>{value.Heading}</p>
            <p className={styles.headerPara}>{value.Para}</p>
          </div>
        </div>
        {type == "reason" && (
          <div className="px-[24px]">
            <textarea
              type="text"
              placeholder="Could you kindly share the reason"
              onChange={handleReasonChange}
              className={styles.textarea}
            />
          </div>
        )}
        <div className={styles.buttonContainer}>
          {value?.Button1 && (
            <button
              className={styles.button}
              onClick={button1Function}
              style={{ background: `${value.Button1Color}` }}
            >
              <span className={styles.buttonSpan}>{value?.Button1}</span>
            </button>
          )}
          {value?.Button2 && (
            <button
              className={styles.deleteButton}
              style={{ background: `${value.Button2Color}` }}
              onClick={button2Function}
            >
              <span className={styles.buttonSpanWhite}>{value?.Button2}</span>
            </button>
          )}
        </div>
      </div>
    </PopupWrapper>
  );
};

export default AlertPopup;
