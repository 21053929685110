import React from "react";
import { Link } from "react-router-dom";

export default function InfluencerHomePage() {
  return (
    <div className="p-[20px] flex flex-col gap-[10px]">
      <Link to="/inf/campaigns">
        <button className="commonButton">Campaigns</button>
      </Link>
      <Link to="/inf/leads/campaigns">
        <button className="commonButton">Campaign Leads</button>
      </Link>
      <Link to="/inf/leads/promotions">
        <button className="commonButton">Promotions Leads</button>
      </Link>
      <Link to="/inf/analytics/campaigns">
        <button className="commonButton">Campaign Analytics</button>
      </Link>
      <Link to="/inf/analytics/promotions">
        <button className="commonButton">Promotion Analytics</button>
      </Link>
      <Link to="/inf/brands">
        <button className="commonButton">Brands</button>
      </Link>
      <Link to="/inf/ads">
        <button className="commonButton">Advertisement</button>
      </Link>
    </div>
  );
}
