import { useState } from "react";
import styles from "./InfApproveTable.module.css";
import AcceptInfRequest from "../AcceptInfRequest/AcceptInfRequest";
export default function InfApproveTable({ obj, type }) {
  const [approveSponsor, setApproveSponsor] = useState(false);
  return (
    <>
      {approveSponsor && (
        <AcceptInfRequest setPopup={setApproveSponsor} data={obj} type={type} />
      )}
      <tbody
        className={styles.sponsorTable}
        key={obj._id}
        onClick={() => setApproveSponsor(true)}
      >
        <tr className={styles.tableBottomLine}>
          <td className=" break-all p-[5px]">
            <div className="flex items-center ">
              <div className={styles.nameDp}>
                <img
                  src={obj?.dop}
                  alt=""
                  className="h-[40px] w-[40px] rounded-full overflow-hidden  object-cover"
                />
              </div>
              <p>{obj?.promotorName}</p>
            </div>
          </td>

          <td className=" break-all p-[5px]">
            <div className="flexio">
              <p>{obj?.about} </p>
            </div>
          </td>
          <td className=" break-all p-[5px]">
            <div className="flexio">
              <p>{obj?.campaignName} </p>
            </div>
          </td>
          <td className=" p-[5px]">
            <div className="flexio">
              <p>{obj?.email} </p>
            </div>
          </td>
          <td className=" p-[5px]">
            <div className="flexio">
              <p>{obj?.phone} </p>
            </div>
          </td>

          {/* <td className=" break-all p-[5px] cursor-pointer relative">
      <AllEntryMoreOption
        data={obj}
        setShowLikes={setShowLikes}
        setpopUp={setpopUp}
        setpopUpData={setpopUpData}
        setShowPrizeClaimDetails={setShowPrizeClaimDetails}
        setShowFeatureDetails={setShowFeatureDetails}
        setSendEmail={setSendEmail}
      />
    </td> */}
        </tr>
      </tbody>
    </>
  );
}
