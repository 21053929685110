import React, { useEffect, useState } from "react";
import styles from "./ForgotPasswordPopup.module.css";
import LoadingFromCenter from "../LoadingFromCenter/LoadingFromCenter";
import singinCloseButton from "../../Assets/singinCloseButton.svg";
import { useUserAuth } from "../../Context/UserAuthContext";


const ForgotPasswordPopup = ({ setPopup }) => {
  const [email, setEmail] = useState("");
  const [mailSented, setMailSented] = useState(false);
  const { pageLoading, emailPasswordReset, setEmailError, emailError } =
    useUserAuth();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setEmailError("");
    if (!email) {
      setEmailError("Enter Email address");
      return;
    }
    const response = await emailPasswordReset(email);
    setMailSented(response);
  };
  const handleInputChange = (value) => {
    setEmail(value);
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.container}>
        <div className="w-[100%] flex justify-end p-[10px] cursor-pointer">
          <img
            src={singinCloseButton}
            onClick={() => {
              setPopup(false);
            }}
          />
        </div>
        {mailSented ? (
          <>
            <p className="text-regular-medium px-[20px] mb-[10px]">
              Email has been sent
            </p>
            <p className="text-small text-[#5f5f5f] px-[20px] mb-[16px]">
              Click link shared though email to reset your password and retry
              login.
            </p>
          </>
        ) : (
          <>
            <p className="text-regular-medium px-[20px] mb-[10px]">
              Password Reset
            </p>
            <p className="text-small text-[#5f5f5f] px-[20px] mb-[16px]">
              Enter your email and we will send you a link to reset your
              password.
            </p>
            <div className="flex flex-col gap-[10px] text-small w-full">
              <input
                type="text"
                placeholder="Enter Email address"
                value={email}
                onChange={(e) => handleInputChange(e.target.value)}
                className={styles.input}
              />
            </div>
            {emailError && (
              <div className="text-small text-left w-[100%] mb-[12px]">
                <span className="text-red-600">*</span>
                {emailError}
              </div>
            )}
            {pageLoading && <LoadingFromCenter />}

            <div className="seperator my-[20px]" />

            <div className="flex flex gap-[16px] w-[100%] cursor-pointer">
              <button
                className={`text-regular-medium bg-[#00A989] ${styles.button}`}
                onClick={handleResetPassword}
              >
                Send
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPopup;
