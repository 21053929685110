import React, { useContext, useEffect, useState } from "react";
import Context from "../../Context/Context";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";
import styles from "./CertificatePage.module.css";
import CreateCertificate from "../../Components/CreateCertificate/CreateCertificate";
import Pagination from "../../Components/Pagination/Pagination";

export default function CertificatePage() {
  const { organizationId, token, setUpdateData, updateData } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { eventId } = useParams();
  const [addNew, setAddNew] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLeads, setTotalLeads] = useState();

  useEffect(() => {
    if (organizationId) {
      setData([]);
      getEvents();
    }
  }, [organizationId, updateData, pageNumber]);
  const getEvents = async () => {
    setLoading(true);
    try {
      let config1 = {
        method: "get",
        url: `${process.env.REACT_APP_LIST_CERTIFICATE}/${organizationId}/${eventId}?page=${pageNumber}&limit=10`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setTotalLeads(setApi?.data?.totalCount);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };
  const handleRefresh = () => {
    setData([]);
    setPageNumber(1);
    setUpdateData((prevState) => prevState + 1);
  };
  return (
    <div className="p-[20px]">
      {addNew && (
        <CreateCertificate showPopup={addNew} setShowPopup={setAddNew} />
      )}
      <div className="flex items-center w-[100%] mb-[30px]">
        <p className="fifaHead">Certificates</p>
        <div className="flex gap-4 items-center ml-[auto]">
          <button className={styles.button} onClick={() => setAddNew(true)}>
            Add New
          </button>
          <RefreshButton handleRefresh={handleRefresh} />
        </div>
      </div>
      <table className={styles.leadsTable}>
        <thead>
          <tr>
            <th>Sl no</th>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((obj, indexnumber) => {
            return (
              <tr>
                <td className={styles.slno}>{indexnumber + 1}</td>
                <td className={styles.name}>
                  {obj?.image && (
                    <img
                      src={obj?.image}
                      alt={obj?.name}
                      className="w-[52px] h-[52px] object-cover"
                    />
                  )}
                  {obj?.name}
                </td>
                <td className={styles.email}>
                  <p>{obj?.email}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && <LoadingAnimation />}
      <div className="py-[20px]">
        {totalLeads && (
          <Pagination
            totalData={totalLeads}
            pageLimit={10}
            setPageNumber={setPageNumber}
            currentPage={pageNumber}
          />
        )}
      </div>
    </div>
  );
}
