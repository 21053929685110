import React from "react";
import { useState } from "react";
import styles from "./MoreOptionDropDown.module.css";
import Union from "../../../src/Assets/Union.svg";
import ShareTube from "../../../src/Assets/sharetube.svg";
import { useRef } from "react";
import { useEffect } from "react";

function ContestMoreDropdown({
  data,
  setClickedContestData,
  setShowEditContest,
  setEditContestData,
  setShowEditPosterContest,
  setShowEditQuizData,
  setShowDeleteContestPopup,
  setShowCloseContestPopup,
  setShowOpenContestPopup,
  setShowAddJudges,
  setShowAddPrize,
}) {
  const [dropDown, setDropDown] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropDown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown]);
  return (
    <div
      onClick={() => {
        setDropDown(!dropDown);
      }}
      className={styles.holoOption}
      ref={modalRef}
    >
      <img src={Union} alt="" />
      {dropDown === true && (
        <div className={styles.bigDropme}>
          <p>More Options</p>

          {data?.contestStatus == "OPEN" ? (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => {
                setClickedContestData(data);
                setShowCloseContestPopup(true);
              }}
            >
              <img src={ShareTube} alt="" />
              <p>Close Contest</p>
            </div>
          ) : (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => {
                setShowOpenContestPopup(true);
                setClickedContestData(data);
              }}
            >
              <img src={ShareTube} alt="" />
              <p>Open Contest</p>
            </div>
          )}
          {(data.campaignStatus === "UPCOMING" ||
            data.campaignStatus === "REQUEST_TO_PUBLISH") && (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => {
                setShowEditContest(true);
                setEditContestData(data);
              }}
            >
              <img src={ShareTube} alt="" />
              <p>Edit Contest</p>
            </div>
          )}
          {(data.campaignStatus === "UPCOMING" ||
            data.campaignStatus === "REQUEST_TO_PUBLISH") &&
            data?.isPosterContest && (
              <div
                className="flex gap-4 mt-[20px] mb-[24px]"
                onClick={() => {
                  setShowEditPosterContest(true);
                  setEditContestData(data);
                }}
              >
                <img src={ShareTube} alt="" />
                <p>Edit Poster Details</p>
              </div>
            )}
          {(data.campaignStatus === "UPCOMING" ||
            data.campaignStatus === "REQUEST_TO_PUBLISH") &&
            data?.contestType?.startsWith("QUIZ") && (
              <div
                className="flex gap-4 mt-[20px] mb-[24px]"
                onClick={() => {
                  setShowEditQuizData(true);
                  setEditContestData(data);
                }}
              >
                <img src={ShareTube} alt="" />
                <p>Edit Quiz Details</p>
              </div>
            )}
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddJudges(true);
              setEditContestData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p>Add Judges</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddPrize(true);
              setEditContestData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p>Prize Details</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContestMoreDropdown;
