import React, { useContext, useEffect, useState } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import styles from "./CampaignAccessPage.module.css";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import StatusChangerLoading from "../../Components/Loading/StatusChangerLoading";
import Plus from "../../Assets/plus.svg";
import GiveAccess from "../../Components/GiveAccess/GiveAccess";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";

export default function CampaignAccessPage() {
  const {
    orgDomain,
    verifyUser,
    userIdToken,
    orgDomainLoading,
    updateData,
    setUpdateData,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const campaignId = searchParams.get("cmpId");
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [showDropDown, setShowDropDown] = useState(false);
  const [accessPopup, setAccessPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editValues, setEditValues] = useState({});
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    if (!isEmpty(orgDomain)) {
      getCampaignDataForLeads();
    }
  }, [orgDomain]);
  useEffect(() => {
    if (!isEmpty(campaignData)) {
      getUserData(orgId, campaignId);
    }
  }, [updateData]);
  const getCampaignDataForLeads = async () => {
    setLoading(true);
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_DATA_FOR_LEADS}/${orgDomain}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data;
      setCampaignData(setApi?.data?.data);
      setLoading(false);

      let findCampaign;
      if (campaignId) {
        findCampaign = campgnData?.find((el) => el?._id == campaignId);
      }
      let filterCampaign = findCampaign || campgnData[0];
      getUserData(filterCampaign?.orgId, filterCampaign?.campaignId, false);
      setSelectedCampaign(filterCampaign);
      return campgnData;
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };
  const handleSearch = (obj) => {
    setSelectedCampaign(obj);
    setSearchParams({ orgId: obj?.orgId, cmpId: obj?._id });
    getUserData(obj?.orgId, obj?.campaignId);
  };
  const getUserData = async (orgId, cmpId) => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_AUTHORISED_USERS}/${orgId}/${cmpId}?type=CAMPAIGN`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      const response = setApi?.data?.data?.authorizedUsers;
      setData(response);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setData([]);
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    setEdit(false);
    setAccessPopup(true);
  };
  const handleViewUser = (obj) => {
    setEditValues(obj);
    setEdit(true);
    setAccessPopup(true);
  };
  return (
    <div>
      {accessPopup && (
        <GiveAccess
          setShowPopup={setAccessPopup}
          edit={edit}
          formValues={editValues}
        />
      )}
      <div className="px-[20px] py-[20px]">
        <Breadcrumb
          items={[
            { label: "Campaigns", url: "/campaigns" },
            {
              label: "Contests",
              url: "/campaigns/contests",
              params: {
                orgId: orgId,
                cmpId: campaignId,
              },
            },
            {
              label: "Campaign access",
              url: "/campaigns/access",
              params: {
                orgId: orgId,
                cmpId: campaignId,
              },
            },
          ]}
        />
      </div>

      {!orgDomainLoading && (
        <div className="flex items-center justify-between">
          <div className={styles.leadsHeading}>
            <div
              className={styles.leadsDropDown}
              onClick={() => {
                setShowDropDown((prevState) => !prevState);
              }}
            >
              <h2>{selectedCampaign?.campaignName}</h2>

              <img src={leadsDropDown} alt="" />
            </div>

            {showDropDown && (
              <div className={styles.dropDownPopup}>
                <h4>Campaigns</h4>
                {campaignData?.map((el, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleSearch(el);
                        setShowDropDown(false);
                      }}
                    >
                      {el?.campaignName}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.addNew} onClick={handleAddClick}>
            <img src={Plus} alt="" />
            <p className={styles.new}>Add New</p>
          </div>
        </div>
      )}
      {loading && <StatusChangerLoading />}
      {!isEmpty(data) ? (
        <table className={styles.leadsTable}>
          <thead>
            <tr>
              <th>Sl no</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.values(data)?.map((obj, indexnumber) => {
              return (
                <tr>
                  <td className={styles.slno}>{indexnumber + 1}</td>
                  <td className={styles.name}>{obj?.userName || obj?.name}</td>
                  <td className={styles.email}>
                    <p>{obj?.userEmail || obj?.email}</p>
                  </td>
                  <td className={styles.requirment}>{obj?.orgRole}</td>
                  <td
                    onClick={() => {
                      handleViewUser(obj);
                    }}
                  >
                    <button>View</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="flex items-center justify-center w-[100%] h-[100px]">
          No Data
        </div>
      )}
    </div>
  );
}
