import React, { useEffect, useState } from "react";
import styles from "./CreationSuccessCard.module.css";
import tempPoster from "../../Assets/signupBanner.jpg";
import creationIcon from "../../Assets/creationReview.svg";

export default function CreationSuccessCard() {
  const [camapignDetails, setCampaignDetails] = useState({});
  useEffect(() => {
    let value = JSON.parse(localStorage?.getItem("campaignDetils"));
    setCampaignDetails(value);
  }, []);
  return (
    <div className={styles.campaignCard}>
      <div className="h-auto w-[100%] md:w-[119px] min-h-[148px] flex flex-col md:flex-row md:basis-1/4 overflow-hidden">
        <img
          src={tempPoster}
          alt=""
          className="h-full w-full object-cover rounded-t-[16px] md:rounded-l-[16px] "
        />
      </div>
      <div className="min-h-auto w-[100%] py-[25px] pl-[16px] pr-[37px] flex flex-col justify-between gap-[10px] md:basis-3/4">
        <div className="flex flex-col gap-[10px]">
          <p className="text-largest-clash text-black">
            {camapignDetails?.campaignName}
          </p>
          <p className="text-regular-medium text-[#787878] text_4Line">
            {camapignDetails?.campaignDescription}
          </p>
        </div>
        <div className="flex gap-[10px] items-center">
          <img src={creationIcon} />
          <p className="text-regular-bold text-[#4E04C6]">
            Campaign is under review
          </p>
        </div>
      </div>
    </div>
  );
}
