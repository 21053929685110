import React from "react";
import styles from "./InfluencerCampaigns.module.css";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import { isEmpty } from "lodash";
import CampaignAdsInf from "../../Components/CampaignAdsInf/CampaignAdsInf";
import ShareCard from "../../Components/PollContest/ShareCard";

export default function InfluencerCampaigns() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAds, setShowAds] = useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [share, setShare] = useState(false);
  const { userIdToken, updateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [status, setStatus] = useState("APPROVED");

  useEffect(() => {
    getData();
  }, [updateData, status]);

  const getData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_REQUESTED_CAMPAIGNS}?type=CAMPAIGNS&status=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleViewAds = (e, obj) => {
    e.stopPropagation();
    setCampaignData(obj);
    setShowAds(true);
  };
  const handleShare = (e, obj) => {
    e.stopPropagation();
    setShare(true);
    setCampaignData(obj);
  };
  return (
    <div className="p-[20px]">
      {share && (
        <ShareCard
          setShare={setShare}
          campaignContestsDatas={data}
          link={campaignData?.aml}
        />
      )}

      {showAds && (
        <CampaignAdsInf
          setShowAddAds={setShowAds}
          editCampaignData={campaignData}
        />
      )}
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Campaigns</p>
      </div>
      <div className={styles.filterChips}>
        <div
          onClick={() => {
            setData([]);
            setStatus("APPROVED");
          }}
          className={
            status == "APPROVED" ? styles.eachChips : styles.eachChipsDark
          }
        >
          <p>Approved</p>
        </div>
        <div
          onClick={() => {
            setData([]);
            setStatus("REQUESTED");
          }}
          className={
            status == "REQUESTED" ? styles.eachChips : styles.eachChipsDark
          }
        >
          <p>Requested</p>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : isEmpty(data) ? (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        ) : (
          <div className={styles.promotionListGrid}>
            {data?.map((obj) => {
              return (
                <div className={styles.entry}>
                  <img src={obj?.coverImage} />
                  <p>{obj?.campaignName}</p>
                  <div className="p-[10px] flex flex-col gap-[5px]">
                    {status == "APPROVED" && (
                      <div className="flex items-center gap-[5px]">
                        <button
                          className={styles.editButton}
                          onClick={(e) => handleViewAds(e, obj)}
                        >
                          Ads
                        </button>
                        <button
                          className={styles.editButton}
                          onClick={(e) => handleShare(e, obj)}
                        >
                          Share
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
