import React from "react";
import styles from "./styles/Insight.module.css";
import Green from "../../Assets/Green.svg";
import BlueUp from "../../Assets/BlueUp.svg";
import Red from "../../Assets/Red.svg";
import DarkGreen from "../../Assets/DarkGreen.svg";
import { isEmpty } from "lodash";

function Insight({
  displayOn,
  userCount,
  likeCount,
  viewCount,
  participantCounter,
  type,
  participantsJoined,
}) {
  return (
    <div>
      <p className="my-[14px] font-medium text-[16px] text-black">
        {type} Insight
      </p>
      <div
        className={
          displayOn == "right"
            ? `${styles.griding}`
            : `${styles.fourColumnGrid}`
        }
      >
        <div className={styles.oneGrid}>
          <p className={styles.oneGridHead}>User Engagement</p>
          <div className="flex gap-3.5 justify-center items-center mt[8px]">
            <p className="text-black text-[24px] font-semibold leading-9">
              {userCount ? userCount : 0}
            </p>
          </div>
        </div>
        <div className={styles.oneGrid}>
          <p className={styles.oneGridHead}>Entries</p>
          <div className="flex gap-3.5 justify-center items-center mt[8px]">
            <p className="text-black text-[24px] font-semibold leading-9">
              {participantCounter ? participantCounter : 0}
            </p>
          </div>
        </div>
        <div className={styles.oneGrid}>
          <p className={styles.oneGridHead}>Total likes</p>
          <div className="flex gap-3.5 justify-center items-center mt[8px]">
            <p className="text-black text-[24px] font-semibold leading-9">
              {likeCount ? likeCount : 0}
            </p>
          </div>
        </div>

        <div className={styles.oneGrid}>
          <p className={styles.oneGridHead}>Total views</p>
          <div className="flex gap-3.5 justify-center items-center mt[8px]">
            <p className="text-black text-[24px] font-semibold leading-9">
              {viewCount ? viewCount : 0}
            </p>
          </div>
        </div>
        {participantsJoined != 0 && participantsJoined && (
          <div className={styles.oneGrid}>
            <p className={styles.oneGridHead}>Participants</p>
            <div className="flex gap-3.5 justify-center items-center mt[8px]">
              <p className="text-black text-[24px] font-semibold leading-9">
                {participantsJoined}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Insight;
