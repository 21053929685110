import React, { useContext, useEffect, useState } from "react";
import Context from "../../Context/Context";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import InfProfileDetails from "../../Components/InfProfileDetails/InfProfileDetails";
import {
  singleFileUpload,
  singleFileUploadInf,
} from "../../utils/SingleFileUpload";
import OrgProfileDetails from "../../Components/OrgProfileDetails/OrgProfileDetails";
import SponsorProfileDetails from "../../Components/SponsorProfileDetails/SponsorProfileDetails";

export default function ProfilePage() {
  const [isSponsor, setIsSponsor] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [isOrganization, setIsOrganization] = useState(false);
  const [infDetails, setInfDetils] = useState({});
  const [orgDetails, setOrgDetils] = useState({});
  const [sponsorDetails, setSponsorDetils] = useState({});
  const [infLoading, setInfLoading] = useState(false);
  const [orgLoading, setOrgLoading] = useState(false);
  const [spLoading, setSpLoading] = useState(false);
  const { dashboardRoles, userIdToken, organizationId } = useContext(Context);
  const { currentUser, userId } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    if (dashboardRoles?.includes("ORGANIZATION")) {
      getOrgDetails();
      setIsOrganization(true);
    }
    if (dashboardRoles?.includes("INFLUENCER")) {
      setIsInfluencer(true);
      getInfDetails();
    }
    if (dashboardRoles?.includes("SPONSER")) {
      setIsSponsor(true);
      getSpDetails();
    }
  }, [dashboardRoles]);

  const getInfDetails = async () => {
    try {
      setInfLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INF_PROFILE}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      let response = await axios(config);
      let { name, dop, email, phone, about } = response?.data?.data;
      setInfDetils({ name, dop, email, phone, about });
      setInfLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getOrgDetails = async () => {
    try {
      setOrgLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ORG_PROFILE}/${organizationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      let response = await axios(config);
      let { organizationName, logo, email, phone, description } =
        response?.data?.data;
      setOrgDetils({ organizationName, logo, email, phone, description });
      setOrgLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getSpDetails = async () => {
    try {
      setSpLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SP_PROFILE}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      let response = await axios(config);
      let { name, logo, email, phone, description } = response?.data?.data;
      setSponsorDetils({ name, spLogo: logo, logo, email, phone, description });
      setSpLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChange = async (event) => {
    const toEdit = event.target.dataset.customInfo;
    console.log("toEdit", toEdit);
    const key = event.target.id;
    const value = event.target.value;
    if (toEdit == "inf") {
      if (key == "dop") {
        event.preventDefault();
        const thumbnail = new FormData();
        const imagedata = event.target.files[0];
        let url = URL.createObjectURL(imagedata);
        thumbnail.append("image", imagedata);
        setInfLoading(true);
        let uploadPath = `/${userId}/`;
        let uploadFile = await singleFileUploadInf(imagedata, uploadPath);
        setInfDetils((prevState) => ({
          ...prevState,
          dop: uploadFile,
        }));
        setInfLoading(false);
      } else {
        setInfDetils((prevState) => ({ ...prevState, [key]: value }));
      }
    } else if (toEdit == "org") {
      if (key == "logo") {
        event.preventDefault();
        const thumbnail = new FormData();
        const imagedata = event.target.files[0];
        let url = URL.createObjectURL(imagedata);
        thumbnail.append("image", imagedata);
        setOrgLoading(true);
        let uploadPath = `/${organizationId}/`;
        let uploadFile = await singleFileUpload(imagedata, uploadPath);
        setOrgDetils((prevState) => ({
          ...prevState,
          logo: uploadFile,
        }));
        setOrgLoading(false);
      } else {
        setOrgDetils((prevState) => ({ ...prevState, [key]: value }));
      }
    } else if (toEdit == "sp") {
      if (key == "spLogo") {
        event.preventDefault();
        const thumbnail = new FormData();
        const imagedata = event.target.files[0];
        let url = URL.createObjectURL(imagedata);
        thumbnail.append("image", imagedata);
        setSpLoading(true);
        let uploadPath = `/${userId}/`;
        let uploadFile = await singleFileUploadInf(imagedata, uploadPath);
        setSponsorDetils((prevState) => ({
          ...prevState,
          logo: uploadFile,
          spLogo: uploadFile,
        }));
        setSpLoading(false);
      } else {
        setSponsorDetils((prevState) => ({ ...prevState, [key]: value }));
      }
    }
  };

  const handleChooseFile = (id) => {
    const divElement = document.getElementById(id);
    if (divElement) {
      divElement.click();
    }
  };

  return (
    <div className="p-[20px]">
      <p className="fifaHead">Profile</p>
      <div className="flex flex-col gap-[50px]">
        <div>
          {orgLoading ? (
            <LoadingAnimation />
          ) : (
            !isEmpty(orgDetails) && (
              <OrgProfileDetails
                data={orgDetails}
                handleChange={handleChange}
                handleChooseFile={handleChooseFile}
              />
            )
          )}
        </div>
        <div>
          {infLoading ? (
            <LoadingAnimation />
          ) : (
            !isEmpty(infDetails) && (
              <InfProfileDetails
                data={infDetails}
                handleChange={handleChange}
                handleChooseFile={handleChooseFile}
              />
            )
          )}
        </div>
        <div>
          {spLoading ? (
            <LoadingAnimation />
          ) : (
            !isEmpty(sponsorDetails) && (
              <SponsorProfileDetails
                data={sponsorDetails}
                handleChange={handleChange}
                handleChooseFile={handleChooseFile}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
