import React from "react";
import { createSearchParams, Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  return (
    <>
      <nav className="flex rounded-[8px]" aria-label="Breadcrumb ">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse rounded-[8px]">
          {items?.map((item, index) => (
            <li key={index} className="border-none p-0">
              <div className="flex items-center">
                {index !== 0 && (
                  <svg
                    className="rtl:rotate-180 w-3 h-3 text-white-400 mx-1 rounded-[8px]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                )}
                {item.url ? (
                  <Link
                    to={{
                      pathname: item.url,
                      search: `?${createSearchParams(item?.params)}`,
                    }}
                    className="ms-1 text-sm font-medium  text-white-700 hover:text-blue-600 md:ms-2 rounded-[8px]"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <span className="ms-1 text-sm font-medium  text-white-500 md:ms-2 dark:text-white-400 rounded-[8px]">
                    {item.label}
                  </span>
                )}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;
