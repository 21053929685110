import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styles from "./MoreOptionDropDown.module.css";
import Union from "../../../src/Assets/Union.svg";
import ShareTube from "../../../src/Assets/sharetube.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";

function MoreOptionDropDown({
  handleOpenCampaignClick,
  handleCloseCampaignClick,
  data,
  setShowEditCampaign,
  setEditCampaignData,
  setShowDeleteCampaignPopup,
  setdeleteCampaignData,
  setShowAddAds,
  setShowAddCoupons,
  setShowAddSponsors,
  setShowAddJudges,
  setShowAddOrganizers,
  setShowAddSpecialGuests,
  handleCloseEditReq,
  setShowAddSupportedBy,
}) {
  const [dropDown, setDropDown] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate();
  const { organizationId } = useContext(Context);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropDown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown]);
  const handleCreatePromotion = (obj) => {
    const params = {
      campaignId: obj._id,
    };
    navigate({
      pathname: `/promotions/create`,
      search: `?${createSearchParams(params)}`,
    });
  };
  const navigateToRequsts = (editCampaignData) => {
    navigate(
      `/influencers/requests?orgId=${organizationId}&cmpId=${editCampaignData?._id}`
    );
  };
  return (
    <div
      onClick={() => {
        setDropDown(!dropDown);
      }}
      className={styles.holoOption}
      ref={modalRef}
    >
      <img src={Union} alt="" />
      {dropDown === true && (
        <div className={styles.bigDropme}>
          <p>More Options</p>
          {(data.campaignStatus === "UPCOMING" ||
            data.campaignStatus === "REQUEST_TO_PUBLISH") && (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => {
                setShowEditCampaign(true);
                setEditCampaignData(data);
              }}
            >
              <img src={ShareTube} alt="" />
              <p>Edit Campaign</p>
            </div>
          )}
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              window.open(
                `https://app.goviral.world/${data?.orgDomain}/${data?.campaignUniqueText}?preview=true`
              );
            }}
          >
            <img src={ShareTube} alt="" />
            <p>View Preview</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddAds(true);
              setEditCampaignData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p> Ads</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddCoupons(true);
              setEditCampaignData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p> Coupons</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              handleCreatePromotion(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p>Create Promotion</p>
          </div>

          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddJudges(true);
              setEditCampaignData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p> Judges</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddSponsors(true);
              setEditCampaignData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p> Sponsors</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddSupportedBy(true);
              setEditCampaignData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p> Supported By</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => navigateToRequsts(data)}
          >
            <img src={ShareTube} alt="" />
            <p>Influencers</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddOrganizers(true);
              setEditCampaignData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p> Organizers</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setShowAddSpecialGuests(true);
              setEditCampaignData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            <p> Special Guests</p>
          </div>
          {data?.campaignStatus == "OPEN" ? (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => handleCloseCampaignClick(data)}
            >
              <img src={ShareTube} alt="" />
              <p>Close Campaign</p>
            </div>
          ) : (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => handleOpenCampaignClick(data)}
            >
              <img src={ShareTube} alt="" />
              <p>Open Campaign</p>
            </div>
          )}
          {data?.campaignStatus == "OPEN" && (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => {
                handleCloseEditReq();
                setEditCampaignData(data);
              }}
            >
              <img src={ShareTube} alt="" />
              <p>Request Editing</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MoreOptionDropDown;
