import React, { useContext, useEffect, useState } from "react";
import Close from "../../Assets/close.svg";
import styles from "./QuizContest.module.css";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import publishIcon from "../../Assets/AlertPopup/publishIcon.svg";
import Context from "../../Context/Context";
import AlertPopup from "../AlertPopups/AlertPopup";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import AddQuizQuestions from "./AddQuizQuestions";
import { isEmpty } from "lodash";
import DynamicInput from "../DynamicInput/DynamicInput";

export default function QuizContest({ showEdit, setShowEdit, contestData }) {
  const [savedChanges, setSavedChanges] = useState(false);
  const [prevQuestions, setPrevQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useUserAuth();
  const [updateDataLocal, setUpdateDataLocal] = useState(1);
  const { modalRef, userIdToken, setUpdateData } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [questions, setQuestions] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);
  const [otherData, setOtherData] = useState({
    timeLimit: contestData?.timeLimit,
    shuffleCount: contestData?.shuffleCount,
  });
  useEffect(() => {
    getQuestions();
  }, [updateDataLocal]);
  const getQuestions = async () => {
    try {
      setLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_QUIZ_QUESTIONS}/${contestData?.orgId}/${contestData?.campaignId}/${contestData?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setPrevQuestions(response?.data?.data?.questions || []);
      setLoading(false);
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };
  const handleSaveAndPublish = async () => {
    await handleSubmit();
    handlePublish();
  };
  const handleSubmit = async () => {
    if (!isEmpty(deleteIds)) {
      await handleDeleteQuestions();
    }
    await handleSave();
  };
  const handleDeleteQuestions = async () => {
    try {
      setLoading(true);
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_DELETE_QUIZ_QUESTIONS}/${contestData?.orgId}/${contestData?.campaignId}/${contestData?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { questionIds: deleteIds },
      };
      const response = await axios(config);
      setDeleteIds([]);
      if (isEmpty(questions)) {
        setSavedChanges(true);
        setLoading(false);
        setUpdateDataLocal((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };
  const handleSave = async () => {
    try {
      setLoading(true);

      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_QUIZ_QUESTIONS}/${contestData?.orgId}/${contestData?.campaignId}/${contestData?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { QA: questions, ...otherData },
      };
      const response = await axios(config);
      setSavedChanges(true);
      setLoading(false);
      setUpdateDataLocal((prev) => prev + 1);
      setQuestions([]);
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };
  const handlePublish = async () => {
    try {
      setLoading(true);
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_PUBLISH_QUIZ_QUESTIONS}/${contestData?.orgId}/${contestData?.campaignId}/${contestData?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setSavedChanges(true);
      setLoading(false);
      setUpdateDataLocal((prev) => prev + 1);
      setQuestions([]);
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };
  const handleRemoveNewQuestions = (index) => {
    setQuestions((prevState) => {
      let newQuestions = prevState;
      newQuestions?.splice(index, 1);
      return [...newQuestions];
    });
  };
  const handleRemoveOldQuestions = (id, index) => {
    setDeleteIds((prevState) => {
      let newIds = prevState;
      newIds?.push(id);
      return [...newIds];
    });
    setPrevQuestions((prevState) => {
      let newQuestions = prevState;
      newQuestions?.splice(index, 1);
      return [...newQuestions];
    });
  };
  const handleChangeOtherData = (e) => {
    setOtherData((prevState) => ({
      ...prevState,
      [e.target.id]: parseInt(e.target.value) || 0,
    }));
  };

  const handleCloseEditing = () => {
    setShowEdit(false);
    setSavedChanges(false);
    setUpdateData((prevState) => prevState + 1);
  };
  const hanldeContinueEditing = () => {
    setSavedChanges(false);
  };
  const popUpSaveValues = {
    Icon: publishIcon,
    Heading: "Continue Editing?",
    Para: "Are you sure you want to continue editing or close the tab? ",
    Button1: "Edit Questions",
    Button2: "Close",
    Button2Color: "#06B818",
  };
  return (
    <>
      {savedChanges && (
        <AlertPopup
          visible={savedChanges}
          setpopUp={setSavedChanges}
          button1Function={hanldeContinueEditing}
          button2Function={handleCloseEditing}
          value={popUpSaveValues}
        />
      )}
      <PopupWrapper visible={showEdit}>
        <div className={styles.editCampaign} ref={modalRef}>
          <div className={styles.contentDiv}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <h2>Edit Quiz Questions</h2>
              </div>
              <img src={Close} alt="x" onClick={() => setSavedChanges(true)} />
            </div>
            <div className={styles.body}>
              <div className={`custom-scrollbar ${styles.left}`}>
                <div className="mb-[20px]">
                  <DynamicInput
                    handleChange={handleChangeOtherData}
                    value={otherData?.timeLimit}
                    field={{
                      name: "Time Limit (in minutes)",
                      type: "text",
                      id: "timeLimit",
                    }}
                  />
                  <DynamicInput
                    handleChange={handleChangeOtherData}
                    value={otherData?.shuffleCount}
                    field={{
                      name: "How many questions to show",
                      type: "text",
                      id: "shuffleCount",
                    }}
                  />
                </div>
                <div className="mb-[20px]">
                  <h3>Add Question</h3>
                  <hr className={styles.line} />
                  <AddQuizQuestions
                    setQuestions={setQuestions}
                    setLoading={setLoading}
                  />
                  <hr className={styles.line} />
                </div>
                <div>
                  <h3>Questions</h3>
                  {prevQuestions?.map((obj, index) => (
                    <div className="flex gap-[15px] mb-[10px]">
                      <p>{index + 1}</p>
                      <p>{obj?.question}</p>
                      <img
                        src={Close}
                        alt="x"
                        onClick={() =>
                          handleRemoveOldQuestions(obj?._id, index)
                        }
                      />
                    </div>
                  ))}
                  {questions?.map((obj, index) => (
                    <div className="flex gap-[15px] mb-[10px]">
                      <p>{prevQuestions?.length + index + 1}</p>
                      <p>{obj?.questionObject?.question}</p>
                      <img
                        src={Close}
                        alt="x"
                        onClick={() => handleRemoveNewQuestions(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <LoadingCircle /> : "Save Changes"}
              </button>
              <button
                className={styles.save}
                onClick={handleSaveAndPublish}
                disabled={loading}
              >
                {loading ? <LoadingCircle /> : "Save and Publish"}
              </button>
            </div>
          </div>
        </div>
      </PopupWrapper>
    </>
  );
}
