import React from "react";
import styles from "./CartBanner.module.css";
import bag from "../../../Assets/bag.svg";
import { Link } from "react-router-dom";

export default function CartBanner({ onClick, count }) {
  return (
    <div className={styles.background}>
      <div className={styles.innerContainer}>
        <Link to="/campaigns/create/cart">
          <div className="flex items-center gap-[9px]">
            <img src={bag} alt="cart" className={styles.bagButton} />
            <p className="md:block hidden">
              You have added {count == 1 ? count + " item" : count + " items"}{" "}
              in cart{" "}
            </p>
            <p className="md:hidden block">
              {count == 1 ? count + " contest" : count + " contests"} in cart{" "}
            </p>
          </div>
        </Link>

        <button onClick={onClick}>Create campaign</button>
      </div>
    </div>
  );
}
