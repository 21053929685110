import React, { useRef, useState } from "react";
import styles from "./CreatePromotion.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import ReactPlayer from "react-player";
import PromotionForm from "./PromotionForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";

export default function CreatePromotion({ showAddPromotion }) {
  const initialValues = {
    description: "",
    promotionHeader: {
      promotionName: "",
      promoterName: "",
      type: "presents",
      promoterLogo: "",
    },
    contactButtonText: "Contact",
    contactTitle: "",
    contactUrl: "",
    promotionLogo: "",
    details: "",
    ads: {
      templateType: "BLACK",
      ads: [],
    },
    galleryPlacement: "top",
    galleryItems: [],
    showMoreMedia: [],
    showMoreHeader: {
      name: "Register",
      type: "form",
      display: true,
    },
    btnColor: "#bb134f",
    btnTextColor: "#ffffff",
    templateType: "DEFAULT",
    themeColors: {
      primary: "#FFF",
      secondary: "#EBEFF7",
      supporting: "#ECF7F9",
      button1: "#E50B80",
      buttton2: "#6BB9EA",
      text1: "#0C0C0D",
      text2: "#F6F6F6",
    },
    showCreateInfluencer: true,
  };
  const inputRef = useRef();
  const inputRefMobile = useRef();
  const thumbnailRef = useRef();
  const [source, setSource] = useState();
  const [mobileSource, setMobileSource] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [uploadStart, setUploadStart] = useState(false);
  const [progressbar, setProgressbar] = useState();
  const [mobileProgressBar, setMobileProgressbar] = useState();
  const [data, setData] = useState("");
  const [mobileData, setMobileData] = useState("");
  const [entryId, setEntryId] = useState();
  const { userIdToken, orgDomain, organizationId } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [searchParams, setSearchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId");

  const [formValues, setFormValues] = useState(initialValues);
  console.log("formValues", formValues);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  //   ADD ENTRY

  const handleFileChange = async (event) => {
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);

    setSource(url);
    setData(file);
    setProgressbar(0);
  };
  const handleFileChangeMobile = async (event) => {
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);

    setMobileSource(url);
    setMobileData(file);
    setMobileProgressbar(0);
  };

  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleMobileEntryChoose = async () => {
    inputRefMobile.current.click();
  };
  const uploadVideo = async (payload, fileUrl, device) => {
    try {
      setUploadStart(true);
      //upload as binary function
      const uploadGcp = (file, url) => {
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-type", file.type);
        xhr.onload = (response) => {};
        xhr.onreadystatechange = async () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              //what to after success
              console.log("SUCCESS");
            }
          }
        };
        xhr.onerror = function (response) {
          console.log("Response error", response);
        };
        xhr.upload.onprogress = function (evt) {
          // For uploads
          if (evt.lengthComputable) {
            let percentComplete = parseInt((evt.loaded / evt.total) * 100);
            if (device === "desktop") {
              setProgressbar(percentComplete);
              setFormValues({
                ...formValues,
                bannerWeb: fileUrl,
                fileUrl: fileUrl,
              });
            } else {
              setMobileProgressbar(percentComplete);
              setFormValues({
                ...formValues,
                bannerMobile: fileUrl,
              });
            }
          }
        };
        xhr.send(file);
      };
      if (device === "desktop") {
        const response3 = await uploadGcp(data, payload);
      } else {
        const response3 = await uploadGcp(mobileData, payload);
      }
      // return response3;
    } catch (error) {
      console.log(error.message);
    }
  };
  const getEntrySignedUrl = async () => {
    setLoading(true);
    let url = "";
    if (entryId === null || entryId === undefined) {
      url = `${process.env.REACT_APP_PROMOTION_SIGNEDURL}/${organizationId}/${orgDomain}`;
    } else {
      url = `${process.env.REACT_APP_PROMOTION_CHANGE_DESKTOP_ENTRY}/${organizationId}/${entryId}`;
    }
    try {
      let config = {
        method: "post",
        url: url,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);

      if (response.status === 201) {
        setEntryId(response.data.data.videoId);
        const gcpUrl = response.data.data.videoUploadURL;
        const fileUrl = response.data.data.fileUrl;
        uploadVideo(gcpUrl, fileUrl, "desktop");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getEntrySignedUrlMobile = async () => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_PROMOTION_PROMOTION_SIGNEDURL_MOBILEENTRY}/${organizationId}/${entryId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);

      if (response.status === 201) {
        const gcpUrl = response.data.data.videoUploadURL;
        const fileUrl = response.data.data.fileUrl;
        uploadVideo(gcpUrl, fileUrl, "mobile");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  //   ADD ENTRY

  //updadate thumbanil
  const handleThumbnailChange = async (event) => {
    console.log("event.target.id", event.target.id);
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);
    setThumbnailUrl(url);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_PROMOTION_UPDATE_THUMBNAIL}/${organizationId}/${entryId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      console.log("thumbanil added");
    }
  };

  const handleThumbnailChoose = () => {
    thumbnailRef.current.click();
  };
  //updadate thumbanil

  //Update promotion Entry
  const patchDetails = async () => {
    let data = {};
    if (campaignId) {
      data = { ...formValues, campaignPromotion: true, campaignId: campaignId };
    } else {
      data = formValues;
    }
    try {
      let config2 = {
        method: "patch",
        url: `${process.env.REACT_APP_PROMOTION_PATCH_DETAILS}/${entryId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response2 = await axios(config2);

      if (response2.status === 201) {
        navigate("/promotions/list");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //Update promotion Entry

  //

  return (
    <div className={styles.createPromotion}>
      <div className={`custom-scrollbar ${styles.contentDiv}`}>
        <h2>Create Promotion</h2>

        <div className={styles.body}>
          {/* UPLOAD ENTRY  */}
          <div className="flex flex-col gap-[10px] mt-[20px]">
            <label
              htmlFor="participantName"
              className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
            >
              Upload Your Entry (desktop version)
              <span className="text-red-600">*</span>
            </label>
            <input
              ref={inputRef}
              className="hidden"
              type="file"
              id="desktop"
              onChange={handleFileChange}
              // accept=".jpg,.jpeg"
            />
            <div
              className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
              onClick={handleChoose}
            >
              {source ? (
                <div>
                  <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                    {data.type.startsWith("image") ? (
                      <img src={source} className="w-auto h-[100%]" />
                    ) : (
                      <ReactPlayer
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing={false}
                        controls={true}
                        width="100%"
                        height="100%"
                        url={source}
                      />
                    )}
                  </div>
                  <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                    Change
                  </p>
                </div>
              ) : (
                "Select"
              )}
            </div>
            {source && (
              <div>
                <button
                  onClick={getEntrySignedUrl}
                  className={styles.uploadButton}
                >
                  upload
                </button>
                <progress value={progressbar} min="0" max="100">
                  {progressbar}%
                </progress>
              </div>
            )}
          </div>
          {data?.type?.startsWith("image") && progressbar == 100 && (
            <div className="flex flex-col gap-[10px] mt-[20px]">
              <label className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] ">
                Upload Your Entry (Mobile version)
                <span className="text-red-600">*</span>
              </label>
              <input
                ref={inputRefMobile}
                className="hidden"
                type="file"
                id="mobile"
                onChange={handleFileChangeMobile}
                // accept=".jpg,.jpeg"
              />
              <div
                className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                onClick={handleMobileEntryChoose}
              >
                {mobileSource ? (
                  <div>
                    <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                      <img src={mobileSource} className="w-auto h-[100%]" />
                    </div>
                    <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                      Change
                    </p>
                  </div>
                ) : (
                  "Select"
                )}
              </div>
              {mobileSource && (
                <div>
                  <button onClick={getEntrySignedUrlMobile}>upload</button>
                  <progress value={mobileProgressBar} min="0" max="100">
                    {mobileProgressBar}%
                  </progress>
                </div>
              )}
            </div>
          )}
          {/* thumbanil upload  */}
          {data?.type?.startsWith("video") && progressbar == 100 && (
            <div className="flex flex-col gap-[10px] my-[20px]">
              <label
                htmlFor="thumbnailUrl"
                className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
              >
                Upload thumbnail
                <span className="text-red-600">*</span>
              </label>
              <input
                ref={thumbnailRef}
                className="hidden"
                type="file"
                onChange={handleThumbnailChange}
                accept=".jpg,.jpeg,.png"
                id="thumbnailUrl"
              />
              <div
                className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                onClick={handleThumbnailChoose}
              >
                {thumbnailUrl ? (
                  <div>
                    <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                      <img src={thumbnailUrl} className="w-auto h-[100%]" />
                    </div>
                    <p className=" font-Inter font-[400] text-[17px] leading-[24px] ">
                      Change
                    </p>
                  </div>
                ) : (
                  "Select"
                )}
              </div>
            </div>
          )}

          {/* FORM DETAILS  */}
          {progressbar == 100 && (
            <PromotionForm
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formErrors}
              entryId={entryId}
              tokenData={tokenData}
              organizationId={organizationId}
            />
          )}
          <div className={styles.buttonWrapper}>
            <button
              className={styles.save}
              onClick={patchDetails}
              disabled={loading}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
