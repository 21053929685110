import axios from "axios";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Leads from "../../Components/Leads/Leads";
import Pagination from "../../Components/Pagination/Pagination";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";

function LeadsPage() {
  const {
    orgDomain,
    verifyUser,
    userIdToken,
    orgDomainLoading,
    updateData,
    setUpdateData,
    campaignIdToPersist,
    setCampaignIdToPersist,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [leadsData, setLeadsData] = useState([]);
  const [leadsCampaignData, setLeadsCampaignData] = useState([]);
  const [leadsPromotionData, setLeadsPromotionData] = useState([]);
  const [leadsType, setLeadsType] = useState("campaign");
  const [leadsDataLoading, setLeadsDataLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [sorting, setSorting] = useState("notCreatedAt ");
  const tokenData = userIdToken || currentUser?.accessToken;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLeads, setTotalLeads] = useState();
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    if (!isEmpty(orgDomain)) {
      getCampaignDataForLeads();
    }
  }, [orgDomain]);

  const getCampaignDataForLeads = async () => {
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_DATA_FOR_LEADS}/${orgDomain}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setLeadsCampaignData(setApi?.data?.data);
      let findCampaign;

      if (campaignIdToPersist) {
        findCampaign = campgnData?.find((el) => el?._id == campaignIdToPersist);
      }
      let filterCampaign = findCampaign || campgnData[0];
      getLeadsData(filterCampaign?.orgId, filterCampaign?.campaignId, false);

      setSelectedCampaign(filterCampaign);

      return campgnData;
    } catch (err) {
      console.log(err.message);
    }
  };

  const getLeadsData = async (orgId, cmpId, promotion) => {
    try {
      setLeadsDataLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_LEADS_DATA}/${orgId}/${cmpId}?page=${pageNumber}&limit=10&sort=${sorting}&promotion=${promotion}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setLeadsData(campgnData);
      setTotalLeads(setApi?.data?.totalCount);
      setLeadsDataLoading(false);
      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsData([]);
      setLeadsDataLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCampaign)
      getLeadsData(
        selectedCampaign?.orgId,
        selectedCampaign?.campaignId,
        false
      );
  }, [sorting, pageNumber, updateData]);
  const handleLeadsSearch = (obj) => {
    setCampaignIdToPersist(obj?._id);
    setSelectedCampaign(obj);
    setLeadsType("campaign");
    getLeadsData(obj?.orgId, obj?.campaignId, false);
  };
  const handleRefresh = () => {
    setUpdateData((prevState) => prevState + 1);
  };
  return (
    <>
      <div className="flex justify-end w-full p-[20px] ">
        <RefreshButton handleRefresh={handleRefresh} />
      </div>
      <Leads
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        leadsCampaignData={leadsCampaignData}
        leadsPromotionData={leadsPromotionData}
        handleLeadsSearch={handleLeadsSearch}
        leadsDataLoading={leadsDataLoading}
        orgDomainLoading={orgDomainLoading}
        leadsData={leadsData}
        setSorting={setSorting}
        sorting={sorting}
        leadsType={leadsType}
        leadType="campaign"
      />
      <div className="p-[20px]">
        <Pagination
          totalData={totalLeads}
          pageLimit="10"
          setPageNumber={setPageNumber}
          currentPage={pageNumber}
        />
      </div>
    </>
  );
}

export default LeadsPage;
