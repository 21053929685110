import React from "react";
import styles from "./UploadMedia.module.css";
import { useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { adsStorage } from "../../store/firebase-config";
const buttonStyle = {
    display: "inline-block",
    padding: "10px 10px",
    backgroundColor: "#4CAF50",
    color: "white",
    textAlign: "center",
    textDecoration: "none",
    fontSize: "12px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };
export default function UploadMedia({setFinalImageUrl,finalImageUrl}) {
  const [shootmatesAlbumFiles, setShootmatesAlbumFiles] = useState([]);
  const [totalFilesToUpload, setTotalFilesToUpload] = useState(0);
  const [currentUploadingFile, setCurrentUploadingFile] = useState({});
  const [progress, setProgress] = useState(0);
  const [currentUploadAlbumTask, setCurrentUploadAlbumTask] = useState(null);
  const [paused, setPaused] = useState(false);
  const [pendingAlbumFilesToUpload, setPendingAlbumFilesToUpload] = useState(0);

  const pauseAlbumFilesUpload = () => {
    if (currentUploadAlbumTask) {
      currentUploadAlbumTask.pause();
      setPaused(true);
    }
  };
  const resumeAlbumFilesUpload = () => {
    if (currentUploadAlbumTask) {
      currentUploadAlbumTask.resume();
      setPaused(false);
    }
  };
  const handleDropShootmates = (e) => {
    e.preventDefault();
    console.log("e.dataTransfer.files", e.dataTransfer.files);
    const files = Array.from(e.dataTransfer.files);
    setShootmatesAlbumFiles(files);
  };
  const uploadAlbumFiles = async (event) => {
    try {
      const totalFiles = shootmatesAlbumFiles.length;
      setTotalFilesToUpload(totalFiles);
      let currentIndex = 0;
      for (let i = 0; i < shootmatesAlbumFiles.length; i++) {
        const file = shootmatesAlbumFiles[i];
        let randomId = Math.floor(Date.now() * Math.random());
        const storageRef = ref(adsStorage, `${randomId}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setCurrentUploadAlbumTask(uploadTask);
        uploadTask.on("state_changed", (snapshot) => {
          if (!paused) {
            const progressPercentage = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setCurrentUploadingFile(file);
            setProgress(progressPercentage);
          }
        });
        await uploadTask;
        setCurrentUploadAlbumTask(null);
        // Get download URL
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        // push the file URL to the finalImageUrl array
        setFinalImageUrl((previousFileUrl) => [
          ...previousFileUrl,
          {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
            fileUrl: url,
          },
        ]);
        setShootmatesAlbumFiles((previousFiles) =>
          previousFiles.filter((fileData) => fileData.name !== file.name)
        );
        currentIndex++;
        setProgress(100);
        setPendingAlbumFilesToUpload(currentIndex);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="p-[10px] bg-[#E5EAFF] ">
      <div className="flex gap-[16px] mb-[20px]">
        <button onClick={pauseAlbumFilesUpload} style={buttonStyle}>
          Pause
        </button>
        <button onClick={resumeAlbumFilesUpload} style={buttonStyle}>
          Resume
        </button>
        <button onClick={uploadAlbumFiles} style={buttonStyle}>
          Upload
        </button>
        <button style={buttonStyle}>{progress}%</button>
        <span>
          {totalFilesToUpload} of {pendingAlbumFilesToUpload} Uploaded
        </span>
      </div>

      <div
        onDrop={handleDropShootmates}
        onDragOver={(e) => e.preventDefault()}
        className={styles.fileUpload}
      >
        {shootmatesAlbumFiles.length > 0 ? (
          <div className={styles.promotionListGrid}>
            {shootmatesAlbumFiles?.map((file) => {
              return (
                <div className={styles.entry} key={file.name}>
                  <img src={URL.createObjectURL(file)} />
                  <p>{file?.name}</p>
                </div>
              );
            })}
          </div>
        ) : (
          <p>Drop files here</p>
        )}
      </div>
      <div className="mt-[20px]">Medias For Advertisement</div>
      <div
        onDrop={handleDropShootmates}
        onDragOver={(e) => e.preventDefault()}
        className={styles.fileUpload}
      >
        {finalImageUrl.length > 0 ? (
          <div className={styles.promotionListGrid}>
            {finalImageUrl?.map((file) => {
              return (
                <div className={styles.entry} key={file.fileName}>
                  <img src={file?.fileUrl} />
                  <p>{file?.fileName}</p>
                  
                </div>
              );
            })}
          </div>
        ) : (
          <p>Add media Files For Advertisement Template</p>
        )}
      </div>
    </div>
  );
}
