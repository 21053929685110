import React from "react";
import { useState } from "react";

export default function TextAdd({ data, setData, type, keyName, name }) {
  const [displayText, setDisplayText] = useState("");

  const createRandomId = () => {
    return Math.random().toString(36).substring(2);
  };

  const handleCreateObject = () => {
    setData((prevState) => {
      return { ...prevState, [keyName]: [...data, displayText] };
    });
    setDisplayText(""); // Clear the input field after creating the object
  };
  const deleteFilter = (id) => {
    const updatedData = data?.filter((item) => item !== id);
    setData((prevState) => {
      return { ...prevState, [keyName]: updatedData };
    });
  };
  return (
    <div>
      {data?.map((obj) => {
        return (
          <div className="flex gap-[10px]">
            <p>{obj}</p>
            <button onClick={() => deleteFilter(obj)}>Delete</button>
          </div>
        );
      })}
      <div className="flex gap-[10px]">
        <input
          type={type || "text"}
          placeholder={name}
          value={displayText}
          onChange={(e) => setDisplayText(e.target.value)}
        />
        <button onClick={handleCreateObject}>Add</button>
      </div>
    </div>
  );
}
