import React, { useState } from "react";
import styles from "./CampaignAds.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import SelectAds from "../SelectAds/SelectAds";
import axios from "axios";
import Template1 from "../AdsTemplate/Template1/Template1";
import Template2 from "../AdsTemplate/Template2/Template2";
import Template3 from "../AdsTemplate/Template3/Template3";
import Template4 from "../AdsTemplate/Template4/Template4";
import Template5 from "../AdsTemplate/Template5/Template5";
import Template6 from "../AdsTemplate/Template6/Template6";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";

export default function CampaignAds({ setShowAddAds, editCampaignData }) {
  const initialValue = editCampaignData?.ads
    ? { ads: editCampaignData?.ads }
    : {
        ads: {
          templateType: "BLACK",
          ads: [],
        },
      };
  const [ads, setAds] = useState(initialValue);
  const [changedValues, setChangedValues] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const {
    organizationId,
    userIdToken,
    setUpdateData,
    setCampaignPageNo,
    setCampign,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_CREATE_ADS_SECTIONS}/${organizationId}/${editCampaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: ads,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoading(false);
        setUpdateData((prevState) => prevState + 1);
        setCampign([]);
        setCampaignPageNo(1);
        setShowAddAds(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handlePublish = async () => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CAMPAIGN_ADS_PUBLISH}/${organizationId}/${editCampaignData?.campaignId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: ads,
      };
      const response2 = await axios(config);

      if (response2.status === 200) {
        handleSave();
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const removeAdsRow = (index) => {
    let adsArray = ads?.ads?.ads;
    adsArray.splice(index, 1);
    let newBrandIds = [];
    adsArray?.forEach((adGroup) => {
      adGroup?.items?.forEach((ad) => {
        const existingBrand = newBrandIds?.find(
          (brand) => brand?.brandId == ad?.brandId
        );
        if (existingBrand) {
          if (!existingBrand?.adsId?.includes(ad?.videoName)) {
            existingBrand?.adsId?.push(ad?.videoName);
          }
        } else {
          newBrandIds?.push({
            brandId: ad?.brandId,
            adsId: [ad.videoName],
          });
        }
      });
    });

    setAds({
      ...ads,
      ads: {
        ...ads?.ads,
        brandIds: newBrandIds,
        ads: adsArray,
      },
    });
  };

  return (
    <div className={styles.popup}>
      <div className={styles.selectAdsPopup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Create Camapign Ads</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowAddAds(false)} />
          </div>
          <div className={styles.body}>
            <div className="w-full flex justify-end">
              <Link
                to={`/ads/influencers?orgId=${organizationId}&cmpId=${editCampaignData?._id}`}
              >
                <button className={styles.requests}>Requests</button>
              </Link>
            </div>
            {ads &&
              ads?.ads?.ads?.map((carousel, index) => {
                return (
                  <div className="relative ">
                    {isEmpty(carousel.tempType) && (
                      <Template1 carousel={carousel} />
                    )}
                    {carousel.tempType === "TEMP_2" && (
                      <Template2 carousel={carousel} />
                    )}

                    {carousel.tempType === "TEMP_3" && (
                      <Template3 carousel={carousel} />
                    )}
                    {carousel.tempType === "TEMP_4" && (
                      <Template4 carousel={carousel} />
                    )}
                    {carousel.tempType === "TEMP_5" && (
                      <Template5 carousel={carousel} />
                    )}
                    {carousel.tempType === "TEMP_6" && (
                      <Template6 carousel={carousel} />
                    )}
                    <img
                      src={Close}
                      alt="x"
                      onClick={() => removeAdsRow(index)}
                      className="absolute top-[-5px] right-[-5px]"
                    />
                  </div>
                );
              })}

            <button
              className={styles.button}
              onClick={() => setShowPopup(true)}
            >
              Add Section
            </button>
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.save}
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </button>
            <button
              className={styles.save}
              onClick={handlePublish}
              disabled={loading}
            >
              Save and publish
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <SelectAds
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          organizationId={organizationId}
          formValues={ads}
          setFormValues={setAds}
          setChangedValues={setChangedValues}
          tokenData={tokenData}
        />
      )}
    </div>
  );
}
