import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import PaymentListing from "../Components/PaymentListing/PaymentListing";
import { useUserAuth } from "../Context/UserAuthContext";
import Context from "../Context/Context";

export default function OrganizerPaymentPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { organizationId, userIdToken, updateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [status, setStatus] = useState("REQUESTED");

  useEffect(() => {
    if (organizationId) {
      getData();
    }
  }, [organizationId, updateData, status]);

  const getData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SPONSOR_REQUESTED_CAMPAIGNS}?type=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return (
    <div className="p-[20px]">
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Campaigns</p>
      </div>
      <div>
        <PaymentListing data={data} dataLoading={loading} />
      </div>
    </div>
  );
}
