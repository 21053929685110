import { isEmpty } from "lodash";
import React, { useState } from "react";
import HitUsers from "../HitUsers/HitUsers";
import AltImage from "../../Assets/AltImage.svg";
export default function AdsAnalytics({ data, campaignId, type }) {
  const [viewHitUsers, setViewHitUsers] = useState(false);
  const [adsDetails, setAdsDetails] = useState({});
  const ads = data?.hits?.ads;
  const coupons = data?.hits?.coupon;
  const brandAds = data?.hits?.brandAds;
  const totalAdHits = data?.hits?.ads?.totalAdHits;
  const leadCounter = data?.hits?.lead?.counter;
  const adsArray = [];
  const brandArray = [];
  const couponsArray = [];

  ads &&
    Object.keys(ads).map((key) => {
      if (key !== "totalAdHits") {
        adsArray.push(ads[key]);
      }
    });

  coupons &&
    Object.keys(coupons).map((key) => {
      couponsArray.push(coupons[key]);
    });

  brandAds &&
    Object.keys(brandAds).map((key) => {
      brandArray.push(brandAds[key]);
    });
  const getImageUrl = (id) => {
    let orgId = id?.split("_")[0];
    let userId = id?.split("_")[1];
    let randomId = id?.split("_")[2];
    let fileType = id?.split("_")[3];

    return {
      fileUrl: `https://storage.googleapis.com/goviral_organizer/${orgId}/ads/${userId}/${orgId}_${userId}_${randomId}`,
      fileType: fileType == "v" ? "video" : "image",
    };
  };
  return (
    <>
      {viewHitUsers && (
        <HitUsers
          showPopup={viewHitUsers}
          setShowPopup={setViewHitUsers}
          adsId={adsDetails?.id}
          cmpId={campaignId}
          type={type}
        />
      )}
      <div className="grid grid-cols-2 p-[15px] gap-[20px]">
        <div>
          {/* <h3>Ads vise analytics</h3> */}

          {!isEmpty(adsArray) && (
            <table className=" border-collapse border border-[#E5EAFF] p-[5px] w-full">
              <tr>
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  SL NO
                </th>{" "}
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  Advertisement Name
                </th>
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  Hits
                </th>
              </tr>
              {adsArray?.map((obj, index) => {
                let imageData = getImageUrl(obj?.id);
                return (
                  <tr
                    onClick={() => {
                      if (campaignId) {
                        setViewHitUsers(true);
                        setAdsDetails(obj);
                      }
                    }}
                    className="cursor-pointer"
                  >
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px] text-center">
                      {index + 1}
                    </td>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px] flex gap-[8px]">
                      {imageData?.fileUrl && (
                        <img
                          src={imageData?.fileUrl}
                          alt={obj?.name}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = AltImage;
                          }}
                          className="w-[52px] h-[52px] object-cover"
                        />
                      )}
                      {obj?.name}
                    </td>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px] text-[#00BA34] text-center">
                      {obj?.counter}
                    </td>
                  </tr>
                );
              })}
              {totalAdHits && (
                <tr>
                  <td className=" border-collapse border border-[#E5EAFF]  p-[5px]"></td>
                  <td className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                    Total Hits
                  </td>
                  <td className=" border-collapse border border-[#E5EAFF]  p-[5px] text-[#00BA34] text-center">
                    {totalAdHits}
                  </td>
                </tr>
              )}
            </table>
          )}
        </div>

        <div>
          {!isEmpty(brandArray) && (
            <table className=" border-collapse border border-[#E5EAFF] p-[5px] ">
              <tr>
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  SL NO
                </th>{" "}
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  Brand Name
                </th>
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  Hits
                </th>
              </tr>
              {brandArray?.map((obj, index) => {
                return (
                  <tr>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px] text-center">
                      {index + 1}
                    </td>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                      {obj?.brandName}
                    </td>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px] text-[#00BA34] text-center">
                      {" "}
                      {obj?.counter}
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>

        <div>
          {/* <h3>Ads vise analytics</h3> */}
          {!isEmpty(couponsArray) && (
            <table className=" border-collapse border border-[#E5EAFF] p-[5px] w-full">
              <tr>
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  SL NO
                </th>{" "}
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  Coupon Name
                </th>
                <th className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                  Hits
                </th>
              </tr>
              {couponsArray?.map((obj, index) => {
                return (
                  <tr>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px] text-center">
                      {index + 1}
                    </td>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px]">
                      {obj?.name}
                    </td>
                    <td className=" border-collapse border border-[#E5EAFF]  p-[5px] text-[#00BA34] text-center">
                      {obj?.counter}
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      </div>
      {/* {leadCounter && (
        <p className="mb-[20px]">Action Button Hits: {leadCounter}</p>
      )} */}
    </>
  );
}
