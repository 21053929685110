import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import CamapignCreationForm from "../../Components/CamapignCreationForm/CamapignCreationForm";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";

export default function CampaignCreatePage() {
  const { selectedContests } = useContext(Context);
  const { currentUser } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/campaigns");
    }
  }, []);
  let initialFormValuesFromLocal = JSON.parse(
    localStorage.getItem(`campaignCreationForm`)
  );
  const initialValues = {
    name: "",
    country: "",
    campaignName: "",
    email: "",
    phone: "",
    address: "",
    description: "",
    giftDetails: "",
    isPrivate: false,
    lookingForInfluencer: false,
    sponsorShipStatus: "REQUESTED",
    campaignDescription: "",
    contestIds: [],
    campaignIds: [],
    categoryTypes: [],
    campaignUniqueText: "",
    orgDomain: "",
    privateType: "PIN",
    show: false,
    packDetails: "",
    ...selectedContests,
  };
  const [formValues, setFormValues] = useState(
    initialFormValuesFromLocal ? initialFormValuesFromLocal : initialValues
  );
  const [formErrors, setFormErrors] = useState({});

  return (
    <div className="bg-white text-black">
      <CamapignCreationForm
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </div>
  );
}
