import React, { useEffect, useState } from "react";
import styles from "./CampaignCoupons.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import LoadingAnimation from "../Loading/LoadingAnimation";
import CreateSponsor from "../CreateSponsor/CreateSponsor";
import EditSponser from "../EditSponser/EditSponser";
import { useNavigate } from "react-router-dom";
import CreateCoupon from "../CreateCoupon/CreateCoupon";

export default function CampaignCoupons({ setPopup, editCampaignData }) {
  const initialValue = editCampaignData?.discountCoupons || [];
  const [coupons, setCoupons] = useState(initialValue);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [sponsorData, setSponsorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addCoupons, setAddCoupons] = useState(false);
  const {
    organizationId,
    userIdToken,
    setCampign,
    setUpdateData,
    setCampaignPageNo,
    updateData,
    setNotAdmin,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const navigate = useNavigate();
  const tokenData = userIdToken || currentUser?.accessToken;
  useEffect(() => {
    getCoupons();
  }, [updateData]);
  const getCoupons = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_COUPONS}/${organizationId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const apiData = await axios(config1);
      let filter = apiData?.data?.data?.discountCoupons?.filter(
        (el) => !coupons?.some((el2) => el2?.videoName === el?.videoName)
      );
      setSponsorData(filter);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const sponsorDataFilter = () => {
    let sponsorFromApi = sponsorData;
    let filter = sponsorFromApi.filter(
      (el) => !coupons?.some((el2) => el2?.videoName === el?.videoName)
    );
    setSponsorData(filter);
  };

  const handleSelect = (obj) => {
    let newArray = coupons;
    newArray.push(obj);
    setCoupons(newArray);
    sponsorDataFilter();
  };
  const handleRemove = (obj) => {
    let newArray = coupons;
    var index = newArray.findIndex((el) => el?.videoName == obj?.videoName);
    newArray.splice(index, 1);
    setCoupons(newArray);
    sponsorDataFilter();
    setSponsorData([obj, ...sponsorData]);
  };
  const handleSave = async () => {
    setLoading(true);

    try {
      let couponArray = coupons;
      let newBrandIds = [];

      couponArray?.forEach((items) => {
        const existingBrand = newBrandIds?.find(
          (brand) => brand?.brandId == items?.brandId
        );
        if (existingBrand) {
          if (!existingBrand?.couponId?.includes(items?.videoName)) {
            existingBrand?.couponId?.push(items?.videoName);
          }
        } else {
          newBrandIds?.push({
            brandId: items?.brandId,
            couponId: [items.videoName],
          });
        }
      });
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_ADD_CAMPAIGN_COUPONS}/${organizationId}/${editCampaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { coupons: coupons, brandIds: newBrandIds },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        setPopup(false);
        setUpdateData((prevState) => prevState + 1);
        setCampign([]);
        setCampaignPageNo(1);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handlePublish = async () => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CAMPAIGN_ADS_PUBLISH}/${organizationId}/${editCampaignData?.campaignId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { discountCoupons: coupons },
      };
      const response2 = await axios(config);

      if (response2.status === 200) {
        handleSave();
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleEdit = (event, data) => {
    event.stopPropagation();
    setEdit(true);
    setEditData(data);
  };
  const navigateToRequsts = () => {
    navigate(
      `/cmp-sponsers/requests?orgId=${organizationId}&cmpId=${editCampaignData?._id}`
    );
  };
  return (
    <div className={styles.popup}>
      {addCoupons && (
        <CreateCoupon showPopup={addCoupons} setShowPopup={setAddCoupons} />
      )}

      <div className={styles.selectAdsPopup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Campaign Coupons</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setPopup(false)} />
          </div>
          <div className={styles.body}>
            {loading ? (
              <LoadingAnimation />
            ) : (
              <>
                <p>Coupons</p>
                <div className={styles.promotionListGrid}>
                  {sponsorData?.map((obj) => {
                    return (
                      <div
                        className={styles.entry}
                        onClick={() => handleSelect(obj)}
                      >
                        <img src={obj?.bgImage} />
                        <p>{obj?.discountName}</p>
                        <p>{obj?.couponCode}</p>
                      </div>
                    );
                  })}
                  <div
                    className={styles.entry}
                    onClick={() => setAddCoupons(true)}
                  >
                    {/* <img src={obj?.fileUrl} /> */}
                    <p>Create Coupon</p>
                  </div>
                </div>
                <p className="mt-[20px]">Selected Coupons</p>

                <div className={styles.promotionListGrid}>
                  {coupons?.map((obj) => {
                    return (
                      <div
                        className={styles.entry}
                        onClick={() => handleRemove(obj)}
                      >
                        <img src={obj?.bgImage} />
                        <div className="p-[10px] flex flex-col gap-[5px]">
                          <p>{obj?.discountName}</p>
                          <p>{obj?.couponCode}</p>
                          {/* <button
                            className={styles.editButton}
                            onClick={(e) => handleEdit(e, obj)}
                          >
                            Edit
                          </button> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.save}
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </button>
            <button
              className={styles.save}
              onClick={handlePublish}
              disabled={loading}
            >
              Save and publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
