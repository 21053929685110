import React from "react";

import * as FileSaver from "file-saver";
import PdfDownloder from "../Components/PollContest/PdfDownloder.js/PdfDownloder";
import * as XLSX from "xlsx";

export const ExportCSV = ({
  csvData,
  fileName,
  found,
  winnersPrepared,
  winnersUrl,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const downloadCSV = () => {
    // window.open(fullData?.qrCodeUrl)
    // e.preventDefault()
    fetch(winnersUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "participant details.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    //   // <div className={styles.butn}>
    //   // <ExportCSV
    //   // style={styles.butn}
    //   //   csvData={participantData}
    //   //   fileName={'participantDetails'}
    //   // />
    //   // {/* <p>Download participant details</p> */}
    // </div>

    <>
      <PdfDownloder
        winnersPrepared={winnersPrepared}
        // exportToCSV={exportToCSV}
        downloadCSV={downloadCSV}
        csvData={csvData}
        fileName={fileName}
        found={found}
      />
    </>
  );
};
