import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./FeatureDetails.module.css";
import Close from "../../Assets/close.svg";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useContext } from "react";
import Context from "../../Context/Context";
import LoadingAnimation from "../Loading/LoadingAnimation";
import user from "../../Assets/user.svg";
import PrizeSentPopup from "../PrizeSentPopup/PrizeSentPopup";

export default function FeatureDetails({ showPopup, setShowPopup, data }) {
  let orgId = data?.orgId;
  let cmpId = data?.campaignId;
  let cntstid = data?.contestId;
  let videoId = data?._id;

  const handleCloseButton = () => {
    setShowPopup(false);
  };

  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.parent}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Featuring Details </h2>
            </div>
            <img src={Close} alt="x" onClick={handleCloseButton} />
          </div>
          <div className={styles.body}>
            <div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  <strong>Name</strong> :
                </p>
                <p className={`text-description  `}>{data?.participantName}</p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap">
                  <strong>Photo</strong> :
                </p>
                <div className="h-[170px]">
                  <img
                    src={data?.prizeClaimDetails?.participantPhoto?.fileUrl}
                    alt={`Image `}
                    className="h-full object-contain"
                  />
                </div>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap">
                  <strong>Occupation / Class</strong> :
                </p>
                <p className={`text-description  `}>
                  {data?.prizeClaimDetails?.occupation}
                </p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap">
                  <strong>Bio</strong> :
                </p>
                <p className={`text-description  `}>
                  {data?.prizeClaimDetails?.bio}
                </p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap">
                  <strong>Achievement</strong> :
                </p>
                <p className={`text-description  `}>
                  {data?.prizeClaimDetails?.achievement}
                </p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold  whitespace-nowrap">
                  <strong>Contact</strong> :
                </p>
                <p className={`text-description  `}>
                  {data?.prizeClaimDetails?.address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
