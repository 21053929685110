import ContestCard from "./ContestCard";
import * as _ from "lodash";
import { useState, useContext, useEffect } from "react";
import styles from "./styles/PollContest.module.css";
import Context from "../../Context/Context";
import LoadingAnimation from "../Loading/LoadingAnimation";
import NavBar from "../NavBar";
import ShareCard from "./ShareCard";
import closeCampaignLogo from "../../Assets/closeCampaignIcon.svg";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import UnderReviewPopup from "../UnderReviewPopup/UnderReviewPopup";

import TopBanner from "../TopBanner/TopBanner";
import RightPanel from "../RightPanel/RightPanel";
import { closeContest, openContest } from "../../utils/StatusChanger";
import Insight from "../RightPanel/Insight";
import usePaginate from "../../Hooks/usePaginate";
import AlertPopup from "../AlertPopups/AlertPopup";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

function PollContest() {
  const {
    contestHead,
    setcontestHead,
    campaignDataLoading,
    BaseApiUrl,
    userIdToken,
    setStatusChangeLoading,
    updateData,
    setUpdateData,
    setNotAdmin,
    setCampaignIdToPersist,
  } = useContext(Context);
  const { currentUser } = useUserAuth();

  const [campaignData, setCampaignData] = useState([]);
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contestDataLoad, setContestDataLoad] = useState(false);
  const [campaignContestsDatas, setCampaignContestsDatas] = useState({});
  const [status, setStatus] = useState("ALL");
  const [share, setShare] = useState();
  const [underReview, setunderReview] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  // const campaignId = window.location.href.split("/")[3];
  const [showCloseLikePopup, setShowCloseLikePopup] = useState(false);
  // const orgId = window.location.href.split("/")[5];
  const [rightPanelData, setRightPanelData] = useState({});
  const [clickedContestData, setClickedContestData] = useState({});
  const [showCloseContestPopup, setShowCloseContestPopup] = useState(false);
  const [showOpenContestPopup, setShowOpenContestPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const campaignId = searchParams.get("cmpId");
  const navigate = useNavigate();
  useEffect(() => {
    getCampaignContestData();
  }, [updateData]);

  const getCampaignContestData = async () => {
    try {
      setContestDataLoad(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ALL_CONTEST_DATA_CAMPAIGN}/${orgId}/${campaignId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campaignData = setApi?.data?.data;
      setCampaignContestsDatas(campaignData);
      setCampaignIdToPersist(campaignData?._id);
      let contestMap = campaignData?.contests?.map((obj) => ({
        ...obj,
        campaignStatus: campaignData?.campaignStatus,
      }));
      setContests(contestMap);
      setContestDataLoad(false);
      return campaignData;
    } catch (err) {
      console.log(err.message);
      setContestDataLoad(false);
    }
  };

  // const closeCampaign = async () => {
  //   setClosingLoading(true);
  //   try {
  //     let config1 = {
  //       method: "PATCH",
  //       url: `${process.env.REACT_APP_CLOSE_ORGANISER_CAMPAIGN}/${orgId}/${campaignId}?orgDomain=${campaignContestsDatas?.orgDomain}&campaignUniqueText=${campaignContestsDatas?.campaignUniqueText}       `,

  //       headers: {
  //         Authorization: `Bearer ${tokenData}`,
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     const setApi = await axios(config1);
  //     setClosingLoading(false);
  //     if (setApi.status === 201) {
  //       window.location.reload();
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //     setClosingLoading(false);
  //   }
  // };

  // const openCampaign = async () => {
  //   setClosingLoading(true);
  //   try {
  //     let config1 = {
  //       method: "PATCH",
  //       url: `${process.env.REACT_APP_OPEN_ORGANISER_CAMPAIGN}/${orgId}/${campaignId}?orgDomain=${campaignContestsDatas?.orgDomain}&campaignUniqueText=${campaignContestsDatas?.campaignUniqueText}       `,

  //       headers: {
  //         Authorization: `Bearer ${tokenData}`,
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     const setApi = await axios(config1);
  //     setClosingLoading(false);
  //     if (setApi.status === 201) {
  //       window.location.reload();
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //     setClosingLoading(false);
  //     setunderReview(true);
  //   }
  // };

  const handleOpenContest = () => {
    setShowOpenContestPopup(true);
    // const { orgId, campaignId, contestId, orgDomain, campaignUniqueText } =
    //   clickedContestData;
    // openContest(
    //   orgId,
    //   campaignId,
    //   contestId,
    //   orgDomain,
    //   campaignUniqueText,
    //   tokenData,
    //   setStatusChangeLoading
    // );
  };
  const handleCloseContet = () => {
    const { orgId, campaignId, contestId, contestType } = clickedContestData;
    closeContest(
      orgId,
      campaignId,
      contestId,
      contestType,
      tokenData,
      setStatusChangeLoading,
      setNotAdmin
    );
  };
  const contestsFiltered = contests?.filter((el) => {
    if (el.selected === true) {
      if (status === "ALL") {
        return el;
      } else {
        return el.contestStatus == status;
      }
    }
  });

  const { data } = usePaginate(
    1,
    5,
    tokenData,
    rightPanelData?.orgId,
    rightPanelData?.campaignId,
    rightPanelData?.contestId,
    [rightPanelData?.contestId],
    "created",
    rightPanelData?.pendingApproval
  );

  const hanldeCloseStatusChangerPopup = () => {
    setShowCloseContestPopup(false);
  };
  const handleCloseOpenContentPopup = () => {
    setShowOpenContestPopup(false);
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Close the Contest",
    Para: "Are you sure you want to close this Contest? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Close this Contest",
    Button2Color: "#F5BF00",
  };
  const openPopUpValues = {
    Icon: "",
    Heading: "Open the Contest",
    Para: "Contact admin for opening this contest!",
    Button1: "Cancel",
    Button2: "",
    Button2Color: "#06B818",
  };
  const closeLikeValues = {
    Icon: "",
    Heading: "Close Accepting Like",
    Para: "Are you sure you want to close accepting likes? ",
    Button1: "Cancel",
    Button2: "Close",
    Button2Color: "#06B818",
  };
  const handleCloseLikePopup = () => {
    setShowCloseLikePopup(false);
  };
  const handleCloseLike = async () => {
    setLoading(true);
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_UPDATE_GIFT_OPTIONS}/${rightPanelData?.orgId}/${rightPanelData?.campaignId}/${rightPanelData?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          likeClosed: true,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        handleCloseLikePopup();
        setUpdateData((prevState) => prevState + 1);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const params = { orgId: orgId, cmpId: campaignId };

  const handleViewEntries = () => {
    navigate({
      pathname: "/campaigns/contests/campaign-all-entries",
      search: `?${createSearchParams(params)}`,
    });
  };
  const handleViewDeclinedEntries = () => {
    navigate({
      pathname: "/campaigns/contests/campaign-declined-entries",
      search: `?${createSearchParams(params)}`,
    });
  };
  const handleViewDeletedEntries = () => {
    navigate({
      pathname: "/campaigns/contests/campaign-deleted-entries",
      search: `?${createSearchParams(params)}`,
    });
  };
  const handleViewAccess = () => {
    navigate({
      pathname: "/campaigns/access",
      search: `?${createSearchParams(params)}`,
    });
  };

  return (
    <div className=" content-wrapper">
      {contestDataLoad ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="center">
            {underReview ? (
              <UnderReviewPopup />
            ) : (
              <div className={`mobWidth ${styles.outLine}`}>
                {showCloseContestPopup && (
                  <AlertPopup
                    visible={showCloseContestPopup}
                    setpopUp={setShowCloseContestPopup}
                    button1Function={hanldeCloseStatusChangerPopup}
                    button2Function={handleCloseContet}
                    value={closePopUpValues}
                  />
                )}
                {showOpenContestPopup && (
                  <AlertPopup
                    visible={showOpenContestPopup}
                    setpopUp={setShowOpenContestPopup}
                    button1Function={handleCloseOpenContentPopup}
                    button2Function={handleOpenContest}
                    value={openPopUpValues}
                  />
                )}
                {showCloseLikePopup && (
                  <AlertPopup
                    visible={showCloseLikePopup}
                    setpopUp={setShowCloseLikePopup}
                    button1Function={handleCloseLikePopup}
                    button2Function={handleCloseLike}
                    value={closeLikeValues}
                  />
                )}
                <TopBanner
                  backgroundImage={campaignContestsDatas?.coverImage}
                  heading={campaignContestsDatas?.campaignName}
                  data={campaignContestsDatas}
                  breadCrumbItem={[
                    { label: "Campaigns", url: "/campaigns" },
                    {
                      label: "Contests",
                      url: "/campaigns/contests",
                      params: {
                        orgId: campaignContestsDatas?.orgId,
                        cmpId: campaignContestsDatas?.campaignId,
                      },
                    },
                  ]}
                />
                <Insight
                  type="Campaign"
                  userCount={campaignContestsDatas?.TotalVisitCount}
                  likeCount={campaignContestsDatas?.TotalLikeCount}
                  viewCount={campaignContestsDatas?.totalEntryViewCount}
                  participantCounter={campaignContestsDatas?.participantCounter}
                />
                <div className="w-full flex items-end justify-end my-[20px] gap-[10px]">
                  <button className={styles.button} onClick={handleViewAccess}>
                    Campaign Access
                  </button>
                  <button
                    className={styles.button}
                    onClick={handleViewDeletedEntries}
                  >
                    Deleted Entries
                  </button>
                  <button
                    className={styles.button}
                    onClick={handleViewDeclinedEntries}
                  >
                    Declined Entries
                  </button>
                  <button className={styles.button} onClick={handleViewEntries}>
                    All Entries
                  </button>
                </div>
                <div className={styles.filterChips}>
                  <div
                    onClick={() => {
                      setStatus("ALL");
                    }}
                    className={
                      status == "ALL" ? styles.eachChips : styles.eachChipsDark
                    }
                  >
                    <p>All Contests</p>
                  </div>
                  <div
                    onClick={() => {
                      setStatus("OPEN");
                    }}
                    className={
                      status == "OPEN" ? styles.eachChips : styles.eachChipsDark
                    }
                  >
                    <p>Open</p>
                  </div>
                  <div
                    onClick={() => {
                      setStatus("CLOSED");
                    }}
                    className={
                      status == "CLOSED"
                        ? styles.eachChips
                        : styles.eachChipsDark
                    }
                  >
                    <p>Closed</p>
                  </div>
                </div>
                {/* <ShareBox /> */}
                {contestDataLoad && <LoadingAnimation />}
                {contestsFiltered &&
                  !contestDataLoad &&
                  contestsFiltered.length === 0 && (
                    <div className="w-[100%] h-[50vh] flex flex-col items-center justify-center text-[#787878]">
                      <span className="text-[17px] font-semibold">
                        No data found
                      </span>
                      {/* <span className="text-[14px] font-medium mt-[10px] text-center">
                All the profile entries will be
                <br /> displayed here.
              </span> */}
                    </div>
                  )}
                <div className={styles.griding}>
                  {contestsFiltered &&
                    contestsFiltered.map((obj) => {
                      return (
                        <ContestCard
                          campaignContestsDatas={campaignContestsDatas}
                          contestHead={contestHead}
                          setcontestHead={setcontestHead}
                          data={obj}
                          setRightPanelData={setRightPanelData}
                          setClickedContestData={setClickedContestData}
                          setShowCloseContestPopup={setShowCloseContestPopup}
                          setShowOpenContestPopup={setShowOpenContestPopup}
                        />
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          {!_.isEmpty(rightPanelData) && (
            <div className=" right-sidebar">
              <RightPanel
                cardTitile="contest details"
                title={rightPanelData.contestName}
                type="Contest"
                description={rightPanelData.description}
                rightPanelData={rightPanelData}
                handleOpenClick={setShowOpenContestPopup}
                handleCloseClick={setShowCloseContestPopup}
                handleCloseLike={setShowCloseLikePopup}
                pendingApprovalList={data}
                pendingArrovalCount={rightPanelData.pendingApproval}
                status={rightPanelData.contestStatus}
                likeCount={rightPanelData?.contestEntryLikeCount}
                viewCount={rightPanelData?.contestEntryViewCount}
                userCount={rightPanelData?.contestVisitCount}
                participantCounter={rightPanelData?.participantCounter}
              />
            </div>
          )}
        </>
      )}
      {share && (
        <>
          <ShareCard
            setShare={setShare}
            campaignContestsDatas={campaignContestsDatas}
          />
        </>
      )}
    </div>
  );
}

export default PollContest;
