import { useEffect, useState } from "react";
import axios from "axios";
import { useContext } from "react";
import Context from "../Context/Context";
export default function useGetCampaignPendingArrovalList(
  pageNumber,
  limit,
  token,
  orgId,
  campaignId,
  rightPanelData,
  sorting
) {
  const [dataloading, setDataLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    setData([]);
  }, []);
  const { updateData } = useContext(Context);
  useEffect(() => {
    setDataLoading(true);
    setError(false);
    let cancel;
    if (orgId && campaignId) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_PENDING_APPROVALS}/${orgId}/${campaignId}?page=${pageNumber}&limit=${limit}&sort=${sorting}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          setData(res.data.data);
          setHasMore(res.data.data.length > 0);
          setDataLoading(false);
        })
        .catch((e) => {
          setDataLoading(false);
          setData([]);
          if (axios.isCancel(e)) return;
          setError(true);
        });
    }

    return () => {
      if (cancel) cancel();
    };
  }, [pageNumber, ...rightPanelData, sorting, updateData]);

  return { dataloading, error, data, hasMore };
}
