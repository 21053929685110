import React, { createContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { authentication } from "../store/firebase-config";
import { useUserAuth } from "./UserAuthContext";
import * as _ from "lodash";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [userIdToken, setUserIdToken] = useState();
  const [orgDomainLoading, setOrgDomainLoading] = useState(false);
  const [notAdmin, setNotAdmin] = useState(false);
  const [campaignDataLoading, setCampaignDataLoading] = useState(false);
  const [campaignHasMore, setCampaignHasMore] = useState(false);
  const [campaignPageNo, setCampaignPageNo] = useState(1);
  const [contestHead, setcontestHead] = useState();
  const [campign, setCampign] = useState([]);
  const { currentUser } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;
  const [status, setStatus] = useState("ALL");
  const [organizerList, setOrganizerList] = useState([]);
  const [dashboardRoles, setDashboardRoles] = useState([]);
  const [updateData, setUpdateData] = useState(1);
  const [isHomePage, setIsHomePage] = useState(false);
  const [selectedContests, setSelectedContests] = useState({});
  const [campaignIdToPersist, setCampaignIdToPersist] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [judmentMap, setJudmentMap] = useState(new Map());
  const [judmentEntries, setJudmentEntries] = useState(new Map());
  let dbType = "";
  if (window.location.pathname.startsWith("/inf")) {
    dbType = "influencer";
  } else if (window.location.pathname.startsWith("/sp")) {
    dbType = "sponsor";
  } else {
    dbType = "organizer";
  }
  const [dashboard, setDashboard] = useState(dbType);
  // localStorage.getItem("dashboardType")
  //   ? localStorage.getItem("dashboardType")
  //   : "organizer"

  const [organizerListOption, setOrganizerListOption] = useState(
    localStorage.getItem("organizationIndex")
      ? localStorage.getItem("organizationIndex")
      : 0
  );
  const [orgDomain, setOrgDomain] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [sideFilter, setSideFilter] = useState(
    localStorage.getItem("sideFilterName")
      ? localStorage.getItem("sideFilterName")
      : "campaign"
  );
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const campaignId = searchParams.get("cmpId");
  const contestId = searchParams.get("conId");

  const BaseApiUrl =
    "https://us-central1-tripsmagic.cloudfunctions.net/goviralOrganizerAdmin/api/goviral/organizeradmin";

  const leftSidebarRef = useRef(null);
  const modalRef = useRef(null);
  const openNav = () => {
    leftSidebarRef.current.style.width = "100%";
    leftSidebarRef.current.style.position = "fixed";
    leftSidebarRef.current.style.opacity = "1";
  };
  const closeNav = () => {
    leftSidebarRef.current.style.width = "0px";
    leftSidebarRef.current.style.opacity = "0";
  };
  const verifyUser = async () => {
    try {
      const idToken = await authentication.currentUser.getIdToken(true);
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setInterval(() => {
      verifyUser();
    }, 600000);
  }, []);
  //Get organization list
  const getOrganizationList = async () => {
    setOrgDomainLoading(true);
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ALL_ORGANIZATION_LIST}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);
      setOrganizerList(setApi?.data?.data[0].userOrganisations);
      setDashboardRoles(setApi?.data?.data[0].dashboardRoles);
      setOrgDomain(
        setApi?.data?.data[0].userOrganisations &&
          setApi?.data?.data[0].userOrganisations[organizerListOption]
            ?.orgDomain
      );
      setOrganizationId(
        setApi?.data?.data[0].userOrganisations &&
          setApi?.data?.data[0].userOrganisations[organizerListOption]?.orgId
      );
      setOrgDomainLoading(false);
    } catch (err) {
      console.log(err.message);
      setOrgDomainLoading(false);
    }
  };
  //GET ALL Campaigns of a organiser
  const CampignDataFunction = async (status, orgDomain) => {
    setCampaignDataLoading(true);
    let cancel;
    try {
      let config1 = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALL_ORGANISER_CAMPAIGNS_TEST}?status=${status}&page=${campaignPageNo}&limit=5&orgDomain=${orgDomain}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      };
      const setApi = await axios(config1);
      setCampign((data) => [...data, ...(setApi?.data?.data || [])]);
      setCampaignHasMore(setApi?.data?.data?.length > 0);
      setCampaignDataLoading(false);
    } catch (err) {
      setCampaignDataLoading(false);
      setCampign([]);
      console.log(err.message);
      if (axios.isCancel(err)) return;
    }
    return () => cancel();
  };
  // useEffect(() => {
  //   verifyUser()
  // }, [])
  useMemo(() => {
    if (_.isEmpty(dashboardRoles) || _.isEmpty(organizerList))
      getOrganizationList();
  }, [currentUser]);

  return (
    <Context.Provider
      value={{
        dashboardRoles,
        setOrganizerList,
        setDashboardRoles,
        dashboard,
        setDashboard,
        campign,
        campaignHasMore,
        campaignDataLoading,
        campaignPageNo,
        setCampaignPageNo,
        setStatus,
        status,
        verifyUser,
        userIdToken,
        contestHead,
        setcontestHead,
        token,
        setCampign,
        BaseApiUrl,
        organizerList,
        organizerListOption,
        setOrganizerListOption,
        orgDomain,
        sideFilter,
        setSideFilter,
        openNav,
        closeNav,
        statusChangeLoading,
        setStatusChangeLoading,
        leftSidebarRef,
        modalRef,
        orgId,
        campaignId,
        contestId,
        organizationId,
        updateData,
        setUpdateData,
        orgDomainLoading,
        setOrganizationId,
        CampignDataFunction,
        setOrgDomain,
        notAdmin,
        setNotAdmin,
        selectedContests,
        setSelectedContests,
        isHomePage,
        setIsHomePage,
        campaignIdToPersist,
        setCampaignIdToPersist,
        successMessage,
        setSuccessMessage,
        judmentMap,
        setJudmentMap,
        judmentEntries,
        setJudmentEntries,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
