import React from "react";
import { useState } from "react";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./FormAttributes.css";

export default function FormAttributes({
  contestFormValue,
  setContestFormValues,
  showAddFormAttributes,
  setShowAddFormAttributes,
  formattributesValues,
  setFormAttributesValues,
}) {
  const handleSave = () => {
                            
  };
  return (
    <PopupWrapper
      setpopUp={setShowAddFormAttributes}
      visible={showAddFormAttributes}
    >
      <div className="w-[40%] h-[60%] ">
        <div className="formAttributes-heading p-[24px] flex flex-row justify-between">
          <h3>Add input field</h3>
        </div>
        <div className="formAttributes-grid">
          <div className="row1">
            <p>
              Type <span className="text-red-600">*</span>
            </p>
            <select
              type="text"
              onChange={(e) => {
                setFormAttributesValues({
                  ...formattributesValues,
                  type: e.target.value,
                });
              }}
              value={formattributesValues.type}
            >
              <option value="text">text field</option>
              <option value="dropdown">Dropdown</option>
            </select>
          </div>
          {/* TYPE IS TEXT  */}

          {formattributesValues.type == "text" && (
            <>
              {/* HEADING  */}
              <div className="row1">
                <p>
                  Heading <span className="text-red-600">*</span>
                </p>
                <input
                  type="text"
                  onChange={(e) => {
                    setFormAttributesValues({
                      ...formattributesValues,
                      title: e.target.value,
                    });
                  }}
                  value={formattributesValues.title}
                />
              </div>
            </>
          )}
          {/* REQUIRED OR NOT  */}
          <div className="row1">
            <p>
              mandatory <span className="text-red-600">*</span>
            </p>
            <input
              type="radio"
              name="mandatory"
              value={true}
              checked={formattributesValues.required}
              onChange={(e) => {
                setFormAttributesValues({
                  ...formattributesValues,
                  required: e.target.value == "true",
                });
              }}
            />
            <label>Yes</label>
            <input
              type="radio"
              name="mandatory"
              value={false}
              checked={!formattributesValues.required}
              onChange={(e) => {
                setFormAttributesValues({
                  ...formattributesValues,
                  required: e.target.value == "true",
                });
              }}
            />
            <label>No</label>
          </div>
          {/* ERROR MESSAGE  */}

          {formattributesValues.required && (
            <div className="row1">
              <p>
                Error message <span className="text-red-600">*</span>
              </p>
              <input
                type="text"
                onChange={(e) => {
                  setFormAttributesValues({
                    ...formattributesValues,
                    errorMessage: e.target.value,
                  });
                }}
                value={formattributesValues.errorMessage}
              />
            </div>
          )}
          <div className="row2 flex flex-row mt-[12px] gap-x-[12px]">
            <button
              className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
              onClick={() => setShowAddFormAttributes(false)}
            >
              Cancel
            </button>
            <button
              className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
