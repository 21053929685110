import React from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../Assets/loadingNew.json";
import styles from "./LoadingAnimation.module.css";

function LoadingAnimation() {
  return (
    <div className={styles.loadingAnimation}>
      <Lottie
        loop
        animationData={loadingAnimation}
        play
        style={{ width: 173, height: 173 }}
      />
    </div>
  );
}

export default LoadingAnimation;
