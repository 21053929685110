import { useContext, useState } from "react";
import styles from "./PaymentListing.module.css";
import Context from "../../Context/Context";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import AlertPopup from "../AlertPopups/AlertPopup";

export default function PaymentListing({ data, dataLoading }) {
  const { orgDomainLoading, userIdToken } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useUserAuth();
  const [paymentDisabled, setPaymentDisabled] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;

  const handlePay = async (cmpData) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_SPONSOR_PAYMENT}/${cmpData?.orgId}/${cmpData?.campaignId}/${cmpData?.sponsorShipId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        let paymentRedirectUrl = response?.data?.paymentRedirectUrl;
        let url =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo
            ?.url;
        handleRedirectToLink(url, paymentRedirectUrl);
      }
      if (response?.data?.status == "fail") {
        // setErrorMessage(response?.data?.msg);
      }
      setLoading(false);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.shootmates.com/thirdpartyPayment";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
  }
  const closePopUpValues = {
    Icon: "",
    Heading: "Payment Currently Unavailable",
    Para: "Payment will be available once your request is approved by the organizer.",
    Button1: "",
    Button2: "Ok",
    Button2Color: "#F5BF00",
  };
  return (
    <div>
      {paymentDisabled && (
        <AlertPopup
          visible={paymentDisabled}
          setpopUp={setPaymentDisabled}
          button2Function={() => setPaymentDisabled(false)}
          value={closePopUpValues}
        />
      )}
      {data && !dataLoading && !orgDomainLoading && data.length === 0 && (
        <div className="w-[100%] h-[50vh] flex flex-col items-center justify-center text-[#787878]">
          <span className="text-[17px] font-semibold">No data found</span>
        </div>
      )}
      {data && !dataLoading && !orgDomainLoading && data.length > 0 && (
        <table className={styles.leadsTable}>
          <thead>
            <tr>
              <th>Sl no</th>
              <th>Campaign Name</th>
              <th>Payment Status</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((obj, indexnumber) => {
              return (
                <tr
                  onClick={() => {
                    // setShowRegPopup(true);
                    // setRegData(obj);
                  }}
                >
                  <td className={styles.slno}>{indexnumber + 1}</td>
                  <td className={styles.name}>
                    {obj?.coverImage && (
                      <img
                        src={obj?.coverImage}
                        alt={obj?.name}
                        className="w-[52px] h-[52px] object-cover"
                      />
                    )}
                    {obj?.campaignName}
                  </td>

                  <td className={styles.email}>{obj?.paymentStatus}</td>
                  <td className={styles.email}>
                    {obj?.sponsorShipAmt ? obj?.sponsorShipAmt + " Rs" : "-"}
                  </td>
                  <td className="">
                    {obj?.paymentStatus !== "PAYMENT_SUCCESS" &&
                      obj?.paymentStatus !== "NO_PAYMENT_NEEDED" && (
                        <button
                          className={`${
                            obj?.status !== "APPROVED" && styles.paymentDisabled
                          } ${styles.reviewButton}`}
                          onClick={() => {
                            obj?.status == "APPROVED"
                              ? handlePay(obj)
                              : setPaymentDisabled(true);
                          }}
                          disabled={loading}
                        >
                          {loading ? "Loading" : "Pay"}
                        </button>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
