import React, { useContext, useState } from "react";
import DynamicInput from "../DynamicInput/DynamicInput";
import styles from "./QuizContest.module.css";
import { organizerStorage } from "../../store/firebase-config";
import { singleFileUploadWithBucket } from "../../utils/SingleFileUpload";
import Context from "../../Context/Context";

export default function AddQuizQuestions({ setQuestions, setLoading }) {
  let qustionSkelton = {
    questionType: "multipleChoice",
    question: "",
    options: [],
    answer: "",
  };
  const [qAvalues, setQAValues] = useState(qustionSkelton);
  const { organizationId } = useContext(Context);

  const handleChange = (e) => {
    setQAValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleChangeOption = (e) => {
    setQAValues((prevState) => {
      let index = e.target.id;
      let value = e.target.value;
      let newOptions = prevState?.options;
      newOptions[index] = value;
      return {
        ...prevState,
        options: newOptions,
      };
    });
  };
  const handleAddOption = () => {
    setQAValues((prevState) => {
      let newOptions = prevState?.options;
      newOptions?.push("");
      return {
        ...prevState,
        options: newOptions,
      };
    });
  };
  const handleRemoveOption = (index) => {
    setQAValues((prevState) => {
      let newOptions = prevState?.options;
      newOptions?.splice(index, 1);
      return {
        ...prevState,
        options: newOptions,
      };
    });
  };
  const handleSelectAnswer = (answerIndex) => {
    setQAValues((prevState) => ({
      ...prevState,
      answerIndex,
    }));
  };
  const handleSave = () => {
    setQuestions((prevState) => {
      let newQuestions = prevState;
      let questionToAdd = {
        questionObject: {
          questionType: "multipleChoice",
          question: qAvalues?.question,
          options: qAvalues?.options,
          image: qAvalues?.image,
        },
        answer: qAvalues?.options[qAvalues?.answerIndex],
      };
      newQuestions?.push(questionToAdd);
      return [...newQuestions];
    });
    setQAValues(qustionSkelton);
  };
  const handleChooseFile = (id) => {
    const divElement = document.getElementById(id);
    if (divElement) {
      divElement.click();
    }
  };
  const handleChangeIcon = async (event) => {
    const key = event.target.id;
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    let uploadPath = `/${organizationId}/quizMedia/`;
    setLoading(true);
    let uploadFile = await singleFileUploadWithBucket(
      organizerStorage,
      "organizer_medias",
      imagedata,
      uploadPath
    );
    setQAValues((prevState) => ({
      ...prevState,
      image: uploadFile?.fileUrl,
    }));
    setLoading(false);
  };
  return (
    <div>
      <DynamicInput
        handleChange={handleChangeIcon}
        handleChooseFile={handleChooseFile}
        value={qAvalues?.image}
        field={{
          name: "Image",
          type: "MEDIA",
          id: "image",
          accept: "image/*",
        }}
      />
      <DynamicInput
        handleChange={handleChange}
        value={qAvalues?.question}
        field={{
          name: "Question",
          type: "text",
          id: "question",
        }}
      />
      <div>
        <p>Options</p>
        {qAvalues?.options?.map((option, index) => (
          <div className={styles.form}>
            <div />
            <div className="flex gap-[10px]">
              <DynamicInput
                handleChange={handleChangeOption}
                value={option}
                field={{
                  name: index + 1,
                  type: "text",
                  id: index,
                }}
              />
              <button onClick={() => handleRemoveOption(index)}>Remove</button>
              <button
                onClick={() => handleSelectAnswer(index)}
                className={qAvalues?.answerIndex == index ? "opacity-30" : ""}
              >
                Select as answer
              </button>
            </div>
          </div>
        ))}
        <div className={styles.form}>
          <div />
          <button onClick={handleAddOption} className="w-fit">
            Add Option
          </button>
        </div>
      </div>
      <div className={styles.form}>
        <p>Answer</p>
        <p>{qAvalues?.options && qAvalues?.options[qAvalues?.answerIndex]}</p>
      </div>
      <div className={styles.form}>
        <div />
        <button onClick={handleSave} className="w-fit ml-auto">
          Add
        </button>
      </div>
    </div>
  );
}
