import React from "react";
import AlertBox from "./AlertBox";
import CampaignAlertBox from "./CampaignAlertBox";
import Insight from "./Insight";
import ParticipantComponenet from "./ParticipantComponenet";
import PendingListOnPanel from "./PendingListOnPanel";
import styles from "./styles/RightPanel.module.css";
function RightPanel({
  cardTitile,
  title,
  type,
  description,
  rightPanelData,
  handleOpenClick,
  handleCloseClick,
  handleCloseLike,
  pendingApprovalList,
  pendingArrovalCount,
  status,
  likeCount,
  viewCount,
  userCount,
  participantCounter,
}) {
  const startDate = new Date(rightPanelData?.startDate);
  const endDate = new Date(rightPanelData?.endDate);
  const judgementCompleteDate = new Date(rightPanelData?.judgementCompleteDate);
  const winnerAnounceDate = new Date(rightPanelData?.winnerAnounceDate);

  const formattedEndDate = `${endDate.toLocaleString("default", {
    month: "long",
  })} ${endDate.getUTCDate()} ${endDate.getUTCFullYear()} , ${endDate.toLocaleTimeString()}`;

  const formattedStartDate = rightPanelData?.startDate
    ? `${startDate.toLocaleString("default", {
        month: "long",
      })} ${startDate.getUTCDate()} ${startDate.getUTCFullYear()} `
    : "";

  const formattedjudgementStartDate = rightPanelData?.endDate
    ? `${endDate.toLocaleString("default", {
        month: "long",
      })} ${endDate.getUTCDate()} ${endDate.getUTCFullYear()} `
    : "";

  const formattedjudgementCompleteDate = rightPanelData?.judgementCompleteDate
    ? `${judgementCompleteDate.toLocaleString("default", {
        month: "long",
      })} ${judgementCompleteDate.getUTCDate()} ${judgementCompleteDate.getUTCFullYear()} `
    : "";

  const formattedwinnerAnounceDate = rightPanelData?.winnerAnounceDate
    ? `${winnerAnounceDate.toLocaleString("default", {
        month: "long",
      })} ${winnerAnounceDate.getUTCDate()} ${winnerAnounceDate.getUTCFullYear()} `
    : "";
  return (
    <div className={`${styles.pannel}  custom-scrollbar`}>
      <p className={styles.name}> {cardTitile}</p>
      <p className={styles.cmpgTtile}>{title}</p>
      <p className={styles.cmpgDetails}>{description}</p>
      <div>
        {formattedStartDate && (
          <p className={styles.cmpgDetails}>
            Start Date : {formattedStartDate}
          </p>
        )}
        {formattedjudgementStartDate && (
          <p className={styles.cmpgDetails}>
            End Date : {formattedjudgementStartDate}
          </p>
        )}
        {formattedjudgementCompleteDate && (
          <p className={styles.cmpgDetails}>
            Judgement Completion Date: {formattedjudgementCompleteDate}
          </p>
        )}
        {formattedwinnerAnounceDate && (
          <p className={styles.cmpgDetails}>
            Winner Announcement Date: {formattedwinnerAnounceDate}
          </p>
        )}
      </div>
      <div className="mt-[23px]">
        {type === "Campaign" &&
          rightPanelData?.campaignStatus !== "PENDING_APPROVAL" && (
            <CampaignAlertBox
              rightPanelData={rightPanelData}
              status={status}
              handleOpenClick={handleOpenClick}
              handleCloseClick={handleCloseClick}
            />
          )}
        {type === "Contest" && (
          <AlertBox
            rightPanelData={rightPanelData}
            status={status}
            handleOpenClick={handleOpenClick}
            handleCloseClick={handleCloseClick}
            handleCloseLike={handleCloseLike}
          />
        )}
      </div>
      <div>{/* <ParticipantComponenet /> */}</div>
      <div>
        <PendingListOnPanel
          pendingApprovalList={pendingApprovalList}
          pendingArrovalCount={pendingArrovalCount}
          type={type}
          rightPanelData={rightPanelData}
        />
      </div>
      <div className="mt-[32px]">
        {rightPanelData?.campaignStatus !== "PENDING_APPROVAL" && (
          <Insight
            displayOn="right"
            likeCount={likeCount}
            viewCount={viewCount}
            type={type}
            userCount={userCount}
            participantCounter={participantCounter}
          />
        )}
      </div>
    </div>
  );
}

export default RightPanel;
