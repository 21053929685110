import React from "react";
import { useState } from "react";
import styles from "./AllEntries.module.css";
import ReviewDetails from "../PollContest/ReviewDetails";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";
import ViewEntry from "../PollContest/ViewEntry";

export default function AllEntriesOnContestPage({
  allEntriesList,
  totalCount,
}) {
  const { orgId, campaignId, contestId } = useContext(Context);
  const params = { orgId: orgId, cmpId: campaignId, conId: contestId };
  const navigate = useNavigate();
  const [popUp, setpopUp] = useState(false);
  const [popUpData, setpopUpData] = useState({});
  return (
    <div className={styles.pendingOnContestDetils}>
      {popUp && (
        <ViewEntry setpopUp={setpopUp} popupData={popUpData} popUp={popUp} />
      )}

      <div className={styles.pendingListContainer}>
        {allEntriesList &&
          allEntriesList?.map((obj, index) => {
            return (
              <div
                className="flex  items-center w-[100%]"
                key={index}
                onClick={() => {
                  setpopUp(true);
                  setpopUpData(obj);
                }}
              >
                <img
                  className="w-[24px] h-[24px] rounded-full object-cover mr-[10px]"
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
                <p>{obj?.participantName}</p>

                <div className={styles.approve}>View</div>
              </div>
            );
          })}
      </div>
      <div className={styles.bottomBand}>
        {totalCount > 5 && (
          <p className={styles.pendingNum}>
            +{totalCount - allEntriesList?.length} Entries
          </p>
        )}
        <button
          onClick={() =>
            navigate({
              pathname: "/campaigns/contests/contest/contest-all-entries",
              search: `?${createSearchParams(params)}`,
            })
          }
        >
          View All Entries
        </button>
      </div>
    </div>
  );
}
