import React, { useState } from "react";
import styles from "./WinnerSelectionList.module.css";
import AlertPopup from "../AlertPopups/AlertPopup";

function WinnerSelectionList({
  obj,
  declareOperations,
  removeWinners,
  selectedWinnersList,
  handleShowPopup,
  singleContestData,
  status,
}) {
  const [position, setPosition] = useState(obj?.position);
  const [positionError, setPositionError] = useState("");
  const prizeObj =
    (singleContestData?.prizeObj && singleContestData?.prizeObj[status]) || {};
  const handleSelectWinner = (obj) => {
    if (position) {
      declareOperations({
        position: position == "general" ? "general" : parseInt(position),
        ...obj,
        id: `#GOUS${obj?.userId}EN${obj._id}`,
      });
    } else {
      setPositionError("Select a postion");
    }
  };
  const errorPopupValues = {
    Icon: "",
    Heading: "Select a Position",
    Para: "Please choose a position to add this entry. Can add position from prize details on the contest more button.",
    Button1: "OK",

    Button2Color: "#5D4AFF",
  };
  return (
    <>
      {positionError && (
        <AlertPopup
          visible={positionError}
          setpopUp={setPositionError}
          button1Function={() => setPositionError("")}
          value={errorPopupValues}
        />
      )}
      <tbody
        className={styles.winnerSelectionParent}
        key={obj._id}
        onClick={() => handleShowPopup(obj)}
      >
        <tr className={styles.tableBottomLine}>
          <td className={` break-all p-[5px]  ${styles.userId}`}>
            <div className="flexio">
              <p>
                {" "}
                #GOUS{obj?.userId}EN{obj._id}
              </p>
            </div>
          </td>
          <td className=" break-all p-[5px]">
            <div className="flex items-center ">
              {/* <div className="md:h-[72px] md:min-w-[72px] h-[49px] min-w-[49px] rounded-full bg-blue-400 flex items-center justify-center mr-[10px] text-[24px] "> */}
              <div className={styles.nameDp}>
                {obj?.participantName?.charAt(0)}
              </div>
              <p>{obj?.participantName}</p>
            </div>
          </td>
          <td>
            <select
              onChange={(e) => {
                setPosition(e.target.value);
              }}
              onClick={(e) => e.stopPropagation()}
              value={position}
            >
              <option key="" value="">
                Select a position
              </option>
              {Object.keys(prizeObj)?.map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </td>
          {selectedWinnersList?.some(
            (el) => el?.id == `#GOUS${obj?.userId}EN${obj._id}`
          ) ? (
            <td
              className=" break-all p-[5px] cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                removeWinners({
                  ...obj,
                  id: `#GOUS${obj?.userId}EN${obj._id}`,
                });
              }}
            >
              <div className={styles.removeButton}>
                <p>Added</p>
              </div>
            </td>
          ) : (
            <td
              className=" break-all p-[5px] cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleSelectWinner(obj);
              }}
            >
              <div className={styles.addButton}>
                <p>Add</p>
              </div>
            </td>
          )}
        </tr>
      </tbody>
    </>
  );
}

export default WinnerSelectionList;
