import React from 'react'
import styles from "./styles/ParticipateComponent.module.css";

function ParticipantComponenet() {

  return (
    <div className="mt-[17px]" >
        <p className='mb-[14px] font-medium text-[16px] text-black'>Participants</p>
        <div className={styles.flexmo}>
            <img className={styles.propik} src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80" alt="" />
            <img className={styles.propik} src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80" alt="" />
            <img className={styles.propik} src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80" alt="" />
            <img className={styles.propik} src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80" alt="" />
        </div>
        <p className={styles.totlaXtra}>+ 1335 Participants</p>
    </div>
  )
}

export default ParticipantComponenet