import React from "react";
import styles from "./InfluencerPromotions.module.css";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import { isEmpty } from "lodash";

export default function InfluencerPromotions() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { organizationId, userIdToken, updateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [status, setStatus] = useState("APPROVED");

  useEffect(() => {
    if (organizationId) {
      getData();
    }
  }, [organizationId, updateData, status]);

  const getData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_REQUESTED_CAMPAIGNS}?type=PROMOTIONS&status=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="p-[20px]">
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Promotions</p>
      </div>
      <div className={styles.filterChips}>
        <div
          onClick={() => {
            setData([]);
            setStatus("APPROVED");
          }}
          className={
            status == "APPROVED" ? styles.eachChips : styles.eachChipsDark
          }
        >
          <p>Approved</p>
        </div>
        <div
          onClick={() => {
            setData([]);
            setStatus("REQUESTED");
          }}
          className={
            status == "REQUESTED" ? styles.eachChips : styles.eachChipsDark
          }
        >
          <p>Requested</p>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : isEmpty(data) ? (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        ) : (
          <div className={styles.promotionListGrid}>
            {data?.map((obj) => {
              return (
                <div className={styles.entry}>
                  <img src={obj?.bannerWeb} />
                  <p>{obj?.promotionHeader?.promotionName}</p>
                  <div className="p-[10px] flex flex-col gap-[5px]"></div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
