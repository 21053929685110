import React, { useState } from "react";
import styles from "./HelpPopup.module.css";
import helpIcon from "../../Assets/helpIcon.png";
import helpIconArrow from "../../Assets/helpIconArrow.png";
import closeButton from "../../Assets/closeButton.png";

function HelpPopup({ content }) {
  const [show, setShow] = useState(false);

  return (
    <div
      className="sm:relative ml-[10px] cursor-pointer"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      <img src={helpIcon} onClick={() => setShow(!show)} />
      {show && (
        <div className={styles.helpPopup}>
          <img src={helpIconArrow} className={styles.helpIconArrow} />
          <img
            src={closeButton}
            className={styles.closeButton}
            onClick={() => setShow(false)}
          />
          <p className={styles.popupContent}>
            {content}
            {/* <span className={styles.learnMore}>Learn More</span> */}
          </p>
        </div>
      )}
    </div>
  );
}

export default HelpPopup;
