import React from "react";
import styles from "./Pagination.module.css";
import left from "../../Assets/paginationLeftIcon.png";
import right from "../../Assets/paginationRightIcon.png";
import { useState } from "react";
import { useEffect } from "react";

function Pagination({ totalData, pageLimit, setPageNumber, currentPage }) {
  const [totalPages, setTotalPages] = useState(0);
  const pageNumbers = [];

  useEffect(() => {
    setTotalPages(Math.ceil(totalData / pageLimit));
  }, [setTotalPages, totalData]);

  for (let i = 1; i <= Math.ceil(totalData / pageLimit); i++) {
    pageNumbers.push(i);
  }

  const handlePreviousPageClick = () => {
    if (currentPage > 1) {
      setPageNumber((currentPage) => currentPage - 1);
    }
  };
  const handleNextPageClick = () => {
    if (currentPage < totalPages) {
      setPageNumber((currentPage) => currentPage + 1);
    }
  };

  return (
    <>
      <div className={styles.paginationParent}>
        <p>Total: {totalData} </p>
        {totalPages > 1 && (
          <div className={styles.pages}>
            <img
              src={left}
              onClick={handlePreviousPageClick}
              style={{ cursor: currentPage == 1 && "not-allowed" }}
            />
            {pageNumbers.map((obj, index) => (
              <p
                key={index}
                onClick={() => {
                  setPageNumber(obj);
                }}
                style={{
                  backgroundColor: obj == currentPage && "#3361FF",
                  color: obj == currentPage && "#FFFFFF",
                }}
              >
                {obj}
              </p>
            ))}
            <img
              src={right}
              onClick={handleNextPageClick}
              style={{ cursor: currentPage == totalPages && "not-allowed" }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Pagination;
