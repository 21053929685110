import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./HitUsers.module.css";
import Close from "../../Assets/close.svg";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useContext } from "react";
import Context from "../../Context/Context";
import LoadingAnimation from "../Loading/LoadingAnimation";
import user from "../../Assets/user.svg";

export default function HitUsers({
  showPopup,
  setShowPopup,
  cmpId,
  adsId,
  type,
}) {
  const [dataloading, setDataLoading] = useState(false);
  const [usersList, setUsersList] = useState({
    anonymousUsers: [],
    realUser: [],
  });

  const { userIdToken, verifyUser } = useContext(Context);
  useEffect(() => {
    verifyUser();
  }, []);
  const { currentUser } = useUserAuth();

  const token = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    setDataLoading(true);
    let cancel;
    let url = "";
    if (type == "influencer") {
      url = `${process.env.REACT_APP_GET_INF_CAMPAIGN_ANALYTICS_USERS}/${cmpId}/${adsId}`;
    } else {
      url = `${process.env.REACT_APP_GET_CAMPAIGN_ANALYTICS_USERS}/${cmpId}/${adsId}`;
    }
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        let data = res?.data?.data;
        let anonymousUsers = [];
        let realUser = [];
        data?.map((obj) => {
          if (obj?.userType == "anonymous") {
            anonymousUsers.push(obj);
          } else {
            realUser.push(obj);
          }
        });
        const groupedRealUsers = realUser?.reduce((acc, obj) => {
          const userId = obj?.userDetails?.userId;

          if (!acc[userId]) {
            acc[userId] = {
              userId: userId,
              name: obj?.userDetails?.name,
              hitCount: 0,
            };
          }

          acc[userId].hitCount += 1;

          return acc;
        }, {});

        const groupedRealUsersArray = Object.values(groupedRealUsers);
        setUsersList({
          anonymousUsers,
          realUser: groupedRealUsersArray,
        });
        setDataLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setDataLoading(false);
      });

    return () => cancel();
  }, []);

  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.parent}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Hits</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <table className="px-[20px] w-full">
              {usersList?.realUser?.map((obj, index) => {
                return (
                  <>
                    <tr key={index} className="p-[10px]">
                      <td className="p-[10px]">
                        <img
                          src={user}
                          alt=""
                          className="h-[40px] w-[40px] rounded-full overflow-hidden  object-cover"
                          onError={(e) => {
                            e.target.src = user;
                          }}
                        />
                      </td>
                      <td className="p-[10px]">
                        <p>{obj?.name}</p>
                      </td>
                      <td className="p-[10px]">
                        <p>{obj?.hitCount}</p>
                      </td>
                    </tr>
                    <div className="seperator" />
                  </>
                );
              })}
              {!dataloading && usersList?.anonymousUsers?.length > 0 && (
                <tr className="p-[10px]">
                  <td className="p-[10px]">
                    <img
                      src={user}
                      alt=""
                      className="h-[40px] w-[40px] rounded-full overflow-hidden  object-cover"
                      onError={(e) => {
                        e.target.src = user;
                      }}
                    />
                  </td>
                  <td className="p-[10px]">
                    <p>Anonymous Users</p>
                  </td>
                  <td className="p-[10px]">
                    {usersList?.anonymousUsers?.length}
                  </td>
                </tr>
              )}
            </table>
            {dataloading && <LoadingAnimation />}
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
