import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../Loading/LoadingAnimation";
import styles from "./Leads.module.css";
import dotsMoreOption from "../../Assets/dotsMoreOption.svg";
import AlertPopup from "../AlertPopups/AlertPopup";
import { useState } from "react";
import deleteIcon from "../../Assets/deleteIcon.svg";
import axios from "axios";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useEffect } from "react";
import StatusChangerLoading from "../Loading/StatusChangerLoading";
import LeadsMoreOptionDropDown from "../MoreOptionDropdown/LeadsMoreOptionDropDown";
import CommentPopup from "../CommentPopup/CommentPopup";

function LeadsListingSponsor({ leadsData, leadsDataLoading }) {
  const { verifyUser, userIdToken, updateData, setUpdateData } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const [showDeletePopup, setShowDeleteCampaignPopup] = useState(false);
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [contacted, setContacted] = useState(leadsData?.contacted);
  const [commentsObj, setCommentsObj] = useState([]);

  const [leadsId, setLeadsId] = useState();
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    verifyUser();
  }, []);
  const tokenData = userIdToken || currentUser?.accessToken;
  const navigate = useNavigate();
  const dateShortFormat = (dateString) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day}  ${monthName}  ${year}`;
  };
  const handleStatusMarker = async (id, status) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_GET_CHANGE_LEADS_STATUS}/${id}?command=STATUS&status=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoading(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleAddComment = async (comment) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_GET_CHANGE_LEADS_STATUS}/${leadsId}?command=COMMENT`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          comment: comment,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoading(false);
        setShowCommentPopup(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const deleteComment = async (commentId) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_DELETE_COMMENTS}/${leadsId}/${commentId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setLoading(false);
        setShowCommentPopup(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleLeadsDelete = async () => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_UPDATE_LEADS_DELETE}/${leadsId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setLoading(false);
        setShowDeleteCampaignPopup(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const popUpValues = {
    Icon: deleteIcon,
    Heading: "Delete this Lead",
    Para: "Are you sure you want to delete this lead? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Delete this Lead",
    Button2Color: "#D92D20",
  };
  const hanldeCloseDeletePopup = () => {
    setShowDeleteCampaignPopup(false);
  };
  const handleCancelComment = () => {
    setShowCommentPopup(false);
  };
  return (
    <>
      {/* {leadsDataLoading && <LoadingAnimation />} */}
      {Loading && <StatusChangerLoading />}
      {showDeletePopup && (
        <AlertPopup
          visible={showDeletePopup}
          setpopUp={setShowDeleteCampaignPopup}
          button1Function={hanldeCloseDeletePopup}
          button2Function={handleLeadsDelete}
          value={popUpValues}
        />
      )}
      {showCommentPopup && (
        <CommentPopup
          visible={showCommentPopup}
          setpopUp={setShowCommentPopup}
          handleAddComment={handleAddComment}
          commentsObj={commentsObj}
          deleteComment={deleteComment}
        />
      )}
      {leadsData && !leadsDataLoading && leadsData.length === 0 && (
        <div className="w-[100%] h-[50vh] flex flex-col items-center justify-center text-[#787878]">
          <span className="text-[17px] font-semibold">No data found</span>
        </div>
      )}
      {leadsData && !leadsDataLoading && leadsData.length > 0 && (
        <table className={styles.leadsTable}>
          <thead>
            <tr>
              <th>Sl no</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact info</th>
              <th>Date</th>
              <th>Comments</th>
              <th>Requirement</th>
            </tr>
          </thead>
          <tbody>
            {leadsData.map((obj, indexnumber) => {
              return (
                <tr>
                  <td className={styles.slno}>{indexnumber + 1}</td>
                  <td className={styles.name}>
                    {obj?.imageUrl && (
                      <img
                        src={obj?.imageUrl}
                        alt={obj?.name}
                        className="w-[52px] h-[52px] object-cover"
                      />
                    )}
                    {obj?.name}
                  </td>
                  <td className={styles.email}>
                    <p>{obj?.userEmail}</p>
                  </td>
                  <td className={styles.phone}>{obj?.phone}</td>
                  <td className={styles.date}>
                    <p>{dateShortFormat(obj?.createdAt)}</p>
                  </td>
                  <td className={styles.comment}>
                    {obj?.comments.length > 0 ? (
                      obj.comments.map((el) => {
                        return <p>{el?.comment}</p>;
                      })
                    ) : (
                      <p>-</p>
                    )}
                  </td>
                  <td className={styles.requirment}>
                    <p>{obj?.requirment}</p>
                    {/* <LeadsMoreOptionDropDown
                      setShowDeleteCampaignPopup={setShowDeleteCampaignPopup}
                      setShowCommentPopup={setShowCommentPopup}
                      setLeadsId={setLeadsId}
                      obj={obj}
                      handleStatusMarker={handleStatusMarker}
                      setCommentsObj={setCommentsObj}
                    /> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}

export default LeadsListingSponsor;
