import React, { useState, createContext, useContext, useEffect } from "react";
import Context from "../Context/Context";
import {
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { authentication } from "../store/firebase-config";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [emailError, setEmailError] = useState("");
  const [newUserId, setNewUserId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  //otp variable
  const [otpError, setOtpError] = useState("");
  const [otpResult, setOtpResult] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [numberVerificationLoading, setNumberVerificationLoading] =
    useState(false);
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const [urlPath, setUrlPath] = useState("");
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);

  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();
  const [previousLocation, setPreviousLocation] = useState();

  useEffect(() => {
    setUrlPath(window.location.href);
    // eslint-disable-next-line
  }, []);
  const userId = localStorage.getItem("userId");
  const loginChecker = async (accessToken) => {
    let config1 = {
      method: "post",
      url: `${process.env.REACT_APP_CREATE_ORGANIZER}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const loginCheck = await axios(config1);
    localStorage.setItem("userId", loginCheck.data?.userId);
  };
  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user !== null) {
        if (userId == null || userId == "undefined") {
          loginChecker(user.accessToken);
        }
      }
      setLoading(false);
      setUserLoading(false);
    });
    return unsubscribe;
  }, []);

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      setPageLoading(true);
      const login = await signInWithPopup(authentication, provider);

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_ORGANIZER}`,
        headers: {
          Authorization: `Bearer ${login._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        if (location?.state?.previousLocation) {
          navigate(
            {
              pathname: location?.state?.previousLocation,
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            },
            { state: location?.state }
          );
        } else {
          navigate(
            {
              pathname: "/",
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            },
            { state: location?.state }
          );
        }
        setPageLoading(false);
      } else {
        setPageLoading(false);

        navigate(`/signup`);
      }
    } catch (error) {
      setPageLoading(false);
      navigate(`/signup`);
    }
  };

  // second step
  const updateUserssProfile = async (name, photoUrl, setEditProfile) => {
    try {
      await updateProfile(currentUser, {
        displayName: name,
        photoURL: photoUrl,
      });
      setEditProfile(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  //otp signin
  const otpSignIn = async (number) => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        authentication
      );
      window.recaptchaVerifier.render();
      const otpData = await signInWithPhoneNumber(
        authentication,
        number,
        window.recaptchaVerifier
      );
      return otpData;
    } catch (error) {
      console.log("error", error);
    }
  };

  //Send OTP
  const sendOtp = async (phoneNumber) => {
    setNumberVerificationLoading(true);
    setOtpError("");
    if (phoneNumber === "" || phoneNumber === undefined)
      return setOtpError("Please enter a valid phone number!");
    try {
      const response = await otpSignIn(phoneNumber);

      setOtpResult(response);
      setNumberVerificationLoading(false);
      setOtpShow(true);
    } catch (err) {
      setOtpError(err.message);
    }
  };

  // Resend OTP
  const resendOtp = async (phoneNumber) => {
    signInWithPhoneNumber(
      authentication,
      phoneNumber,
      window.recaptchaVerifier
    );
  };

  //Verify Otp
  const verifyOtp = async (result, otp) => {
    setOtpError("");
    setOtpVerificationLoading(true);
    if (otp === "" || otp === null) return;
    try {
      const otpResponse = await result.confirm(otp);

      let config2 = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_ORGANIZER}`,
        headers: {
          Authorization: `Bearer ${otpResponse._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config2);
      if (loginCheck.status === 201) {
        window.location.href = "/";
      } else {
        setPageLoading(false);

        navigate(`/signup`);
      }
    } catch (error) {
      setPageLoading(false);
      navigate(`/signup`);
    }
  };

  //email signin
  const emailSignIn = async (name, email, password) => {
    try {
      setPageLoading(true);
      const response = await createUserWithEmailAndPassword(
        authentication,
        email,
        password
      );
      const updateUserProfile = await updateProfile(response?.user, {
        displayName: name,
      });
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_ORGANIZER}`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
        data: { name },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setNewUserId(loginCheck.data?.userId);
        if (location?.state?.previousLocation) {
          navigate(
            {
              pathname: location?.state?.previousLocation,
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            },
            { state: location?.state }
          );
        } else {
          navigate(
            {
              pathname: "/",
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            },
            { state: location?.state }
          );
        }
        setPageLoading(false);
        emailVerification(response?.user);
      }
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };
  //email login
  const emailLogin = async (email, password) => {
    try {
      setPageLoading(true);
      const response = await signInWithEmailAndPassword(
        authentication,
        email,
        password
      );

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_ORGANIZER}`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setNewUserId(loginCheck.data?.userId);
        if (location?.state?.previousLocation) {
          navigate(
            {
              pathname: location?.state?.previousLocation,
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            },
            { state: location?.state }
          );
        } else {
          navigate(
            {
              pathname: "/",
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            },
            { state: location?.state }
          );
        }
        setPageLoading(false);
      }
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }

      console.log("error", error);
    }
  };

  //email login password reset
  const emailPasswordReset = async (email) => {
    try {
      setPageLoading(true);
      const response = await sendPasswordResetEmail(authentication, email);

      return true;
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }

      console.log("error", error);
    }
  };
  //email login password reset
  const emailVerification = async (user) => {
    console.log("user", user);
    try {
      setPageLoading(true);
      const response = await sendEmailVerification(user);
      setPageLoading(false);

      return true;
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };
  return (
    <userAuthContext.Provider
      value={{
        googleSignIn,
        updateUserssProfile,
        currentUser,
        pageLoading,
        setPageLoading,
        setPreviousLocation,
        previousLocation,
        loading,
        updateUserssProfile,
        otpSignIn,
        verifyOtp,
        otpError,
        setOtpError,
        otpResult,
        setOtpResult,
        sendOtp,
        otpShow,
        setOtpShow,
        numberVerificationLoading,
        otpVerificationLoading,
        urlPath,
        resendOtp,
        userId,
        emailPasswordReset,
        emailLogin,
        emailSignIn,
        emailVerification,
        emailError,
        setEmailError,
        newUserId,
        userLoading,
      }}
    >
      {!loading && children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
