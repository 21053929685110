import React, { useContext } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./SelectAds.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import CreateAdd from "../CreateAdd/CreateAdd";
import { useEffect } from "react";
import Context from "../../Context/Context";
import axios from "axios";
import _, { isEmpty } from "lodash";
import LoadingAnimation from "../Loading/LoadingAnimation";
import adsJson from "../../Assets/adstemplate.json";
import Template1 from "../AdsTemplate/Template1/Template1";
import Template2 from "../AdsTemplate/Template2/Template2";
import Template3 from "../AdsTemplate/Template3/Template3";
import Template4 from "../AdsTemplate/Template4/Template4";
import Template5 from "../AdsTemplate/Template5/Template5";
import Template6 from "../AdsTemplate/Template6/Template6";
import UploadMedia from "../UploadMedia/UploadMedia";

export default function SelectAds({
  showPopup,
  setShowPopup,
  organizationId,
  setFormValues,
  setChangedValues,
  formValues,
  tokenData,
}) {
  const initialValue = { items: [] };
  const [ads, setAds] = useState([]);
  const [formData, setFormData] = useState({});

  const [adsObj, setAdsObj] = useState(initialValue);
  const [adsData, setAdsData] = useState([]);
  const [filteredAdsData, setFilteredAdsData] = useState([]);
  const { updateData, verifyUser } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [showAdsPopup, setShowAdsPopup] = useState(false);
  const [adsTemplate, setAdsTemplate] = useState([]);
  const [templateId, setTemplateId] = useState();
  const [templateDataItems, setTemplateDataItems] = useState({});
  const [templateDataObjects, setTemplateDataObjects] = useState({});
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getTemplates();
    getAdsData();
    getAdsTemplate();
    getBrandData();
  }, [organizationId, updateData]);
  const getTemplates = async () => {
    try {
      setLoading(true);
      setTemplateData(adsJson.templatesAdvertisements);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleSelectTemplate = (templateId) => {
    setTemplateId(templateId);
    const templateData = _.find(adsJson?.templatesAdvertisements, (data) => {
      return data.templateType === templateId;
    });
    setSelectedTemplate(templateData);
    setTemplateDataItems(templateData?.adsInnerItems);
    setTemplateDataObjects(templateData?.adsOuterItems);
  };
  const handleAdsOuterFields = (fieldName, value) => {
    setTemplateDataObjects((prevFields) => ({
      ...prevFields,
      [fieldName]: { ...prevFields[fieldName], value, error: "" },
    }));
    setAdsObj({ ...adsObj, [fieldName]: value });
  };
  const getAdsData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ADS}/${organizationId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setAdsData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleObjChange = (e) => {
    setAdsObj({ ...adsObj, [e.target.id]: e.target.value });
  };

  const handleSave = () => {
    let adsArray = formValues?.ads?.ads;
    adsArray.push(adsObj);
    setFormValues({
      ...formValues,
      ads: { ...formValues?.ads, ads: adsArray },
    });
    setChangedValues((prevState) => ({
      ...prevState,
      ads: { ...formValues?.ads, ads: adsArray },
    }));
    setShowPopup(false);
  };

  const handleSelectAds = (obj) => {
    let itemsArray = adsObj.items;
    itemsArray.push(obj);
    setAdsObj({ ...adsObj, items: itemsArray });
    adsDataFilter();
  };
  const handleRemoveAds = (obj) => {
    let itemsArray = adsObj.items;
    var index = itemsArray.findIndex((el) => el.videoName == obj.videoName);
    itemsArray.splice(index, 1);
    setAdsObj({ ...adsObj, items: itemsArray });
    adsDataFilter();
    setFilteredAdsData([obj, ...filteredAdsData]);
  };

  const adsDataFilter = () => {
    let adsFromApi = filteredAdsData;

    let filter = adsFromApi.filter(
      (el) => !adsObj?.items?.some((el2) => el2?.videoName === el?.videoName)
    );
    setFilteredAdsData(filter);
  };
  // GET BRAND DETAILS
  const getAdsTemplate = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ADS_TEMPLATE}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setAdsTemplate(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  // GET BRAND DETAILS
  const getBrandData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_BRAND_REQUSTS}/${organizationId}?type=ACCEPTED`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setBrandData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleBrandChange = (e) => {
    const brand = e.target.value;
    if (brand === "all") {
      setFilteredAdsData(adsData);
    } else {
      const filter = adsData.filter((el) => el.brandId == brand);
      setFilteredAdsData(filter);
    }
  };
  const handleSubmitOuter = (e) => {
    e.preventDefault();
    let valid = true;
    const updatedFields = { ...templateDataObjects };
    for (const fieldName in updatedFields) {
      if (!updatedFields[fieldName].value) {
        updatedFields[
          fieldName
        ].error = `${updatedFields[fieldName].inputDisplayName} is required`;
        valid = false;
      }
    }

    setTemplateDataObjects(updatedFields);

    if (valid) {
      let formObjectsOuter = {};
      let itemsArray = [];
      {
        Object.keys(templateDataObjects).map((fieldName) => {
          const field = templateDataObjects[fieldName];
          formObjectsOuter[fieldName] = field.value;
        });
      }

      let adsArray = formValues?.ads?.ads;
      let newBrandIds = formValues?.ads?.brandIds || [];

      adsObj?.items?.forEach((ad) => {
        const existingBrand = newBrandIds?.find(
          (brand) => brand?.brandId == ad?.brandId
        );
        if (existingBrand) {
          if (!existingBrand?.adsId?.includes(ad?.videoName)) {
            existingBrand?.adsId?.push(ad?.videoName);
          }
        } else {
          newBrandIds?.push({
            brandId: ad?.brandId,
            adsId: [ad.videoName],
          });
        }
      });

      adsArray.push({ ...adsObj, tempType: templateId, font: "inter" });
      setFormValues({
        ...formValues,
        ads: { ...formValues?.ads, ads: adsArray, brandIds: newBrandIds },
      });
      setChangedValues((prevState) => ({
        ...prevState,
        ads: { ...formValues?.ads, ads: adsArray, brandIds: newBrandIds },
      }));
      setFormData({ ...formObjectsOuter, items: [...itemsArray] });
      setShowPopup(false);
    }
  };

  return (
    <>
      {showAdsPopup && (
        <CreateAdd showPopup={showAdsPopup} setShowPopup={setShowAdsPopup} />
      )}
      <div className={styles.popup}>
        <div className={styles.selectAdsPopup}>
          <div className={`custom-scrollbar ${styles.content}`}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                {/* <img src={editCampaignHeaderLogo} /> */}
                <h2>Create Advertisement Template</h2>
              </div>
              <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
            </div>
            <div className={styles.body}>
              <div className={`custom-scrollbar ${styles.left}`}>
                <div>
                  <div className={styles.form}>
                    <label>Template</label>
                    <select
                      value={templateId}
                      onChange={(e) => handleSelectTemplate(e.target.value)}
                    >
                      <option value="">Select a template</option>
                      {templateData.map((option) => (
                        <option
                          key={option.templateType}
                          value={option.templateType}
                        >
                          {option.templateDisplayName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col gap-[8px]">
                    {selectedTemplate?.uploadMedia && (
                      <UploadMedia
                        finalImageUrl={medias}
                        setFinalImageUrl={setMedias}
                      />
                    )}
                    {templateDataObjects &&
                      Object.keys(templateDataObjects)?.map((fieldName) => {
                        const field =
                          templateDataObjects && templateDataObjects[fieldName];
                        if (field.inputType == "text") {
                          return (
                            <div key={fieldName} className={styles.form}>
                              <div>
                                <label>{field.inputDisplayName}</label>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  value={field.value}
                                  onChange={(e) =>
                                    handleAdsOuterFields(
                                      fieldName,
                                      e.target.value
                                    )
                                  }
                                  className={field.error && styles.inputError}
                                />
                                {field.error && (
                                  <div className={styles.error}>
                                    {field.error}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                        if (field.inputType == "color") {
                          return (
                            <div key={fieldName} className={styles.form}>
                              <div>
                                <label>{field.inputDisplayName}</label>
                              </div>
                              <div>
                                <input
                                  type="color"
                                  value={field.value}
                                  onChange={(e) =>
                                    handleAdsOuterFields(
                                      fieldName,
                                      e.target.value
                                    )
                                  }
                                  className={field.error && styles.inputError}
                                />
                                {field.error && (
                                  <div className={styles.error}>
                                    {field.error}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                        if (field.inputType == "select") {
                          return (
                            <div key={fieldName} className={styles.form}>
                              <div>
                                <label>{field.inputDisplayName}</label>
                              </div>
                              <div>
                                <select
                                  value={field.value}
                                  onChange={(e) =>
                                    handleAdsOuterFields(
                                      fieldName,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select an image</option>
                                  {medias?.map((file) => (
                                    <option
                                      key={file.fileUrl}
                                      value={file.fileUrl}
                                    >
                                      {file?.fileName}
                                    </option>
                                  ))}
                                </select>
                                <div className={styles.optionImage}>
                                  <img
                                    src={
                                      templateDataObjects &&
                                      templateDataObjects[fieldName]?.value
                                    }
                                  />
                                </div>
                                {field.error && (
                                  <div className={styles.error}>
                                    {field.error}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
                {/* SELECT BRAND */}
                <h2>Select Ads</h2>

                <div className={styles.form}>
                  <p>Brand</p>
                  <select
                    onChange={handleBrandChange}
                    // value={brandData?.findIndex(
                    //   (el) => el.brandId === formValues?.brandId
                    // )}
                    value={formValues?.brandId}
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="all">All Brands</option>
                    {brandData?.map((obj, index) => {
                      return (
                        <option key={index} value={obj?.brandId}>
                          {obj.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={styles.adsGrid}>
                  <div>
                    <h3>Ads</h3>
                    {loading ? (
                      <LoadingAnimation />
                    ) : (
                      <div className={styles.promotionListGrid}>
                        {filteredAdsData?.map((obj) => {
                          return (
                            <div
                              className={styles.entry}
                              onClick={() => handleSelectAds(obj)}
                            >
                              <img src={obj?.fileUrl} />
                              <p>{obj?.description}</p>
                            </div>
                          );
                        })}
                        <div
                          className={styles.entry}
                          onClick={() => setShowAdsPopup(true)}
                        >
                          {/* <img src={obj?.fileUrl} /> */}
                          <p>Create add</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <h3>Selected Adds</h3>
                    <div className={styles.promotionListGrid}>
                      {adsObj?.items?.map((obj) => {
                        return (
                          <div
                            className={styles.entry}
                            onClick={() => handleRemoveAds(obj)}
                          >
                            <img src={obj?.fileUrl} />
                            <p>{obj?.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`custom-scrollbar ${styles.right}`}>
                <>
                  {isEmpty(templateId) && <Template1 carousel={adsObj} />}
                  {templateId === "TEMP_2" && <Template2 carousel={adsObj} />}

                  {templateId === "TEMP_3" && <Template3 carousel={adsObj} />}
                  {templateId === "TEMP_4" && <Template4 carousel={adsObj} />}
                  {templateId === "TEMP_5" && <Template5 carousel={adsObj} />}
                  {templateId === "TEMP_6" && <Template6 carousel={adsObj} />}
                </>
              </div>
            </div>

            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                // onClick={handleSave}
                onClick={handleSubmitOuter}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
