import { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Context from "../../Context/Context";
import styles from "./PopupWrapper.module.css";
import PopupPortal from "../Layout/PopupPortal";

export const PopupWrapper = (props) => {
  const { visible, setpopUp, children, zIndex } = props;
  const { modalRef } = useContext(Context);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (visible && modalRef.current && !modalRef.current.contains(e.target)) {
        setpopUp(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [visible]);

  return (
    <PopupPortal>
      {visible && (
        <div className={styles.popupWrapper} style={{ zIndex: zIndex || 99 }}>
          {children}
        </div>
      )}
    </PopupPortal>
  );
};
