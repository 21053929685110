import styles from "../Components/Nav.module.css";
import Logo from "../Assets/Logo.svg";
import Down from "../Assets/down.svg";
import Share from "../Assets/share.svg";
import Srch from "../Assets/srch.svg";
import Bell from "../Assets/bell.svg";
import Eglabs from "../Assets/eglabs.svg";
import { useState, useContext, useEffect, useRef } from "react";
import Context from "../Context/Context";
import { Link, useNavigate } from "react-router-dom";
import AlertPopup from "./AlertPopups/AlertPopup";
import { authentication } from "../store/firebase-config";
function NavBar() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {
    dashboardRoles,
    organizerList,
    organizerListOption,
    setOrganizerListOption,
    setCampaignPageNo,
    setCampign,
    openNav,
    closeNav,
    dashboard,
    setDashboard,
    setOrganizationId,
    setOrgDomain,
    setCampaignIdToPersist,
    setOrganizerList,
    setDashboardRoles,
  } = useContext(Context);
  const dropdownRef = useRef();
  const [signout, setSignout] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        open &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const handleOrganizationClick = (index) => {
    navigate("/");
    setOrganizerListOption(index);
    setCampaignIdToPersist();
    setOrgDomain(organizerList && organizerList[index]?.orgDomain);
    setOrganizationId(organizerList && organizerList[index]?.orgId);
    localStorage.setItem("organizationIndex", index);
    setCampaignPageNo(1);

    setCampign([]);
  };
  const signoutPopupValues = {
    Icon: "",
    Heading: "Signout",
    Para: "Are you sure you want to signout? ",
    Button1: "Cancel",
    Button2: "Signout",
    Button2Color: "#5D4AFF",
  };
  const handleCloseSignoutPopup = () => {
    setSignout(false);
  };
  const handleSignout = () => {
    setOrganizerList([]);
    setDashboardRoles([]);
    authentication.signOut();
    setSignout(false);
    navigate("/signup");
  };

  useEffect(() => {
    if (dashboard == "influencer") {
      if (!window.location.pathname.startsWith("/inf")) {
        navigate("/inf");
      }
    } else if (dashboard == "sponsor") {
      if (!window.location.pathname.startsWith("/sp")) {
        navigate("/sp");
      }
    }
  }, []);
  const handleSwitchDashboard = (e) => {
    let button = e.target.id;
    if (button == "organizer") {
      navigate("/");
      setDashboard("organizer");
    } else if (button == "influencer") {
      navigate("/inf");

      setDashboard("influencer");
    } else if (button == "sponsor") {
      navigate("/sp");

      setDashboard("sponsor");
    }
    localStorage.setItem("dashboardType", button);
  };

  const [isSponsor, setIsSponsor] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [isOrganization, setIsOrganization] = useState(false);
  useEffect(() => {
    if (dashboardRoles?.includes("SPONSER")) {
      setIsSponsor(true);
    }
    if (dashboardRoles?.includes("INFLUENCER")) {
      setIsInfluencer(true);
    }
    if (dashboardRoles?.includes("ORGANIZATION")) {
      setIsOrganization(true);
    }
  }, [dashboardRoles]);

  return (
    <div>
      {signout && (
        <AlertPopup
          visible={signout}
          setpopUp={setSignout}
          button1Function={handleCloseSignoutPopup}
          button2Function={handleSignout}
          value={signoutPopupValues}
        />
      )}
      <div className={styles.navBar}>
        <div className="max-w-[1440px] w-[100%] mx-auto flex items-center justify-between md:px-[27px]">
          {/* <img src={Logo} alt="" /> */}
          <div className="flex items-center ">
            <div
              onClick={openNav}
              className={`mr-[10px] text-[30px] ${styles.openbutton}`}
            >
              ☰
            </div>
            <div
              onClick={closeNav}
              className={`mr-[10px] text-[30px] ${styles.closebutton}`}
            >
              ×
            </div>
            <p className={styles.logText}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="157"
                height="33"
                viewBox="0 0 157 33"
                fill="none"
                className="h-[30px]"
              
              >
                <path
                  d="M17.9011 17.4879L18.2266 15.9393L17.0704 17.0197C15.5426 18.4472 13.4497 19.2922 10.7252 19.2922C4.74232 19.2922 0.5 15.6973 0.5 10.5915C0.5 5.21663 5.17589 0.5 12.1695 0.5C17.0522 0.5 20.7067 2.65163 21.8018 5.76185L19.0803 6.92247C18.0745 4.3202 15.2477 2.95026 11.9288 2.95026C9.44433 2.95026 7.35338 3.72603 5.87732 5.05448C4.39854 6.38538 3.56542 8.24683 3.56542 10.3508C3.56542 12.4158 4.36017 14.0658 5.727 15.1902C7.08227 16.305 8.95659 16.8687 11.0729 16.8687C14.607 16.8687 17.7419 15.2897 18.6374 12.4538L18.8429 11.8032H18.1606H10.0949L10.4124 9.48671H21.6989L20.378 18.2847H17.7336L17.9011 17.4879Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M23.9694 17.454L24.1641 16.0561L23.1328 17.0197C21.6051 18.4472 19.5122 19.2922 16.7877 19.2922C10.8048 19.2922 6.56244 15.6973 6.56244 10.5915C6.56244 5.21663 11.2383 0.5 18.2319 0.5C23.1147 0.5 26.7691 2.65163 27.8642 5.76185L25.1428 6.92247C24.137 4.3202 21.3101 2.95026 17.9912 2.95026C15.5068 2.95026 13.4158 3.72603 11.9398 5.05448C10.461 6.38538 9.62786 8.24683 9.62786 10.3508C9.62786 12.4158 10.4226 14.0658 11.7894 15.1902C13.1447 16.305 15.019 16.8687 17.1354 16.8687C20.6694 16.8687 23.8043 15.2897 24.6999 12.4538L24.9053 11.8032H24.2231H16.1573L16.4749 9.48671H27.7672L26.4361 18.998H23.7544L23.9694 17.454Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M15.2008 0C7.97935 0 3.03131 4.89455 3.03131 10.5915C3.03131 16.0477 7.57816 19.7922 13.7565 19.7922C16.5916 19.7922 18.8116 18.9095 20.4431 17.385L20.1489 19.498H23.8398L25.3109 8.98671H13.0076L12.5529 12.3032H21.192C20.3896 14.8441 17.5277 16.3687 14.1042 16.3687C10.0388 16.3687 7.09673 14.2022 7.09673 10.3508C7.09673 6.41908 10.1993 3.45026 14.9601 3.45026C18.3836 3.45026 21.085 4.94804 21.8071 7.59591L25.4446 6.04463C24.455 2.40715 20.4163 0 15.2008 0Z"
                  fill="black"
                />
                <path
                  d="M45.539 9.22743C45.539 14.5126 41.085 19.2922 34.2172 19.2922C27.975 19.2922 23.8582 15.5171 23.8582 10.5647C23.8582 5.2803 28.3114 0.5 35.2068 0.5C41.4472 0.5 45.539 4.2736 45.539 9.22743ZM42.4735 9.4414C42.4735 7.51253 41.7279 5.87404 40.4022 4.72413C39.0828 3.5797 37.2264 2.95026 35.0463 2.95026C30.2288 2.95026 26.9236 6.21872 26.9236 10.3508C26.9236 12.2811 27.6771 13.9194 29.0055 15.0685C30.3276 16.2121 32.1835 16.8419 34.3509 16.8419C39.1963 16.8419 42.4735 13.5723 42.4735 9.4414Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M51.6016 9.22743C51.6016 14.5126 47.1477 19.2922 40.2798 19.2922C34.0377 19.2922 29.9209 15.5171 29.9209 10.5647C29.9209 5.2803 34.3741 0.5 41.2695 0.5C47.5099 0.5 51.6016 4.2736 51.6016 9.22743ZM48.5362 9.4414C48.5362 7.51253 47.7906 5.87404 46.4649 4.72413C45.1455 3.5797 43.2891 2.95026 41.109 2.95026C36.2915 2.95026 32.9863 6.21872 32.9863 10.3508C32.9863 12.2811 33.7398 13.9194 35.0682 15.0685C36.3903 16.2121 38.2462 16.8419 40.4136 16.8419C45.259 16.8419 48.5362 13.5723 48.5362 9.4414Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M37.2485 19.7922C44.363 19.7922 49.0703 14.8174 49.0703 9.22743C49.0703 3.93169 44.684 0 38.2381 0C31.0969 0 26.3896 4.97479 26.3896 10.5647C26.3896 15.8605 30.8027 19.7922 37.2485 19.7922ZM38.0777 3.45026C42.2501 3.45026 45.0049 5.85741 45.0049 9.4414C45.0049 13.2661 41.9826 16.3419 37.3823 16.3419C33.2366 16.3419 30.455 13.9348 30.455 10.3508C30.455 6.52607 33.5041 3.45026 38.0777 3.45026Z"
                  fill="black"
                />
                <path
                  d="M52.7724 0.794601H55.856L61.0619 15.7842L61.395 16.7436L61.9522 15.8945L71.8607 0.794601H75.049L63.0568 18.9984H59.1868L52.7724 0.794601Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M58.8348 0.794601H61.9185L67.1243 15.7842L67.4575 16.7436L68.0147 15.8945L77.9231 0.794601H81.1114L69.1193 18.9984H65.2492L58.8348 0.794601Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M66.3575 19.4984L79.0084 0.294601H74.6221L64.5655 15.6202L59.243 0.294601H55.0974L61.8641 19.4984H66.3575Z"
                  fill="black"
                />
                <path
                  d="M88.191 11.8571H87.7555L87.6957 12.2885L86.7659 18.9984H83.845L86.4057 0.794601H97.6859C99.6763 0.794601 101.245 1.31474 102.306 2.18841C103.357 3.0536 103.953 4.29908 103.953 5.8578C103.953 8.61999 102.188 10.8208 99.0144 11.5765L98.3846 11.7265L98.6888 12.2979L102.256 18.9984H99.0314L95.4274 12.1249L95.2869 11.8571H94.9845H88.191ZM88.1771 8.99903L88.0987 9.56735H88.6724H96.5893C97.9318 9.56735 99.0458 9.21016 99.8356 8.58296C100.632 7.95076 101.074 7.06014 101.074 6.07177C101.074 5.19196 100.723 4.43199 100.036 3.90607C99.3648 3.39271 98.4215 3.13787 97.2847 3.13787H89.4213H88.9856L88.926 3.56955L88.1771 8.99903Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M94.2534 11.8571H93.818L93.7582 12.2885L92.8284 18.9984H89.9074L92.4681 0.794601H103.748C105.739 0.794601 107.307 1.31474 108.369 2.18841C109.419 3.0536 110.015 4.29908 110.015 5.8578C110.015 8.61999 108.251 10.8208 105.077 11.5765L104.447 11.7265L104.751 12.2979L108.318 18.9984H105.094L101.49 12.1249L101.349 11.8571H101.047H94.2534ZM94.2396 8.99903L94.1612 9.56735H94.7349H102.652C103.994 9.56735 105.108 9.21016 105.898 8.58296C106.694 7.95076 107.137 7.06014 107.137 6.07177C107.137 5.19196 106.786 4.43199 106.098 3.90607C105.427 3.39271 104.484 3.13787 103.347 3.13787H95.4838H95.048L94.9885 3.56955L94.2396 8.99903Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M91.2223 12.3571H98.0158L101.76 19.4984H106.12L102.162 12.0629C105.532 11.2605 107.484 8.88012 107.484 5.8578C107.484 2.46104 104.863 0.294601 100.717 0.294601H89.0024L86.301 19.4984H90.2327L91.2223 12.3571ZM91.7038 9.06735L92.4526 3.63787H100.316C102.456 3.63787 103.606 4.60074 103.606 6.07177C103.606 7.73004 102.135 9.06735 99.6206 9.06735H91.7038Z"
                  fill="black"
                />
                <path
                  d="M113.52 14.5317H113.249L113.101 14.7585L110.334 18.9984H107.196L119.112 0.794601H122.902L129.341 18.9984H126.312L124.878 14.8677L124.761 14.5317H124.405H113.52ZM121.079 3.92798L120.743 2.96331L120.188 3.82069L115.266 11.4166L114.766 12.1885H115.686H123.255H123.959L123.727 11.5239L121.079 3.92798Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M119.582 14.5317H119.311L119.163 14.7585L116.396 18.9984H113.259L125.175 0.794601H128.964L135.404 18.9984H132.375L130.94 14.8677L130.823 14.5317H130.468H119.582ZM127.142 3.92798L126.806 2.96331L126.25 3.82069L121.329 11.4166L120.829 12.1885H121.748H129.318H130.021L129.79 11.5239L127.142 3.92798Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M121.873 0.294601L109.302 19.4984H113.635L116.551 15.0317H127.436L128.988 19.4984H133.08L126.286 0.294601H121.873ZM123.638 4.09256L126.286 11.6885H118.717L123.638 4.09256Z"
                  fill="black"
                />
                <path
                  d="M137.431 16.6016H150.362L150.022 18.9984H133.593L136.154 0.794601H139.076L136.936 16.0321L136.856 16.6016H137.431Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M143.493 16.6016H156.424L156.085 18.9984H139.655L142.216 0.794601H145.138L142.998 16.0321L142.918 16.6016H143.493Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M136.049 19.4984H153.488L153.969 16.1016H140.462L142.682 0.294601H138.75L136.049 19.4984Z"
                  fill="black"
                />
                <path
                  d="M76.8911 18.9984L79.4517 0.794601H82.3736L79.8129 18.9984H76.8911Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M82.9535 18.9984L85.5142 0.794601H88.436L85.8753 18.9984H82.9535Z"
                  fill="white"
                  stroke="black"
                />
                <path
                  d="M85.9802 0.294601H82.0485L79.3471 19.4984H83.2788L85.9802 0.294601Z"
                  fill="black"
                />
                <path
                  d="M90.8794 24.9018L87.8311 31.1464L86.7902 24.9018H85.4094L86.7796 32.5141H88.4046L91.4317 26.3649L92.3982 32.5141H94.0445L97.9957 24.9018H96.5724L93.386 31.1464L92.3876 24.9018H90.8794Z"
                  fill="black"
                />
                <path
                  d="M101.735 32.6307C104.539 32.6307 106.387 30.6481 106.387 28.4323C106.387 26.3437 104.656 24.7852 102.107 24.7852C99.303 24.7852 97.4443 26.7678 97.4443 28.9836C97.4443 31.0722 99.1862 32.6307 101.735 32.6307ZM102.065 25.9832C103.838 25.9832 104.985 27.001 104.985 28.5065C104.985 30.1286 103.732 31.4327 101.778 31.4327C100.004 31.4327 98.8463 30.4149 98.8463 28.9094C98.8463 27.2873 100.11 25.9832 102.065 25.9832Z"
                  fill="black"
                />
                <path
                  d="M108.614 29.5667H111.545L113.096 32.5141H114.593L112.99 29.4819C114.328 29.1957 115.135 28.2627 115.135 27.054C115.135 25.7288 114.126 24.9018 112.565 24.9018H107.913L106.84 32.5141H108.199L108.614 29.5667ZM108.784 28.4005L109.102 26.068H112.405C113.308 26.068 113.807 26.4709 113.807 27.107C113.807 27.8174 113.16 28.4005 112.087 28.4005H108.784Z"
                  fill="black"
                />
                <path
                  d="M115.356 32.5141H122.281L122.451 31.3267H116.885L117.788 24.9018H116.428L115.356 32.5141Z"
                  fill="black"
                />
                <path
                  d="M123.249 32.5141H127.274C130.227 32.5141 131.948 30.5739 131.948 28.4323C131.948 26.4709 130.44 24.9018 127.954 24.9018H124.322L123.249 32.5141ZM124.747 31.3479L125.49 26.068H127.805C129.558 26.068 130.556 27.1707 130.556 28.5065C130.556 30.0226 129.324 31.3479 127.328 31.3479H124.747Z"
                  fill="black"
                />
                <circle cx="80.2385" cy="27.1025" r="1.96139" fill="black" />
              </svg>
            </p>
          </div>
          {/* <div className="flex items-center gap-5 ml-[auto]">
            <div className={styles.searchBox}>
              <img src={Srch} alt="" />
              <input
                className="w-[100%]"
                style={{ backgroundColor: "transparent" }}
                type="text"
                placeholder="Search Campaign or Contest"
              />
            </div>
            <div className={styles.bel}>
              <img src={Bell} alt="" />
              <div className={styles.notification}>2</div>
            </div>
          </div> */}
          <div className="flex gap-[12px] justify-between items-cener">
            <Link to="/campaigns/create/Festival">
              <button
                className="commonButton"
                style={{ background: "#71bc73" }}
              >
                Create Campaign
              </button>
            </Link>

            {isInfluencer && (
              <button
                onClick={handleSwitchDashboard}
                id="influencer"
                className={
                  dashboard == "influencer"
                    ? "commonButton"
                    : "commonButtonNotSelected"
                }
              >
                Influencer
              </button>
            )}
            {isSponsor && (
              <button
                onClick={handleSwitchDashboard}
                id="sponsor"
                className={
                  dashboard == "sponsor"
                    ? "commonButton"
                    : "commonButtonNotSelected"
                }
              >
                Sponsor
              </button>
            )}

            {isOrganization && (
              <button
                onClick={handleSwitchDashboard}
                id="organizer"
                className={
                  dashboard == "organizer"
                    ? "commonButton"
                    : "commonButtonNotSelected"
                }
              >
                Organizer
              </button>
            )}
            <div
              className="relative ml-[30px]"
              onClick={() => setOpen(!open)}
              ref={dropdownRef}
            >
              <div
                className={`${styles.dropDown}  ${
                  open ? "rounded-t-[30px]" : "rounded-[30px]"
                }`}
              >
                {/* <img className={styles.prodropdown} src={Eglabs} alt="" /> */}
                <p>
                  {dashboard === "organizer"
                    ? organizerList[organizerListOption]?.OrgName
                    : "Profile"}
                </p>
                <img className="ml-[auto]" src={Down} alt="" />
              </div>
              {open === true && (
                <div className={`scrollbar-hide ${styles.option}`}>
                  {dashboard === "organizer" && (
                    <>
                      <h4>Organizations</h4>
                      <ul>
                        {organizerList?.map((el, index) => {
                          return (
                            <li>
                              <p
                                onClick={() => {
                                  handleOrganizationClick(index);
                                }}
                              >
                                {el?.OrgName}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  <h4 onClick={() => navigate("/profile")}>Accout</h4>
                  <h4 onClick={() => setSignout(true)}>Sign Out</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
