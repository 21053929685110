import React, { useState } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./AdvertiseMentForm.module.css";
import _ from "lodash";
const AdvertiseMentForm = ({
  buttonStyleForm,
  handleAdsOuterFields,
  handleAdsInnerFields,
  handleAddDynamicField,
  handleSubmitOuter,
  handleDelete,
  handleSubmitInner,
  templateDataObjects,
  medias,
  templateDataItems,
  showPopup,
  adsItems,
}) => {
  console.log("medias", medias);

  return (
    <div>
      <div className={styles.form}>
        {Object.keys(templateDataObjects).map((fieldName) => {
          const field = templateDataObjects[fieldName];
          if (field.inputType == "text") {
            return (
              <div key={fieldName}>
                <div>
                  <label>{field.inputDisplayName}</label>
                </div>
                <div>
                  <input
                    type="text"
                    value={field.value}
                    onChange={(e) =>
                      handleAdsOuterFields(fieldName, e.target.value)
                    }
                  />
                </div>
                {field.error && <div className="error">{field.error}</div>}
                <br></br>
              </div>
            );
          }
          if (field.inputType == "color") {
            return (
              <div key={fieldName}>
                <div>
                  <label>{field.inputDisplayName}</label>
                </div>
                <div>
                  <input
                    type="color"
                    value={field.value}
                    onChange={(e) =>
                      handleAdsOuterFields(fieldName, e.target.value)
                    }
                  />
                </div>
                {field.error && <div className="error">{field.error}</div>}
                <br></br>
              </div>
            );
          }
          if (field.inputType == "select") {
            return (
              <div key={fieldName}>
                <div>
                  <label>{field.inputDisplayName}</label>
                </div>
                <div>
                  <select
                    value={field.value}
                    onChange={(e) =>
                      handleAdsOuterFields(fieldName, e.target.value)
                    }
                  >
                    {medias &&
                      medias.map((file) => (
                        <option
                          key={file.fileUrl}
                          value={file.fileUrl}
                          style={{ backgroundImage: file?.fileUrl }}
                        >
                          {/* <img
                            style={{ width: '35px', height: '35px' }}
                            src={file?.fileUrl}
                            alt={file?.fileName}
                          /> */}
                        </option>
                      ))}
                  </select>
                </div>
                {field.error && <div className="error">{field.error}</div>}
                <br></br>
              </div>
            );
          }
        })}
      </div>
      <div>
        <button
          type="button"
          onClick={handleAddDynamicField}
          style={{ ...buttonStyleForm, backgroundColor: "#4ec2a5" }}
        >
          Add Dynamic Field
        </button>
      </div>
      {adsItems.length >= 0 &&
        adsItems.map((item, index) => {
          return (
            <div>
              <span style={{ color: "blue" }} key={index}>
                {item["name"]?.value}
              </span>
              <span>
                <button onClick={() => handleDelete(item.id)}>DELETE</button>
              </span>
            </div>
          );
        })}
      <PopupWrapper visible={showPopup}>
        <div style={{ backgroundColor: "#ffffff", width: "600px" }}>
          {Object.keys(templateDataItems).map((fieldName) => {
            const field = templateDataItems[fieldName];
            if (field.inputType == "text") {
              return (
                <div key={fieldName}>
                  <div>
                    <label>{field.inputDisplayName}</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={field.value}
                      onChange={(e) =>
                        handleAdsInnerFields(fieldName, e.target.value)
                      }
                    />
                  </div>
                  {field.error && <div className="error">{field.error}</div>}
                  <br></br>
                </div>
              );
            }
            if (field.inputType == "color") {
              return (
                <div key={fieldName}>
                  <div>
                    <label>{field.inputDisplayName}</label>
                  </div>
                  <div>
                    <input
                      type="color"
                      value={field.value}
                      onChange={(e) =>
                        handleAdsInnerFields(fieldName, e.target.value)
                      }
                    />
                  </div>
                  {field.error && <div className="error">{field.error}</div>}
                  <br></br>
                </div>
              );
            }
            if (field.inputType == "select") {
              return (
                <div key={fieldName}>
                  <div>
                    <label>{field.inputDisplayName}</label>
                  </div>
                  <div>
                    <select
                      value={field.value}
                      onChange={(e) =>
                        handleAdsInnerFields(fieldName, e.target.value)
                      }
                    >
                      {medias &&
                        medias.map((file) => (
                          <option key={file.fileUrl} value={file.fileUrl}>
                            <img src={file?.fileUrl} alt={file?.fileName} />
                          </option>
                        ))}
                    </select>
                  </div>
                  {field.error && <div className="error">{field.error}</div>}
                  <br></br>
                </div>
              );
            }
          })}
          <button
            type="submit"
            style={{ ...buttonStyleForm, backgroundColor: "#4ec2a5" }}
            onClick={handleSubmitInner}
          >
            Submit
          </button>
        </div>
      </PopupWrapper>
      <br></br>
      <div>
        <button
          type="submit"
          onClick={handleSubmitOuter}
          style={{ ...buttonStyleForm, backgroundColor: "#408099" }}
        >
          Save Template Advertisement
        </button>
      </div>
    </div>
  );
};

export default AdvertiseMentForm;
