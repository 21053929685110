import React from "react";
import styles from "../styles/PdfDowloder.module.css";
import Doc from "../../../Assets/doc.svg";
import { useNavigate } from "react-router-dom";

function PdfDownloder({
  exportToCSV,
  csvData,
  fileName,
  winnersPrepared,
  downloadCSV,
}) {
  const campaignId = window.location.href.split("/")[4];
  const orgId = window.location.href.split("/")[5];
  const contestId = window.location.href.split("/")[6];
  const navigate = useNavigate();
 
  return (
    <div className="">
      <div className={styles.box}>
        <p className={styles.inlines}>1. </p>
        <p className={styles.inlines}>
          Download participant’s PDF, print and create small chits.
        </p>
        <div
          className={`${styles.dowlodePdf} ${
            !winnersPrepared && styles.StatusClosed
          }`}
          onClick={
            // winnersPrepared ? (e) => exportToCSV(csvData, fileName) : undefined
            winnersPrepared ? (e) => downloadCSV() : undefined
          }
        >
          <img src={Doc} alt="" />

          <p>Download participant details</p>
        </div>
        <div>
          <p className={styles.inlines}>2. </p>
          <p className={styles.inlines}>Invite a guest for the lucky draw.</p>
        </div>
        <div className="mt-[30px]">
          <p className={styles.inlines}>3. </p>
          <p className={styles.inlines}>
            {" "}
            Declare the winner in the application.
          </p>
        </div>
        {/* <div className={styles.declarme}>
          <div
            // className={styles.declbutn}
            className={`${styles.declbutn} ${
              !winnersPrepared && styles.StatusClosed
            }`}
            onClick={
              winnersPrepared
                ? () =>
                    navigate(
                      `/contest/${campaignId}/${orgId}/${contestId}/luckydraw`
                    )
                : undefined
            }
          >
            <p>Declare the winners</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PdfDownloder;
