import React from "react";
import styles from "./PromotionPage.module.css";
import Plus from "../../Assets/plus.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import AlertPopup from "../../Components/AlertPopups/AlertPopup";
import Pagination from "../../Components/Pagination/Pagination";

export default function PromotionPage() {
  const navigate = useNavigate();
  const [promotionData, setPromotionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organizationId, userIdToken, setUpdateData, updateData } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [promotionId, setPromotionId] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [archivePopup, setArchivePopup] = useState(false);
  const [status, setStatus] = useState("ADMIN_APPROVED");
  useEffect(() => {
    if (organizationId) getPromotionData();
  }, [organizationId, updateData, status, pageNumber]);
  const handleAddPromotion = () => {
    const params = {
      orgId: organizationId,
    };
    navigate({
      pathname: `/promotions/create`,
      // search: `?${createSearchParams(params)}`,
    });
  };
  const getPromotionData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_PROMOTIONS}/${organizationId}?page=${pageNumber}&limit=9&status=${status}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setPromotionData(setApi?.data?.data);
      setTotalData(setApi?.data?.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error.message);
    }
  };
  const handleArchiveClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_PROMOTION_ARCHIVE}/${organizationId}/${promotionId}?status=ARCHIVED`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config);
      if (setApi.status == 201) {
        setArchivePopup(false);
        setLoading(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (error) {
      setArchivePopup(false);
      setLoading(false);
      console.log(error.message);
    }
  };
  const popUpValues = {
    Icon: "",
    Heading: "Move to archive",
    Para: "While performing this action, your shared URL won't be visible to the public.",
    Button1: "Cancel",
    Button2: "Move to archive",
    Button2Color: "#D92D20",
  };
  const hanldeCloseArchivePopup = () => {
    setArchivePopup(false);
  };
  return (
    <>
      {archivePopup && (
        <AlertPopup
          visible={archivePopup}
          setpopUp={setArchivePopup}
          button1Function={hanldeCloseArchivePopup}
          button2Function={handleArchiveClick}
          value={popUpValues}
        />
      )}
      <div className="p-[20px]">
        <div className="flex items-center w-[100%]">
          <p className="fifaHead">Promotions</p>
          <div className="flex gap-4 items-center ml-[auto]">
            <div className={styles.addNew} onClick={handleAddPromotion}>
              <img src={Plus} alt="" />
              <p className={styles.new}>Add New</p>
            </div>
          </div>
        </div>
        <div className={styles.filterChips}>
          <button
            onClick={() => {
              setStatus("ADMIN_APPROVED");
            }}
            className={
              status == "ADMIN_APPROVED" ? styles.selected : styles.notSelected
            }
          >
            Live
          </button>
          <button
            onClick={() => {
              setStatus("EDITED");
            }}
            className={
              status == "EDITED" ? styles.selected : styles.notSelected
            }
          >
            Unapproved
          </button>
          <button
            onClick={() => {
              setStatus("ARCHIVED");
            }}
            className={
              status == "ARCHIVED" ? styles.selected : styles.notSelected
            }
          >
            Archived
          </button>
        </div>
        <div>
          {loading ? (
            <LoadingAnimation />
          ) : (
            <>
              <div className={styles.promotionListGrid}>
                {promotionData?.map((obj) => {
                  return (
                    <div
                      className={styles.entry}
                      onClick={() => navigate(`/promotions/list/${obj?._id}`)}
                    >
                      <img src={obj?.fileUrl} />
                      <div className={styles.dataSection}>
                        <p>{obj?.promotionHeader?.promotionName}</p>
                        <div className="flex items-center gap-[5px] justify-end">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/promotions/list/${obj?._id}/edit`);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setArchivePopup(true);
                              setPromotionId(obj?._id);
                            }}
                          >
                            Archive
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="p-[20px]">
                <Pagination
                  totalData={totalData}
                  pageLimit="9"
                  setPageNumber={setPageNumber}
                  currentPage={pageNumber}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
