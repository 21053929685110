import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import HelpPopup from "../HelpPopup/HelpPopup";
import CommonRichTextEditor from "../RichtextEditor/CommonRichTextEditor";
import styles from "./EditContest.module.css";
import FormAttributes from "./FormAttributes";
import { singleFileUploadWithConfig } from "../../utils/SingleFileUpload";
import Context from "../../Context/Context";
import DynamicInput from "../DynamicInput/DynamicInput";
import PerformanceData from "./PerformanceData";
import { isEmpty } from "lodash";
import ExplanationDoc from "./ExplanationDoc";
import { getCurrentTimezone } from "../../utils/DateFormator";

export default function ContestFormInputs({
  contestFormValue,
  setContestFormValues,
  contestFormErrors,
  helpData,
  tokenData,
  contestData,
  campaignContestsDatas,
  setLoading,
}) {
  const { organizationId } = useContext(Context);
  const inputRef = useRef();
  const [source, setSource] = useState("");
  const [addExpDoc, setAddExpDoc] = useState(false);
  const [addPerformance, setAddPerfomance] = useState(false);
  const [editPerformance, setEditPerformance] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [addFormAttributes, setAddFormAttributes] = useState(false);
  const [additionalFields, setAdditionalField] = useState([
    "email",
    "mobile",
    "field1",
    "field2",
    "field3",
    "field4",
    "field5",
    "field6",
    "field7",
    "field8",
    "field9",
  ]);

  useEffect(() => {
    setSource(contestFormValue.contestPoster);
  }, [contestFormValue]);
  useEffect(() => {
    let formAttr = contestFormValue?.formAttributes;

    let propertyNames = formAttr?.flatMap((obj) => obj?.items);
    let propertyValues = formAttr?.reduce((result, obj) => {
      propertyNames?.forEach((name) => {
        if (obj?.property && obj?.property[name]) {
          result?.push(obj?.property[name]);
        }
      });
      return result;
    }, []);
    setAdditionalField((prevState) => {
      let newArray = prevState?.filter((el) => !propertyValues?.includes(el));
      return newArray;
    });
  }, [contestFormValue]);

  const handleFileChange = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    setSource(url);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPDATE_CONSTEST_POSTER}/${contestData?.orgId}/${campaignContestsDatas?._id}/${contestData?.contestId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: thumbnail,
    };
    setLoading(true);
    const coverUpdate = await axios(config);
    if (coverUpdate.status === 200) {
      setContestFormValues((prevState) => ({
        ...prevState,
        contestPoster: coverUpdate.data.posterUrl,
      }));
      setLoading(false);
    }
  };
  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleChangeInput = (e) => {
    const { id, value } = e.target;
    let formatedValue = value;
    if (id?.includes("Date")) {
      const currentTimezone = getCurrentTimezone();
      formatedValue = formatedValue + ":00.000" + currentTimezone;
    } else if (
      id == "pin" ||
      id == "allowedEntryCount" ||
      id == "maxFileNumber"
    ) {
      formatedValue = parseInt(value);
    }
    setContestFormValues((prevState) => ({
      ...prevState,
      [id]: formatedValue,
    }));
  };

  const handleFormAttrDelete = (obj) => {
    const { id } = obj;
    setContestFormValues((prevState) => {
      let formAttrArray = prevState?.formAttributes;
      const updatedItems = formAttrArray?.filter((item) => item.id !== id);
      return {
        ...prevState,
        formAttributes: updatedItems,
      };
    });
  };
  const handlePerformanceDelete = () => {
    setContestFormValues((prevState) => ({
      ...prevState,
      performanceData: {},
    }));
  };
  const handleEdit = (obj) => {
    setEditValues(obj);
    setEdit(true);
    setAddFormAttributes(true);
  };
  const handleEditPerformance = () => {
    setEditPerformance(true);
    setAddPerfomance(true);
  };
  const handleEditExpDoc = () => {
    setAddExpDoc(true);
  };
  const handleExpDocDelete = () => {
    setContestFormValues((prevState) => ({
      ...prevState,
      explanationDoc: {},
    }));
  };
  return (
    <div>
      {addFormAttributes && (
        <FormAttributes
          data={contestFormValue?.formAttributes}
          setValue={setContestFormValues}
          setPopup={setAddFormAttributes}
          additionalFields={additionalFields}
          setAdditionalField={setAdditionalField}
          edit={edit}
          editValues={editValues}
          setEdit={setEdit}
        />
      )}
      {addPerformance && (
        <PerformanceData
          data={contestFormValue?.performanceData}
          setValue={setContestFormValues}
          setPopup={setAddPerfomance}
        />
      )}
      {addExpDoc && (
        <ExplanationDoc
          data={contestFormValue?.explanationDoc}
          setValue={setContestFormValues}
          setPopup={setAddExpDoc}
        />
      )}
      <h3>Contest Details</h3>
      {/* cover image  */}
      <div className="relative h-[200px]">
        <input
          ref={inputRef}
          className="hidden"
          type="file"
          onChange={handleFileChange}
          accept=".jpg,.jpeg"
        />
        <div
          className="w-[100%] min-h-[60px]   overflow-hidden  cursor-pointer  "
          onClick={handleChoose}
        >
          {source ? (
            <div>
              <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                <img src={source} className="" />
              </div>
            </div>
          ) : (
            "Add Cover Image"
          )}
          <div className={styles.changeImageButton}>Change Cover Image</div>
        </div>
      </div>
      {/* contest name  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Name<span className="text-red-600">*</span>
            </p>
            {helpData?.contestName && (
              <HelpPopup content={helpData?.contestName} />
            )}
          </div>
          <input
            type="text"
            id="contestName"
            className={` ${
              contestFormErrors?.contestName && styles.inputError
            }`}
            onChange={handleChangeInput}
            value={contestFormValue?.contestName}
          />
        </div>
        {contestFormErrors?.contestName && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.contestName}
          </span>
        )}
      </div>
      {/* tagline */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Tag line</p>
            {helpData?.contestTagLine && (
              <HelpPopup content={helpData?.contestTagLine} />
            )}
          </div>
          <input
            type="text"
            className={` ${
              contestFormErrors?.contestTagLine && styles.inputError
            }`}
            id="contestTagLine"
            onChange={handleChangeInput}
            value={contestFormValue?.contestTagLine}
          />
        </div>
        {contestFormErrors?.contestTagLine && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.contestTagLine}
          </span>
        )}
      </div>
      {/* Specifications */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Specifications</p>
            {helpData?.specs && <HelpPopup content={helpData?.specs} />}
          </div>
          <input
            type="text"
            className={` ${contestFormErrors?.specs && styles.inputError}`}
            id="specs"
            onChange={handleChangeInput}
            value={contestFormValue?.specs}
          />
        </div>
        {contestFormErrors?.specs && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.specs}
          </span>
        )}
      </div>
      {/* contest description */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Description<span className="text-red-600">*</span>
            </p>
            {helpData?.contestDescription && (
              <HelpPopup content={helpData?.description} />
            )}
          </div>
          <textarea
            //   ref={textInputEmail}
            type="text"
            className={` ${
              contestFormErrors?.description && styles.inputError
            } scrollbar-hide `}
            id="description"
            onChange={handleChangeInput}
            value={contestFormValue.description}
          />
        </div>
        {contestFormErrors?.description && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors?.description}
          </span>
        )}
      </div>
      {/* theme color  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Theme Color<span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="color"
            className={` ${
              contestFormErrors?.buttonColor && styles.inputError
            }`}
            id="buttonColor"
            onChange={handleChangeInput}
            value={contestFormValue?.buttonColor}
          />
        </div>
        {contestFormErrors?.buttonColor && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors?.buttonColor}
          </span>
        )}
      </div>
      {/* button text name  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Participate Button Text<span className="text-red-600">*</span>
            </p>
          </div>
          <input
            type="text"
            id="buttonText"
            className={` ${contestFormErrors?.buttonText && styles.inputError}`}
            onChange={handleChangeInput}
            value={contestFormValue?.buttonText}
          />
        </div>
        {contestFormErrors?.buttonText && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.buttonText}
          </span>
        )}
      </div>
      {/* button Color  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Button Color<span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="color"
            className={` ${
              contestFormErrors?.participateBtnClr && styles.inputError
            }`}
            id="participateBtnClr"
            onChange={handleChangeInput}
            value={contestFormValue?.participateBtnClr}
          />
        </div>
        {contestFormErrors?.participateBtnClr && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors?.participateBtnClr}
          </span>
        )}
      </div>
      {/*FilterChips  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Select Filter<span className="text-red-600">*</span>
            </p>
          </div>
          <select
            value={contestFormValue?.groupId}
            onChange={handleChangeInput}
            id="groupId"
          >
            <option value="">Select an item</option>
            {campaignContestsDatas?.filterChips?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.displayText}
              </option>
            ))}
          </select>
        </div>
        {contestFormErrors?.participateBtnClr && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors?.participateBtnClr}
          </span>
        )}
      </div>
      {/* howIt WOrks */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              Steps to participate<span className="text-red-600">*</span>
            </p>
            {helpData?.howItsWorks && (
              <HelpPopup content={helpData?.howItsWorks} />
            )}
          </div>
        </div>
        <div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${contestFormErrors?.howItsWorks && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setContestFormValues}
              formValues={contestFormValue}
              keyToEdit="howItsWorks"
            />
          </div>
          {contestFormErrors?.howItsWorks && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.howItsWorks}
            </span>
          )}
        </div>
      </div>
      {/* contestRules */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              Contest Rules<span className="text-red-600">*</span>
            </p>
            {helpData?.contestRules && (
              <HelpPopup content={helpData?.contestRules} />
            )}
          </div>
        </div>
        <div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${contestFormErrors?.contestRules && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setContestFormValues}
              formValues={contestFormValue}
              keyToEdit="contestRules"
            />
          </div>
          {contestFormErrors?.contestRules && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.contestRules}
            </span>
          )}
        </div>
      </div>
      {/* rulesAndRegulations */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              Rules and Regulations<span className="text-red-600">*</span>
            </p>
            {helpData?.rulesAndRegulations && (
              <HelpPopup content={helpData?.rulesAndRegulations} />
            )}
          </div>
        </div>
        <div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${contestFormErrors?.rulesAndRegulations && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setContestFormValues}
              formValues={contestFormValue}
              keyToEdit="rulesAndRegulations"
            />
          </div>
          {contestFormErrors?.rulesAndRegulations && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.rulesAndRegulations}
            </span>
          )}
        </div>
      </div>

      {/* FORM ATTRIBUTES */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`my-[20px]  `}>Extra questions</p>
          </div>
        </div>
        <div className="my-[20px]">
          {contestFormValue?.formAttributes?.map((obj) => {
            return (
              <div className="flex items-center gap-[10px] my-[6px]">
                <p>{obj?.title}</p>
                <button onClick={() => handleEdit(obj)}>EDIT</button>
                <button onClick={() => handleFormAttrDelete(obj)}>
                  DELETE
                </button>
              </div>
            );
          })}
          {!isEmpty(additionalFields) && (
            <button
              className="my-[6px]"
              onClick={() => {
                setEdit(false);
                setEditValues({});
                setAddFormAttributes(true);
              }}
            >
              ADD
            </button>
          )}
        </div>
      </div>
      {/* is  private  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>Security</p>
            {helpData?.isPrivate && <HelpPopup content={helpData?.isPrivate} />}
          </div>
          <div className="flex gap-[30px]">
            <div className="flex items-center gap-[20px]">
              <div className="h-[18px] w-[18px]">
                <input
                  type="radio"
                  className="outline-none h-[18px] w-[18px]"
                  name="public"
                  id="pin"
                  onChange={(e) =>
                    setContestFormValues((prevState) => ({
                      ...prevState,
                      isPrivate: true,
                      privateType: "PIN",
                    }))
                  }
                  checked={contestFormValue?.privateType == "PIN"}
                />
              </div>
              <label
                htmlFor="pin"
                className={`hover:cursor-pointer ${styles.formdesc}`}
              >
                PIN
              </label>
            </div>
            <div className="flex items-center gap-[20px]">
              <div className="h-[18px] w-[18px]">
                <input
                  type="radio"
                  className="outline-none h-[18px] w-[18px]"
                  name="public"
                  id="voucher"
                  onChange={(e) =>
                    setContestFormValues((prevState) => ({
                      ...prevState,
                      isPrivate: true,
                      privateType: "VOUCHER",
                    }))
                  }
                  checked={contestFormValue?.privateType == "VOUCHER"}
                />
              </div>
              <label
                htmlFor="voucher"
                className={`hover:cursor-pointer ${styles.formdesc}`}
              >
                Voucher Code
              </label>
            </div>
            <div className="flex items-center gap-[20px]">
              <div className="h-[18px] w-[18px]">
                <input
                  type="radio"
                  className="outline-none h-[18px] w-[18px]"
                  name="public"
                  id="public"
                  onChange={(e) =>
                    setContestFormValues((prevState) => ({
                      ...prevState,
                      isPrivate: false,
                      privateType: "",
                    }))
                  }
                  checked={!contestFormValue?.isPrivate}
                />
              </div>
              <label
                htmlFor="public"
                className={`hover:cursor-pointer ${styles.formdesc}`}
              >
                Public
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* pin  */}
      {contestFormValue?.privateType == "PIN" && (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px]`}>
                Pin<span className="text-red-600">*</span>
              </p>
              {helpData?.pin && <HelpPopup content={helpData?.pin} />}
            </div>
            <input
              //   ref={textInputEmail}
              type="number"
              id="pin"
              onChange={handleChangeInput}
              value={contestFormValue?.pin}
            />
          </div>
        </div>
      )}
      {/* Auto Approval  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Auto Approval
            </p>
            {helpData?.autoApprove && (
              <HelpPopup content={helpData?.autoApprove} />
            )}
          </div>
          <input
            //   ref={textInputEmail}
            type="checkbox"
            // className={` ${formErrors.email && styles.inputError}`}
            onChange={(e) => {
              setContestFormValues((prevState) => ({
                ...prevState,
                autoApprove: !prevState.autoApprove,
              }));
            }}
            checked={contestFormValue.autoApprove}
          />
        </div>
      </div>
      {/* multiple files  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Allow multiple File upload <span className="text-red-600">*</span>
            </p>
            {helpData?.multipleUpload && (
              <HelpPopup content={helpData?.multipleUpload} />
            )}
          </div>
          <input
            //   ref={textInputEmail}
            type="checkbox"
            // className={` ${formErrors.email && styles.inputError}`}
            onChange={(e) => {
              setContestFormValues((prevState) => ({
                ...prevState,
                multipleUpload: !prevState.multipleUpload,
              }));
            }}
            checked={contestFormValue.multipleUpload}
          />
        </div>
      </div>
      {/* files numbber  */}
      {contestFormValue?.multipleUpload && (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>
                Number of Files<span className="text-red-600">*</span>
              </p>
            </div>
            <input
              type="number"
              id="maxFileNumber"
              className={` ${
                contestFormErrors?.maxFileNumber && styles.inputError
              }`}
              onChange={handleChangeInput}
              value={contestFormValue?.maxFileNumber}
            />
          </div>
          {contestFormErrors?.maxFileNumber && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.maxFileNumber}
            </span>
          )}
        </div>
      )}

      {/* multiple entries*/}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Limit multiple Entries <span className="text-red-600">*</span>
            </p>
            {helpData?.multipleEntriesLimited && (
              <HelpPopup content={helpData?.multipleEntriesLimited} />
            )}
          </div>
          <input
            //   ref={textInputEmail}
            type="checkbox"
            // className={` ${formErrors.email && styles.inputError}`}
            onChange={(e) => {
              setContestFormValues((prevState) => ({
                ...prevState,
                multipleEntriesLimited: !prevState.multipleEntriesLimited,
              }));
            }}
            checked={contestFormValue.multipleEntriesLimited}
          />
        </div>
      </div>
      {/* multipple entries numbber  */}
      {contestFormValue?.multipleEntriesLimited && (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>
                Number of Entries<span className="text-red-600">*</span>
              </p>
            </div>
            <input
              type="number"
              id="allowedEntryCount"
              className={` ${
                contestFormErrors?.allowedEntryCount && styles.inputError
              }`}
              onChange={handleChangeInput}
              value={contestFormValue?.allowedEntryCount}
            />
          </div>
          {contestFormErrors?.allowedEntryCount && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.allowedEntryCount}
            </span>
          )}
        </div>
      )}
      {/* REFERENCE VIDEO OR AUDIO */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`my-[20px]  `}>Reference File </p>
          </div>
        </div>
        <div className="my-[20px]">
          {!isEmpty(contestFormValue?.performanceData) ? (
            <div className="flex items-center gap-[10px] my-[6px]">
              <p>{contestFormValue?.performanceData?.fileName}</p>
              <button onClick={handleEditPerformance}>EDIT</button>
              <button onClick={handlePerformanceDelete}>DELETE</button>
            </div>
          ) : (
            <button
              className="my-[20px]"
              onClick={() => {
                setEditPerformance(false);
                setAddPerfomance(true);
              }}
            >
              ADD
            </button>
          )}
        </div>
      </div>
      {/* explanationDoc */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`my-[20px]  `}>Contest Explanation Document</p>
          </div>
        </div>
        <div className="my-[20px]">
          {!isEmpty(contestFormValue?.explanationDoc) ? (
            <div className="flex items-center gap-[10px] my-[6px]">
              <p>{contestFormValue?.explanationDoc?.fileName}</p>
              <button onClick={handleEditExpDoc}>EDIT</button>
              <button onClick={handleExpDocDelete}>DELETE</button>
            </div>
          ) : (
            <button
              className="my-[20px]"
              onClick={() => {
                setAddExpDoc(true);
              }}
            >
              ADD
            </button>
          )}
        </div>
      </div>
      {/* displayPlaceHolder  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Description placeholder</p>
          </div>
          <input
            type="text"
            id="displayPlaceHolder"
            className={` ${
              contestFormErrors?.displayPlaceHolder && styles.inputError
            }`}
            onChange={handleChangeInput}
            value={contestFormValue?.displayPlaceHolder}
          />
        </div>
        {contestFormErrors?.displayPlaceHolder && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.displayPlaceHolder}
          </span>
        )}
      </div>
      {/* start date  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Start Date<span className="text-red-600">*</span>
            </p>
            {helpData?.startDate && <HelpPopup content={helpData?.startDate} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${contestFormErrors.startDate && styles.inputError}`}
            id="startDate"
            onChange={handleChangeInput}
            value={contestFormValue?.startDate?.substring(0, 16)}
          />
        </div>
        {contestFormErrors.startDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.startDate}
          </span>
        )}
      </div>
      {/* endDate   */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              End Date<span className="text-red-600">*</span>
            </p>
            {helpData?.endDate && <HelpPopup content={helpData?.endDate} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${contestFormErrors.endDate && styles.inputError}`}
            id="endDate"
            onChange={handleChangeInput}
            value={contestFormValue?.endDate?.substring(0, 16)}
            min={contestFormValue?.startDate?.substring(0, 16)}
          />
        </div>
        {contestFormErrors.endDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.endDate}
          </span>
        )}
      </div>
      {/* judgementCompleteDate    */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              judgement Complete Date<span className="text-red-600">*</span>
            </p>
            {helpData?.judgementCompleteDate && (
              <HelpPopup content={helpData?.judgementCompleteDate} />
            )}
          </div>
          <input
            type="datetime-local"
            className={` ${
              contestFormErrors.judgementCompleteDate && styles.inputError
            }`}
            id="judgementCompleteDate"
            onChange={handleChangeInput}
            value={contestFormValue?.judgementCompleteDate?.substring(0, 16)}
            min={contestFormValue?.startDate?.substring(0, 16)}
          />
        </div>
        {contestFormErrors.judgementCompleteDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.judgementCompleteDate}
          </span>
        )}
      </div>
      {/* winner annoncement date   */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Winner Anounce Date<span className="text-red-600">*</span>
            </p>
            {helpData?.winnerAnounceDate && (
              <HelpPopup content={helpData?.winnerAnounceDate} />
            )}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${
              contestFormErrors.winnerAnounceDate && styles.inputError
            }`}
            id="winnerAnounceDate"
            onChange={handleChangeInput}
            value={contestFormValue?.winnerAnounceDate?.substring(0, 16)}
            min={contestFormValue?.startDate?.substring(0, 16)}
          />
        </div>
        {contestFormErrors.winnerAnounceDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.winnerAnounceDate}
          </span>
        )}
      </div>
      {/* FORM ATTRIBUTES */}
      {/* <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Form Attributes</p>
            {helpData?.contestTagLine && (
              <HelpPopup content={helpData?.contestTagLine} />
            )}
          </div>
          {contestFormValue?.formAttributes?.map((obj) => (
            <p
              onClick={() => {
                setShowAddFormAttributes(true);
                setFormAttributesValues(obj);
              }}
            >
              {obj.title}
            </p>
          ))}
        </div>
      </div> */}
    </div>
  );
}
