import React, { useContext, useState } from "react";
import Context from "../../Context/Context";
import Close from "../../Assets/close.svg";
import styles from "./SearchByDatePopup.module.css";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { values } from "lodash";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

export default function SearchByDatePopup({ visible, setpopUp, handleSearch }) {
  const { currentUser } = useUserAuth();
  const { modalRef, userIdToken } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState({});
  const [progress, setProgress] = useState(0);
  const [noData, setNoData] = useState(false);

  const handleChangedate = (e) => {
    let key = e.target.id;
    let value = e.target.value;
    setDate((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <PopupWrapper visible={visible} setpopUp={setpopUp} zIndex="100">
      <div className={styles.popUpContainer} ref={modalRef}>
        <div>
          <img
            src={Close}
            className="p-[20px] ml-auto cursor-pointer"
            onClick={() => setpopUp(false)}
          />
        </div>
        <div className={styles.content}>
          <input type="date" id="startDate" onChange={handleChangedate} />
          to
          <input
            type="date"
            id="endDate"
            onChange={handleChangedate}
            min={date?.startDate}
          />
        </div>
        <div className="w-full flex items-center gap-[30px] justify-end px-[16px] pb-[16px]">
          {progress > 0 && (
            <progress value={progress} max="100" className="w-full" />
          )}
          {noData && <p>No data from the given date range.</p>}
          <button
            className={styles.button}
            onClick={() => handleSearch(date)}
            disabled={loading}
          >
            {loading ? <LoadingCircle /> : "Search"}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
}
