import { useState } from "react";
import { useContext } from "react";
import Context from "../../Context/Context";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./PopUp.module.css";
import Close from "../../Assets/close.svg";

const CommentPopup = ({
  visible,
  setpopUp,
  handleAddComment,
  commentsObj,
  deleteComment,
}) => {
  const { modalRef } = useContext(Context);
  const [comment, setComment] = useState("");
  return (
    <PopupWrapper visible={visible} setpopUp={setpopUp}>
      <div className={styles.popUpContainer} ref={modalRef}>
        <div className={styles.headerContainer}>
          <div>
            <p className={styles.popUpHeader}>Add Comments</p>
            {/* <p className={styles.headerPara}>{value.Para}</p> */}
          </div>
        </div>

        <div className={styles.body}>
          {commentsObj?.length > 0 && (
            <div>
              <h4>Comments</h4>
              {commentsObj.map((obj) => {
                return (
                  <div className="flex gap-[5px]">
                    <p>{obj?.comment}</p>
                    <img
                      src={Close}
                      alt="x"
                      onClick={() => deleteComment(obj?._id)}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px]  `}>
                Comment<span className="text-red-600">*</span>
              </p>
            </div>
            <textarea
              type="text"
              className={`  scrollbar-hide `}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={() => setpopUp(false)}>
            <span className={styles.buttonSpan}>Cancel</span>
          </button>
          <button
            className={styles.proceedButton}
            onClick={() => handleAddComment(comment)}
          >
            <span className={styles.buttonSpanWhite}>Add Comment</span>
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default CommentPopup;
