import { useState, useContext, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import PendingLeads from "./PendingLeads";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import LoadingAnimation from "../Loading/LoadingAnimation";
import TopBanner from "../TopBanner/TopBanner";
import RightPanel from "../RightPanel/RightPanel";
import SortByFilter from "../Filters/SortByFilter";
import sortingJson from "../../Json/allEntriesSorting.json";
import RefreshButton from "../RefreshButton/RefreshButton";
import useGetDeletedEntries from "../../Hooks/useGetDeletedEntries";

function CampaignDeletedEntriesDetailPage() {
  const { currentUser } = useUserAuth();
  const {
    verifyUser,
    userIdToken,
    setUpdateData,
    updateData,
    setCampaignIdToPersist,
  } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { orgId, campaignId } = useContext(Context);
  const [headData, setHeadData] = useState({});
  const [loading, setLoading] = useState(false);
  const [participantCount, setParticipantCount] = useState("");
  const [sorting, setSorting] = useState("created");
  const [filter, setFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignContestData();
  }, [updateData]);
  const getCampaignContestData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ALL_CONTEST_DATA_CAMPAIGN}/${orgId}/${campaignId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data;
      setCampaignIdToPersist(campgnData?._id);
      setHeadData(campgnData);

      const participantCounter = _.get(campgnData, "participantCounter", 0);
      setParticipantCount(participantCounter);

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const { dataloadingEntries, entries, entryCount } = useGetDeletedEntries(
    pageNumber,
    10,
    tokenData,
    orgId,
    campaignId,
    0,
    "CAMPAIGN",
    sorting
  );
  const handleRefresh = () => {
    setPageNumber(1);
    setUpdateData((prevState) => prevState + 1);
  };
  return (
    <div className="content-wrapper ">
      <div className="center">
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className={`mobWidth paddingx`}>
            {/* <ContestHeadData found={headData} openNav={openNav} /> */}
            <TopBanner
              backgroundImage={headData?.coverImage}
              heading={headData?.campaignName}
              data={headData}
              breadCrumbItem={[
                { label: "Campaigns", url: "/campaigns" },
                {
                  label: "Contests",
                  url: "/campaigns/contests",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                  },
                },
                {
                  label: "Deleted Entries",
                  url: "/campaigns/contests/campaign-deleted-entries",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                  },
                },
              ]}
            />
            <div className="w-full flex items-center justify-between my-[20px]">
              <div className="flex gap-[10px] items-center">
                <SortByFilter
                  sorting={sorting}
                  setSorting={setSorting}
                  options={sortingJson.options}
                />
              </div>
              <RefreshButton handleRefresh={handleRefresh} />
            </div>

            <div className="mt-[45px]">
              <PendingLeads
                found={headData}
                pendingApprovalCount={entryCount}
                sorting={sorting}
                filter={filter}
                data={entries}
                dataloading={dataloadingEntries}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                type="allEntries"
              />
            </div>
          </div>
        )}
      </div>
      <div className=" right-sidebar">
        <RightPanel
          cardTitile="Campaign details"
          title={headData?.campaignName}
          type="Campaign"
          description={headData.description}
          rightPanelData={headData}
          status={headData.contestStatus}
          likeCount={headData?.TotalLikeCount}
          userCount={headData?.TotalVisitCount}
          viewCount={headData?.totalEntryViewCount}
          participantCounter={headData?.participantCounter}
        />
      </div>
    </div>
  );
}

export default CampaignDeletedEntriesDetailPage;
