import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import styles from "./SortByFilter.module.css";

export default function Filter({
  sorting,
  setSorting,
  options,
  setPageNumber,
}) {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const modalRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        showFilterDropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setShowFilterDropdown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showFilterDropdown]);
  const [selectedOption, setSelectedOption] = useState(sorting?.filter || "");
  const [selectedSubMenu, setSelectedSubMenu] = useState(sorting?.status || "");
  const handleFilterChange = (selectedOption) => {
    setPageNumber(1);
    setSorting(selectedOption);
    // setShowFilterDropdown(false);
  };

  useEffect(() => {
    if (selectedOption || selectedSubMenu) {
      handleFilterChange({ filter: selectedOption, status: selectedSubMenu });
    }
  }, [selectedOption, selectedSubMenu]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    let subMenu = options?.find((obj) => obj?.key === option)?.subMenu;
    setSelectedSubMenu(subMenu ? subMenu[0]?.key : ""); // Reset sub-menu when changing the main option
  };

  const handleSubMenuChange = (subMenuOption) => {
    setSelectedSubMenu(subMenuOption);
  };

  let subOptions =
    selectedOption &&
    selectedOption !== "" &&
    options?.find((option) => option.key === selectedOption)?.subMenu;

  return (
    <>
      <div className={styles.filterParent}>
        <select
          onChange={(e) => handleOptionChange(e.target.value)}
          value={selectedOption}
        >
          {options?.map((option) => (
            <option key={option.key} value={option.key}>
              {option.optionName}
            </option>
          ))}
        </select>
      </div>
      {subOptions && (
        <div className={styles.filterParent}>
          <select
            onChange={(e) => handleSubMenuChange(e.target.value)}
            value={selectedSubMenu}
          >
            {subOptions?.map((subOption) => (
              <option key={subOption?.key} value={subOption?.key}>
                {subOption?.optionName}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}
