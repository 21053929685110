import React from "react";
import styles from "./EditCampaign.module.css";

function ContestEditList({
  formValues,
  setFormValues,
  setContestFormValues,
  contestFormValue,
  handleSave,
}) {
  const handleContestClick = (obj) => {
    const success = handleSave();
    if (success) {
      setContestFormValues(obj);
      let newContestArray = formValues?.contests.map((el) =>
        contestFormValue.contestId === el.contestId ? contestFormValue : el
      );
      setFormValues({ ...formValues, contests: newContestArray });
      // setSelectedContest(obj);
    }
  };

  return (
    <div>
      <h3>Contests</h3>
      <div className={styles.contestEditGrid}>
        {formValues.contests.map((obj) => {
          if (obj.selected) {
            return (
              <div
                className={`${styles.contestInContestEdit} 
             ${
               contestFormValue.contestId === obj.contestId &&
               styles.selectedContestInContestEdit
             }
              `}
                key={obj.contestId}
                onClick={() => handleContestClick(obj)}
              >
                <img src={obj?.contestPoster} />
                <p>{obj?.contestName}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default ContestEditList;
