import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./PrizeClaimDetails.module.css";
import Close from "../../Assets/close.svg";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useContext } from "react";
import Context from "../../Context/Context";
import LoadingAnimation from "../Loading/LoadingAnimation";
import user from "../../Assets/user.svg";
import PrizeSentPopup from "../PrizeSentPopup/PrizeSentPopup";

export default function PrizeClaimDetails({ showPopup, setShowPopup, data }) {
  const { userIdToken, organizationId, verifyUser, setUpdateData } =
    useContext(Context);
  const [sentGift, setSentGift] = useState(false);
  const [updateCheck, setUpdateCheck] = useState({});
  useEffect(() => {
    verifyUser();
  }, []);
  const { currentUser } = useUserAuth();

  const token = userIdToken || currentUser?.accessToken;

  let orgId = data?.orgId;
  let cmpId = data?.campaignId;
  let cntstid = data?.contestId;
  let videoId = data?._id;

  const handleSentGift = () => {
    setSentGift(true);
  };

  const handleCloseButton = () => {
    if (updateCheck?.status) {
      setUpdateData((prevState) => prevState+1);
    }
    setShowPopup(false);
  };

  let giftClaimStatus = updateCheck?.giftClaimStatus || data?.giftClaimStatus;

  return (
    <PopupWrapper visible={showPopup}>
      {sentGift && (
        <PrizeSentPopup
          showPopup={sentGift}
          setShowPopup={setSentGift}
          data={data}
          setUpdateCheck={setUpdateCheck}
        />
      )}
      <div className={styles.parent}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Prize Claim Details </h2>
            </div>
            <img src={Close} alt="x" onClick={handleCloseButton} />
          </div>
          <div className={styles.body}>
            <div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  <strong>Name</strong> :
                </p>
                <p className={`text-description  `}>{data?.participantName}</p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  <strong>Contest</strong> :
                </p>
                <p className={`text-description  `}>{data?.contestName}</p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  <strong>Prize</strong> :
                </p>
                <p className={`text-description  `}>
                  {data?.prizeClaimDetails?.giftWorth}
                </p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap">
                  <strong>Prize Type</strong> :
                </p>
                <p className={`text-description  `}>
                  {data?.prizeClaimDetails?.prizeType}
                </p>
              </div>
              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold  whitespace-nowrap">
                  <strong>Contact</strong> :
                </p>
                <p className={`text-description  `}>
                  {data?.prizeClaimDetails?.address}
                </p>
              </div>

              <div className="mb-[30px] flex gap-[20px]">
                <p className="text-description-heading  font-bold whitespace-nowrap">
                  <strong>Proof</strong> :
                </p>
                <div className="h-[170px] w-full">
                  {data?.prizeClaimDetails?.proofData?.fileType?.startsWith(
                    "image"
                  ) ? (
                    <img
                      src={data?.prizeClaimDetails?.proofData?.fileUrl}
                      alt={`Image `}
                      className="h-full object-contain"
                    />
                  ) : (
                    <video
                      src={data?.prizeClaimDetails?.proofData?.fileUrl}
                      alt={`Video `}
                      autoPlay
                      muted
                      loop
                      className={`w-full h-full ${styles.active} object-contain`}
                      controls
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mb-[30px] flex gap-[20px]">
              <p className="text-description-heading  font-bold  whitespace-nowrap">
                <strong>Feedback</strong> :
              </p>
              <p className={`text-description  `}>
                {data?.prizeClaimDetails?.feedback}
              </p>
            </div>
            <div>
              <h3>Status ({giftClaimStatus})</h3>
              {giftClaimStatus == "ADDRESS_UPDATED" && (
                <button onClick={handleSentGift}>Sent Gift</button>
              )}
              {giftClaimStatus == "GIFT_SENTED" && (
                <button>Mark as Claimed</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
