import React, { useEffect, useRef } from "react";
import styles from "./Leads.module.css";
import LeadsListing from "./LeadsListing";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import { useState } from "react";
import LoadingAnimation from "../Loading/LoadingAnimation";
import RegistrationListing from "./RegistrationListing";
import ColorIndicator from "../ColorIndicator/ColorIndicator";
import PurchaseListing from "./PurchaseListing";

function Leads({
  selectedCampaign,
  leadsCampaignData,
  leadsPromotionData,
  handleLeadsSearch,
  leadsDataLoading,
  orgDomainLoading,
  mainDataLoading,
  leadsData,
  setSorting,
  filter,
  setFilter,
  sorting,
  leadsType,
  leadType,
  refferalData,
  setSearchDate,
}) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  return (
    <>
      {!orgDomainLoading && !mainDataLoading && (
        <div className={styles.leadsHeading}>
          <div
            className={styles.leadsDropDown}
            onClick={() => {
              setShowDropDown((prevState) => !prevState);
            }}
          >
            {leadsType === "campaign" && (
              <h2>{selectedCampaign?.campaignName}</h2>
            )}
            {leadsType === "promotion" && (
              <h2>
                {selectedCampaign?.promotionHeader?.promotionName ||
                  selectedCampaign?.name}
              </h2>
            )}
            <img src={leadsDropDown} alt="" />
          </div>

          {showDropDown && (
            <div className={styles.dropDownPopup}>
              {leadsType === "campaign" && (
                <>
                  <h4>Campaigns</h4>
                  {leadsCampaignData?.map((el, index) => {
                    return (
                      <p
                        onClick={() => {
                          handleLeadsSearch(el);
                          setShowDropDown(false);
                        }}
                      >
                        {el?.campaignName}
                      </p>
                    );
                  })}
                </>
              )}
              {leadsType === "promotion" && (
                <>
                  <h4>Promotions</h4>
                  {leadsPromotionData?.map((el, index) => {
                    return (
                      <p
                        onClick={() => {
                          handleLeadsSearch(el);
                          setShowDropDown(false);
                        }}
                      >
                        {el?.promotionHeader?.promotionName}
                      </p>
                    );
                  })}

                  {refferalData?.map((el, index) => {
                    return (
                      <p
                        onClick={() => {
                          handleLeadsSearch(el);
                          setShowDropDown(false);
                        }}
                      >
                        {el?.name}
                      </p>
                    );
                  })}
                </>
              )}
            </div>
          )}

          {/* filter  */}
          {(leadType == "campaign" || leadType == "promotion") && (
            <div className="mt-[20px]">
              <div
                className={styles.filterParent}
                onClick={() => {
                  setShowFilterDropdown((prevState) => !prevState);
                }}
              >
                <h2>Sort by Date</h2>
                <img src={leadsDropDown} alt="" />
              </div>

              {showFilterDropdown && (
                <div className={styles.dropDownPopup}>
                  <h4>Sort by Date</h4>
                  <p
                    onClick={() => {
                      setSorting("notCreatedAt");
                      setShowFilterDropdown(false);
                    }}
                    className={
                      sorting == "notCreatedAt" && styles.filterSelected
                    }
                  >
                    first created
                  </p>
                  <p
                    onClick={() => {
                      setSorting("createdAt");
                      setShowFilterDropdown(false);
                    }}
                    className={sorting == "createdAt" && styles.filterSelected}
                  >
                    last created
                  </p>
                </div>
              )}
            </div>
          )}
          {leadType == "REGISTER" && (
            <>
              <div className="mt-[20px]">
                <div
                  className={styles.filterParent}
                  onClick={() => {
                    setShowFilterDropdown((prevState) => !prevState);
                  }}
                >
                  <h2>Flter Registration</h2>
                  <img src={leadsDropDown} alt="" />
                </div>

                {showFilterDropdown && (
                  <div className={styles.dropDownPopup}>
                    <h4>Filter</h4>
                    <p
                      onClick={() => {
                        setFilter("PAYMENT_SUCCESS");
                        setShowFilterDropdown(false);
                      }}
                      className={
                        filter == "PAYMENT_SUCCESS" && styles.filterSelected
                      }
                    >
                      Payment Success
                    </p>
                    <p
                      onClick={() => {
                        setFilter("PAYMENT_PENDING");
                        setShowFilterDropdown(false);
                      }}
                      className={
                        filter == "PAYMENT_PENDING" && styles.filterSelected
                      }
                    >
                      Payment Pending
                    </p>
                    <p
                      onClick={() => {
                        setFilter("PENDING");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "PENDING" && styles.filterSelected}
                    >
                      Pending Approval
                    </p>
                    <p
                      onClick={() => {
                        setFilter("APPROVED");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "APPROVED" && styles.filterSelected}
                    >
                      Approved
                    </p>
                    <p
                      onClick={() => {
                        setFilter("REJECTED");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "REJECTED" && styles.filterSelected}
                    >
                      Declined
                    </p>

                    <p
                      onClick={() => {
                        setFilter("INSIDER");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "INSIDER" && styles.filterSelected}
                    >
                      Inside Student
                    </p>
                    <p
                      onClick={() => {
                        setFilter("OUTSIDER");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "OUTSIDER" && styles.filterSelected}
                    >
                      Outside Student
                    </p>
                    <p
                      onClick={() => {
                        setFilter("GENERAL");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "GENERAL" && styles.filterSelected}
                    >
                      General Public
                    </p>
                    <p
                      onClick={() => {
                        setFilter("FACULTY");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "FACULTY" && styles.filterSelected}
                    >
                      Faculty
                    </p>
                    <p
                      onClick={() => {
                        setFilter("FACULTY_PENDING");
                        setShowFilterDropdown(false);
                      }}
                      className={
                        filter == "FACULTY_PENDING" && styles.filterSelected
                      }
                    >
                      Faculty Pending Approval
                    </p>
                    <p
                      onClick={() => {
                        setFilter("FACULTY_APPROVED");
                        setShowFilterDropdown(false);
                      }}
                      className={
                        filter == "FACULTY_APPROVED" && styles.filterSelected
                      }
                    >
                      Faculty Approved
                    </p>
                    <p
                      onClick={() => {
                        setFilter("ALL");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "ALL" && styles.filterSelected}
                    >
                      All
                    </p>
                  </div>
                )}
              </div>

              <div className="flex flex-wrap items-center gap-[10px] mt-[20px]">
                <ColorIndicator
                  color="rgba(246,222,22,0.3)"
                  text="Pending Approval"
                />
                <ColorIndicator color="rgba(75,181,67,0.3)" text="Approved" />
                <ColorIndicator color="rgba(235, 77, 11,0.3)" text="Declined" />
              </div>
            </>
          )}
          {leadType == "PURCHASE" && (
            <>
              <div className="mt-[20px] flex gap-[10px]">
                <div
                  className={styles.filterParent}
                  onClick={() => {
                    setShowFilterDropdown((prevState) => !prevState);
                  }}
                >
                  <h2>Flter Data</h2>
                  <img src={leadsDropDown} alt="" />
                </div>

                {showFilterDropdown && (
                  <div className={styles.dropDownPopup}>
                    <h4>Filter</h4>
                    <p
                      onClick={() => {
                        setFilter("PAYMENT_SUCCESS");
                        setShowFilterDropdown(false);
                      }}
                      className={
                        filter == "PAYMENT_SUCCESS" && styles.filterSelected
                      }
                    >
                      Payment Success
                    </p>
                    <p
                      onClick={() => {
                        setFilter("PENDING");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "PENDING" && styles.filterSelected}
                    >
                      Payment Pending
                    </p>
                    <p
                      onClick={() => {
                        setFilter("ALL");
                        setShowFilterDropdown(false);
                      }}
                      className={filter == "ALL" && styles.filterSelected}
                    >
                      All
                    </p>
                  </div>
                )}
                <button
                  className={styles.filterParent}
                  onClick={() => setSearchDate(true)}
                >
                  Search by Date
                </button>
              </div>

              <div className="flex flex-wrap items-center gap-[10px] mt-[20px]">
                <ColorIndicator
                  color="rgba(75,181,67,0.3)"
                  text="Payment Success"
                />
                <ColorIndicator
                  color="rgba(235, 77, 11,0.3)"
                  text="Payment Pending"
                />
              </div>
            </>
          )}
        </div>
      )}
      {(leadsDataLoading || orgDomainLoading) && <LoadingAnimation />}
      {leadType == "REGISTER" && (
        <RegistrationListing
          leadsData={leadsData}
          leadsDataLoading={leadsDataLoading}
          orgDomainLoading={orgDomainLoading}
          mainDataLoading={mainDataLoading}
          selectedCampaign={selectedCampaign}
        />
      )}{" "}
      {(leadType == "campaign" || leadType == "promotion") && (
        <LeadsListing
          leadsData={leadsData}
          leadsDataLoading={leadsDataLoading}
        />
      )}
      {leadType == "PURCHASE" && (
        <PurchaseListing
          leadsData={leadsData}
          leadsDataLoading={leadsDataLoading}
          orgDomainLoading={orgDomainLoading}
          mainDataLoading={mainDataLoading}
        />
      )}
    </>
  );
}

export default Leads;
