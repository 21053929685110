import React, { useEffect, useState } from "react";
import _ from "lodash";
import { adsStorage } from "../../store/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import AdvertiseMentForm from "../AdvertiseMentForm/AdvertiseMentForm";
import styles from "./AdvertisementsPanel.module.css";
import adsJson from "../../Assets/adstemplate.json";
const buttonStyle = {
  display: "inline-block",
  padding: "10px 10px",
  backgroundColor: "#4CAF50",
  color: "white",
  textAlign: "center",
  textDecoration: "none",
  fontSize: "12px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};
const buttonStyleForm = {
  display: "inline-block",
  padding: "10px 10px",
  color: "white",
  textAlign: "center",
  textDecoration: "none",
  fontSize: "12px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};
function AdvertisementsPanel() {
  const { templateId } = useParams();
  const [formData, setFormData] = useState({});
  const [adsItems, setadsItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [shootmatesAlbumFiles, setShootmatesAlbumFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateDataItems, setTemplateDataItems] = useState({});
  const [templateDataObjects, setTemplateDataObjects] = useState({});
  const [finalImageUrl, setFinalImageUrl] = useState([]);
  const [totalFilesToUpload, setTotalFilesToUpload] = useState(0);
  const [currentUploadingFile, setCurrentUploadingFile] = useState({});
  const [progress, setProgress] = useState(0);
  const [currentUploadAlbumTask, setCurrentUploadAlbumTask] = useState(null);
  const [paused, setPaused] = useState(false);
  const [pendingAlbumFilesToUpload, setPendingAlbumFilesToUpload] = useState(0);
  useEffect(() => {
    getTemplates();
  }, []);
  const getTemplates = async () => {
    try {
      setLoading(true);
      const templateData = _.find(adsJson?.templatesAdvertisements, (data) => {
        return data.templateType === templateId;
      });
      console.log("templateData", templateData);
      setTemplateDataItems(templateData?.adsInnerItems);
      setTemplateDataObjects(templateData?.adsOuterItems);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const pauseAlbumFilesUpload = () => {
    if (currentUploadAlbumTask) {
      currentUploadAlbumTask.pause();
      setPaused(true);
    }
  };
  const resumeAlbumFilesUpload = () => {
    if (currentUploadAlbumTask) {
      currentUploadAlbumTask.resume();
      setPaused(false);
    }
  };
  const handleDropShootmates = (e) => {
    e.preventDefault();
    console.log("e.dataTransfer.files", e.dataTransfer.files);
    const files = Array.from(e.dataTransfer.files);
    setShootmatesAlbumFiles(files);
  };
  const uploadAlbumFiles = async (event) => {
    try {
      const totalFiles = shootmatesAlbumFiles.length;
      setTotalFilesToUpload(totalFiles);
      let currentIndex = 0;
      for (let i = 0; i < shootmatesAlbumFiles.length; i++) {
        const file = shootmatesAlbumFiles[i];
        let randomId = Math.floor(Date.now() * Math.random());
        const storageRef = ref(adsStorage, `${randomId}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setCurrentUploadAlbumTask(uploadTask);
        uploadTask.on("state_changed", (snapshot) => {
          if (!paused) {
            const progressPercentage = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setCurrentUploadingFile(file);
            setProgress(progressPercentage);
          }
        });
        await uploadTask;
        setCurrentUploadAlbumTask(null);
        // Get download URL
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        // push the file URL to the finalImageUrl array
        setFinalImageUrl((previousFileUrl) => [
          ...previousFileUrl,
          {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
            fileUrl: url,
          },
        ]);
        setShootmatesAlbumFiles((previousFiles) =>
          previousFiles.filter((fileData) => fileData.name !== file.name)
        );
        currentIndex++;
        setProgress(100);
        setPendingAlbumFilesToUpload(currentIndex);
      }
    } catch (error) {
      console.error(error);
    }
  };
  //---------------------------------------------------------Advertisement Form----------------------------------------------------------//

  const handleAdsOuterFields = (fieldName, value) => {
    setTemplateDataObjects((prevFields) => ({
      ...prevFields,
      [fieldName]: { ...prevFields[fieldName], value, error: "" },
    }));
  };
  const handleAdsInnerFields = (fieldName, value) => {
    setTemplateDataItems((prevFields) => ({
      ...prevFields,
      [fieldName]: { ...prevFields[fieldName], value, error: "" },
    }));
  };
  const handleAddDynamicField = () => {
    setShowPopup(true);
  };
  const handleSubmitOuter = (e) => {
    e.preventDefault();
    let valid = true;
    const updatedFields = { ...templateDataObjects };
    for (const fieldName in updatedFields) {
      if (!updatedFields[fieldName].value) {
        updatedFields[
          fieldName
        ].error = `${updatedFields[fieldName].inputDisplayName} is required`;
        valid = false;
      }
    }

    setTemplateDataObjects(updatedFields);

    if (valid) {
      let formObjectsOuter = {};
      let itemsArray = [];
      {
        Object.keys(templateDataObjects).map((fieldName) => {
          const field = templateDataObjects[fieldName];
          formObjectsOuter[fieldName] = field.value;
        });
      }
      console.log("formData", formData);
      adsItems &&
        adsItems.map((item) => {
          let formTemplateObjectsOuter = {};
          Object.keys(item).map((fieldName) => {
            const field = item[fieldName];
            formTemplateObjectsOuter[fieldName] = field.value
              ? field.value
              : item.id;
          });
          itemsArray.push(formTemplateObjectsOuter);
        });
      setFormData({ ...formObjectsOuter, items: [...itemsArray] });
    }
  };
  const handleSubmitInner = (e) => {
    console.log("hiiii1");
    e.preventDefault();
    let valid = true;
    const updatedFields = { ...templateDataItems };
    console.log("updatedFields", updatedFields);
    for (const fieldName in updatedFields) {
      if (!updatedFields[fieldName].value) {
        updatedFields[
          fieldName
        ].error = `${updatedFields[fieldName].inputDisplayName} is required`;
        valid = false;
      }
    }
    setTemplateDataItems(updatedFields);
    if (valid) {
      console.log("hoooooo");
      const randomId = Math.floor(Date.now() * Math.random());

      const newItem = {
        id: randomId,
        ...templateDataItems,
      };
      setadsItems((prevadsItems) => [...prevadsItems, newItem]);
      setTemplateDataItems(templateDataItems);
      console.log("hiii22");
      setShowPopup(false);
    }
  };
  const handleDelete = (deleteId) => {
    const adsItemsAfterRemoved = _.filter(adsItems, (data) => {
      console.log(data.id);
      return parseInt(deleteId) !== parseInt(data.id);
    });
    setadsItems(adsItemsAfterRemoved);
  };
  console.log("form", formData);
  console.log("adsItems", adsItems);
  return (
    <div className="p-[20px]">
      <div className="p-[10px] bg-[#E5EAFF] ">
        <div className="flex gap-[16px] mb-[20px]">
          <button onClick={pauseAlbumFilesUpload} style={buttonStyle}>
            Pause
          </button>
          <button onClick={resumeAlbumFilesUpload} style={buttonStyle}>
            Resume
          </button>
          <button onClick={uploadAlbumFiles} style={buttonStyle}>
            Upload
          </button>
          <button style={buttonStyle}>{progress}%</button>
          <span>
            {totalFilesToUpload} of {pendingAlbumFilesToUpload} Uploaded
          </span>
        </div>

        <div
          onDrop={handleDropShootmates}
          onDragOver={(e) => e.preventDefault()}
          className={styles.fileUpload}
        >
          {shootmatesAlbumFiles.length > 0 ? (
            <div className={styles.promotionListGrid}>
              {shootmatesAlbumFiles?.map((file) => {
                return (
                  <div className={styles.entry} key={file.name}>
                    <img src={URL.createObjectURL(file)} />
                    <p>{file?.name}</p>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>Drop files here</p>
          )}
        </div>
        <div className="mt-[20px]">Medias For Advertisement</div>
        <div
          onDrop={handleDropShootmates}
          onDragOver={(e) => e.preventDefault()}
          className={styles.fileUpload}
        >
          {finalImageUrl.length > 0 ? (
            <div className={styles.promotionListGrid}>
              {finalImageUrl?.map((file) => {
                return (
                  <div className={styles.entry} key={file.fileName}>
                    <img src={file?.fileUrl} />
                    <p>{file?.fileName}</p>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>Add media Files For Advertisement Template</p>
          )}
        </div>
      </div>
      <AdvertiseMentForm
        buttonStyleForm={buttonStyleForm}
        handleAdsOuterFields={handleAdsOuterFields}
        handleAdsInnerFields={handleAdsInnerFields}
        handleAddDynamicField={handleAddDynamicField}
        handleSubmitOuter={handleSubmitOuter}
        handleDelete={handleDelete}
        templateDataObjects={templateDataObjects}
        medias={finalImageUrl}
        templateDataItems={templateDataItems}
        showPopup={showPopup}
        adsItems={adsItems}
        handleSubmitInner={handleSubmitInner}
      />
    </div>
  );
}

export default AdvertisementsPanel;
