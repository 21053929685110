import React from "react";
import { useState } from "react";
import styles from "./EditCampaign.module.css";
import editCampaignContestIcon from "../../Assets/editCampaignContestIcon.png";
import { isEmpty } from "lodash";

export default function AddContestList({
  setFormValues,
  formValues,
  formErrors,
}) {
  const [contests, setContests] = useState(formValues?.contests);
  const addtoCampaign = (obj) => {
    const objCopy = { ...obj, selected: true };

    let newSelectedContests = formValues?.contests.map((el) =>
      objCopy._id === el._id ? objCopy : el
    );
    setContests(newSelectedContests);

    setFormValues({
      ...formValues,
      contests: newSelectedContests,
    });
  };

  const removeFromCampaign = (obj) => {
    const objCopy = { ...obj, selected: false };
    let newSelectedContests = formValues?.contests.map((el) =>
      objCopy._id === el._id ? objCopy : el
    );
    setContests(newSelectedContests);
    setFormValues({
      ...formValues,
      contests: newSelectedContests,
    });
  };

  return (
    <div>
      {!isEmpty(contests?.filter((obj) => obj.selected === true)) && (
        <>
          <h3>Selected Contests</h3>
          {contests
            .filter((obj) => obj.selected === true)
            .map((obj) => {
              return (
                <div className={styles.contest} key={obj._id}>
                  <img src={editCampaignContestIcon} />
                  <p>{obj.contestName}</p>
                  <p
                    className={styles.removeButton}
                    onClick={() => removeFromCampaign(obj)}
                  >
                    Remove
                  </p>
                </div>
              );
            })}
        </>
      )}
      {!isEmpty(contests?.filter((obj) => obj.selected === false)) && (
        <>
          <h3 className="mt-[30px]">Other Contests</h3>
          {contests
            .filter((obj) => obj.selected === false)
            .map((obj) => {
              return (
                <div className={styles.contest} key={obj._id}>
                  <img src={editCampaignContestIcon} />
                  <p>{obj.contestName}</p>
                  <p
                    className={styles.addButton}
                    onClick={() => addtoCampaign(obj)}
                  >
                    Add
                  </p>
                </div>
              );
            })}
        </>
      )}
      {formErrors?.contestEmpty && (
        <span className={styles.error}>
          <span className="text-red-600">*</span>
          {formErrors.contestEmpty}
        </span>
      )}
    </div>
  );
}
