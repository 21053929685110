import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useRef } from "react";
import Close from "../../Assets/close.svg";

import Context from "../../Context/Context";
import SelectAds from "../SelectAds/SelectAds";
import UploadGalleryItems from "../UploadPromotionMedia/UploadGalleryItems";
import UploadMoreMedia from "../UploadPromotionMedia/UploadMoreMedia";
import UploadPromotionMedia from "../UploadPromotionMedia/UploadPromotionMedia";
import styles from "./PromotionForm.module.css";

export default function PromotionForm({
  formValues,
  setFormValues,
  formErrors,
  setChangedValues,
  entryId,
  tokenData,
  organizationId,
}) {
  const [promotionLogo, setPromotionLogo] = useState(
    formValues?.promotionLogo || ""
  );
  const [gotoLink, setGotoLink] = useState(false);
  const [promoterLogo, setPromoterLogo] = useState(
    formValues?.promotionHeader?.promoterLogo || ""
  );
  const [showPopup, setShowPopup] = useState(false);

  const promotionLogoRef = useRef();
  const promoterLogoRef = useRef();
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
    setChangedValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleChangeHeader = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      showMoreHeader: {
        ...prevState?.showMoreHeader,
        [e.target.id]: e.target.value,
      },
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      showMoreHeader: {
        ...formValues?.showMoreHeader,
        [e.target.id]: e.target.value,
      },
    }));
  };
  const handleObjChange = (e) => {
    setFormValues({
      ...formValues,
      promotionHeader: {
        ...formValues.promotionHeader,
        [e.target.id]: e.target.value,
      },
    });
    setChangedValues((prevState) => ({
      ...prevState,
      promotionHeader: {
        ...formValues.promotionHeader,
        [e.target.id]: e.target.value,
      },
    }));
  };
  const handleFileChange = async (event) => {
    console.log("event.target.id", event.target.id);
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);
    if (event.target.id == "promotionLogo") {
      setPromotionLogo(url);
    }
    if (event.target.id == "promoterLogo") {
      setPromoterLogo(url);
    }
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_PROMOTION_UPLOAD_LOGO}/${organizationId}/${entryId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      if (event.target.id == "promotionLogo") {
        setFormValues({
          ...formValues,
          promotionLogo: upload.data.logoUrl,
        });
        setChangedValues((prevState) => ({
          ...prevState,
          promotionLogo: upload.data.logoUrl,
        }));
      }
      if (event.target.id == "promoterLogo") {
        setFormValues({
          ...formValues,
          promotionHeader: {
            ...formValues.promotionHeader,
            promoterLogo: upload.data.logoUrl,
          },
        });
        setChangedValues((prevState) => ({
          ...prevState,
          promotionHeader: {
            ...formValues.promotionHeader,
            promoterLogo: upload.data.logoUrl,
          },
        }));
      }
    }
  };

  const handlePromotionLogoChoose = () => {
    promotionLogoRef.current.click();
  };
  const handlePromoterLogoChoose = () => {
    promoterLogoRef.current.click();
  };

  const removeAdsRow = (index) => {
    let adsArray = formValues?.ads?.ads;
    adsArray.splice(index, 1);
    setFormValues({
      ...formValues,
      ads: {
        ...formValues?.ads,
        ads: adsArray,
      },
    });
    setChangedValues((prevState) => ({
      ...prevState,
      ads: {
        ...formValues?.ads,
        ads: adsArray,
      },
    }));
  };
  return (
    <div>
      {/* PROMOTer DEATILS  */}
      <h3>Promoter Details</h3>

      {/* PROMOTer NAME */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Promoter name
              <span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            id="promoterName"
            className={` ${formErrors.promoterName && styles.inputError}`}
            onChange={handleObjChange}
            value={formValues?.promotionHeader?.promoterName}
          />
        </div>
        {formErrors.promoterName && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.promoterName}
          </span>
        )}
      </div>
      {/* promotionName */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Promotion Name
              <span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="url"
            id="promotionName"
            className={` ${formErrors.promotionName && styles.inputError}`}
            onChange={handleObjChange}
            value={formValues?.promotionHeader?.promotionName}
          />
        </div>
        {formErrors.promotionName && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.promotionName}
          </span>
        )}
      </div>
      {/*PROMOTer LOGO */}
      <div className="flex flex-col gap-[10px] my-[20px]">
        <label
          htmlFor="logo"
          className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
        >
          Upload Promoter Logo
          <span className="text-red-600">*</span>
        </label>
        <input
          ref={promoterLogoRef}
          className="hidden"
          type="file"
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.svg"
          id="promoterLogo"
        />
        <div
          className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
          onClick={handlePromoterLogoChoose}
        >
          {promoterLogo ? (
            <div>
              <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                <img src={promoterLogo} className="w-auto h-[100%]" />
              </div>
              <p className=" font-Inter font-[400] text-[17px] leading-[24px] ">
                Change
              </p>
            </div>
          ) : (
            "Select"
          )}
        </div>
      </div>
      <hr className={styles.line} />

      {/*PROMOTION*/}
      <h3>Promotion Details</h3>

      {/* NAME  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Brand Name
              <span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            id="contactTitle"
            className={` ${formErrors.contactTitle && styles.inputError}`}
            onChange={handleChange}
            value={formValues.contactTitle}
          />
        </div>
        {formErrors.contactTitle && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.contactTitle}
          </span>
        )}
      </div>
      {/* TAGLINE  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Brand Tagline
              <span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            id="description"
            className={` ${formErrors.description && styles.inputError}`}
            onChange={handleChange}
            value={formValues.description}
          />
        </div>
        {formErrors.description && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.description}
          </span>
        )}
      </div>
      {/* contactUrl  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Brand contactUrl
              <span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="url"
            id="contactUrl"
            className={` ${formErrors.contactUrl && styles.inputError}`}
            onChange={handleChange}
            value={formValues.contactUrl}
          />
        </div>
        {formErrors.contactUrl && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.contactUrl}
          </span>
        )}
      </div>
      {/* LOGO */}
      <div className="flex flex-col gap-[10px] my-[20px]">
        <label
          htmlFor="logo"
          className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
        >
          Upload Brand Logo
          <span className="text-red-600">*</span>
        </label>
        <input
          ref={promotionLogoRef}
          className="hidden"
          type="file"
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.svg"
          id="promotionLogo"
        />
        <div
          className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
          onClick={handlePromotionLogoChoose}
        >
          {promotionLogo ? (
            <div>
              <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                <img src={promotionLogo} className="w-auto h-[100%]" />
              </div>
              <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                Change
              </p>
            </div>
          ) : (
            "Select"
          )}
        </div>
      </div>
      {/* UPLOAD Gallery media  */}
      <div className={styles.form}>
        <UploadGalleryItems
          formValues={formValues}
          setChangedValues={setChangedValues}
          setFormValues={setFormValues}
          tokenData={tokenData}
          entryId={entryId}
          organizationId={organizationId}
        />
      </div>
      {/* Gallery placement */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Gallery Placement</p>
          </div>
          <div>
            <div className="flex">
              <input
                type="radio"
                id="top"
                name="galleryPlacement"
                className={`  ${styles.formRadio}`}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    galleryPlacement: "top",
                  });
                  setChangedValues((prevState) => ({
                    ...prevState,
                    galleryPlacement: "top",
                  }));
                }}
                checked={formValues?.galleryPlacement == "top"}
              />
              <label for="showForm"> Top</label>
            </div>
            <div className="flex">
              <input
                //   ref={textInputEmail}
                type="radio"
                id="bottom"
                name="galleryPlacement"
                className={` ${formErrors.goto && styles.inputError} ${
                  styles.formRadio
                }`}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    galleryPlacement: "bottom",
                  });
                  setChangedValues((prevState) => ({
                    ...prevState,
                    galleryPlacement: "bottom",
                  }));
                }}
                checked={formValues?.galleryPlacement == "bottom"}
              />
              <label for="toLink">Bottom</label>
            </div>
          </div>
        </div>
        {formErrors.galleryPlacement && (
          <span className={styles.error}>{formErrors.galleryPlacement}</span>
        )}
      </div>

      <hr className={styles.line} />

      <h3>More Details</h3>
      {/* UPLOAD MEDIA  */}
      <div className={styles.form}>
        <UploadPromotionMedia
          formValues={formValues}
          setFormValues={setFormValues}
          setChangedValues={setChangedValues}
          tokenData={tokenData}
          entryId={entryId}
          organizationId={organizationId}
        />
      </div>

      {/* Header button text  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Header button text
              <span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            id="name"
            className={` ${formErrors.details && styles.inputError}`}
            onChange={handleChangeHeader}
            value={formValues?.showMoreHeader?.name}
          />
        </div>
        {formErrors.details && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.details}
          </span>
        )}
      </div>
      {/* BUTTON ACTION */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Button action</p>
          </div>
          <div>
            <div className="flex">
              <input
                //   ref={textInputEmail}
                type="radio"
                id="showForm"
                name="goto"
                className={`  ${styles.formRadio}`}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      type: "form",
                    },
                  });
                  setChangedValues((prevState) => ({
                    ...prevState,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      type: "form",
                    },
                  }));
                  setGotoLink(false);
                }}
                checked={formValues?.showMoreHeader?.type == "form"}
              />
              <label for="showForm"> Show form</label>
            </div>
            <div className="flex">
              <input
                //   ref={textInputEmail}
                type="radio"
                id="showForm"
                name="goto"
                className={`  ${styles.formRadio}`}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      type: "register",
                    },
                  });
                  setChangedValues((prevState) => ({
                    ...prevState,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      type: "register",
                    },
                  }));
                  setGotoLink(false);
                }}
                checked={formValues?.showMoreHeader?.type == "register"}
              />
              <label for="showForm"> Register</label>
            </div>
            <div className="flex">
              <input
                //   ref={textInputEmail}
                type="radio"
                id="toLink"
                name="goto"
                className={` ${formErrors.goto && styles.inputError} ${
                  styles.formRadio
                }`}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      type: "link",
                    },
                  });
                  setChangedValues((prevState) => ({
                    ...prevState,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      type: "link",
                    },
                  }));
                  setGotoLink(true);
                }}
                checked={formValues?.showMoreHeader?.type == "link"}
              />
              <label for="toLink"> Redirect to link</label>
            </div>
          </div>
        </div>
        {formErrors.goto && (
          <span className={styles.error}>{formErrors.goto}</span>
        )}
      </div>
      {/*GO TO LINK  */}
      {gotoLink && (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>Link</p>
            </div>
            <input
              //   ref={textInputEmail}
              type="url"
              id="url"
              className={` ${formErrors.contactTitle && styles.inputError}`}
              onChange={handleChangeHeader}
              value={formValues?.showMoreHeader?.url}
            />
          </div>
          {formErrors.goto && (
            <span className={styles.error}>{formErrors.goto}</span>
          )}
        </div>
      )}
      {/* DISPLAY HEADER */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Display Header Button</p>
          </div>
          <div>
            <div className="flex">
              <input
                //   ref={textInputEmail}
                type="checkbox"
                id="showForm"
                name="display"
                className={`  ${styles.formRadio}`}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      display: !formValues?.showMoreHeader?.display,
                    },
                  });
                  setChangedValues((prevState) => ({
                    ...prevState,
                    showMoreHeader: {
                      ...formValues.showMoreHeader,
                      display: !formValues?.showMoreHeader?.display,
                    },
                  }));
                }}
                checked={formValues?.showMoreHeader?.display}
              />
            </div>
          </div>
        </div>
        {formErrors.display && (
          <span className={styles.error}>{formErrors.display}</span>
        )}
      </div>
      {/* Description  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Description
              <span className="text-red-600">*</span>
            </p>
          </div>
          <textarea
            //   ref={textInputEmail}
            type="text"
            id="details"
            className={` ${formErrors.details && styles.inputError}`}
            onChange={handleChange}
            value={formValues.details}
          />
        </div>
        {formErrors.details && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.details}
          </span>
        )}
      </div>
      {/* Description  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Short Description
              <span className="text-red-600">*</span>
            </p>
          </div>
          <textarea
            //   ref={textInputEmail}
            type="text"
            id="aboutPromotion"
            className={` ${formErrors.aboutPromotion && styles.inputError}`}
            onChange={handleChange}
            value={formValues.aboutPromotion}
          />
        </div>
        {formErrors.aboutPromotion && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.aboutPromotion}
          </span>
        )}
      </div>
      {/* UPLOAD mORE MEDIA  */}
      <div className={styles.form}>
        <UploadMoreMedia
          formValues={formValues}
          setFormValues={setFormValues}
          setChangedValues={setChangedValues}
          tokenData={tokenData}
          entryId={entryId}
          organizationId={organizationId}
        />
      </div>
      {/* INFLUENCER */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Inviting Influencers</p>
          </div>
          <div>
            <div className="flex">
              <input
                //   ref={textInputEmail}
                type="checkbox"
                id="showCreateInfluencer"
                name="showCreateInfluencer"
                className={`  ${styles.formRadio}`}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    showCreateInfluencer: !formValues?.showCreateInfluencer,
                  });
                  setChangedValues((prevState) => ({
                    ...prevState,
                    showCreateInfluencer: !formValues?.showCreateInfluencer,
                  }));
                }}
                checked={formValues?.showCreateInfluencer}
              />
            </div>
          </div>
        </div>
        {formErrors.display && (
          <span className={styles.error}>{formErrors.display}</span>
        )}
      </div>
      <hr className={styles.line} />
      <h3>Ads</h3>
      {formValues?.ads?.ads?.map((obj, index) => {
        return (
          <div className="flex gap-[5px]">
            <p>Section {index + 1}</p>
            <img src={Close} alt="x" onClick={() => removeAdsRow(index)} />
          </div>
        );
      })}
      <p onClick={() => setShowPopup(true)}>Add Section</p>

      {showPopup && (
        <SelectAds
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          organizationId={organizationId}
          formValues={formValues}
          setFormValues={setFormValues}
          setChangedValues={setChangedValues}
          tokenData={tokenData}
        />
      )}
    </div>
  );
}
