import React, { useContext, useEffect, useState } from "react";
import Leads from "../../Components/Leads/Leads";
import Pagination from "../../Components/Pagination/Pagination";
import { useUserAuth } from "../../Context/UserAuthContext";
import Context from "../../Context/Context";
import { isEmpty } from "lodash";
import axios from "axios";

export default function InfluencerCmpLeadsPage() {
  const {
    orgDomain,
    verifyUser,
    userIdToken,
    orgDomainLoading,
    updateData,
    campaignIdToPersist,
    setCampaignIdToPersist,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [leadsData, setLeadsData] = useState([]);
  const [leadsCampaignData, setLeadsCampaignData] = useState([]);
  const [leadsType, setLeadsType] = useState("campaign");
  const [leadsDataLoading, setLeadsDataLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [sorting, setSorting] = useState("notCreatedAt");
  const tokenData = userIdToken || currentUser?.accessToken;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLeads, setTotalLeads] = useState();
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignDataList();
  }, []);

  const getCampaignDataList = async () => {
    setLeadsDataLoading(true);

    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_REQUESTED_CAMPAIGNS}?type=CAMPAIGNS&status=APPROVED`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data;
      let findCampaign;

      if (campaignIdToPersist) {
        findCampaign = campgnData?.find(
          (el) => el?.campaignId == campaignIdToPersist
        );
      }
      let filterCampaign = findCampaign || campgnData[0];
      getLeadsData(filterCampaign?.campaignId);
      setSelectedCampaign(filterCampaign);

      setLeadsCampaignData(campgnData);
      setLeadsDataLoading(false);

      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsDataLoading(false);
    }
  };

  const getLeadsData = async (promotionId) => {
    try {
      setLeadsDataLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_LEADS}/${promotionId}?type=CAMPAIGN`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setLeadsData(campgnData);
      setTotalLeads(setApi?.data?.totalCount);
      setLeadsDataLoading(false);
      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsData([]);
      setLeadsDataLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCampaign?.campaignId) {
      getLeadsData(selectedCampaign?.campaignId);
    }
  }, [sorting, pageNumber, updateData]);
  const handleLeadsSearch = (obj) => {
    setCampaignIdToPersist(obj?.campaignId);
    setSelectedCampaign(obj);
    setLeadsType("campaign");
    getLeadsData(obj?.campaignId);
  };

  return (
    <>
      <Leads
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        leadsCampaignData={leadsCampaignData}
        handleLeadsSearch={handleLeadsSearch}
        leadsDataLoading={leadsDataLoading}
        orgDomainLoading={orgDomainLoading}
        leadsData={leadsData}
        setSorting={setSorting}
        sorting={sorting}
        leadsType={leadsType}
        leadType="campaign"
      />
      <div className="p-[20px]">
        <Pagination
          totalData={totalLeads}
          pageLimit="10"
          setPageNumber={setPageNumber}
          currentPage={pageNumber}
        />
      </div>
    </>
  );
}
