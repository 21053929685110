import React from "react";
import styles from "./AdsApprovePage.module.css";
import Plus from "../../Assets/plus.svg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import { isEmpty } from "lodash";
import filterJson from "../../Json/approveAds.json";
import AccceptInfAdsRequest from "../../Components/AccceptInfAdsRequest/AccceptInfAdsRequest";

export default function AdsApprovePage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [reqData, setReqData] = useState({});

  const { organizationId, userIdToken, updateData, campaignId } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [status, setStatus] = useState("ALL");

  useEffect(() => {
    if (organizationId) {
      getData();
    }
  }, [organizationId, updateData, status]);
  const handleAddClick = () => {
    setShowPopup(true);
  };
  const getData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_ADS_ORG}/${organizationId}/${campaignId}?type=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleOpenRequest = (obj) => {
    setReqData(obj);
    setShowPopup(true);
  };
  return (
    <div className="p-[20px]">
      {showPopup && (
        <AccceptInfAdsRequest setPopup={setShowPopup} data={reqData} />
      )}
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Inflencers Ads Requests</p>
      </div>
      <div className={styles.filterChips}>
        {filterJson?.options?.map((obj) => {
          return (
            <div
              onClick={() => {
                setData([]);
                setStatus(obj?.key);
              }}
              className={
                status == obj?.key ? styles.eachChips : styles.eachChipsDark
              }
            >
              <p>{obj?.optionName}</p>
            </div>
          );
        })}
      </div>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : isEmpty(data) ? (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        ) : (
          <div className={styles.promotionListGrid}>
            {data?.map((obj) => {
              return (
                <div
                  className={styles.entry}
                  onClick={() => handleOpenRequest(obj)}
                >
                  <img src={obj?.dop} />
                  <p>{obj?.name}</p>
                  <p>{obj?.phone}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
