import React, { useEffect } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./CreateSpecialGuests.module.css";
import Close from "../../Assets/close.svg";
import ReactPlayer from "react-player";
import axios from "axios";
import { useRef } from "react";
import { useState } from "react";
import Context from "../../Context/Context";
import { useContext } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import CreateBrand from "../CreateBrand/CreateBrand";
import { ref, uploadBytesResumable } from "firebase/storage";
import closebutton from "../../Assets/closeButton.png";
import { organizerStorage } from "../../store/firebase-config";

export default function CreateSpecialGuests({ showPopup, setShowPopup }) {
  const logoRef = useRef();
  const inputRef = useRef();
  const [logo, setLogo] = useState("");
  const [createBrand, setCreateBrand] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [source, setSource] = useState([]);
  const [files, setFiles] = useState([]);
  const { userIdToken, organizationId, updateData, setUpdateData, verifyUser } =
    useContext(Context);
  useEffect(() => {
    verifyUser();
  }, [createBrand]);

  const { currentUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  //Update sponsor
  const patchDetails = async (uplodedMedia) => {
    try {
      let config2 = {
        method: "patch",
        url: `${process.env.REACT_APP_CREATE_GUESTS}/${organizationId}?type=special_guests`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { ...formValues, media: uplodedMedia },
      };
      const response2 = await axios(config2);

      setShowPopup(false);
      setUpdateData((prevState) => prevState + 1);
    } catch (error) {
      console.log(error.message);
    }
  };
  //Update ads Entry
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
  };

  const handleFileChange = async (event) => {
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);

    setLogo(url);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPLOAD_SPONSOR_LOGO}/${organizationId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      setFormValues((prevState) => ({
        ...prevState,
        dop: upload.data.url,
      }));
    }
  };
  const handleLogoChoose = () => {
    logoRef.current.click();
  };

  const handleMediaSelect = async (event) => {
    const fileList = [...files, ...event.target.files];
    setFiles(fileList);
    const newFileUrls = fileList.map((file) => ({
      fileType: file.type,
      fileUrl: URL.createObjectURL(file),
    }));
    setSource(newFileUrls);
  };
  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleDelete = (url) => {
    const index = source.indexOf(url);
    const newFileList = [...files];
    newFileList.splice(index, 1);
    setFiles(newFileList);

    const newFileUrls = source.filter((fileUrl) => fileUrl !== url);
    setSource(newFileUrls);
    URL.revokeObjectURL(url);
  };

  const uploadGoviralMedias = async () => {
    setLoading(true);

    try {
      const goviralMediaFiles = Array.from(files);
      const medias = [...goviralMediaFiles];
      let uploadedFiles = [];
      for (let i = 0; i < medias.length; i++) {
        const file = medias[i];

        // add goviralMediaFiles path
        let randomId = Math.floor(Date.now() * Math.random());
        const folderPath = `/${organizationId}/${randomId}`;
        const storageRef = ref(organizerStorage, folderPath);

        const uploadTask = uploadBytesResumable(storageRef, file);

        // Wait for the upload task to complete before proceeding to the next file
        await uploadTask;

        let uploadedFile = {
          fileSize: file.size,
          fileType: file.type,
          fileUrl:
            "https://storage.googleapis.com/organizer_medias" + folderPath,
        };

        uploadedFiles.push(uploadedFile);
      }

      return uploadedFiles;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const hadleSubmit = async () => {
    const response = await uploadGoviralMedias();
    patchDetails(response);
  };

  const handleChangeSocialMedias = (e) => {
    setFormValues({
      ...formValues,
      socialmedialink: {
        ...formValues?.socialmedialink,
        [e.target.id]: e.target.value,
      },
    });
  };
  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.createAdd}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Create Special Guests</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <>
              {/* FORM  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Name</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="promotorName"
                    className={` ${
                      formErrors.promotorName && styles.inputError
                    }`}
                    onChange={handleChange}
                    value={formValues?.promotorName}
                  />
                </div>
                {formErrors.promotorName && (
                  <span className={styles.error}>
                    {formErrors.promotorName}
                  </span>
                )}
              </div>
              {/* position  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>Position</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="position"
                    className={` ${formErrors.position && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues?.position}
                  />
                </div>
                {formErrors.position && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.position}
                  </span>
                )}
              </div>

              {/* LOGO */}
              <div
                className="flex flex-col gap-[10px] my-[20px]"
                onClick={handleLogoChoose}
              >
                <label
                  htmlFor="logo"
                  className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                >
                  <input
                    ref={logoRef}
                    className="hidden"
                    type="file"
                    onChange={handleFileChange}
                    accept=".jpg,.jpeg,.png,.svg"
                    id="logo"
                  />
                  Upload Profile Picture
                  <span className="text-red-600">*</span>
                </label>

                <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]">
                  {logo ? (
                    <div>
                      <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                        <img src={logo} className="w-auto h-[100%]" />
                      </div>
                      <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                        Change
                      </p>
                    </div>
                  ) : (
                    "Select"
                  )}
                </div>
              </div>
              {/* MEDIAS */}
              <div className="flex flex-col gap-[10px] my-[20px]">
                <label
                  htmlFor="media"
                  className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                >
                  <input
                    ref={inputRef}
                    className="hidden"
                    type="file"
                    multiple
                    onChange={handleMediaSelect}
                    accept="image/*,video/*"
                    id="media"
                  />
                  Upload Medias
                  <span className="text-red-600">*</span>
                </label>

                <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]">
                  <div className="grid grid-cols-3 gap-[18px]">
                    {source.map((obj) => {
                      if (obj?.fileType.startsWith("video")) {
                        return (
                          <div className="pb-[20px] w-[100%] relative h-[120px]">
                            <div
                              className="flex items-center justify-center absolute right-[-10px] top-[-10px] h-[20px] w-[20px] rounded-full bg-[#363636] p-[7px] cursor-pointer"
                              onClick={() => handleDelete(obj)}
                            >
                              <img src={closebutton} alt="x" />
                            </div>
                            <div className="h-[100%] w-[100%] overflow-hidden flex items-start justify-center">
                              <ReactPlayer
                                controls={true}
                                muted
                                light
                                width="auto"
                                height="100%"
                                url={obj?.fileUrl}
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="pb-[20px] w-[100%] relative h-[120px]">
                            <div
                              className="flex items-center justify-center absolute right-[-10px] top-[-10px] h-[20px] w-[20px] rounded-full bg-[#363636] p-[7px] cursor-pointer"
                              onClick={() => handleDelete(obj)}
                            >
                              <img src={closebutton} alt="x" />
                            </div>
                            <div className="h-[100%] w-[100%] overflow-hidden flex items-start justify-center">
                              <img
                                src={obj?.fileUrl}
                                className="outline-none w-auto h-[100%] object-cover"
                              />
                            </div>
                          </div>
                        );
                      }
                    })}

                    <p onClick={handleChoose}>
                      {source ? "Add more" : "Select"}
                    </p>
                  </div>
                </div>
              </div>
              {/* bio  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>Bio</p>
                  </div>
                  <textarea
                    //   ref={textInputEmail}
                    type="text"
                    id="about"
                    className={` ${formErrors.about && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues?.about}
                  />
                </div>
                {formErrors.about && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.about}
                  </span>
                )}
              </div>
              {/* email  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>Email ID</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="email"
                    className={` ${formErrors.email && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues?.email}
                  />
                </div>
                {formErrors.email && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.email}
                  </span>
                )}
              </div>

              {/* facebook  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>facebook</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="facebook"
                    className={` ${formErrors.facebook && styles.inputError}`}
                    onChange={handleChangeSocialMedias}
                    value={formValues?.socialmedialink?.facebook}
                  />
                </div>
                {formErrors.facebook && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.facebook}
                  </span>
                )}
              </div>
              {/* instagram  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>instagram</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="instagram"
                    className={` ${formErrors.instagram && styles.inputError}`}
                    onChange={handleChangeSocialMedias}
                    value={formValues?.socialmedialink?.instagram}
                  />
                </div>
                {formErrors.instagram && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.instagram}
                  </span>
                )}
              </div>
              {/* linkedIn  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>linkedIn</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="linkedIn"
                    className={` ${formErrors.linkedIn && styles.inputError}`}
                    onChange={handleChangeSocialMedias}
                    value={formValues?.socialmedialink?.linkedIn}
                  />
                </div>
                {formErrors.linkedIn && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.linkedIn}
                  </span>
                )}
              </div>
              {/* twitter  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>twitter</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="twitter"
                    className={` ${formErrors.twitter && styles.inputError}`}
                    onChange={handleChangeSocialMedias}
                    value={formValues?.socialmedialink?.twitter}
                  />
                </div>
                {formErrors.twitter && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.twitter}
                  </span>
                )}
              </div>
            </>

            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={hadleSubmit}
                disabled={loading}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
