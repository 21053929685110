import styles from "./styles/LuckDrw.module.css";
//import Timer from './Timer'
import { useState, useContext, useEffect } from "react";
//import PdfDownloder from './PdfDownloder.js/PdfDownloder'
import _ from "lodash";
import { ExportCSV } from "../../utils/ExportCSV";
import Context from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../Context/UserAuthContext";
import ContestHeadData from "./ContestHeadData";
import LoadingAnimation from "../Loading/LoadingAnimation";
import axios from "axios";
function Declaration() {
  const campaignId = window.location.href.split("/")[4];
  const orgId = window.location.href.split("/")[5];
  const [singleContestData, setSingleContest] = useState([]);
  const contestId = window.location.href.split("/")[6];
  const parsedContestId = parseInt(contestId);
  const { currentUser } = useUserAuth();
  const { verifyUser, userIdToken, setCampaignIdToPersist } =
    useContext(Context);
  const [participantData, setParticipantData] = useState([]);
  // const [singleContestData, setSingleContest] = useState([])
  const [loading, setLoading] = useState(false);
  const [headData, setHeadData] = useState({});
  const [contestStatus, setContestStatus] = useState("");
  //const [campaignStatus, setCampaignStatus] = useState('')
  const [participantCount, setParticipantCount] = useState("");
  const [pendingApprovalCount, setPendingApprovalCount] = useState("");
  //const [winnerSelectedStatus, setWinnerSelectedStatus] = useState('')
  //const [winnersList, setWinnersList] = useState([])
  const [winnersPrepared, setWinnersPrepared] = useState(false);
  const [winnersUrl, setWinnersUrl] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    verifyUser();
    getCampaignContestData();
    // excelParticipantFullLists()
  }, []);
  const tokenData = userIdToken || currentUser?.accessToken;
  // const excelParticipantFullLists = async () => {
  //   try {
  //     let config1 = {
  //       method: 'GET',
  //       url: `${process.env.REACT_APP_ALL_WINNERS_LISTS}/${orgId}/${campaignId}/${contestId}`,
  //       headers: {
  //         Authorization: `Bearer ${tokenData}`,
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //     const setApi = await axios(config1)
  //     setParticipantData(setApi.data.data)
  //   } catch (err) {
  //     console.log(err.message)
  //   }
  // }

  const getCampaignContestData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ALL_CONTEST_DATA_CAMPAIGN}/${orgId}/${campaignId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data;
      setCampaignIdToPersist(campgnData?._id);

      const contestDetails =
        campgnData &&
        campgnData?.contests.find((el) => {
          return el.contestId == contestId;
        });
      setHeadData(contestDetails);
      const participantCount = _.get(
        campgnData && campgnData.contestMetaData[parsedContestId],
        "participantCounter",
        0
      );
      setParticipantCount(participantCount);
      const pendingApprovalCount = _.get(
        campgnData && campgnData.contestMetaData[parsedContestId],
        "pendingApproval",
        0
      );
      setPendingApprovalCount(pendingApprovalCount);
      // const campaignStatus = _.get(campgnData, 'campaignStatus', '')
      // setCampaignStatus(campaignStatus)
      const contestStatus = _.get(contestDetails, "contestStatus", "");
      setContestStatus(contestStatus);
      // const winnerSelectedStatus = _.get(
      //   campgnData && campgnData.contestMetaData[parsedContestId],
      //   'winnerSelected',
      //   '',
      // )
      // setWinnerSelectedStatus(winnerSelectedStatus)
      // const winnersList = _.get(
      //   campgnData && campgnData.contestMetaData[parsedContestId],
      //   'winnersList',
      //   [],
      // )
      // setWinnersList(winnersList)
      const winnersPrepared = _.get(
        campgnData && campgnData.contestMetaData[parsedContestId],
        "winnersPrepared",
        false
      );
      setWinnersPrepared(winnersPrepared);

      const winnerList = _.get(
        campgnData && campgnData.contestMetaData[parsedContestId],
        "winnersUrl",
        ""
      );
      setWinnersUrl(winnerList);

      setSingleContest(contestDetails);

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  return (
    <div className="fullWidthController ">
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className={`mobWidth paddingx`}>
          <ContestHeadData found={headData} />
          <div className={styles.participts}>
            {/* <div className={styles.flexme}>
            <img
              className={styles.imgCirc}
              src="https://images.unsplash.com/photo-1506404523803-9f9fa45e066e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              alt=""
            />
          </div> */}
            <p className={styles.numbp}>
              {pendingApprovalCount} Pending Approvals
            </p>
            <p className={styles.numbp}>{participantCount} Participants</p>
          </div>
          <div className="mt-[45px]">
            <ExportCSV
              winnersPrepared={winnersPrepared}
              csvData={participantData}
              fileName={"participantDetails"}
              found={headData}
              winnersUrl={winnersUrl}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Declaration;
