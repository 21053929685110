import styles from "./styles/LuckDrw.module.css";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import PendingLeads from "./PendingLeads";
import Context from "../../Context/Context";
import ContestHeadData from "./ContestHeadData";
import { useUserAuth } from "../../Context/UserAuthContext";
import LoadingAnimation from "../Loading/LoadingAnimation";

import TopBanner from "../TopBanner/TopBanner";
import RightPanel from "../RightPanel/RightPanel";
import AlertPopup from "../AlertPopups/AlertPopup";
import { closeContest, openContest } from "../../utils/StatusChanger";
import SortByFilter from "../Filters/SortByFilter";
import filterjson from "../../Json/PendingApprovalSorting.json";
import usePaginate from "../../Hooks/usePaginate";
import RefreshButton from "../RefreshButton/RefreshButton";

function PendingDetailsPage() {
  const { currentUser } = useUserAuth();
  const [singleContestData, setSingleContest] = useState([]);
  const {
    verifyUser,
    userIdToken,
    setUpdateData,
    updateData,
    setNotAdmin,
    setCampaignIdToPersist,
  } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { orgId, campaignId, contestId } = useContext(Context);
  // const [singleContestData, setSingleContest] = useState([])
  const [headData, setHeadData] = useState({});
  const [loading, setLoading] = useState(false);
  //const [contestStatus, setContestStatus] = useState('')
  //const [campaignStatus, setCampaignStatus] = useState('')
  //  const [participantCount, setParticipantCount] = useState('')
  const [pendingApprovalCount, setPendingApprovalCount] = useState("");
  //const [winnerSelectedStatus, setWinnerSelectedStatus] = useState('')
  //const [winnersList, setWinnersList] = useState([])
  const [showCloseContestPopup, setShowCloseContestPopup] = useState(false);
  const [showOpenContestPopup, setShowOpenContestPopup] = useState(false);
  const [StatusChangeLoading, setStatusChangeLoading] = useState(false);
  const [sorting, setSorting] = useState("created");

  const [pageNumber, setPageNumber] = useState(1);

  const [sideFilter, setSideFilter] = useState("campaign");

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignContestData();
  }, [updateData]);
  const getCampaignContestData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ALL_CONTEST_DATA_CAMPAIGN}/${orgId}/${campaignId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data;
      setCampaignIdToPersist(campgnData?._id);

      const contestDetails =
        campgnData &&
        campgnData?.contests.find((el) => {
          return el.contestId == contestId;
        });
      setHeadData(contestDetails);
      // const participantCount = _.get(
      //   campgnData && contestDetails,
      //   'participantCounter',
      //   0,
      // )
      // setParticipantCount(participantCount)
      const pendingApprovalCount = _.get(
        contestDetails && contestDetails,
        "pendingApproval",
        0
      );
      setPendingApprovalCount(pendingApprovalCount);
      // const campaignStatus = _.get(campgnData, 'campaignStatus', '')
      // setCampaignStatus(campaignStatus)
      // const contestStatus = _.get(contestDetails, 'contestStatus', '')
      // setContestStatus(contestStatus)
      // const winnerSelectedStatus = _.get(
      //   campgnData && contestDetails,
      //   'winnerSelected',
      //   '',
      // )
      // setWinnerSelectedStatus(winnerSelectedStatus)
      // const winnersList = _.get(
      //   campgnData && contestDetails,
      //   'winnersList',
      //   [],
      // )
      // setWinnersList(winnersList)

      setSingleContest(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const { dataloadingPending, error, data, hasMore } = usePaginate(
    pageNumber,
    10,
    tokenData,
    orgId,
    campaignId,
    contestId,
    [contestId],
    sorting
  );
  const handleOpenContest = () => {
    setShowOpenContestPopup(true);

    // const { orgId, campaignId, contestId, orgDomain, campaignUniqueText } =
    //   headData;
    // openContest(
    //   orgId,
    //   campaignId,
    //   contestId,
    //   orgDomain,
    //   campaignUniqueText,
    //   tokenData,
    //   setStatusChangeLoading
    // );
  };
  const handleCloseContet = () => {
    const { orgId, campaignId, contestId, contestType } = headData;
    closeContest(
      orgId,
      campaignId,
      contestId,
      contestType,
      tokenData,
      setStatusChangeLoading,
      setNotAdmin
    );
  };
  const hanldeCloseStatusChangerPopup = () => {
    setShowCloseContestPopup(false);
  };
  const handleCloseOpenContentPopup = () => {
    setShowOpenContestPopup(false);
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Close the Contest",
    Para: "Are you sure you want to close this Contest? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Close this Contest",
    Button2Color: "#F5BF00",
  };
  const openPopUpValues = {
    Icon: "",
    Heading: "Open the Contest",
    Para: "Contact admin for opening this contest! ",
    Button1: "Cancel",
    Button2: "",
    Button2Color: "#06B818",
  };
  const handleRefresh = () => {
    setPageNumber(1);
    setUpdateData((prevState) => prevState + 1);
  };
  return (
    <div className="content-wrapper ">
      <div className="center">
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className={`mobWidth paddingx`}>
            {showCloseContestPopup && (
              <AlertPopup
                visible={showCloseContestPopup}
                setpopUp={setShowCloseContestPopup}
                button1Function={hanldeCloseStatusChangerPopup}
                button2Function={handleCloseContet}
                value={closePopUpValues}
              />
            )}
            {showOpenContestPopup && (
              <AlertPopup
                visible={showOpenContestPopup}
                setpopUp={setShowOpenContestPopup}
                button1Function={handleCloseOpenContentPopup}
                button2Function={handleOpenContest}
                value={openPopUpValues}
              />
            )}
            {/* <ContestHeadData found={headData} openNav={openNav} /> */}
            <TopBanner
              backgroundImage={headData?.contestPoster}
              heading={headData?.contestName}
              data={headData}
              breadCrumbItem={[
                { label: "Campaigns", url: "/campaigns" },
                {
                  label: "Contests",
                  url: "/campaigns/contests",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                  },
                },
                {
                  label: headData?.contestName,
                  url: "/campaigns/contests/contest",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                    conId: headData?.contestId,
                  },
                },
                {
                  label: "Pending Entries",
                  url: "/campaigns/contests/contest/contest-pending-approvals",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                    conId: headData?.contestId,
                  },
                },
              ]}
            />
            <div className="w-full flex items-center justify-between my-[20px]">
              <SortByFilter
                sorting={sorting}
                setSorting={setSorting}
                options={filterjson.options}
              />
              <RefreshButton handleRefresh={handleRefresh} />
            </div>
            <div className="mt-[45px]">
              <PendingLeads
                found={headData}
                pendingApprovalCount={pendingApprovalCount}
                sorting={sorting}
                data={data}
                dataloading={dataloadingPending}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </div>
          </div>
        )}
      </div>
      <div className=" right-sidebar">
        <RightPanel
          cardTitile="contest details"
          title={headData.contestName}
          type="Contest"
          description={headData.description}
          rightPanelData={headData}
          status={headData.contestStatus}
          handleOpenClick={setShowOpenContestPopup}
          handleCloseClick={setShowCloseContestPopup}
          likeCount={headData?.contestEntryLikeCount}
          viewCount={headData?.contestEntryViewCount}
          userCount={headData?.contestVisitCount}
          participantCounter={headData?.participantCounter}
        />
      </div>
    </div>
  );
}

export default PendingDetailsPage;
