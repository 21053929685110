import React from "react";
import { Link } from "react-router-dom";
import CreationSuccessCard from "../../Components/CreationSuccessCard/CreationSuccessCard";
import { useContext } from "react";
import Context from "../../Context/Context";

export default function CampaignCreationSuccessPage() {
  const { setUpdateData, setCampign, setCampaignPageNo } = useContext(Context);
  return (
    <>
      <div className="container-one mt-[120px] text-black px-[20px]">
        <CreationSuccessCard />

        <div className="mt-[40px] md:mt-[60px] mb-[32px]">
          <p className="text-largest-clash text-black md:mb-[21px] mb-[16px] text-large-bold ">
            Campaign is created{" "}
          </p>
          <p className="text-regular-medium text-[#787878] md:w-[80%] md:mb-[32px] mb-[20px]">
            Campaign created successfully .After approval, Please edit your
            campign with neccessary details and publish the campaign. Your
            campaign will be live after admin approval
          </p>
          <Link to="/campaigns">
            <button
              className="tempButton"
              onClick={() => {
                setUpdateData((prevState) => prevState + 1);
                setCampign([]);
                setCampaignPageNo(1);
              }}
            >
              View Campaigns
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
