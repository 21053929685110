import React from "react";
import styles from "./EditContest.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import { isEmpty } from "lodash";

export default function FormAttributes({
  data,
  setValue,
  setPopup,
  additionalFields,
  setAdditionalField,
  edit,
  editValues,
  setEdit,
}) {
  const [formAttrError, setFormAttrError] = useState({});
  const [formAttr, setFormAttr] = useState(editValues || { display: true });
  let attr = [
    {
      displayText: "Email",
      default: {
        property: {
          email: "email",
        },
        attrType: "scoring",
        fieldType: "text",
        items: ["email"],
      },
      id: "email",
    },
    {
      displayText: "Mobile Number",
      default: {
        property: {
          mobile: "mobile",
        },
        attrType: "scoring",
        fieldType: "text",
        items: ["mobile"],
      },
      id: "mobile",
    },
    {
      displayText: "Single Text",
      fieldType: [
        {
          displayText: "text",
          key: "text",
        },
        {
          displayText: "description",
          key: "textfield",
        },
      ],
      id: "scoring",
    },
    {
      displayText: "Prediction",
      options: true,
      id: "prediction",
    },
    {
      displayText: "Media",
      type: [
        {
          displayText: "video",
          key: "video",
        },
        {
          displayText: "image",
          key: "image",
        },
      ],
      id: "media",
    },
  ];
  if (!isEmpty(data)) {
    const isEmailPresent = data?.some((el) => el?.property?.email);
    const isPhonePresent = data?.some((el) => el?.property?.mobile);
    let newAttr = [];
    if (isEmailPresent) {
      newAttr = attr?.filter((obj) => obj?.id !== "email");
      attr = newAttr;
    }
    if (isPhonePresent) {
      newAttr = attr?.filter((obj) => obj?.id !== "mobile");
      attr = newAttr;
    }
  }

  let seletedInitialValue = attr?.find((el) => el?.id == editValues?.attrType);
  const [selectedType, setSelectedType] = useState(seletedInitialValue || {});
  const [displayText, setDisplayText] = useState("");

  const handleChangeInput = (e) => {
    setFormAttr((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleCreateObject = () => {
    setFormAttr((prevState) => {
      let itemsLocal = prevState?.items || [];
      itemsLocal.push(displayText);
      return { ...prevState, items: itemsLocal };
    });
    setDisplayText(""); // Clear the input field after creating the object
  };
  const deleteFilter = (el) => {
    const updatedData = formAttr?.items?.filter((item) => item !== el);
    setFormAttr((prevState) => {
      return { ...prevState, items: updatedData };
    });
  };
  const createRandomId = () => {
    return Math.random().toString(36).substring(2);
  };
  const handleAddField = () => {
    let error = {};
    if (!formAttr?.title) {
      error.title = "Enter a question or title";
    }
    if (!formAttr?.attrType) {
      error.attrType = "Select what should we ask from users";
    }
    setFormAttrError(error);
    if (!isEmpty(error)) {
      return;
    }
    const elementsToRemove = ["email", "apartmentDetails", "mobile"];
    const filteredItemsArray = additionalFields?.filter(
      (element) => !elementsToRemove?.includes(element)
    );
    let finalField = {};
    if (edit) {
      setValue((prevState) => {
        let formAttrArray = prevState?.formAttributes;
        const indexToEdit = formAttrArray?.findIndex(
          (obj) => obj?.id == formAttr?.id
        );
        formAttrArray[indexToEdit] = formAttr;
        return {
          ...prevState,
          formAttributes: formAttrArray,
        };
      });
    } else {
      if (selectedType?.id == "email") {
        finalField = {
          ...formAttr,
          ...selectedType?.default,
          id: createRandomId(),
        };
      } else if (selectedType?.id == "mobile") {
        finalField = {
          ...formAttr,
          ...selectedType?.default,
          id: createRandomId(),
        };
      } else if (selectedType?.id == "scoring") {
        let firstKey = filteredItemsArray[0] || "";
        let itemsArray = [firstKey];
        let property = {
          [firstKey]: firstKey,
        };
        finalField = {
          ...formAttr,
          ...selectedType?.default,
          property,
          items: itemsArray,
          id: createRandomId(),
        };
      } else if (selectedType?.id == "prediction") {
        let itemsArray = formAttr?.items;
        const property = {};
        itemsArray?.forEach((key, index) => {
          if (filteredItemsArray[index]) {
            property[key] = filteredItemsArray[index];
          }
        });
        finalField = {
          ...formAttr,
          ...selectedType?.default,
          property,
          id: createRandomId(),
        };
      } else if (selectedType?.id == "media") {
        let firstKey = filteredItemsArray[0] || "";
        let itemsArray = [firstKey];
        let property = {
          [firstKey]: firstKey,
        };
        finalField = {
          ...formAttr,
          ...selectedType?.default,
          property,
          items: itemsArray,
          id: createRandomId(),
        };
      }
      setValue((prevState) => {
        let formAttrArray = prevState?.formAttributes;
        formAttrArray.push(finalField);
        return {
          ...prevState,
          formAttributes: formAttrArray,
        };
      });
    }

    setPopup(false);
    setEdit(false);
  };
  return (
    <div className={styles.formAttrWrapper}>
      <div className={styles.formAttr}>
        <div className={styles.contentDiv}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <h2>Add Extra Fields</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setPopup(false)} />
          </div>
          <div className={`p-[20px] ${styles.body}`}>
            <div className={`custom-scrollbar ${styles.left}`}>
              {/* title */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      Title<span className="text-red-600">*</span>
                    </p>
                  </div>
                  <div>
                    <input
                      type="text"
                      id="title"
                      onChange={handleChangeInput}
                      value={formAttr?.title}
                      className={`${formAttrError?.title && styles.inputError}`}
                    />
                    {formAttrError?.title && (
                      <span className={styles.error}>
                        <span className="text-red-600">*</span>
                        {formAttrError?.title}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {!edit && (
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      Type<span className="text-red-600">*</span>
                    </p>
                  </div>
                  <div>
                    <select
                      onChange={(e) => {
                        setFormAttr((prevState) => ({
                          ...prevState,
                          attrType: JSON.parse(e.target.value)?.id,
                        }));
                        setSelectedType(JSON.parse(e.target.value));
                      }}
                      className={`${
                        formAttrError?.attrType && styles.inputError
                      }`}
                      id="attrType"
                    >
                      <option value="">Select an item</option>
                      {attr?.map((item) => (
                        <option key={item.id} value={JSON.stringify(item)}>
                          {item.displayText}
                        </option>
                      ))}
                    </select>
                    {formAttrError?.attrType && (
                      <span className={styles.error}>
                        <span className="text-red-600">*</span>
                        {formAttrError?.attrType}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {/* OPTIONS  */}
              {selectedType?.options && (
                <div>
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}>
                        Options<span className="text-red-600">*</span>
                      </p>
                    </div>
                    <div>
                      {formAttr?.items?.map((el, index) => {
                        return (
                          <div className="flex gap-[10px]">
                            <p>{el}</p>
                            <button onClick={() => deleteFilter(el)}>
                              Delete
                            </button>
                          </div>
                        );
                      })}
                      {!edit && (
                        <div className="flex gap-[10px]">
                          <input
                            type="text"
                            value={displayText}
                            placeholder="Enter Options"
                            onChange={(e) => setDisplayText(e.target.value)}
                          />
                          <button onClick={handleCreateObject}>Create</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* placeholeder */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      PlaceHolder<span className="text-red-600">*</span>
                    </p>
                  </div>
                  <input
                    type="text"
                    id="placeHolder"
                    onChange={handleChangeInput}
                    value={formAttr?.placeHolder}
                  />
                </div>
              </div>
              {/* display type */}
              {!isEmpty(selectedType?.fieldType) && (
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      Ask for?<span className="text-red-600">*</span>
                    </p>
                  </div>
                  <select
                    value={formAttr?.fieldType}
                    onChange={handleChangeInput}
                    id="fieldType"
                  >
                    <option value="">Select an item</option>
                    {selectedType?.fieldType?.map((item) => (
                      <option key={item.id} value={item.key}>
                        {item.displayText}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {/*  type */}
              {!isEmpty(selectedType?.type) && (
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      File Type<span className="text-red-600">*</span>
                    </p>
                  </div>
                  <select
                    value={formAttr?.type}
                    onChange={(e) => {
                      setFormAttr((prevState) => {
                        return {
                          ...prevState,
                          type: e.target.value,
                          fieldType: e.target.value + "/*",
                        };
                      });
                    }}
                    id="type"
                  >
                    <option value="">Select an item</option>
                    {selectedType?.type?.map((item) => (
                      <option key={item.id} value={item.key}>
                        {item.displayText}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {/* DISPLAY  */}
              <div className={styles.form}>
                <div className="flex items-center relative">
                  <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
                    Show on Entry Detials?
                    <span className="text-red-600">*</span>
                  </p>
                </div>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setFormAttr((prevState) => ({
                      ...prevState,
                      display: !prevState?.display,
                    }));
                  }}
                  checked={formAttr?.display}
                />
              </div>

              {/* MANDATORY  */}
              <div className={styles.form}>
                <div className="flex items-center relative">
                  <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
                    Mandatory Question
                    <span className="text-red-600">*</span>
                  </p>
                </div>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setFormAttr((prevState) => ({
                      ...prevState,
                      required: !prevState?.required,
                    }));
                  }}
                  checked={formAttr?.required}
                />
              </div>

              <div className={styles.buttonWrapper}>
                <button className={styles.save} onClick={handleAddField}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
