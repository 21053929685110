import React, { useContext } from "react";
import styles from "./AllEntries.module.css";
import AllEntryMoreOption from "../MoreOptionDropdown/AllEntryMoreOption";
import { useState } from "react";
import user from "../../Assets/user.svg";
import LikedUsers from "../LikedUsers/LikedUsers";
import PrizeClaimDetails from "../PrizeClaimDetails/PrizeClaimDetails";
import FeatureDetails from "../FeatureDetails/FeatureDetails";
import SendEmail from "../SendEmail/SendEmail";
import Context from "../../Context/Context";

function AllEntries({ setpopUp, popUp, setpopUpData, obj, filter, judgment }) {
  const [showLikes, setShowLikes] = useState(false);
  const [showPrizeClaimDetails, setShowPrizeClaimDetails] = useState(false);
  const [showFeatureDetails, setShowFeatureDetails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const { judmentMap, setJudmentMap, setJudmentEntries, judmentEntries } =
    useContext(Context);
  const dateShortFormat = (dateString) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day}  ${monthName}  ${year}`;
  };
  const getProfilePicture = (uid) => {
    let url = `https://storage.googleapis.com/goviralprofiledatas/${uid}`;
    return url;
  };

  const status = () => {
    let style = {};

    if (obj?.giftClaimStatus == "GIFT_SENTED") {
      style.background = "rgba(75,181,67,0.3)";
    } else if (obj?.giftClaimStatus == "ADDRESS_UPDATED") {
      style.background = "rgba(246,222,22,0.3)";
    }
    return style;
  };
  const changeJudgmentState = () => {
    let updatedEntryMap = new Map(judmentMap);
    let judmentEntriesMap = new Map(judmentEntries);
    const currentValue = updatedEntryMap?.get(obj?._id);
    updatedEntryMap.set(obj?._id, !currentValue);
    judmentEntriesMap.set(obj?._id, !currentValue);
    setJudmentEntries(judmentEntriesMap);
    setJudmentMap(updatedEntryMap);
  };
  return (
    <>
      {showLikes && (
        <LikedUsers
          showPopup={showLikes}
          setShowPopup={setShowLikes}
          data={obj}
        />
      )}
      {showPrizeClaimDetails && (
        <PrizeClaimDetails
          showPopup={showPrizeClaimDetails}
          setShowPopup={setShowPrizeClaimDetails}
          data={obj}
        />
      )}
      {showFeatureDetails && (
        <FeatureDetails
          showPopup={showFeatureDetails}
          setShowPopup={setShowFeatureDetails}
          data={obj}
        />
      )}
      {sendEmail && (
        <SendEmail
          showPopup={sendEmail}
          setShowPopup={setSendEmail}
          data={obj}
          type="individual"
          toAddress={true}
        />
      )}
      <tbody className={styles.pendingApprovalParent} key={obj._id}>
        <tr className={styles.tableBottomLine} style={status()}>
          {judgment && (
            <td className={` break-all p-[5px]  ${styles.userId}`}>
              <input
                type="checkbox"
                onClick={changeJudgmentState}
                checked={judmentMap?.get(obj?._id)}
              />
            </td>
          )}
          <td className={` break-all p-[5px]  ${styles.userId}`}>
            <div className="flexio">
              <p> #GOUS{obj?.userId}</p>
            </div>
          </td>
          <td className=" break-all p-[5px]">
            <div className="flex items-center ">
              {/* <div className="md:h-[72px] md:min-w-[72px] h-[49px] min-w-[49px] rounded-full bg-blue-400 flex items-center justify-center mr-[10px] text-[24px] "> */}
              <div className={styles.nameDp}>
                <img
                  src={getProfilePicture(obj?.uid) || user}
                  alt=""
                  className="h-[40px] w-[40px] rounded-full overflow-hidden  object-cover"
                  onError={(e) => {
                    e.target.src = user;
                  }}
                />
              </div>
              <p>{obj?.participantName}</p>
            </div>
          </td>
          <td className=" break-all p-[5px]">
            <div className="flexio">
              <p>
                {filter?.filter == "PRIZE_CLAIM" ||
                filter?.filter == "GIFT_TYPE"
                  ? dateShortFormat(obj?.prizeClaimTime?.address_updated)
                  : dateShortFormat(obj?.uploadTime)}{" "}
              </p>
            </div>
          </td>
          <td className=" break-all p-[5px]">
            <div className="flexio">
              <p>{obj?.email} </p>
            </div>
          </td>
          <td className=" break-all p-[5px]">
            <div className="flexio">
              <p>{obj?.phone} </p>
            </div>
          </td>

          <td className=" break-all p-[5px] cursor-pointer relative">
            <AllEntryMoreOption
              data={obj}
              setShowLikes={setShowLikes}
              setpopUp={setpopUp}
              setpopUpData={setpopUpData}
              setShowPrizeClaimDetails={setShowPrizeClaimDetails}
              setShowFeatureDetails={setShowFeatureDetails}
              setSendEmail={setSendEmail}
            />
          </td>
        </tr>
      </tbody>
    </>
  );
}

export default AllEntries;
