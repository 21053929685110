import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/campaigns");
  }, []);

  return (
    <div className="p-[20px] flex flex-wrap gap-[10px]">
      <Link to="/campaigns">
        <button className="commonButton">Campaigns</button>
      </Link>
      <Link to="/leads/campaigns">
        <button className="commonButton">Campaign Leads</button>
      </Link>
      <Link to="/leads/promotions">
        <button className="commonButton">Promotion Leads</button>
      </Link>
      <Link to="/promotions/list">
        <button className="commonButton">Promotions</button>
      </Link>
      <Link to="/ads">
        <button className="commonButton">Ads</button>
      </Link>
      <Link to="/analytics/campaigns">
        <button className="commonButton">Campaign Analytics</button>
      </Link>
      <Link to="/analytics/promotions">
        <button className="commonButton">Promotion Analytics</button>
      </Link>
    </div>
  );
}
