import { useContext, useEffect, useRef, useState } from "react";
import "../../src/App.css";
import styles from "./styles/SignupPage.module.css";
import { useLocation, useNavigate } from "react-router";
import * as _ from "lodash";
import googleLogo from "../Assets/googleLogo.svg";
import passwordShow from "../Assets/passwordShow.svg";
import passwordHide from "../Assets/passwordHide.svg";
import LoadingFromCenter from "../Components/LoadingFromCenter/LoadingFromCenter";
import RetryLoginPopup from "../Components/RetryLoginPopup/RetryLoginPopup";
import axios from "axios";
import ForgotPasswordPopup from "../Components/ForgotPasswordPopup/ForgotPasswordPopup";
import Context from "../Context/Context";
import { useUserAuth } from "../Context/UserAuthContext";

function SignupPage() {
  const { details, userIdToken } = useContext(Context);
  const {
    sendOtp,
    otpShow,
    numberVerificationLoading,
    enterUserDisplayName,
    patchDisplayNameAndCreateUser,
    namePatchLoading,
    googleSignIn,
    setEnterUserDisplayName,
    pageLoading,
    setOtpShow,
    otpError,
    retryCreateUser,
    currentUser,
    emailError,
    emailSignIn,
    setEmailError,
    emailLogin,
    faceBookSignIn,
  } = useUserAuth();
  const [invalidPhone, setInvalidPhone] = useState("");
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [numberObj, setNumberObj] = useState({
    countryCode: "",
    number: null,
  });
  const [emailObj, setEmailObj] = useState({});
  const [useDetails, setUserDetails] = useState({
    name: "",
    whatsApp: true,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filterdCountryCodes, setFilteredCountryCode] = useState([]);
  const dropdownRef = useRef(null);
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const token = userIdToken || currentUser?.accessToken;

  const validatePhone = (code) => {
    let regexPhone =
      // eslint-disable-next-line no-useless-escape
      /^\+(?:[0-9]●?){6,14}[0-9]$/;

    if (code.match(regexPhone)) {
      setInvalidPhone("");
      return true;
    } else {
      setInvalidPhone("Invalid Phone number");
      return false;
    }
  };

  const handleSubmitNumber = (e) => {
    e.preventDefault();

    if (_.isEmpty(numberObj.countryCode)) {
      setInvalidPhone("Select Country Code");
      return;
    }
    if (_.isEmpty(numberObj.number)) {
      setInvalidPhone("Invalid Phone number");
      return;
    }
    let temp = numberObj.countryCode + numberObj.number;
    temp = temp.replace(/\s+/g, "");
    const validateRespose = validatePhone(temp);
    if (!validateRespose) {
      return;
    }
    setPhoneNumber(`${temp}`);
    sendOtp(`${temp}`);
  };
  const handleSubmitEmailPassword = (e, type) => {
    e.preventDefault();
    setEmailError("");
    if (_.isEmpty(emailObj.name) && type == "signin") {
      setEmailError("Please Enter your Name");
      return;
    }
    if (_.isEmpty(emailObj.email)) {
      setEmailError("Please Enter Email address");
      return;
    }
    if (_.isEmpty(emailObj.password)) {
      setEmailError("Please Enter a password");
      return;
    }
    switch (type) {
      case "signin":
        emailSignIn(emailObj?.name, emailObj?.email, emailObj?.password);
        break;
      default:
        emailLogin(emailObj?.email, emailObj?.password);
        break;
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setNumberObj((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleInputChangeEmail = (e) => {
    e.preventDefault();
    setEmailObj((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleWhatappStausChange = (e) => {
    let status = !useDetails?.whatsApp;
    setUserDetails((prevState) => ({
      ...prevState,
      whatsApp: status,
    }));
  };
  const handleNameChange = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };
  const handleSubmit = () => {
    let otpResponse = { _tokenResponse: { idToken: token } };
    patchDisplayNameAndCreateUser(
      enterUserDisplayName?.otpResponse || otpResponse,
      useDetails
    );
  };

  const getCountryCodes = async () => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviralcampaign/CountryCodes.json`
      );
      setCountryCodes(data.data);
      setFilteredCountryCode(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  useEffect(() => {
    handleCountryCodeChange({
      name: "India",
      code: "+91",
      shortCode: "IN",
    });
  }, []);
  const handleCountryCodeChange = (country) => {
    let { code } = country;
    setSelectedCountry(country);
    setNumberObj((prevState) => ({
      ...prevState,
      countryCode: code,
    }));
    setIsDropdownOpen(false);
  };
  const handleCountryChange = (event) => {
    const inputValue = event.target.value;

    // Filter country codes based on user input
    const filteredCountryCodes = countryCodes?.filter((country) =>
      country.code.includes(inputValue)
    );

    setFilteredCountryCode(filteredCountryCodes);
    setNumberObj((prevState) => ({
      ...prevState,
      countryCode: event.target.value,
    }));
    // Update the dropdown visibility
    setIsDropdownOpen(filteredCountryCodes.length > 0);

    // When the input is cleared, close the dropdown
    if (!inputValue) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  const getCountryFlag = (code) => {
    let lowerCaseCode = code?.toLowerCase();
    return (
      <img
        src={`https://storage.googleapis.com/goviralcampaign/countryFlags/${lowerCaseCode}.png`}
        className="h-full w-full "
      />
    );
  };
  const handleGoogleSignin = () => {
    googleSignIn();
  };
  return (
    <>
      {forgotPasswordPopup && (
        <ForgotPasswordPopup setPopup={setForgotPasswordPopup} />
      )}
      <div className="min-h-[100vh] w-full px-[20px] py-[40px]">
        <div className={styles.signupPage}>
          <div className="w-[100%] ">
            <div className="theBlurBgSignup  rounded-[24px] w-[100%] pb-[40px]">
              <div className={styles.buttonContainer}>
                {pageLoading && <LoadingFromCenter />}
                <h3 className="text-large mb-[14px]">
                  {showEmailLogin ? "Login to proceed" : "Create an Account"}{" "}
                </h3>
              </div>

              {!showEmailLogin && (
                <div className="px-[20px]">
                  <div className="mb-[32px]">
                    <div className="flex items-center relative">
                      <div class={styles.mobileLogin}>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          className="text-regular-callout mb-[20px]"
                          onChange={handleInputChangeEmail}
                          value={emailObj?.name}
                        />
                        <input
                          type="email"
                          id="email"
                          name="number"
                          placeholder="Email"
                          className="text-regular-callout mb-[20px]"
                          onChange={handleInputChangeEmail}
                          value={emailObj?.email}
                        />
                        <div className="relative w-full">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="number"
                            placeholder="Password"
                            className="text-regular-callout"
                            onChange={handleInputChangeEmail}
                            value={emailObj?.password}
                          />
                          <div
                            className="h-full absolute top-0 right-0 flex items-center"
                            onClick={() =>
                              setShowPassword((prevState) => !prevState)
                            }
                          >
                            <img
                              src={showPassword ? passwordHide : passwordShow}
                              className="w-[30px] h-[30px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {emailError && (
                      <div className="text-small text-left w-[100%] my-[10px]">
                        <span className="text-red-600">*</span>
                        {emailError}
                      </div>
                    )}
                  </div>
                  <button
                    className={`${styles.button} text-regular-medium bg-[#00A989] mb-[16px]`}
                    onClick={(e) => handleSubmitEmailPassword(e, "signin")}
                    disabled={numberVerificationLoading || pageLoading}
                  >
                    Sign up
                  </button>
                  <p
                    className="text-left mb-[10px] text-small text-center cursor-pointer"
                    onClick={() => {
                      setEmailError("");
                      setShowEmailLogin(true);
                    }}
                  >
                    Already have an account?{" "}
                    <span className="ml-[5px]  underline text-[#3075FC]">
                      Sign in
                    </span>
                  </p>
                </div>
              )}
              {showEmailLogin && (
                <div className="px-[20px] w-full">
                  <div className="mb-[32px] w-full">
                    <div className="flex items-center relative w-full">
                      <div class={styles.mobileLogin}>
                        <input
                          type="email"
                          id="email"
                          name="number"
                          placeholder="Email"
                          className="text-regular-callout mb-[20px]"
                          onChange={handleInputChangeEmail}
                          value={emailObj?.email}
                        />
                        <div className="relative w-full">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Password"
                            className="text-regular-callout"
                            onChange={handleInputChangeEmail}
                            value={emailObj?.password}
                          />
                          <div
                            className="h-full absolute top-0 right-0 flex items-center"
                            onClick={() =>
                              setShowPassword((prevState) => !prevState)
                            }
                          >
                            <img
                              src={showPassword ? passwordHide : passwordShow}
                              className="w-[30px] h-[30px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-beween">
                      {emailError && (
                        <div className="text-small text-left w-[100%] my-[10px]">
                          <span className="text-red-600">*</span>
                          {emailError}
                        </div>
                      )}
                      <p
                        className="text-right w-full my-[10px] text-small cursor-pointer"
                        onClick={() => {
                          setEmailError("");
                          setForgotPasswordPopup(true);
                        }}
                      >
                        Forgot your password?
                      </p>
                    </div>
                  </div>
                  <button
                    className={`${styles.button} text-regular-medium bg-[#00A989] mb-[16px]`}
                    onClick={(e) => handleSubmitEmailPassword(e, "login")}
                    disabled={numberVerificationLoading || pageLoading}
                  >
                    Sign in
                  </button>
                  <p
                    className="text-left mb-[10px] text-small text-center cursor-pointer"
                    onClick={() => setShowEmailLogin(false)}
                  >
                    Don't have an account?
                    <span className="ml-[5px] underline text-[#3075FC]">
                      Create one
                    </span>
                  </p>
                </div>
              )}
              <p className="text-regular my-[28px]">Or connect with</p>
              <div className="px-[20px] w-full">
                <button
                  className={`${styles.button} text-regular-medium bg-[#0092D6]  mx-auto flex items-center justify-center gap-[14px]`}
                  onClick={handleGoogleSignin}
                  disabled={pageLoading}
                >
                  <img src={googleLogo} alt="" />
                  Google
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="recaptcha-container"></div>
      </div>

      {retryCreateUser && <RetryLoginPopup />}
    </>
  );
}

export default SignupPage;
