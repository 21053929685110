import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles/AlertBox.module.css";

function CampaignAlertBox({
  rightPanelData,
  status,
  handleOpenClick,
  handleCloseClick,
}) {
  const navigate = useNavigate();

  return (
    <div className={styles.box}>
      <p className={styles.closing}>
        {status == "OPEN" && `Close Campaign`}

        {status == "UPCOMING" && `Open Campaign`}
      </p>
      {status == "UPCOMING" && (
        <p className={styles.closingText}>
          Open this campaign for participants to upload entries
        </p>
      )}
      {status == "OPEN" && (
        <p className={styles.closingText}>
         Close this campaign? You need admin approval for reopening
        </p>
      )}
      {status == "CLOSED" && (
        <p className={styles.closingText}>Campaign Closed</p>
      )}

      {status == "OPEN" && (
        <div className={styles.buttun} onClick={() => handleCloseClick(true)}>
          Close Campaign
        </div>
      )}
      {status == "UPCOMING" && (
        <div className={styles.buttun} onClick={() => handleOpenClick(true)}>
          Open Campaign
        </div>
      )}
    </div>
  );
}

export default CampaignAlertBox;
