import styles from "./styles/EventsCard.module.css";
import Timer from "../../../src/Assets/tym.png";
import Crown from "../../../src/Assets/crown.png";

import * as _ from "lodash";
import underReviewImage from "../../Assets/underReview.svg";

import Share from "../../../src/Assets/share.svg";
import Downlode from "../../../src/Assets/downlode.svg";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import MoreOptionDropDown from "../MoreOptionDropdown/MoreOptionDropDown";
import EditCampaign from "../EditCampaign/EditCampaign";
import AlertPopup from "../AlertPopups/AlertPopup";
import publishIcon from "../../Assets/AlertPopup/publishIcon.svg";
import { publishCampaign, requestEdit } from "../../utils/StatusChanger";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import CampaignAds from "../CampaignAds/CampaignAds";
import CampaignSponsors from "../CampaignSponsors/CampaignSponsors";
import CampaignJudges from "../CampaignJudges/CampaignJudges";
import CampaignSpecialGuests from "../CampaignSpecialGuests/CampaignSpecialGuests";
import CampaignCoupons from "../CampaignCoupons/CampaignCoupons";

function EventCard({
  campaignData,
  lastElementRef,
  setShare,
  setRightPanelData,
  handleOpenCampaignClick,
  handleCloseCampaignClick,
}) {
  // const [dropDown, setDropDown] = useState(false);
  const navigate = useNavigate();

  const [downloadPosterCardIndex, setDownloadPosterCardIndex] = useState();
  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [showAddAds, setShowAddAds] = useState(false);
  const [showAddCoupons, setShowAddCoupons] = useState(false);
  const [showAddSponsors, setShowAddSponsors] = useState(false);
  const [showAddSupportedBy, setShowAddSupportedBy] = useState(false);
  const [showAddOrganizers, setShowAddOrganizers] = useState(false);
  const [addSpecialGuests, setShowAddSpecialGuests] = useState(false);
  const [showAddJudges, setShowAddJudges] = useState(false);
  const [showDeleteCampaignPopup, setShowDeleteCampaignPopup] = useState(false);
  const [editCampaignData, setEditCampaignData] = useState({});
  const [deleteCampaignData, setdeleteCampaignData] = useState({});
  const [showPublish, setShowPublish] = useState(false);
  const [reqEdit, setReqEdit] = useState(false);
  const { currentUser } = useUserAuth();
  const {
    userIdToken,
    setStatusChangeLoading,
    setUpdateData,
    setCampign,
    setCampaignPageNo,
    setNotAdmin,
  } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;

  const handleDownloadPoster = (e, indexnumber, file) => {
    e.stopPropagation();
    downloadPoster(file);
    // if (_.isEmpty(campaignData?.campaignQrUrl)) {
    //   setDownloadPosterCardIndex(indexnumber);
    // } else {
    //   downloadPoster(campaignData?.campaignQrUrl);
    // }
  };

  const downloadPoster = (file) => {
    // window.open(fullData?.qrCodeUrl)
    // e.preventDefault()

    fetch(file, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const popUpValues = {
    Icon: "",
    Heading: "Delete the Campaign",
    Para: "Are you sure you want to delete this Camapign? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Delete this Campaign",
    Button2Color: "#D92D20",
  };
  const popUpPublishValues = {
    Icon: publishIcon,
    Heading: "Publish Campaign",
    Para: "Are you sure you want to Publish this Camapign? This action cannot be undone. or Edit Before pulishing",
    Button1: "Edit Camapign",
    Button2: "Publish",
    Button2Color: "#06B818",
  };
  const popUpReqEdit = {
    Icon: publishIcon,
    Heading: "Request For Editing",
    Para: "Requesting Goviral to edit this campaign. ",
    Button1: "Cancel",
    Button2: "Request",
    Button2Color: "#06B818",
  };
  const hanldeCloseDeletePopup = () => {
    setShowDeleteCampaignPopup(false);
  };
  const handleShowEditCampaign = () => {
    setShowPublish(false);
    setShowEditCampaign(true);
  };
  const handleCloseEditReq = () => {
    setReqEdit((prev) => !prev);
  };

  const handlePublishCampaign = () => {
    const { _id } = editCampaignData;

    publishCampaign(
      _id,
      tokenData,
      setStatusChangeLoading,
      setUpdateData,
      setCampign,
      setCampaignPageNo,
      setShowPublish,
      setShowEditCampaign,
      setNotAdmin
    );
  };
  const handleEditReq = () => {
    const { _id } = editCampaignData;

    requestEdit(
      _id,
      tokenData,
      setStatusChangeLoading,
      setUpdateData,
      setCampign,
      setCampaignPageNo,
      setReqEdit,
      setShowEditCampaign,
      setNotAdmin
    );
  };
  return (
    // <div className="flex gap-x-5 flex-col">
    <>
      {showEditCampaign && (
        <EditCampaign
          setShowEditCampaign={setShowEditCampaign}
          showEditCampaign={showEditCampaign}
          editCampaignData={editCampaignData}
        />
      )}
      {showAddAds && (
        <CampaignAds
          setShowAddAds={setShowAddAds}
          editCampaignData={editCampaignData}
        />
      )}
      {showAddCoupons && (
        <CampaignCoupons
          setPopup={setShowAddCoupons}
          editCampaignData={editCampaignData}
        />
      )}
      {showAddSponsors && (
        <CampaignSponsors
          setShowAddSponsors={setShowAddSponsors}
          editCampaignData={editCampaignData}
          editType="sponsor"
        />
      )}
      {showAddSupportedBy && (
        <CampaignSponsors
          setShowAddSponsors={setShowAddSupportedBy}
          editCampaignData={editCampaignData}
          editType="supportedBy"
        />
      )}
      {showAddOrganizers && (
        <CampaignSponsors
          setShowAddSponsors={setShowAddOrganizers}
          editCampaignData={editCampaignData}
          editType="organizers"
        />
      )}
      {showAddJudges && (
        <CampaignJudges
          setShowAddJudges={setShowAddJudges}
          editCampaignData={editCampaignData}
        />
      )}
      {addSpecialGuests && (
        <CampaignSpecialGuests
          setShowAddJudges={setShowAddSpecialGuests}
          editCampaignData={editCampaignData}
        />
      )}
      {showDeleteCampaignPopup && (
        <AlertPopup
          visible={showDeleteCampaignPopup}
          setpopUp={setShowDeleteCampaignPopup}
          button1Function={hanldeCloseDeletePopup}
          button2Function="some function"
          value={popUpValues}
        />
      )}
      {showPublish && (
        <AlertPopup
          visible={showPublish}
          setpopUp={setShowPublish}
          button1Function={handleShowEditCampaign}
          button2Function={handlePublishCampaign}
          value={popUpPublishValues}
        />
      )}
      {reqEdit && (
        <AlertPopup
          visible={reqEdit}
          setpopUp={setReqEdit}
          button1Function={handleCloseEditReq}
          button2Function={handleEditReq}
          value={popUpReqEdit}
        />
      )}
      <div className="flex gap-5 flex-col">
        {campaignData?.map((obj, indexnumber) => {
          if (campaignData.length === indexnumber + 1) {
            return (
              <>
                <div
                  ref={lastElementRef}
                  className={`${styles.card} ${
                    (obj.campaignStatus === "UPCOMING" ||
                      obj.campaignStatus === "REQUEST_TO_PUBLISH") &&
                    styles.upcomingCampaigns
                  } ${
                    obj.campaignStatus === "PENDING_APPROVAL" &&
                    styles.pendingCampaigns
                  }`}
                  onClick={() =>
                    obj.campaignStatus === "PENDING_APPROVAL"
                      ? ""
                      : navigate(
                          `/campaigns/contests?orgId=${obj?.orgId}&cmpId=${obj?.campaignId}`
                        )
                  }
                >
                  <div className={styles.flemOfpaty}>
                    {/* <div className={styles.timer}>
                      <img src={Timer} alt="" />
                      <p>17d 22h 37m</p>
                    </div> */}
                    <div className={styles.timer}>
                      <img src={Crown} alt="" />
                      <p style={{ color: "black" }}>
                        {obj?.contests?.filter((el) => el?.selected)?.length}{" "}
                        Contests
                      </p>
                    </div>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="ml-auto"
                    >
                      <MoreOptionDropDown
                        handleOpenCampaignClick={handleOpenCampaignClick}
                        handleCloseCampaignClick={handleCloseCampaignClick}
                        data={obj}
                        setShowEditCampaign={setShowEditCampaign}
                        setEditCampaignData={setEditCampaignData}
                        setShowDeleteCampaignPopup={setShowDeleteCampaignPopup}
                        setdeleteCampaignData={setdeleteCampaignData}
                        setShowAddAds={setShowAddAds}
                        setShowAddCoupons={setShowAddCoupons}
                        setShowAddSponsors={setShowAddSponsors}
                        setShowAddJudges={setShowAddJudges}
                        setShowAddOrganizers={setShowAddOrganizers}
                        setShowAddSpecialGuests={setShowAddSpecialGuests}
                        handleCloseEditReq={handleCloseEditReq}
                        setShowAddSupportedBy={setShowAddSupportedBy}
                      />
                    </div>
                  </div>

                  <div className={styles.fifaAbsolute}>
                    <p className={styles.campaingn}>{obj?.campaignName}</p>
                    {obj.campaignStatus === "PENDING_APPROVAL" && (
                      <p className={styles.pendingCampaign}>
                        Camapign will be active only after admin approval
                      </p>
                    )}
                    {/* <p className={styles.date}>
                    {obj?.participantCounter} participants •{" "}
                    {obj?.pendingApproval} pending approval
                  </p> */}
                  </div>
                  {obj.campaignStatus !== "PENDING_APPROVAL" && (
                    <div
                      style={{ flexDirection: "row" }}
                      className={`flexico ${styles.abso}`}
                    >
                      <div className={styles.flexint}>
                        <div className={styles.flemOfpaty}>
                          <div className={styles.numberOfparti}>
                            {obj?.participantCounter}
                          </div>
                          <p className={styles.participantText}>Participants</p>
                        </div>
                        <div className={styles.flemOfpaty}>
                          <div className={styles.numberOfparti}>
                            {obj?.pendingApproval}
                          </div>
                          <p className={styles.participantText}>
                            Pending Approval
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className={styles.downShare}>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setRightPanelData(obj);
                            }}
                            className={styles.buttun}
                          >
                            <p>Overview</p>
                          </button>
                          {obj?.campaignQrUrl && (
                            <div
                              className={styles.buttun}
                              onClick={(e) =>
                                handleDownloadPoster(
                                  e,
                                  indexnumber,
                                  obj?.campaignQrUrl
                                )
                              }
                            >
                              <img src={Downlode} alt="" />

                              <p>Download Poster</p>
                            </div>
                          )}
                          {obj.campaignStatus === "UPCOMING" ||
                          obj.campaignStatus === "REQUEST_TO_PUBLISH" ? (
                            <div
                              className={styles.publishButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditCampaignData(obj);
                                setShowPublish(true);
                              }}
                            >
                              <p>Publish Campign</p>
                            </div>
                          ) : (
                            <div
                              className={styles.buttun}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShare(true);
                              }}
                            >
                              <img src={Share} alt="" />
                              <p>Share Campign</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  className={`${styles.card} ${
                    (obj.campaignStatus === "UPCOMING" ||
                      obj.campaignStatus === "REQUEST_TO_PUBLISH") &&
                    styles.upcomingCampaigns
                  }
                  ${
                    obj.campaignStatus === "PENDING_APPROVAL" &&
                    styles.pendingCampaigns
                  }
                  `}
                  onClick={() =>
                    obj.campaignStatus === "PENDING_APPROVAL"
                      ? ""
                      : navigate(
                          `/campaigns/contests?orgId=${obj?.orgId}&cmpId=${obj?.campaignId}`
                        )
                  }
                >
                  <div className={styles.flemOfpaty}>
                    {/* <div className={styles.timer}>
                      <img src={Timer} alt="" />
                      <p>17d 22h 37m</p>
                    </div> */}
                    <div className={styles.timer}>
                      <img src={Crown} alt="" />
                      <p style={{ color: "black" }}>
                        {obj?.contests?.filter((el) => el?.selected)?.length}{" "}
                        Contests
                      </p>
                    </div>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="ml-auto"
                    >
                      <MoreOptionDropDown
                        handleOpenCampaignClick={handleOpenCampaignClick}
                        handleCloseCampaignClick={handleCloseCampaignClick}
                        data={obj}
                        setShowEditCampaign={setShowEditCampaign}
                        setEditCampaignData={setEditCampaignData}
                        setShowDeleteCampaignPopup={setShowDeleteCampaignPopup}
                        setdeleteCampaignData={setdeleteCampaignData}
                        setShowAddAds={setShowAddAds}
                        setShowAddCoupons={setShowAddCoupons}
                        setShowAddSponsors={setShowAddSponsors}
                        setShowAddJudges={setShowAddJudges}
                        setShowAddOrganizers={setShowAddOrganizers}
                        setShowAddSpecialGuests={setShowAddSpecialGuests}
                        handleCloseEditReq={handleCloseEditReq}
                        setShowAddSupportedBy={setShowAddSupportedBy}
                      />
                    </div>
                  </div>

                  <div className={styles.fifaAbsolute}>
                    <p className={styles.campaingn}>{obj?.campaignName}</p>
                    {obj.campaignStatus === "PENDING_APPROVAL" && (
                      <p className={styles.pendingCampaign}>
                        Camapign will be active only after admin approval
                      </p>
                    )}
                    {/* <p className={styles.date}>
                      {obj?.participantCounter} participants •{" "}
                      {obj?.pendingApproval} pending approval
                    </p> */}
                  </div>
                  {obj.campaignStatus !== "PENDING_APPROVAL" && (
                    <div
                      style={{ flexDirection: "row" }}
                      className={`flexico ${styles.abso}`}
                    >
                      <div className={styles.flexint}>
                        <div className={styles.flemOfpaty}>
                          <div className={styles.numberOfparti}>
                            {obj?.participantCounter > 0
                              ? obj?.participantCounter
                              : 0}
                          </div>
                          <p className={styles.participantText}>Participants</p>
                        </div>
                        <div className={styles.flemOfpaty}>
                          <div className={styles.numberOfparti}>
                            {obj?.pendingApproval > 0
                              ? obj?.pendingApproval
                              : 0}
                          </div>
                          <p className={styles.participantText}>
                            Pending Approval
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className={styles.downShare}>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setRightPanelData(obj);
                            }}
                            className={styles.buttun}
                          >
                            <p>Overview</p>
                          </button>
                          {obj?.campaignQrUrl && (
                            <div
                              className={styles.buttun}
                              onClick={(e) =>
                                handleDownloadPoster(
                                  e,
                                  indexnumber,
                                  obj?.campaignQrUrl
                                )
                              }
                            >
                              <img src={Downlode} alt="" />

                              <p>Download Poster</p>
                            </div>
                          )}
                          {obj.campaignStatus === "UPCOMING" ||
                          obj.campaignStatus === "REQUEST_TO_PUBLISH" ? (
                            <div
                              className={styles.publishButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditCampaignData(obj);
                                setShowPublish(true);
                              }}
                            >
                              <p>Publish Campign</p>
                            </div>
                          ) : (
                            <div
                              className={styles.buttun}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShare(true);
                              }}
                            >
                              <img src={Share} alt="" />
                              <p>Share Campign</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          }
        })}
      </div>
    </>
  );
}

export default EventCard;
