import React from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../Assets/loadingNew.json";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./LoadingAnimation.module.css";

function StatusChangerLoading() {
  return (
    <div className={styles.statusLoadingAnimation}>
      <Lottie
        loop
        animationData={loadingAnimation}
        play
        style={{ width: 150, height: 150 }}
      />
    </div>
  );
}

export default StatusChangerLoading;
