import React, { useEffect, useState } from "react";
import styles from "./CampaignSponsors.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import LoadingAnimation from "../Loading/LoadingAnimation";
import CreateSponsor from "../CreateSponsor/CreateSponsor";
import EditSponser from "../EditSponser/EditSponser";
import { useNavigate } from "react-router-dom";

export default function CampaignSponsors({
  setShowAddSponsors,
  editCampaignData,
  editType,
}) {
  let field = "";
  switch (editType) {
    case "sponsor":
      field = "sponsors";
      break;
    case "supportedBy":
      field = "supportedBy";
      break;
    case "organizers":
      field = "organizer";
      break;
  }

  const initialValue = editCampaignData[field] ? editCampaignData[field] : [];
  const [sponsors, setSponsors] = useState(initialValue);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [sponsorData, setSponsorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addSponsors, setAddSponsors] = useState(false);
  const {
    organizationId,
    userIdToken,
    setCampign,
    setUpdateData,
    setCampaignPageNo,
    updateData,
    setNotAdmin,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const navigate = useNavigate();
  const tokenData = userIdToken || currentUser?.accessToken;
  useEffect(() => {
    getSponsorData();
  }, [updateData]);
  const getSponsorData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_GUESTS}/${organizationId}?type=${editType}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const apiData = await axios(config1);
      let filter = apiData?.data?.data[field]?.filter(
        (el) => !sponsors?.some((el2) => el2?._id === el?._id)
      );
      setSponsorData(filter);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const sponsorDataFilter = () => {
    let sponsorFromApi = sponsorData;
    let filter = sponsorFromApi.filter(
      (el) => !sponsors?.some((el2) => el2?._id === el?._id)
    );
    setSponsorData(filter);
  };

  const handleSelect = (obj) => {
    let newArray = sponsors;
    newArray.push(obj);
    setSponsors(newArray);
    sponsorDataFilter();
  };
  const handleRemove = (obj) => {
    let newArray = sponsors;
    var index = newArray.findIndex((el) => el._id == obj._id);
    newArray.splice(index, 1);
    setSponsors(newArray);
    sponsorDataFilter();
    setSponsorData([obj, ...sponsorData]);
  };
  const handleSave = async () => {
    setLoading(true);

    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_CAMPAIGN_DETAILS}/${editCampaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { [field]: sponsors },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        setShowAddSponsors(false);
        setUpdateData((prevState) => prevState + 1);
        setCampign([]);
        setCampaignPageNo(1);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handlePublish = async () => {
    setLoading(true);

    try {
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_CAMPAIGN_DETAILS}/${editCampaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { [field]: sponsors },
      };
      const response = await axios(config1);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        let config2 = {
          method: "PATCH",
          url: `${process.env.REACT_APP_PUBLISH_ORGANISER_CAMPAIGN}/${editCampaignData?._id}`,
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
        };
        const response2 = await axios(config2);
        if (response2.status === 201) {
          if (response2?.data?.admin == false) {
            setNotAdmin(true);
            setLoading(false);
            return;
          }
          setShowAddSponsors(false);
          setUpdateData((prevState) => prevState + 1);
          setCampign([]);
          setCampaignPageNo(1);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleEdit = (event, data) => {
    event.stopPropagation();
    setEdit(true);
    setEditData(data);
  };
  const navigateToRequsts = () => {
    navigate(
      `/cmp-sponsers/requests?orgId=${organizationId}&cmpId=${editCampaignData?._id}`
    );
  };
  return (
    <div className={styles.popup}>
      {addSponsors && (
        <CreateSponsor
          showPopup={addSponsors}
          setShowPopup={setAddSponsors}
          type={editType}
        />
      )}
      {edit && (
        <EditSponser
          showPopup={edit}
          setShowPopup={setEdit}
          editData={editData}
          type="campaign"
          editType={editType}
          campaignId={editCampaignData?._id}
          setSponsors={setSponsors}
        />
      )}
      <div className={styles.selectAdsPopup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>{editType}</h2>
            </div>
            <img
              src={Close}
              alt="x"
              onClick={() => setShowAddSponsors(false)}
            />
          </div>
          <div className={styles.body}>
            {editType == "sponsor" && (
              <div className="flex gap-4 items-center ml-[auto] w-full justify-end">
                <button onClick={navigateToRequsts}>Requests</button>
              </div>
            )}
            {loading ? (
              <LoadingAnimation />
            ) : (
              <>
                <p>{editType}</p>
                <div className={styles.promotionListGrid}>
                  {sponsorData?.map((obj) => {
                    if (editType == "sponsor" && obj?.status == "APPROVED") {
                      return (
                        <div
                          className={styles.entry}
                          onClick={() => handleSelect(obj)}
                        >
                          <img src={obj?.logo} />
                          <p>{obj?.name}</p>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={styles.entry}
                          onClick={() => handleSelect(obj)}
                        >
                          <img src={obj?.logo} />
                          <p>{obj?.name}</p>
                        </div>
                      );
                    }
                  })}
                  <div
                    className={styles.entry}
                    onClick={() => setAddSponsors(true)}
                  >
                    {/* <img src={obj?.fileUrl} /> */}
                    <p>Create {editType}</p>
                  </div>
                </div>
                <p className="mt-[20px]">Selected {editType}</p>

                <div className={styles.promotionListGrid}>
                  {sponsors?.map((obj) => {
                    if (editType == "sponsor" && obj?.status == "APPROVED") {
                      return (
                        <div
                          className={styles.entry}
                          onClick={() => handleRemove(obj)}
                        >
                          <img src={obj?.logo} />
                          <div className="p-[10px] flex flex-col gap-[5px]">
                            <p>{obj?.name}</p>
                            <button
                              className={styles.editButton}
                              onClick={(e) => handleEdit(e, obj)}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={styles.entry}
                          onClick={() => handleRemove(obj)}
                        >
                          <img src={obj?.logo} />
                          <div className="p-[10px] flex flex-col gap-[5px]">
                            <p>{obj?.name}</p>
                            <button
                              className={styles.editButton}
                              onClick={(e) => handleEdit(e, obj)}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.save}
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </button>
            <button
              className={styles.save}
              onClick={handlePublish}
              disabled={loading}
            >
              Save and publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
