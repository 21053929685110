import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import styles from "./SortByFilter.module.css";

export default function SortByFilter({ sorting, setSorting, options }) {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const modalRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        showFilterDropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setShowFilterDropdown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showFilterDropdown]);
  return (
    <div ref={modalRef}>
      <div
        className={styles.filterParent}
        onClick={() => {
          setShowFilterDropdown((prevState) => !prevState);
        }}
      >
        <h2>Sort by</h2>
        <img src={leadsDropDown} alt="" />
      </div>

      {showFilterDropdown && (
        <div className={styles.dropDownPopup}>
          {/* <h4>Sort by</h4> */}
          {options.map((obj, index) => {
            return (
              <p
                onClick={() => {
                  setSorting(obj.key);
                  setShowFilterDropdown(false);
                }}
                className={sorting == obj.key && styles.filterSelected}
                key={index}
              >
                {obj.optionName}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
}
