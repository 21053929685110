import { useEffect, useState } from "react";
import axios from "axios";
import { useContext } from "react";
import Context from "../Context/Context";
export default function useGetAllEntriesCampaign(
  pageNumber,
  limit,
  token,
  orgId,
  campaignId,
  sorting,
  filter
) {
  const [dataloadingEntries, setDataLoading] = useState(true);
  const [error, setError] = useState(false);
  const [entries, setEntries] = useState([]);
  const [entryCount, setEntryCount] = useState();
  const [hasMore, setHasMore] = useState(false);
  const { updateData } = useContext(Context);
  useEffect(() => {
    setEntries([]);
  }, []);
  useEffect(() => {
    // if (pendingApproval > 0) {
    setDataLoading(true);
    setError(false);
    let cancel;
    if (orgId && campaignId) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_GET_CONTEST_APPROVED_ENTRIES_CAMPAIGN}/${orgId}/${campaignId}?page=${pageNumber}&limit=${limit}&sort=${sorting}&filter=${filter?.filter}&status=${filter?.status}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          setEntryCount(res?.data?.totalCount);
          setEntries(res?.data?.data);
          setHasMore(res?.data?.data?.length > 0);
          setDataLoading(false);
        })
        .catch((e) => {
          setDataLoading(false);
          setEntries([]);
          if (axios.isCancel(e)) return;
          setError(true);
        });
    }

    return () => {
      if (cancel) cancel();
    };
    // }
  }, [pageNumber, sorting, filter, updateData]);

  return { dataloadingEntries, error, entries, hasMore, entryCount };
}
