import React, { useContext, useEffect, useState } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import PaymentListing from "../../Components/PaymentListing/PaymentListing";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";

export default function SponsorPaymentPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { organizationId, userIdToken, updateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [status, setStatus] = useState("ALL");

  useEffect(() => {
    if (organizationId) {
      getData();
    }
  }, [organizationId, updateData, status]);

  const getData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SPONSOR_REQUESTED_CAMPAIGNS}?type=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return (
    <div className="p-[20px]">
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Campaigns</p>
      </div>
      {loading && (
        <div className="h-[20%]">
          <LoadingAnimation />
        </div>
      )}
      <div>
        {data && !loading && data?.length === 0 && (
          <div className="w-[100%] h-[50vh] flex flex-col items-center justify-center text-[#787878]">
            <span className="text-[17px] font-semibold">No data found</span>
          </div>
        )}
        <PaymentListing data={data} dataLoading={loading} />
      </div>
    </div>
  );
}
