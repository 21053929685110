import React, { useEffect } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./EditBrand.module.css";
import Close from "../../Assets/close.svg";
import axios from "axios";
import { useRef } from "react";
import { useState } from "react";
import Context from "../../Context/Context";
import { useContext } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import DynamicInput from "../DynamicInput/DynamicInput";
import { singleFileUpload } from "../../utils/SingleFileUpload";

export default function EditBrand({ showPopup, setShowPopup, editData }) {
  const { userIdToken, organizationId, setUpdateData, verifyUser } =
    useContext(Context);
  useEffect(() => {
    verifyUser();
  }, []);
  const { currentUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [formValues, setFormValues] = useState(editData);
  const [changedValue, setChangedValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  //Update sponsor
  const patchDetails = async () => {
    setLoading(true);
    try {
      let config2 = {
        method: "patch",
        url: `${process.env.REACT_APP_EDIT_BRANDS}/${formValues?.brandId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: changedValue,
      };
      const response2 = await axios(config2);
      setShowPopup(false);
      setUpdateData((prevState) => prevState + 1);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const hadleSubmit = async () => {
    let errors = validate();
    if (isEmpty(errors)) {
      patchDetails();
    }
  };
  const validate = () => {
    let error = {};
    if (!formValues?.name) {
      error.name = "Please enter this field";
    }
    setFormErrors(error);
    return error;
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    let uploadPath = `/${organizationId}/`;
    setLoading(true);
    let uploadFile = await singleFileUpload(imagedata, uploadPath);
    setFormValues((prevState) => ({
      ...prevState,
      logo: uploadFile,
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      logo: uploadFile,
    }));
    setLoading(false);
  };

  const handleChooseFile = (id) => {
    const divElement = document.getElementById(id);
    if (divElement) {
      divElement.click();
    }
  };

  const handleChangeInput = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.createAdd}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Edit Brand</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <>
              <DynamicInput
                handleChange={handleChangeInput}
                value={formValues?.name}
                field={{
                  name: "Name",
                  type: "text",
                  id: "name",
                }}
              />
              <DynamicInput
                handleChange={handleChange}
                handleChooseFile={handleChooseFile}
                value={formValues?.logo}
                fileType="image"
                field={{
                  name: "Logo",
                  type: "MEDIA",
                  id: "logo",
                  accept: ".jpg,.jpeg,.png,.svg",
                }}
              />
            </>

            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={hadleSubmit}
                disabled={loading}
              >
                {loading ? "Loading" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
