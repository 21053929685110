import React, { useContext, useEffect, useRef } from "react";
import styles from "./AcceptSponsorRequest.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";

export default function AcceptSponsorRequest({ setPopup, data }) {
  const [loading, setLoading] = useState(false);
  const { userIdToken, setUpdateData, organizationId } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  const handleClose = () => {
    setPopup(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_APPROVE_SPONSOR}/${organizationId}/${data?.campaignId}/${data?.sponsorUserId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    setLoading(false);
    if (response.data?.status == "success") {
      setPopup(false);
      setUpdateData((prevState) => prevState + 1);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2> Details</h2>
            </div>
            <img src={Close} alt="x" onClick={handleClose} />
          </div>
          <div className={styles.body}>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Campaign Name
              </p>
              <p>{data?.campaignName}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Name
              </p>
              <p>{data?.name}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Logo
              </p>
              <div className="h-[170px] w-full">
                <img
                  src={data?.logo}
                  alt={`Image `}
                  className="h-full object-contain"
                />
              </div>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Email
              </p>
              <p>{data?.email}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Phone
              </p>
              <p>{data?.phone}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Description
              </p>
              <p>{data?.description}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Payment Status
              </p>
              <p>{data?.paymentStatus}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Sponsorship Amount
              </p>
              <p>{data?.sponsorShipAmt} Rs</p>
            </div>
          </div>

          <div className={styles.buttonWrapper}>
            {data?.status == "REQUESTED" && (
              <button
                className={styles.save}
                onClick={handleSubmit}
                disabled={loading}
              >
                Accept
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
