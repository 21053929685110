import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import _, { sum } from "lodash";
import IntroShowcase from "../../Components/CampaignCreation/IntroShowcase";
import CampaignTemplate from "../../Components/CampaignCreation/CampaignTemplate";
import { useUserAuth } from "../../Context/UserAuthContext";
import Context from "../../Context/Context";
import CartBanner from "../../Components/CampaignCreation/CartBanner/CartBanner";

function CampaignListingPage() {
  const [Campignkey, setCampignkey] = useState({});
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { setIsHomePage, setSelectedContests } = useContext(Context);
  const [dataOfCatergory, setDataOfCatergory] = useState([]);
  const [count, setCount] = useState(0);
  let initialFormValuesFromLocal = JSON.parse(
    localStorage.getItem(`campaignCreationForm`)
  );
  const initialValues = {
    name: "",
    country: "",
    campaignName: "",
    email: "",
    phone: "",
    address: "",
    description: "",
    giftDetails: "",
    isPrivate: false,
    sponsorShipStatus: "REQUESTED",
    lookingForInfluencer: false,
    campaignDescription: "",
    contestIds: [],
    campaignIds: [],
    categoryTypes: [],
    campaignUniqueText: "",
    orgDomain: "",
    privateType: "PIN",
    show: false,
    pack: "",
  };
  const [formValues, setFormValues] = useState(
    initialFormValuesFromLocal ? initialFormValuesFromLocal : initialValues
  );
  let initialCartValuesFromLocal = JSON.parse(
    localStorage.getItem(`campaignCreationCart`)
  );
  const [cartMap, setCartMap] = useState(
    initialCartValuesFromLocal ? new Map(initialCartValuesFromLocal) : new Map()
  );

  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setIsHomePage(false);
  }, []);
  useEffect(() => {
    // Get the values from the map
    const values = Array.from(cartMap.values());

    // Calculate the sum of all counts
    const sum = values.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0
    );
    setCount(sum);
  }, [cartMap]);
  const handleAddtoMyCampaign = (contest) => {
    const {
      _id,
      contestId,
      campaignId,
      campaignType,
      contestName,
      contestType,
      contestPoster,
      description,
    } = contest;

    const key = contest?._id;
    const value = {
      _id,
      contestId,
      campaignId,
      campaignType,
      contestName,
      contestPoster,
      description,
      contestType,
      count: cartMap.get(key)?.count ? cartMap.get(key)?.count + 1 : 1,
    };
    const updatedMap = new Map(cartMap);
    updatedMap.set(key, value);
    localStorage.setItem(
      `campaignCreationCart`,
      JSON.stringify(Array.from(updatedMap))
    );
    setCartMap(updatedMap);
  };

  const handleRemoveFromCampaign = (selected, presentIds) => {
    const diff = _.difference(presentIds, selected);
    if (diff.length === presentIds.length) {
      presentIds?.forEach((id) => {
        let findIndex = cart.indexOf(id);
        if (findIndex > -1) {
          cart.splice(findIndex, 1);
          setCart(cart);
        }
      });
    } else if (diff === presentIds) {
      const difference = _.difference(cart, diff);
      setCart(difference);
    } else {
      const difference = _.difference(cart, diff);
      setCart(difference);
      let array = [];
      selected.forEach((id) => {
        if (!difference.includes(id)) {
          array.push(id);
        }
        setCart([...difference, ...array]);
      });
    }
  };
  const handleRemoveOtherContest = (contest) => {
    const {
      _id,
      contestId,
      campaignId,
      campaignType,
      contestName,
      contestType,
      contestPoster,
      description,
    } = contest;
    const key = contest?._id;
    const value = {
      _id,
      contestId,
      campaignId,
      campaignType,
      contestName,
      contestPoster,
      description,
      contestType,
      count: cartMap.get(key)?.count ? cartMap.get(key)?.count - 1 : 0,
    };
    const updatedMap = new Map(cartMap);
    updatedMap.set(key, value);
    localStorage.setItem(
      `campaignCreationCart`,
      JSON.stringify(Array.from(updatedMap))
    );
    setCartMap(updatedMap);
  };

  const handleProceed = () => {
    const contestIds = Array.from(cartMap).map(([key, value]) => ({
      contestId: value.contestId,
      count: value.count,
    }));

    const campaignIdsSet = new Set();
    Array.from(cartMap).forEach(([key, value]) => {
      campaignIdsSet.add(value.campaignId);
    });
    const categoryIds = Array.from(campaignIdsSet);

    const categoryTypesSet = new Set();
    Array.from(cartMap).forEach(([key, value]) => {
      categoryTypesSet.add(value.campaignType);
    });
    const categoryTypes = Array.from(categoryTypesSet);

    const templateType = Array.from(cartMap).some(
      ([key, value]) => value.contestType === "WP" || value.contestType === "SP"
    )
      ? "LEAUGE"
      : "NORMAL";
    let campaignsObj = {
      ...formValues,
      contestIds,
      categoryIds,
      categoryTypes,
      templateType,
    };

    setSelectedContests({
      contestIds,
      categoryIds,
      categoryTypes,
      templateType,
    });
    localStorage.setItem(`campaignCreationForm`, JSON.stringify(campaignsObj));
    if (currentUser) {
      navigate("/campaigns/create/details");
    } else {
      setPreviousLocation(window.location.pathname);
      navigate("/signup", { state: { nextStage: "step3" } });
    }
  };

  return (
    <div className="bg-white pb-[150px] w-[100%] mx-auto">
      <div className="w-[100%]  mx-auto min-h-[500px]">
        <IntroShowcase
          setCampignkey={setCampignkey}
          handleAddtoMyCampaign={handleAddtoMyCampaign}
          handleRemoveOtherContest={handleRemoveOtherContest}
          cart={cart}
          cartMap={cartMap}
          count={count}
        />
        <CampaignTemplate
          Campignkey={Campignkey}
          dataOfCatergory={dataOfCatergory}
          setDataOfCatergory={setDataOfCatergory}
          handleAddtoMyCampaign={handleAddtoMyCampaign}
          handleRemoveOtherContest={handleRemoveOtherContest}
          cart={cart}
          cartMap={cartMap}
        />
        {count > 0 && <CartBanner onClick={handleProceed} count={count} />}

        {/* <HowItWorks /> */}
        {/* <CoverBanner /> */}
        {/* <ContestBanner /> */}
        {/* <OtherContest upcomingContest={'https://s3.ap-south-1.amazonaws.com/goviral.world/home/UpcomingContest.json'}/> */}
        {/* <DiffrentCampign typeOfCampaign={'https://s3.ap-south-1.amazonaws.com/goviral.world/home/template.json'}/> */}
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default CampaignListingPage;
