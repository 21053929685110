import React, { useContext, useState } from "react";
import styles from "./CampaignListing.module.css";
import EventCard from "../PollContest/EventCard";
import LoadingAnimation from "../Loading/LoadingAnimation";
import Plus from "../../Assets/plus.svg";
import Grid from "../../Assets/Button.svg";
import RefreshButton from "../RefreshButton/RefreshButton";
import Context from "../../Context/Context";
import { Link } from "react-router-dom";
import filter from "../../Json/campiagnFilter.json";
function CampaignListing({
  openNav,
  setCampign,
  setCampaignPageNo,
  setStatus,
  status,
  campaignDataLoading,
  campign,
  lastElementRef,
  setShare,
  setRightPanelData,
  handleOpenCampaignClick,
  handleCloseCampaignClick,
}) {
  const { setUpdateData } = useContext(Context);
  const handleRefresh = () => {
    setCampign([]);
    setCampaignPageNo(1);
    setUpdateData((prevState) => prevState + 1);
  };
  return (
    <>
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Campaigns</p>
        <div className="flex gap-4 items-center ml-[auto]">
          <RefreshButton handleRefresh={handleRefresh} />
          {/* <div className={styles.addNew}>
            <img src={Plus} alt="" />
            <p className={styles.new}>Add New</p>
          </div>
          <div>
            <img src={Grid} alt="" />
          </div> */}
        </div>
      </div>
      <div className={styles.filterChips}>
        <div
          onClick={() => {
            setCampign([]);
            setCampaignPageNo(1);
            setStatus("ALL");
          }}
          className={status == "ALL" ? styles.eachChips : styles.eachChipsDark}
        >
          <p>All</p>
        </div>
        {filter?.options?.map((obj) => {
          return (
            <div
              onClick={() => {
                setCampign([]);
                setCampaignPageNo(1);
                setStatus(obj?.key);
              }}
              className={
                status == obj?.key ? styles.eachChips : styles.eachChipsDark
              }
            >
              <p>{obj?.optionName}</p>
            </div>
          );
        })}
      </div>{" "}
      <div className="w-full flex items-end justify-end mt-[20px] gap-[10px]">
        <Link to="/campaigns/create/Festival">
          <button className={styles.button}>Create Campaign</button>
        </Link>
      </div>
      {campign && !campaignDataLoading && campign.length === 0 && (
        <div className="w-[100%] h-[50vh] flex flex-col items-center justify-center text-[#787878]">
          <span className="text-[17px] font-semibold">No data found</span>
          {/* <span className="text-[14px] font-medium mt-[10px] text-center">
              All the profile entries will be
              <br /> displayed here.
            </span> */}
        </div>
      )}
      <div className={styles.griding}>
        <EventCard
          lastElementRef={lastElementRef}
          campaignData={campign}
          setShare={setShare}
          setRightPanelData={setRightPanelData}
          handleOpenCampaignClick={handleOpenCampaignClick}
          handleCloseCampaignClick={handleCloseCampaignClick}
        />
      </div>
      {campaignDataLoading && (
        <div className="h-[20%]">
          <LoadingAnimation />
        </div>
      )}
    </>
  );
}

export default CampaignListing;
