import React, { useState } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import styles from "./RichtextEditor.module.css";
import { isEmpty } from "lodash";

function RichtextEditor({ setFormValues, formValues }) {
  const [description, setDescription] = useState({
    htmlValue: "",
    editorState: isEmpty(formValues?.tandc?.tandc)
      ? EditorState.createEmpty()
      : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(formValues?.tandc?.tandc)?.contentBlocks
          )
        ),
  });

  const onEditorStateChange = (editorValue) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );

    setDescription({
      htmlValue: editorStateInHtml,
      editorState: editorValue,
    });

    setFormValues({
      ...formValues,
      // tandc: editorStateInHtml,
      tandc: {
        tandc: JSON.parse(JSON.stringify(editorStateInHtml)),
      },
    });
  };

  return (
    <div>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorState={description.editorState}
        onEditorStateChange={onEditorStateChange}
      />
      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </div>
  );
}

export default RichtextEditor;
