import { useState, useContext, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import PendingLeads from "./PendingLeads";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import LoadingAnimation from "../Loading/LoadingAnimation";

import TopBanner from "../TopBanner/TopBanner";
import RightPanel from "../RightPanel/RightPanel";
import AlertPopup from "../AlertPopups/AlertPopup";
import { closeContest, openContest } from "../../utils/StatusChanger";
import SortByFilter from "../Filters/SortByFilter";
import sortingJson from "../../Json/allEntriesSorting.json";
import filterJson from "../../Json/allEntriesFilter.json";
import RefreshButton from "../RefreshButton/RefreshButton";
import useGetAllEntries from "../../Hooks/useGetAllEntries";
import Filter from "../Filters/Filter";
import SendEmail from "../SendEmail/SendEmail";

function AllEntriesDetailPage() {
  const { currentUser } = useUserAuth();
  const [singleContestData, setSingleContest] = useState([]);
  const {
    verifyUser,
    userIdToken,
    setUpdateData,
    updateData,
    judmentMap,
    setJudmentMap,
    judmentEntries,
    setJudmentEntries,
    setNotAdmin,
    setCampaignIdToPersist,
  } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { orgId, campaignId, contestId } = useContext(Context);
  // const [singleContestData, setSingleContest] = useState([])
  const [headData, setHeadData] = useState({});
  const [loading, setLoading] = useState(false);
  //const [contestStatus, setContestStatus] = useState('')
  //const [campaignStatus, setCampaignStatus] = useState('')
  //  const [participantCount, setParticipantCount] = useState('')
  const [participantCount, setParticipantCount] = useState("");
  //const [winnerSelectedStatus, setWinnerSelectedStatus] = useState('')
  //const [winnersList, setWinnersList] = useState([])
  const [showCloseContestPopup, setShowCloseContestPopup] = useState(false);
  const [showOpenContestPopup, setShowOpenContestPopup] = useState(false);
  const [StatusChangeLoading, setStatusChangeLoading] = useState(false);
  const [sorting, setSorting] = useState("created");
  const [sendEmail, setSendEmail] = useState(false);
  const [filter, setFilter] = useState("");
  const [judgment, setJudment] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [sideFilter, setSideFilter] = useState("campaign");

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignContestData();
  }, [updateData]);

  const getCampaignContestData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ALL_CONTEST_DATA_CAMPAIGN}/${orgId}/${campaignId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data;
      setCampaignIdToPersist(campgnData?._id);

      const contestDetails =
        campgnData &&
        campgnData?.contests.find((el) => {
          return el.contestId == contestId;
        });
      setHeadData(contestDetails);
      const participantCounter = _.get(
        contestDetails && contestDetails,
        "participantCounter",
        0
      );
      setParticipantCount(participantCounter);

      setSingleContest(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const { dataloadingEntries, entries, entryCount } = useGetAllEntries(
    pageNumber,
    10,
    tokenData,
    orgId,
    campaignId,
    contestId,
    [contestId],
    sorting,
    filter
  );
  useEffect(() => {
    const updatedEntryMap = new Map(judmentMap);
    if (entries) {
      entries?.map((value) => {
        if (updatedEntryMap.has(value?._id)) {
        } else {
          updatedEntryMap.set(value?._id, value?.eligibleForJudgement || false);
        }
      });
      setJudmentMap(updatedEntryMap);
    }
  }, [entries]);

  const handleOpenContest = () => {
    setShowOpenContestPopup(true);
    // const { orgId, campaignId, contestId, orgDomain, campaignUniqueText } =
    //   headData;
    // openContest(
    //   orgId,
    //   campaignId,
    //   contestId,
    //   orgDomain,
    //   campaignUniqueText,
    //   tokenData,
    //   setStatusChangeLoading
    // );
  };
  const handleCloseContet = () => {
    const { orgId, campaignId, contestId, contestType } = headData;
    closeContest(
      orgId,
      campaignId,
      contestId,
      contestType,
      tokenData,
      setStatusChangeLoading,
      setNotAdmin
    );
  };
  const hanldeCloseStatusChangerPopup = () => {
    setShowCloseContestPopup(false);
  };
  const handleCloseOpenContentPopup = () => {
    setShowOpenContestPopup(false);
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Close the Contest",
    Para: "Are you sure you want to close this Contest? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Close this Contest",
    Button2Color: "#F5BF00",
  };
  const openPopUpValues = {
    Icon: "",
    Heading: "Open the Contest",
    Para: "Contact admin for opening this contest! ",
    Button1: "Cancel",
    Button2: "",
    Button2Color: "#06B818",
  };
  const handleRefresh = () => {
    setPageNumber(1);
    setUpdateData((prevState) => prevState + 1);
  };

  const handleChangeJudmentEntries = async () => {
    try {
      setLoading(true);
      let selectedArray = [];
      let notSelectedArray = [];
      judmentEntries?.forEach((value, key) => {
        if (value === true) {
          selectedArray.push(key);
        } else {
          notSelectedArray.push(key);
        }
      });

      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_SELECT_JUDGE_ENTRIES}/${orgId}/${campaignId}/${contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { selectedArray, notSelectedArray },
      };
      const response = await axios(config);
      if (response?.data?.admin == false) {
        setNotAdmin(true);
        setLoading(false);
        return;
      }
      if (response?.data?.status == "success") {
        setLoading(false);
        setJudment(false);
        setJudmentEntries(new Map());
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  return (
    <>
      {sendEmail && (
        <SendEmail
          showPopup={sendEmail}
          setShowPopup={setSendEmail}
          data={{ orgId, campaignId, contestId }}
          type="cntCommon"
        />
      )}
      <div className="content-wrapper ">
        <div className="center">
          {loading ? (
            <LoadingAnimation />
          ) : (
            <div className={`mobWidth paddingx`}>
              {showCloseContestPopup && (
                <AlertPopup
                  visible={showCloseContestPopup}
                  setpopUp={setShowCloseContestPopup}
                  button1Function={hanldeCloseStatusChangerPopup}
                  button2Function={handleCloseContet}
                  value={closePopUpValues}
                />
              )}
              {showOpenContestPopup && (
                <AlertPopup
                  visible={showOpenContestPopup}
                  setpopUp={setShowOpenContestPopup}
                  button1Function={handleCloseOpenContentPopup}
                  button2Function={handleOpenContest}
                  value={openPopUpValues}
                />
              )}
              {/* <ContestHeadData found={headData} openNav={openNav} /> */}
              <TopBanner
                backgroundImage={headData?.contestPoster}
                heading={headData?.contestName}
                data={headData}
                breadCrumbItem={[
                  { label: "Campaigns", url: "/campaigns" },
                  {
                    label: "Contests",
                    url: "/campaigns/contests",
                    params: {
                      orgId: headData?.orgId,
                      cmpId: headData?.campaignId,
                    },
                  },
                  {
                    label: headData?.contestName,
                    url: "/campaigns/contests/contest",
                    params: {
                      orgId: headData?.orgId,
                      cmpId: headData?.campaignId,
                      conId: headData?.contestId,
                    },
                  },
                  {
                    label: "All Entries",
                    url: "/campaigns/contests/contest/contest-all-entries",
                    params: {
                      orgId: headData?.orgId,
                      cmpId: headData?.campaignId,
                      conId: headData?.contestId,
                    },
                  },
                ]}
              />
              <div className="w-full flex items-center justify-between my-[20px]">
                <div className="flex gap-[10px] items-center">
                  <SortByFilter
                    sorting={sorting}
                    setSorting={setSorting}
                    options={sortingJson.options}
                  />
                  <Filter
                    sorting={filter}
                    setSorting={setFilter}
                    options={filterJson.options}
                    setPageNumber={setPageNumber}
                  />
                </div>
                <div className="flex items-center gap-[5px]">
                  {judgment ? (
                    <button
                      className="button-common"
                      onClick={handleChangeJudmentEntries}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="button-common"
                      onClick={() => setJudment(true)}
                    >
                      Select For Judgment
                    </button>
                  )}
                  <button
                    className="button-common"
                    onClick={() => setSendEmail(true)}
                  >
                    Sent Email
                  </button>
                  <RefreshButton handleRefresh={handleRefresh} />
                </div>
              </div>
              <div className="mt-[45px]">
                <PendingLeads
                  found={headData}
                  pendingApprovalCount={entryCount}
                  sorting={sorting}
                  data={entries}
                  dataloading={dataloadingEntries}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  type="allEntries"
                  judgment={judgment}
                />
              </div>
            </div>
          )}
        </div>
        <div className=" right-sidebar">
          <RightPanel
            cardTitile="contest details"
            title={headData.contestName}
            type="Contest"
            description={headData.description}
            rightPanelData={headData}
            status={headData.contestStatus}
            handleOpenClick={setShowOpenContestPopup}
            handleCloseClick={setShowCloseContestPopup}
            likeCount={headData?.contestEntryLikeCount}
            viewCount={headData?.contestEntryViewCount}
            participantCounter={headData?.participantCounter}
            userCount={headData?.contestVisitCount}
          />
        </div>
      </div>
    </>
  );
}

export default AllEntriesDetailPage;
