import axios from "axios";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Leads from "../../Components/Leads/Leads";
import Pagination from "../../Components/Pagination/Pagination";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";
import { useSearchParams } from "react-router-dom";

function ReferalPromotionRegisterPage() {
  const {
    orgDomain,
    verifyUser,
    userIdToken,
    orgDomainLoading,
    updateData,
    setUpdateData,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [leadsData, setLeadsData] = useState([]);
  const [mainDataLoading, setMainDataLoading] = useState(false);
  const [leadsCampaignData, setLeadsCampaignData] = useState([]);
  const [refferalData, setRefferalData] = useState([]);
  const [leadsType, setLeadsType] = useState("promotion");
  const [leadsDataLoading, setLeadsDataLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [sorting, setSorting] = useState("notCreatedAt");
  const [filter, setFilter] = useState("ALL");
  const tokenData = userIdToken || currentUser?.accessToken;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLeads, setTotalLeads] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");
  const promotionId = searchParams.get("proId");
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    if (!isEmpty(orgDomain)) {
      getPromotionDataForLeads();
    }
  }, [orgDomain]);

  const getPromotionDataForLeads = async () => {
    try {
      setMainDataLoading(true);

      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP__GET_REFERALS}/${orgId}/${promotionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const couponsArray = setApi?.data?.data?.referralCoupon;

      setRefferalData(couponsArray);
      getLeadsData(couponsArray[0]?.coupon, true);
      setSelectedCampaign(couponsArray[0]);
      setMainDataLoading(false);
    } catch (err) {
      setMainDataLoading(false);
      console.log(err.message);
    }
  };

  const getLeadsData = async (couponId, promotion) => {
    try {
      setLeadsDataLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_REF_REGISTRATION}/${orgId}/${promotionId}?page=${pageNumber}&limit=10&promotion=${promotion}&type=${filter}&referralCoupon=${couponId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setLeadsData(campgnData);
      setTotalLeads(setApi?.data?.totalCount);
      setLeadsDataLoading(false);
      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsData([]);
      setLeadsDataLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCampaign) getLeadsData(selectedCampaign?.coupon, true);
  }, [sorting, filter, pageNumber, updateData]);
  const handleLeadsSearch = (obj) => {
    setSelectedCampaign(obj);
    setLeadsType("promotion");
    getLeadsData(obj?.coupon, true);
  };
  const handleRefresh = () => {
    setUpdateData((prevState) => prevState + 1);
  };
  return (
    <>
      <div className="flex justify-end w-full pt-[20px] px-[20px]">
        <RefreshButton handleRefresh={handleRefresh} />
      </div>
      <Leads
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        leadsCampaignData={leadsCampaignData}
        refferalData={refferalData}
        handleLeadsSearch={handleLeadsSearch}
        leadsDataLoading={leadsDataLoading}
        orgDomainLoading={orgDomainLoading}
        mainDataLoading={mainDataLoading}
        leadsData={leadsData}
        setSorting={setSorting}
        sorting={sorting}
        filter={filter}
        setFilter={setFilter}
        leadsType={leadsType}
        leadType="REGISTER"
      />
      <div className="p-[20px]">
        <Pagination
          totalData={totalLeads}
          pageLimit="10"
          setPageNumber={setPageNumber}
          currentPage={pageNumber}
        />
      </div>
    </>
  );
}

export default ReferalPromotionRegisterPage;
