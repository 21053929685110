import axios from "axios";

export const closeCampaign = async (
  orgId,
  campaignId,
  orgDomain,
  campaignUniqueText,
  tokenData,
  setStatusChangeLoading
) => {
  setStatusChangeLoading(true);
  try {
    let config1 = {
      method: "PATCH",
      url: `${process.env.REACT_APP_CLOSE_ORGANISER_CAMPAIGN}/${orgId}/${campaignId}?orgDomain=${orgDomain}&campaignUniqueText=${campaignUniqueText}       `,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const setApi = await axios(config1);
    setStatusChangeLoading(false);
    if (setApi.status === 201) {
      window.location.reload();
    }
  } catch (err) {
    console.log(err.message);
    setStatusChangeLoading(false);
  }
};

export const openCampaign = async (
  orgId,
  campaignId,
  orgDomain,
  campaignUniqueText,
  tokenData,
  setStatusChangeLoading
) => {
  setStatusChangeLoading(true);
  try {
    let config1 = {
      method: "PATCH",
      url: `${process.env.REACT_APP_OPEN_ORGANISER_CAMPAIGN}/${orgId}/${campaignId}?orgDomain=${orgDomain}&campaignUniqueText=${campaignUniqueText}       `,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const setApi = await axios(config1);
    setStatusChangeLoading(false);
    if (setApi.status === 201) {
      window.location.reload();
    }
  } catch (err) {
    console.log(err.message);
    setStatusChangeLoading(false);
    // setunderReview(true);
  }
};
export const publishCampaign = async (
  campaignId,
  tokenData,
  setStatusChangeLoading,
  setUpdateData,
  setCampign,
  setCampaignPageNo,
  setShowPublish,
  setShowEditCampaign,
  setNotAdmin
) => {
  setStatusChangeLoading(true);
  try {
    let config1 = {
      method: "PATCH",
      url: `${process.env.REACT_APP_PUBLISH_ORGANISER_CAMPAIGN}/${campaignId}`,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const setApi = await axios(config1);
    if (setApi?.data?.admin == false) {
      setNotAdmin(true);
      setShowPublish(false);
      setStatusChangeLoading(false);
      return;
    }
    setStatusChangeLoading(false);
    if (setApi.status === 201) {
      setCampign([]);
      setCampaignPageNo(1);
      setShowPublish(false);
      setShowEditCampaign(false);
      setUpdateData((prevState) => prevState + 1);
    }
  } catch (err) {
    console.log(err.message);
    setStatusChangeLoading(false);
    // setunderReview(true);
  }
};
export const requestEdit = async (
  campaignId,
  tokenData,
  setStatusChangeLoading,
  setUpdateData,
  setCampign,
  setCampaignPageNo,
  setReqEdit,
  setShowEditCampaign,
  setNotAdmin
) => {
  setStatusChangeLoading(true);
  try {
    let config1 = {
      method: "PATCH",
      url: `${process.env.REACT_APP_EDITREQ_ORGANISER_CAMPAIGN}/${campaignId}`,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const setApi = await axios(config1);
    if (setApi?.data?.admin == false) {
      setNotAdmin(true);
      setReqEdit(false);
      setStatusChangeLoading(false);
      return;
    }
    setStatusChangeLoading(false);
    if (setApi?.data?.status == "success") {
      setCampign([]);
      setCampaignPageNo(1);
      setReqEdit(false);
      setShowEditCampaign(false);
      setUpdateData((prevState) => prevState + 1);
    }
  } catch (err) {
    console.log(err.message);
    setStatusChangeLoading(false);
    // setunderReview(true);
  }
};
export const closeContest = async (
  orgId,
  campaignId,
  contestId,
  contestType,
  tokenData,
  setStatusChangeLoading,
  setNotAdmin
) => {
  setStatusChangeLoading(true);
  try {
    let config1 = {
      method: "PATCH",
      url: `${process.env.REACT_APP_CLOSE_CONTEST}/${orgId}/${campaignId}/${contestId}?contestType=${contestType} `,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };

    const setApi = await axios(config1);
    if (setApi?.data?.admin == false) {
      setNotAdmin(true);
      setStatusChangeLoading(false);
      return;
    }
    setStatusChangeLoading(false);
    if (setApi.status === 201) {
      window.location.reload();
    }
  } catch (err) {
    console.log(err.message);
    setStatusChangeLoading(false);
  }
};
export const openContest = async (
  orgId,
  campaignId,
  contestId,
  orgDomain,
  campaignUniqueText,
  tokenData,
  setStatusChangeLoading
) => {
  setStatusChangeLoading(true);
  try {
    let config1 = {
      method: "PATCH",
      url: `${process.env.REACT_APP_OPEN_CONTEST}/${orgId}/${campaignId}/${contestId}?orgDomain=${orgDomain}&campaignUniqueText=${campaignUniqueText}       `,

      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const setApi = await axios(config1);
    setStatusChangeLoading(false);

    if (setApi.status === 201) {
      window.location.reload();
    }
  } catch (err) {
    console.log(err.message);
    setStatusChangeLoading(false);
  }
};

//closeContest ,closeCampaign need to change reload concept and admin popup
