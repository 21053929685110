import styles from "./styles/LuckDrw.module.css";
function ContestHeadData({ found, openNav }) {

  return (
    <div className="mt-[30px]">
      <div className="flex items-center">
        <div
          onClick={openNav}
          className={`mr-[10px] text-[30px] ${styles.openbutton}`}
        >
          ☰
        </div>
        <p className="fifaHead">Contests </p>
      </div>
      <div
        className={styles.pending}
        style={{ background: found?.buttonColor }}
      >
        <div>
          <p className={styles.pendingTxt}>{found?.grouping}</p>
          <p className={styles.pendingTxt}>{found?.contestName}</p>
        </div>
        {/* <div>
          <Timer />
          <p className={styles.end}>{found?.endDate}</p>
        </div> */}
      </div>
    </div>
  );
}

export default ContestHeadData;
