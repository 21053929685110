import React, { useEffect, useState } from "react";
import styles from "./ContestJudges.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import LoadingAnimation from "../Loading/LoadingAnimation";
import CreateJudge from "../CreateJudge/CreateJudge";
import { isEmpty } from "lodash";

export default function ContestJudges({
  setShowAddJudges,
  campaignData,
  campaignContestsDatas,
  data,
}) {
  const initialValue = data?.judges ? data?.judges : [];

  const constetJudges = campaignContestsDatas?.judges?.filter((el) =>
    initialValue?.some((el2) => el2 == el?._id)
  );
  let filter = campaignContestsDatas?.judges?.filter(
    (el) => !initialValue?.some((el2) => el2 == el?._id)
  );
  const [judges, setJudges] = useState(constetJudges);
  const [judgesToRemove, setJudgesToRemove] = useState([]);
  const [judgesData, setSponsorData] = useState(filter);
  const [loading, setLoading] = useState(false);
  const [addSponsors, setAddSponsors] = useState(false);
  const { organizationId, userIdToken, setUpdateData, updateData } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  const judgesDataFilter = () => {
    let sponsorFromApi = judgesData;
    let filter = sponsorFromApi.filter(
      (el) => !judges?.some((el2) => el2?._id === el?._id)
    );
    setSponsorData(filter);
  };

  const handleSelect = (obj) => {
    let newArray = judges;
    let removeArray = judgesToRemove;
    var removeIndex = removeArray?.findIndex((el) => el == obj?._id);
    if (removeIndex >= 0) {
      removeArray?.splice(removeIndex, 1);
      setJudgesToRemove([...new Set(removeArray)]);
    }
    newArray.push(obj);
    setJudges(newArray);
    judgesDataFilter();
  };
  const handleRemove = (obj) => {
    let newArray = judges;
    let removeArray = judgesToRemove;
    var removeIndex = data?.judges?.findIndex((el) => el == obj?._id);
    if (removeIndex >= 0) {
      removeArray?.push(obj?._id);
    }
    setJudgesToRemove([...new Set(removeArray)]);
    var index = newArray.findIndex((el) => el._id == obj._id);
    newArray?.splice(index, 1);
    setJudges(newArray);
    judgesDataFilter();
    setSponsorData([obj, ...judgesData]);
  };
  const handleSave = async () => {
    setLoading(true);
    if (!isEmpty(judgesToRemove)) {
      await handleRemoveJudges();
    }
    const saveResponse = await handleSaveJudges();
    if (saveResponse?.data?.status === "success") {
      setShowAddJudges(false);
      setUpdateData((prevState) => prevState + 1);
    } else {
      alert("please retry");
    }
    setLoading(false);
  };

  const handlePublish = async () => {
    setLoading(true);
    if (!isEmpty(judgesToRemove)) {
      await handleRemoveJudges();
    }
    const saveResponse = await handleSaveJudges();
    await handlePublishCampaign();
    setLoading(false);
  };
  const handleSaveJudges = async () => {
    const arrayOfIds = judges?.map((obj) => obj._id);
    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_ADD_JUDGE_CONTEST}/${campaignData?.orgId}/${campaignData?._id}/${data?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { judgeIds: arrayOfIds },
      };
      const response = await axios(config);
      return response;
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleRemoveJudges = async () => {
    setLoading(true);
    const arrayOfIds = judges?.map((obj) => obj._id);
    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_REMOVE_JUDGE_CONTEST}/${campaignData?.orgId}/${campaignData?._id}/${data?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { judgeIds: judgesToRemove },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
      } else {
        alert("please retry");
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handlePublishCampaign = async () => {
    try {
      let config2 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_PUBLISH_ORGANISER_CAMPAIGN}/${campaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response2 = await axios(config2);
      if (response2.status === 201) {
        setShowAddJudges(false);
        setUpdateData((prevState) => prevState + 1);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <div className={styles.popup}>
      {addSponsors && (
        <CreateJudge showPopup={addSponsors} setShowPopup={setAddSponsors} />
      )}
      <div className={styles.selectAdsPopup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Judges</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowAddJudges(false)} />
          </div>
          <div className={styles.body}>
            {loading ? (
              <LoadingAnimation />
            ) : (
              <>
                <p>judges</p>
                <div className={styles.promotionListGrid}>
                  {judgesData?.map((obj) => {
                    return (
                      <div
                        className={styles.entry}
                        onClick={() => handleSelect(obj)}
                      >
                        <img src={obj?.logo} />
                        <p>{obj?.name}</p>
                      </div>
                    );
                  })}
                  {/* <div
                    className={styles.entry}
                    onClick={() => setAddSponsors(true)}
                  >
                    <img src={obj?.fileUrl} />
                    <p>Create judge</p>
                  </div> */}
                </div>
                <p className="mt-[20px]">Selected judges</p>

                <div className={styles.promotionListGrid}>
                  {judges?.map((obj) => {
                    return (
                      <div
                        className={styles.entry}
                        onClick={() => handleRemove(obj)}
                      >
                        <img src={obj?.logo} />
                        <p>{obj?.name}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.save}
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </button>
            <button
              className={styles.save}
              onClick={handlePublish}
              disabled={loading}
            >
              Save and publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
