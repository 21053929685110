import React, { useContext, useEffect } from "react";
import * as _ from "lodash";
import Context from "../../Context/Context";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./EditCampaign.module.css";
import Close from "../../Assets/close.svg";
import editCampaignHeaderLogo from "../../Assets/editCampaignHeaderLogo.png";
import editCampaignLeftArrow from "../../Assets/editCampaignLeftArrow.png";
import CampignFormInputs from "./CampignFormInputs";
import { useState } from "react";
import ContestEditList from "./ContestEditList";
import ContestFormInputs from "./ContestFormInputs";
import AddContestList from "./AddContestList";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import AlertPopup from "../AlertPopups/AlertPopup";
import publishIcon from "../../Assets/AlertPopup/publishIcon.svg";
import { addHoursAndMinutesToUTC } from "../../utils/DateFormator";

export default function EditCampaign({
  setShowEditCampaign,
  showEditCampaign,
  editCampaignData,
}) {
  const {
    modalRef,
    userIdToken,
    verifyUser,
    setCampign,
    setUpdateData,
    setCampaignPageNo,
    setNotAdmin,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [campaignEditing, setCampaignEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);

  const initialValues = {
    coverImage: editCampaignData?.coverImage,
    campaignName: editCampaignData?.campaignName,
    campaignUniqueText: editCampaignData?.campaignUniqueText,
    description: editCampaignData?.description,
    tandc: editCampaignData?.tandc,
    faq: editCampaignData?.faq,
    startDate: editCampaignData?.startDate
      ? addHoursAndMinutesToUTC(editCampaignData?.startDate)
      : "",
    endDate: editCampaignData?.endDate
      ? addHoursAndMinutesToUTC(editCampaignData?.endDate)
      : "",
    conductedBy: editCampaignData?.conductedBy,
    orgName: editCampaignData?.orgName,
    phone: editCampaignData?.phone,
    howToParticipate: editCampaignData?.howToParticipate,
    btnColor: editCampaignData?.btnColor,
    filterChips: editCampaignData?.filterChips || [],
    certificateImage: editCampaignData?.certificateImage || "",
    certificate: editCampaignData?.certificate || false,
    defaultThumbnail: editCampaignData?.defaultThumbnail || "",
    instagramParticipateText: editCampaignData?.instagramParticipateText,
    color1: editCampaignData?.color1 || "#EE89f5",
    color2: editCampaignData?.color2 || "#A262A7",
    enableCouponRedeemNotification:
      editCampaignData?.enableCouponRedeemNotification || false,
    couponRedeemOrgNum: editCampaignData?.couponRedeemOrgNum || [],
    enableUploadMsgToOrg: editCampaignData?.enableUploadMsgToOrg || false,
    uploadMsgToOrgNum: editCampaignData?.uploadMsgToOrgNum || [],
  };
  useEffect(() => {
    verifyUser();
  }, []);
  const [formValues, setFormValues] = useState(initialValues);
  const [modifiedCampaignValues, setModifiedCampaignValues] = useState({});
  const [modifiedFormValues, setModifiedFormValues] = useState({});
  const [campaignSpecs, setCampaignSpecs] = useState({});
  const [modifiedCampaignSpecs, setModifiedCampaignSpecs] = useState({});
  const [contestFormValue, setContestFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [contestFormErrors, setContestFormErrors] = useState({});
  const [showClosePopup, setShowClosePopup] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    getGiftDetails();
  }, []);
  const getGiftDetails = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `https://storage.googleapis.com/goviral_campaign_data/${editCampaignData?.orgDomain}/${editCampaignData?.campaignUniqueText}/campaignSpecs.json`,
      };
      const apiData = await axios(config1);
      setCampaignSpecs(apiData?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleUpdateCamapignDetails = async () => {
    // const success = handleSave();

    // let newContestArray = formValues?.contests.map((el) =>
    //   contestFormValue.contestId === el.contestId ? contestFormValue : el
    // );
    // let data = { ...formValues, contests: newContestArray };
    // setFormValues({ ...formValues, contests: newContestArray });
    let data = formValues;
    setLoading(true);
    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_CAMPAIGN_DETAILS}/${editCampaignData?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        if (!_.isEmpty(modifiedFormValues)) {
          let config = {
            method: "post",
            url: `${process.env.REACT_APP_UPDATE_SHAREPAGE_CAMPAIGN_JSON}/${editCampaignData?.orgId}/${editCampaignData._id}`,
            headers: {
              Authorization: `Bearer ${tokenData}`,
              "Content-Type": "application/json",
            },
            data: {
              certificate:
                modifiedFormValues?.certificate || formValues?.certificate,
              bgImage:
                modifiedFormValues?.certificateImage ||
                formValues?.certificateImage,
              color1: modifiedFormValues?.color1,
              color2: modifiedFormValues?.color2,
            },
          };
          const updateSharejson = await axios(config);
        }
        if (!_.isEmpty(modifiedCampaignSpecs)) {
          let config1 = {
            method: "post",
            url: `${process.env.REACT_APP_UPDATE_CAMPAIGN_SPECS}?orgDomain=${editCampaignData?.orgDomain}&campaignUniqueText=${editCampaignData?.campaignUniqueText}`,
            headers: {
              Authorization: `Bearer ${tokenData}`,
              "Content-Type": "application/json",
            },
            data: {
              campaignBackground:
                modifiedCampaignSpecs?.campaignBackground ||
                campaignSpecs?.campaignBackground,
              eventFacts: {
                coverImg:
                  modifiedCampaignSpecs?.eventFacts?.coverImg ||
                  campaignSpecs?.eventFacts?.coverImg,
                facts:
                  modifiedCampaignSpecs?.eventFacts?.facts ||
                  campaignSpecs?.eventFacts?.facts,
              },
            },
          };
          const updatecampaignSpec = await axios(config1);
        }
        setLoading(false);
        setSavedChanges(true);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const hanldeContinueEditing = () => {
    setSavedChanges(false);
  };
  const handleCloseEditing = () => {
    setCampign([]);
    setCampaignPageNo(1);
    setShowEditCampaign(false);
    setUpdateData((prevState) => prevState + 1);
    setSavedChanges(false);
  };
  const popUpSaveValues = {
    Icon: publishIcon,
    Heading: "Changes Saved",
    Para: "Are you sure you want to continue editing or close the tab? ",
    Button1: "Edit Camapign",
    Button2: "Close",
    Button2Color: "#06B818",
  };
  // CAMPAIGN FROM VALIDATION
  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailAdress && emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhone = (number) => {
    let regexmobile =
      // eslint-disable-next-line no-useless-escape
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (number.match(regexmobile)) {
      return true;
    } else {
      return false;
    }
  };

  const handleNext = () => {
    setFormErrors(validate(formValues));
    let errorObj = validate(formValues);

    if (
      _.isEmpty(errorObj) &&
      !_.isEmpty(`${formValues.phone}`) &&
      !_.isEmpty(formValues.conductedBy?.about) &&
      !_.isEmpty(formValues.conductedBy.email) &&
      !_.isEmpty(formValues.conductedBy.name) &&
      !_.isEmpty(formValues.tandc.tandc) &&
      formValues.tandc.tandc !== "<p></p>\n" &&
      !_.isEmpty(formValues.description) &&
      !_.isEmpty(formValues.campaignName) &&
      validateEmail(formValues.conductedBy.email) !== false &&
      validatePhone(`${formValues.phone}`) !== false
    ) {
      handleUpdateCamapignDetails();
      // what to do login need to write
    }
  };

  const validate = (values) => {
    const errors = {};

    if (_.isEmpty(`${values.phone}`)) {
      errors.phone = "This field is required ";
      //   textInputContactNumber.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    } else if (!validatePhone(`${values.phone}`)) {
      errors.phone = "Please enter a valid phone number";
      //   textInputContactNumber.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    }
    // if (_.isEmpty(values.conductedBy?.location)) {
    //   errors.address = "This field is required";
    //   //   textInputAddress.current.scrollIntoView({
    //   //     behavior: "smooth",
    //   //     block: "center",
    //   //     inline: "start",
    //   //   });
    // }
    if (_.isEmpty(values.conductedBy?.about)) {
      errors.about = "This field is required  ";
      //   textInputGifts.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    }
    if (_.isEmpty(values.conductedBy.email)) {
      errors.email = "This field is required  ";
      //   textInputEmail.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    } else if (!validateEmail(values.conductedBy.email)) {
      errors.email = "Please enter a valid Email";
      //   textInputEmail.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    }
    if (_.isEmpty(values.conductedBy.name)) {
      errors.orgName = "This field is required";
      //   textInputAboutOrganiser.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    }
    if (_.isEmpty(values.pin) && values.isPrivate) {
      errors.pin = "This field is required  ";
    }
    if (values.tandc?.tandc === "<p></p>\n" || _.isEmpty(values.tandc?.tandc)) {
      errors.tandc = "This field is required  ";
      //   textInputTandc.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    }

    if (_.isEmpty(values.description)) {
      errors.description = "This field is required";
      //   textInputAboutOrganiser.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    }
    if (_.isEmpty(values.campaignName)) {
      errors.campaignName = "This field is required";
      //   textInputAboutOrganiser.current.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "start",
      //   });
    }

    // window.scrollTo({ top: 0, behavior: "smooth" });
    return errors;
  };
  // CAMPAIGN FROM VALIDATION

  // CONTEST FORM VALIDATION
  const handleSave = () => {
    setContestFormErrors(validateContestValues(contestFormValue));
    if (
      !_.isEmpty(`${contestFormValue.contestName}`) &&
      !_.isEmpty(contestFormValue.contestTagLine) &&
      contestFormValue.priceDescription !== "<p></p>\n" &&
      !_.isEmpty(contestFormValue.priceDescription) &&
      contestFormValue.contestRules !== "<p></p>\n" &&
      !_.isEmpty(contestFormValue.contestRules) &&
      contestFormValue.howToParticipate !== "<p></p>\n" &&
      !_.isEmpty(contestFormValue.howToParticipate)
    ) {
      return true;
    }
  };

  const validateContestValues = (values) => {
    const errors = {};
    if (_.isEmpty(values.contestName)) {
      errors.contestName = "This field is required";
    }
    if (_.isEmpty(values.contestTagLine)) {
      errors.contestTagLine = "This field is required";
    }
    if (
      values.priceDescription === "<p></p>\n" ||
      _.isEmpty(values.priceDescription)
    ) {
      errors.priceDescription = "This field is required  ";
    }
    if (values.contestRules === "<p></p>\n" || _.isEmpty(values.contestRules)) {
      errors.contestRules = "This field is required  ";
    }
    if (
      values.howToParticipate === "<p></p>\n" ||
      _.isEmpty(values.howToParticipate)
    ) {
      errors.howToParticipate = "This field is required  ";
    }
    return errors;
  };
  // CONTEST FORM VALIDATION
  const handleClosePopup = () => {
    setShowClosePopup(false);
  };
  const handleCloseEditPopup = () => {
    setShowEditCampaign(false);
  };
  const handleBackButton = () => {
    const success = handleSave();
    if (success) {
      let newContestArray = formValues?.contests.map((el) =>
        contestFormValue.constestId === el.contestId ? contestFormValue : el
      );
      setFormValues({ ...formValues, contests: newContestArray });
      setCampaignEditing(true);
    }
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Are you sure?",
    Para: "All your changes will be discarded ",
    Button1: "Cancel",
    Button2: "Close",
    Button2Color: "#F5BF00",
  };

  return (
    <PopupWrapper visible={showEditCampaign}>
      {showClosePopup && (
        <AlertPopup
          visible={showClosePopup}
          setpopUp={setShowClosePopup}
          button1Function={handleClosePopup}
          button2Function={handleCloseEditPopup}
          value={closePopUpValues}
        />
      )}
      {savedChanges && (
        <AlertPopup
          visible={savedChanges}
          setpopUp={setSavedChanges}
          button1Function={hanldeContinueEditing}
          button2Function={handleCloseEditing}
          value={popUpSaveValues}
        />
      )}
      <div className={styles.editCampaign} ref={modalRef}>
        <div className={styles.contentDiv}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {campaignEditing ? (
                <>
                  <img src={editCampaignHeaderLogo} />
                  <h2>Edit your Campaign</h2>
                </>
              ) : (
                <>
                  <img
                    src={editCampaignLeftArrow}
                    onClick={handleBackButton}
                    className="cursor-pointer"
                  />
                  <h2>Edit your Contests</h2>
                </>
              )}
            </div>
            <img src={Close} alt="x" onClick={() => setShowClosePopup(true)} />
          </div>
          <div className={styles.body}>
            <div className={`custom-scrollbar ${styles.left}`}>
              {campaignEditing ? (
                <CampignFormInputs
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setModifiedCampaignValues={setModifiedCampaignValues}
                  setModifiedFormValues={setModifiedFormValues}
                  campaignSpecs={campaignSpecs}
                  setCampaignSpecs={setCampaignSpecs}
                  setModifiedCampaignSpecs={setModifiedCampaignSpecs}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  tokenData={tokenData}
                  editCampaignData={editCampaignData}
                  setLoading={setLoading}
                />
              ) : (
                <ContestEditList
                  setContestFormValues={setContestFormValues}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  contestFormValue={contestFormValue}
                  handleSave={handleSave}
                />
              )}
            </div>
            <div className={`custom-scrollbar ${styles.right}`}>
              {campaignEditing ? (
                <AddContestList
                  formValues={formValues}
                  setFormValues={setFormValues}
                  formErrors={formErrors}
                />
              ) : (
                <ContestFormInputs
                  contestFormValue={contestFormValue}
                  setContestFormValues={setContestFormValues}
                  editCampaignData={editCampaignData}
                  tokenData={tokenData}
                  contestFormErrors={contestFormErrors}
                  formValues={formValues}
                />
              )}
              <div className={styles.buttonWrapper}>
                <button className={styles.discard}>Discard Changes</button>

                <button
                  className={styles.save}
                  onClick={loading ? "" : handleNext}
                >
                  {loading ? "Saving" : "Save Changes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
