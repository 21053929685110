import React, { useState } from "react";
import { useContext } from "react";
import { Outlet } from "react-router";
import HomeSidebar from "../../Components/HomeSidebar/HomeSidebar";
import StatusChangerLoading from "../../Components/Loading/StatusChangerLoading";
import NavBar from "../../Components/NavBar";
import Context from "../../Context/Context";
import { useEffect } from "react";
import axios from "axios";
import AlertPopup from "../../Components/AlertPopups/AlertPopup";
import DonePopup from "../../Components/DonePopup/DonePopup";
function Layout() {
  const {
    statusChangeLoading,
    orgDomainLoading,
    dashboard,
    notAdmin,
    setNotAdmin,
    successMessage,
  } = useContext(Context);
  const [leftMenuOptions, setLeftMenuJson] = useState({ menu: [] });
  const [dbChangeLoading, setDbChangeLoading] = useState(false);
  useEffect(() => {
    getSideMenu(dashboard);
  }, [dashboard]);
  const getSideMenu = async (dashboardType) => {
    let url = "";
    if (dashboardType == "organizer") {
      url = "/json/LeftMenu.json";
    } else if (dashboardType == "influencer") {
      url = "/json/InfluencerSideMenu.json";
    } else if (dashboardType == "sponsor") {
      url = "/json/SponsorSideMenu.json";
    }
    try {
      setDbChangeLoading(true);
      const setdata = await axios.get(url);
      setLeftMenuJson(setdata?.data);
      setDbChangeLoading(false);
    } catch (err) {
      setDbChangeLoading(false);
      console.log(err.message);
    }
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Sorry, access denied",
    Para: "You don't access to perform this action, Please contact admin",
    Button1: "OK",
  };
  return (
    <div>
      {notAdmin && (
        <AlertPopup
          visible={notAdmin}
          setpopUp={setNotAdmin}
          button1Function={() => setNotAdmin(false)}
          value={closePopUpValues}
        />
      )}
      {successMessage && <DonePopup message={successMessage} />}
      {dbChangeLoading && <StatusChangerLoading />}
      {statusChangeLoading && <StatusChangerLoading />}
      {orgDomainLoading && <StatusChangerLoading />}
      <NavBar />
      <div className="wrapper">
        <HomeSidebar LeftMenu={leftMenuOptions} />
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
