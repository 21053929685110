import React from "react";
import styles from "./styles/PendingListOnPanel.module.css";
import DownA from "../../Assets/downA.svg";
import { isEmpty } from "lodash";
import { useState } from "react";
import ReviewDetails from "../PollContest/ReviewDetails";
import { useNavigate } from "react-router-dom";

function PendingListOnPanel({
  pendingApprovalList,
  pendingArrovalCount,
  type,
  rightPanelData,
}) {
  const [popUp, setpopUp] = useState(false);
  const [popUpData, setpopUpData] = useState({});
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (type === "Campaign") {
      navigate(
        `/campaigns/campaign-pending-approvals?orgId=${rightPanelData?.orgId}&cmpId=${rightPanelData?.campaignId}`
      );
    } else {
      navigate(
        `/campaigns/contests/contest/contest-pending-approvals?orgId=${rightPanelData?.orgId}&cmpId=${rightPanelData?.campaignId}&conId=${rightPanelData?.contestId}`
      );
    }
  };
  return (
    <>
      {popUp && (
        <ReviewDetails
          setpopUp={setpopUp}
          popupData={popUpData}
          popUp={popUp}
        />
      )}
      {!isEmpty(pendingApprovalList) && (
        <div className="mt-[17px]">
          <div className="flex justify-between items-baseline">
            <p className="mb-[14px] font-normal text-[12px] text-black">
              Pending Approvals
            </p>
            <img className="cursor-pointer" src={DownA} alt="" />
          </div>
          {pendingApprovalList &&
            pendingApprovalList.map((obj, index) => {
              return (
                <div
                  className="flex gap-3 mt-[22px] items-center"
                  key={index}
                  onClick={() => {
                    setpopUp(true);
                    setpopUpData(obj);
                  }}
                >
                  {/* <img
                    className="w-[24px] h-[24px] rounded-full object-cover"
                    src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                    alt=""
                  /> */}
                  <div className={styles.nameDp}>
                    {obj?.participantName?.charAt(0).toUpperCase()}
                  </div>
                  <p>{obj?.participantName}</p>
                  <div className={styles.approve}>Pending</div>
                </div>
              );
            })}
          {pendingArrovalCount > 5 && (
            <p className={styles.pendingNum} onClick={handleNavigate}>
              + {pendingArrovalCount - pendingApprovalList.length} Pending
              Approvals
            </p>
          )}
        </div>
      )}
    </>
  );
}

export default PendingListOnPanel;
