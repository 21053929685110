import React, { useEffect, useRef, useState } from "react";
import styles from "./EditCampaign.module.css";
import Close from "../../Assets/close.svg";
import HelpPopup from "../HelpPopup/HelpPopup";
import RichtextEditor from "../RichtextEditor/RichtextEditor";
import axios from "axios";
import CommonRichTextEditor from "../RichtextEditor/CommonRichTextEditor";
import RichtextEditorWithSubValues from "../RichtextEditor/RichtextEditorWithSubValues";
import FilterChips from "./FilterChips";
import { singleFileUpload } from "../../utils/SingleFileUpload";
import { isEmpty } from "lodash";
import DynamicInput from "../DynamicInput/DynamicInput";
import HowToParticipateVideo from "./HowToParticipateVideo";
import { getCurrentTimezone } from "../../utils/DateFormator";
import TextAdd from "../TextAdd/TextAdd";

function CampignFormInputs({
  formValues,
  setFormValues,
  setModifiedFormValues,
  setModifiedCampaignValues,
  campaignSpecs,
  setCampaignSpecs,
  setModifiedCampaignSpecs,
  formErrors,
  setFormErrors,
  helpData,
  tokenData,
  editCampaignData,
  setLoading,
}) {
  const [addPartiVideo, setAddPartiVideo] = useState(false);
  const inputRef = useRef();
  const [source, setSource] = useState(formValues.coverImage);
  const [changedCampaigSpec, setChangedCampaignSpec] = useState(false);
  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleFileChange = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    setSource(url);
    setLoading(true);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPDATE_CAMPAIGN_POSTER}/${editCampaignData?.orgId}/${editCampaignData._id}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: thumbnail,
    };
    const coverUpdate = await axios(config);
    if (coverUpdate.status === 200) {
      setFormValues({ ...formValues, coverImage: coverUpdate.data.posterUrl });
    }
    setLoading(false);
  };

  const inputRefCertificate = useRef();
  const [certificateImage, setCertificateImage] = useState(
    formValues.certificateImage
  );
  const handleFileChangeCertificate = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    setCertificateImage(url);
    setLoading(true);
    let uploadPath = `/${editCampaignData?.orgId}/`;
    let uploadFile = await singleFileUpload(imagedata, uploadPath);
    setFormValues((prevState) => ({
      ...prevState,
      certificateImage: uploadFile,
    }));
    setModifiedFormValues((prevState) => ({
      ...prevState,
      certificateImage: uploadFile,
    }));
    setLoading(false);
  };
  const handleChooseCertificate = async () => {
    inputRefCertificate.current.click();
  };

  const inputRefThumbnail = useRef();
  const [defaultThumbnail, setDefaultThumbnail] = useState(
    formValues.defaultThumbnail
  );
  const handleFileChangeThumbnail = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    setDefaultThumbnail(url);
    setLoading(true);
    let uploadPath = `/${editCampaignData?.orgId}/`;
    let uploadFile = await singleFileUpload(imagedata, uploadPath);
    setFormValues((prevState) => ({
      ...prevState,
      defaultThumbnail: uploadFile,
    }));
    setLoading(false);
  };
  const handleChooseThumbnail = async () => {
    inputRefThumbnail.current.click();
  };

  const inputRefDesktopBg = useRef();
  const [desktopBg, setDesktopBg] = useState();

  useEffect(() => {
    if (isEmpty(desktopBg)) {
      setDesktopBg(campaignSpecs?.campaignBackground);
    }
  }, [campaignSpecs]);
  const handleFileChangeDesktopBg = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    setDesktopBg(url);
    setLoading(true);
    let uploadPath = `/${editCampaignData?.orgId}/`;
    let uploadFile = await singleFileUpload(imagedata, uploadPath);
    setCampaignSpecs((prevState) => ({
      ...prevState,
      campaignBackground: uploadFile,
    }));
    setModifiedCampaignSpecs((prevState) => ({
      ...prevState,
      campaignBackground: uploadFile,
    }));
    setLoading(false);
  };
  const handleChooseDesktopBg = async () => {
    inputRefDesktopBg.current.click();
  };

  const inputRefDidImg = useRef();
  const [didImg, setDidImg] = useState();

  useEffect(() => {
    if (isEmpty(didImg)) {
      setDidImg(campaignSpecs?.eventFacts?.coverImg);
    }
    if (!isEmpty(campaignSpecs)) {
      setChangedCampaignSpec(true);
    }
  }, [campaignSpecs]);
  const handleFileChangeDidImg = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    setDidImg(url);
    setLoading(true);
    let uploadPath = `/${editCampaignData?.orgId}/`;
    let uploadFile = await singleFileUpload(imagedata, uploadPath);
    setCampaignSpecs((prevState) => ({
      ...prevState,
      eventFacts: {
        ...prevState?.eventFacts,
        coverImg: uploadFile,
      },
    }));
    setModifiedCampaignSpecs((prevState) => ({
      ...prevState,
      eventFacts: {
        ...prevState?.eventFacts,
        coverImg: uploadFile,
      },
    }));
    setLoading(false);
  };
  const handleChooseDidImg = async () => {
    inputRefDidImg.current.click();
  };

  const handleChangeInput = (e) => {
    const { id, value } = e.target;
    let formatedValue = value;
    if (id?.includes("Date")) {
      const currentTimezone = getCurrentTimezone();
      formatedValue = formatedValue + ":00.000" + currentTimezone;
    }
    if (
      id == "enableUploadMsgToOrg" ||
      id == "enableCouponRedeemNotification"
    ) {
      setFormValues((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };
  const handleRemovePartiVideo = (index) => {
    setFormValues((prevState) => {
      let newArray = prevState.howToParticipate?.files;
      newArray?.splice(index, 1);
      return {
        ...prevState,
        howToParticipate: {
          ...prevState?.howToParticipate,
          files: [...newArray],
        },
      };
    });
  };

  return (
    <div>
      {addPartiVideo && (
        <HowToParticipateVideo
          setValue={setFormValues}
          setPopup={setAddPartiVideo}
        />
      )}
      {/* cover image  */}
      <div className="relative h-[200px]">
        <input
          ref={inputRef}
          className="hidden"
          type="file"
          onChange={handleFileChange}
          accept="image/*"
        />
        <div
          className="w-[100%] min-h-[60px]   overflow-hidden  cursor-pointer  "
          onClick={handleChoose}
        >
          {source ? (
            <div>
              <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                <img src={source} className="" />
              </div>
            </div>
          ) : (
            "Add Cover Image"
          )}
          <div className={styles.changeImageButton}>Change Cover Image</div>
        </div>
      </div>
      {/* camapin name  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Campaign Name<span className="text-red-600">*</span>
            </p>
            {helpData?.campaignName && (
              <HelpPopup content={helpData?.campaignName} />
            )}
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            className={` ${formErrors.campaignName && styles.inputError}`}
            id="campaignName"
            onChange={handleChangeInput}
            value={formValues.campaignName}
          />
        </div>
        {formErrors.campaignName && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.campaignName}
          </span>
        )}
      </div>
      {/* campain unique text  */}
      {/* <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Campaign Unique Text<span className="text-red-600">*</span>
            </p>
            {helpData?.campaignUniqueText && (
              <HelpPopup content={helpData?.campaignUniqueText} />
            )}
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            className={` ${formErrors.campaignUniqueText && styles.inputError}`}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                campaignUniqueText: e.target.value,
              })
            }
            value={formValues.campaignUniqueText}
          />
        </div>
        {formErrors.campaignUniqueText && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.campaignUniqueText}
          </span>
        )}
      </div> */}
      {/* camapaign description  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Campaign Description<span className="text-red-600">*</span>
            </p>
            {helpData?.startDate && <HelpPopup content={helpData?.startDate} />}
          </div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${formErrors?.description && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setFormValues}
              formValues={formValues}
              keyToEdit="description"
            />
          </div>
          {formErrors?.description && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.description}
            </span>
          )}
        </div>
      </div>

      {/* howToParticipate  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              How This Contest Works<span className="text-red-600">*</span>
            </p>
          </div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${formErrors?.description && styles.inputError}`}
            // ref={textInputTandc}
          >
            <RichtextEditorWithSubValues
              setFormValues={setFormValues}
              setModifiedValue={setModifiedCampaignValues}
              formValues={formValues}
              objectName="howToParticipate"
              keyToEdit="description"
            />
          </div>
        </div>
      </div>

      {/* btnColor  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Theme Color<span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="color"
            className={` ${formErrors.btnColor && styles.inputError}`}
            id="btnColor"
            onChange={handleChangeInput}
            value={formValues?.btnColor}
          />
        </div>
        {formErrors?.btnColor && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors?.btnColor}
          </span>
        )}
      </div>

      {/* start date  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Start Date<span className="text-red-600">*</span>
            </p>
            {helpData?.startDate && <HelpPopup content={helpData?.startDate} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${formErrors.startDate && styles.inputError}`}
            id="startDate"
            onChange={handleChangeInput}
            value={formValues?.startDate?.substring(0, 16)}
          />
        </div>
        {formErrors.startDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.startDate}
          </span>
        )}
      </div>
      {/* end date  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              End Date<span className="text-red-600">*</span>
            </p>
            {helpData?.endDate && <HelpPopup content={helpData?.endDate} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${formErrors.endDate && styles.inputError}`}
            id="endDate"
            onChange={handleChangeInput}
            value={formValues?.endDate?.substring(0, 16)}
            min={formValues?.startDate?.substring(0, 16)}
          />
        </div>
        {formErrors.endDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.endDate}
          </span>
        )}
      </div>
      {/* Group   */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Filter Contests</p>
          </div>
          <FilterChips setData={setFormValues} data={formValues?.filterChips} />
        </div>
        {formErrors.endDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.endDate}
          </span>
        )}
      </div>
      {/* termsAndConditions */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              Terms and conditions<span className="text-red-600">*</span>
            </p>
            {helpData?.tandc && <HelpPopup content={helpData?.tandc} />}
          </div>
        </div>
        <div
          className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] mt-[20px] 
              ${formErrors.tandc && styles.inputError}`}
          // ref={textInputTandc}
        >
          <RichtextEditor
            setFormValues={setFormValues}
            formValues={formValues}
          />
        </div>
        {formErrors.tandc && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.tandc}
          </span>
        )}
      </div>

      {/* color1  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Share Page Primary Color<span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="color"
            className={` ${formErrors.color1 && styles.inputError}`}
            id="color1"
            onChange={(e) => {
              setFormValues((prevState) => ({
                ...prevState,
                color1: e.target.value,
              }));

              setModifiedFormValues((prevState) => ({
                ...prevState,
                color1: e.target.value,
              }));
            }}
            value={formValues?.color1}
          />
        </div>
        {formErrors?.color1 && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors?.color1}
          </span>
        )}
      </div>
      {/* color1  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Share Page Secondary Color<span className="text-red-600">*</span>
            </p>
          </div>
          <input
            //   ref={textInputEmail}
            type="color"
            className={` ${formErrors.color1 && styles.inputError}`}
            id="color2"
            onChange={(e) => {
              setFormValues((prevState) => ({
                ...prevState,
                color2: e.target.value,
              }));

              setModifiedFormValues((prevState) => ({
                ...prevState,
                color2: e.target.value,
              }));
            }}
            value={formValues?.color2}
          />
        </div>
        {formErrors?.color2 && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors?.color2}
          </span>
        )}
      </div>
      {/* is  certificate  */}
      {/* <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Certificate <span className="text-red-600">*</span>
            </p>
            {helpData?.certificate && (
              <HelpPopup content={helpData?.certificate} />
            )}
          </div>
          <input
            type="checkbox"
            onChange={(e) => {
              setFormValues((prevState) => ({
                ...prevState,
                certificate: !prevState.certificate,
              }));

              setModifiedFormValues((prevState) => ({
                ...prevState,
                certificate: !formValues.certificate,
              }));
            }}
            checked={formValues.certificate}
          />
        </div>
      </div> */}
      {/* Certificate imafge  */}
      {formValues?.certificate && (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px]`}>Certificate image</p>
              {helpData?.pin && <HelpPopup content={helpData?.pin} />}
            </div>
            <div className="relative h-[200px] bg-[]">
              <input
                ref={inputRefCertificate}
                className="hidden"
                type="file"
                onChange={handleFileChangeCertificate}
                accept="image/*"
              />
              <div
                className="w-[100%] min-h-[60px]  h-full overflow-hidden  cursor-pointer  "
                onClick={handleChooseCertificate}
              >
                {certificateImage ? (
                  <div>
                    <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                      <img src={certificateImage} className="" />
                    </div>
                    <div className={styles.changeImageButton}>
                      Change Certificate Image
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    Upload Certificate Image
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* DEFAULT THUMBNAIL  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]`}>
              Video default thumbnail<span className="text-red-600">*</span>
            </p>
            {helpData?.pin && <HelpPopup content={helpData?.pin} />}
          </div>
          <div className="relative h-[200px] bg-[]">
            <input
              ref={inputRefThumbnail}
              className="hidden"
              type="file"
              onChange={handleFileChangeThumbnail}
              accept="image/*"
            />
            <div
              className="w-[100%] min-h-[60px]  h-full overflow-hidden  cursor-pointer  "
              onClick={handleChooseThumbnail}
            >
              {defaultThumbnail ? (
                <div>
                  <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                    <img src={defaultThumbnail} className="" />
                  </div>
                  <div className={styles.changeImageButton}>
                    Change Default Thumbnail
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  Upload Default Thumbnail
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* faq  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>FAQ</p>
            {helpData?.faq && <HelpPopup content={helpData?.faq} />}
          </div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${formErrors?.faq && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setFormValues}
              formValues={formValues}
              keyToEdit="faq"
            />
          </div>
          {formErrors?.faq && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.faq}
            </span>
          )}
        </div>
      </div>
      {/* hide how to particiapte video  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Hide How to particiapte video{" "}
            </p>
            {helpData?.hideHowToParticipate && (
              <HelpPopup content={helpData?.hideHowToParticipate} />
            )}
          </div>
          <input
            type="checkbox"
            onChange={(e) => {
              setFormValues((prevState) => ({
                ...prevState,
                howToParticipate: {
                  ...prevState?.howToParticipate,
                  hideHowToParticipate:
                    !prevState?.howToParticipate?.hideHowToParticipate,
                },
              }));
            }}
            checked={formValues?.howToParticipate?.hideHowToParticipate}
          />
        </div>
      </div>
      {/*particiapte video  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              How to Participate Video
            </p>
            {helpData?.hideHowToParticipate && (
              <HelpPopup content={helpData?.hideHowToParticipate} />
            )}
          </div>
          <div>
            {formValues?.howToParticipate?.files?.map((obj, index) => (
              <div className="flex gap-[15px] mb-[10px]">
                <p>{obj?.displayText}</p>
                <img
                  src={Close}
                  alt="x"
                  onClick={() => handleRemovePartiVideo(index)}
                />
              </div>
            ))}
            <button
              className={styles.addNew}
              onClick={() => setAddPartiVideo(true)}
            >
              ADD NEW
            </button>
          </div>
        </div>
      </div>
      <hr className={styles.line} />
      <>
        <DynamicInput
          handleChange={handleChangeInput}
          value={formValues?.enableUploadMsgToOrg}
          field={{
            name: "Partipation Alert",
            type: "CHECKBOX",
            id: "enableUploadMsgToOrg",
            options: [
              {
                displayText: "",
                value: "",
              },
            ],
          }}
        />
        {formValues?.enableUploadMsgToOrg && (
          <div className="my-[20px]">
            <div className={styles.form}>
              <div className="flex items-center relative">
                <p className={``}>Participation Alert Numbers</p>
              </div>
              <TextAdd
                setData={setFormValues}
                data={formValues?.uploadMsgToOrgNum || []}
                keyName="uploadMsgToOrgNum"
                type="number"
                name="Numbers"
              />
            </div>
          </div>
        )}
      </>
      <>
        <DynamicInput
          handleChange={handleChangeInput}
          value={formValues?.enableCouponRedeemNotification}
          field={{
            name: "Coupon redeem Alert",
            type: "CHECKBOX",
            id: "enableCouponRedeemNotification",
            options: [
              {
                displayText: "",
                value: "",
              },
            ],
          }}
        />
        {formValues?.enableCouponRedeemNotification && (
          <div className="my-[20px]">
            <div className={styles.form}>
              <div className="flex items-center relative">
                <p className={``}>Coupon Redeem Alert Numbers</p>
              </div>
              <TextAdd
                setData={setFormValues}
                data={formValues?.couponRedeemOrgNum || []}
                keyName="couponRedeemOrgNum"
                type="number"
                name="Numbers"
              />
            </div>
          </div>
        )}
      </>
      <hr className={styles.line} />

      {/* DESKTOP COVER IMAGE  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]`}>Desktop Cover Image</p>
          </div>
          <div className="relative h-[200px] bg-[]">
            <input
              ref={inputRefDesktopBg}
              className="hidden"
              type="file"
              onChange={handleFileChangeDesktopBg}
              accept="image/*"
            />
            <div
              className="w-[100%] min-h-[60px]  h-full overflow-hidden  cursor-pointer  "
              onClick={handleChooseDesktopBg}
            >
              {desktopBg ? (
                <div>
                  <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                    <img src={desktopBg} className="" />
                  </div>
                  <div className={styles.changeImageButton}>
                    Change Desktop Cover Image
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  Upload Desktop Cover Image
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* DID UPO KNOW CONTENT  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Did you know content
            </p>
          </div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
             `}
          >
            <RichtextEditorWithSubValues
              setFormValues={setCampaignSpecs}
              setModifiedValue={setModifiedCampaignSpecs}
              formValues={campaignSpecs}
              objectName="eventFacts"
              keyToEdit="facts"
              change={changedCampaigSpec}
            />
          </div>
          {formErrors?.description && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.description}
            </span>
          )}
        </div>
      </div>
      {/* DESKTOP didyou know IMAGE  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]`}>Did you know Image</p>
          </div>
          <div className="relative h-[200px] bg-[]">
            <input
              ref={inputRefDidImg}
              className="hidden"
              type="file"
              onChange={handleFileChangeDidImg}
              accept="image/*"
            />
            <div
              className="w-[100%] min-h-[60px]  h-full overflow-hidden  cursor-pointer  "
              onClick={handleChooseDidImg}
            >
              {didImg ? (
                <div>
                  <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                    <img src={didImg} className="" />
                  </div>
                  <div className={styles.changeImageButton}>
                    Change Did you know Image
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  Upload Did you know Image
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* instagram text  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Instagram Particiapte Steps<span className="text-red-600">*</span>
            </p>
          </div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${formErrors?.instagramParticipateText && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setFormValues}
              formValues={formValues}
              keyToEdit="instagramParticipateText"
            />
          </div>
          {formErrors?.instagramParticipateText && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.instagramParticipateText}
            </span>
          )}
        </div>
      </div>
      {/* Organisation name 
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Organisation Name<span className="text-red-600">*</span>
            </p>
            {helpData?.orgName && <HelpPopup content={helpData?.orgName} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            className={` ${formErrors.orgName && styles.inputError}`}
            onChange={(e) =>
              setFormValues((prevState) => ({
                ...prevState,
                conductedBy: {
                  ...prevState.conductedBy,
                  name: e.target.value,
                },
              }))
            }
            value={formValues?.conductedBy?.name}
          />
        </div>
        {formErrors.orgName && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.orgName}
          </span>
        )}
      </div>
      email 
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Email ID<span className="text-red-600">*</span>
            </p>
            {helpData?.email && <HelpPopup content={helpData?.email} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            className={` ${formErrors.email && styles.inputError}`}
            onChange={(e) =>
              setFormValues((prevState) => ({
                ...prevState,
                conductedBy: {
                  ...prevState.conductedBy,
                  email: e.target.value,
                },
              }))
            }
            value={formValues?.conductedBy?.email}
          />
        </div>
        {formErrors.email && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.email}
          </span>
        )}
      </div>
      about Organisation 
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              About organizer<span className="text-red-600">*</span>
            </p>
            {helpData?.aboutOrganizer && (
              <HelpPopup content={helpData?.aboutOrganizer} />
            )}
          </div>
          <textarea
            //   ref={textInputEmail}
            type="text"
            className={` ${
              formErrors.about && styles.inputError
            } scrollbar-hide `}
            onChange={(e) =>
              setFormValues((prevState) => ({
                ...prevState,
                conductedBy: {
                  ...prevState.conductedBy,
                  about: e.target.value,
                },
              }))
            }
            value={formValues?.conductedBy?.about}
          />
        </div>
        {formErrors.about && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.about}
          </span>
        )}
      </div>
      address
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Address<span className="text-red-600">*</span>
            </p>
            {helpData?.address && <HelpPopup content={helpData?.address} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            className={` ${formErrors.address && styles.inputError}`}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                conductedBy: {
                  ...formValues.conductedBy,
                  location: e.target.value,
                },
              })
            }
            value={formValues?.conductedBy?.location}
          />
        </div>
        {formErrors.address && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.address}
          </span>
        )}
      </div>
      phone number 
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Contact Number<span className="text-red-600">*</span>
            </p>
            {helpData?.phone && <HelpPopup content={helpData?.phone} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="text"
            id="phone"
            className={` ${formErrors.phone && styles.inputError}`}
            onChange={handleChangeInput}
            value={formValues.phone}
          />
        </div>
        {formErrors.phone && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {formErrors.phone}
          </span>
        )}
      </div> */}
    </div>
  );
}

export default CampignFormInputs;
