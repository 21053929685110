import React, { useContext, useEffect, useRef, useState } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./CreateCertificate.module.css";
import Close from "../../Assets/close.svg";
import uploadIcon from "../../Assets/uploadIcon.svg";
import { singleFileUploadWithBucket } from "../../utils/SingleFileUpload";
import { organizerStorage } from "../../store/firebase-config";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

export default function CreateCertificate({ showPopup, setShowPopup }) {
  const logoRef = useRef();
  const [logo, setLogo] = useState("");
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { currentUser } = useUserAuth();
  const { organizationId, verifyUser, userIdToken, setUpdateData } =
    useContext(Context);
  const { eventId } = useParams();
  useEffect(() => {
    verifyUser();
  }, []);
  const tokenData = userIdToken || currentUser?.accessToken;
  const handleSubmit = () => {
    let error = {};
    if (!formValues?.name) {
      error.name = "Please Enter name";
    }
    if (!formValues?.email) {
      error.email = "Please Enter Email";
    }
    if (isEmpty(logo)) {
      error.image = "Please Select user image";
    }
    setFormErrors(error);
    if (isEmpty(error)) {
      generateCertificate();
    }
  };
  const generateCertificate = async () => {
    setLoading(true);
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_GENERATE_CERTIFICATE}/${organizationId}/${eventId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: formValues,
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        setShowPopup(false);
        setUpdateData((prevState) => prevState + 1);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleFileChange = async (event) => {
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);
    setLogo(url);
    let uploadPath = `/${organizationId}/certficate/`;
    setLoading(true);
    let uploadFile = await singleFileUploadWithBucket(
      organizerStorage,
      "organizer_medias",
      imagedata,
      uploadPath
    );
    setFormValues((prevState) => {
      let key = event.target.id;
      return {
        ...prevState,
        [key]: uploadFile?.fileUrl,
      };
    });
    setLoading(false);
  };

  const handleLogoChoose = () => {
    logoRef.current.click();
  };

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.createAdd}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Create Certificate</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <>
              {/* FORM  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      Name <span className="text-red-600">*</span>
                    </p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="name"
                    className={` ${formErrors.name && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues?.name}
                  />
                </div>
                {formErrors.name && (
                  <span className={styles.error}>{formErrors.name}</span>
                )}
              </div>
              {/* email  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>
                      Email ID <span className="text-red-600">*</span>
                    </p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="email"
                    className={` ${formErrors.email && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues?.email}
                  />
                </div>
                {formErrors.email && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.email}
                  </span>
                )}
              </div>

              {/* PHOTO */}
              <div
                className="flex flex-col gap-[10px] my-[20px] "
                onClick={handleLogoChoose}
              >
                <label
                  htmlFor="image"
                  className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                >
                  <input
                    ref={logoRef}
                    className="hidden"
                    type="file"
                    onChange={handleFileChange}
                    accept=".jpg,.jpeg,.png,.svg"
                    id="image"
                  />
                  <p>
                    Upload Image <span className="text-red-600">*</span>
                  </p>
                </label>

                <div
                  className={` min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]  border-2 border-dashed ${
                    formErrors?.image && "border-[#ff2929]"
                  }`}
                >
                  {logo ? (
                    <div>
                      <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                        <img src={logo} className="w-auto h-[100%]" />
                      </div>
                      <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                        Change
                      </p>
                    </div>
                  ) : (
                    <div>
                      <img src={uploadIcon} className="h-[80px] mx-auto" />
                      <p>Select Photo</p>
                    </div>
                  )}
                </div>
                {formErrors.image && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.image}
                  </span>
                )}
              </div>
            </>

            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Loading.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
