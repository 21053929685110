import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useState } from "react";
import axios from "axios";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import styles from "./InfluencerRequestPage.module.css";
import { isEmpty } from "lodash";
import SponsorApproveTable from "../../Components/SponsorApproveTable/SponsorApproveTable";
import InfApproveTable from "../../Components/InfApproveTable/InfApproveTable";

export default function InfluencerRequestPage() {
  const { orgId, campaignId, updateData, userIdToken } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("REQUESTED");
  useEffect(() => {
    if (orgId) {
      getData();
    }
  }, [orgId, updateData, status]);

  const getData = async () => {
    try {
      setLoading(true);
      let url = "";
      if (campaignId) {
        url = `${process.env.REACT_APP_GET_CAMPAIGN_INFLUENCERS}/${orgId}/${campaignId}?type=${status}`;
      } else {
        url = `${process.env.REACT_APP_GET_CAMPAIGN_INFLUENCERS}/${orgId}?type=${status}`;
      }
      let config1 = {
        method: "GET",
        url: url,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return (
    <div className="p-[20px]">
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Inflencer Requests</p>
      </div>
      <div className={styles.filterChips}>
        <div
          onClick={() => {
            setData([]);
            setStatus("REQUESTED");
          }}
          className={
            status == "REQUESTED" ? styles.eachChips : styles.eachChipsDark
          }
        >
          <p>Requested</p>
        </div>
        <div
          onClick={() => {
            setData([]);
            setStatus("APPROVED");
          }}
          className={
            status == "APPROVED" ? styles.eachChips : styles.eachChipsDark
          }
        >
          <p>Approved</p>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : isEmpty(data) ? (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        ) : (
          <table className=" border-collapse    border border-[#E5EAFF]  w-[100%] min-w-full mt-[30px]">
            {data?.map((obj) => {
              return <InfApproveTable obj={obj} />;
            })}
          </table>
        )}
      </div>
    </div>
  );
}
