import React, { useEffect } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./EditAds.module.css";
import Close from "../../Assets/close.svg";
import ReactPlayer from "react-player";
import axios from "axios";
import { useRef } from "react";
import { useState } from "react";
import Context from "../../Context/Context";
import { useContext } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import CreateBrand from "../CreateBrand/CreateBrand";
import {
  singleFileUpload,
  singleFileUploadWithParams,
  singleFileUploadWithoutName,
} from "../../utils/SingleFileUpload";

export default function EditAds({ showPopup, setShowPopup, editObj }) {
  const inputRef = useRef();
  const thumbnailRef = useRef();
  const [source, setSource] = useState(editObj?.fileUrl);
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [uploadStart, setUploadStart] = useState(false);
  const [createBrand, setCreateBrand] = useState(false);
  const [progressbar, setProgressbar] = useState();
  const [data, setData] = useState(editObj);
  const [videoName, setVideoName] = useState();
  const [gotoLink, setGotoLink] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const { userIdToken, organizationId, updateData, setUpdateData, verifyUser } =
    useContext(Context);
  useEffect(() => {
    verifyUser();
  }, [createBrand]);
  useEffect(() => {
    getBrandData();
  }, [updateData]);
  const { currentUser, userId } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  const initialValues = editObj || {
    description: "",
    email: "",
    goto: "",
    fileUrl: "",
    shopbuttton: "",
    tagLine: "",
    type: "",
    name: "",
    bgColor: "#eaddca",
    btnColor: "#591d13",
    btnTextColor: "#ffffff",
    textColor: "#000000",
    status: "UPLOAD_COMPLETED",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  //   ADD ENTRY

  const handleFileChange = async (event) => {
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);
    setSource(url);
    setData(file);
    setLoading(true);
    console.log("file", file);
    let randomId = Math.floor(Date.now() * Math.random());
    let videoName = formValues?.videoName;
    let splitOrgId = formValues?.videoName.split("_")[0];
    let splitUserId = formValues?.videoName.split("_")[1];
    let uploadPath = `/${splitOrgId}/ads/${splitUserId}/${videoName}_${randomId}`;
    let uploadFile = await singleFileUploadWithoutName(file, uploadPath);
    setFormValues((prevState) => ({
      ...prevState,
      fileUrl: uploadFile?.fileUrl,
      type: uploadFile?.fileType,
    }));
    setLoading(false);
  };

  const handleChoose = async () => {
    inputRef.current.click();
  };

  //updadate thumbanil
  const handleThumbnailChange = async (event) => {
    console.log("event.target.id", event.target.id);
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);
    setThumbnailUrl(url);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_ADS_UPDATE_THUMBNAIL}/${organizationId}/${videoName}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      console.log("thumbanil added");
    }
  };

  const handleThumbnailChoose = () => {
    thumbnailRef.current.click();
  };
  //updadate thumbanil

  //Update ads Entry
  const patchDetails = async () => {
    setLoading(true);
    try {
      let config2 = {
        method: "patch",
        url: `${process.env.REACT_APP_EDIT_ADS}/${organizationId}/${formValues?.brandId}/${formValues?.videoName}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: formValues,
      };
      const response2 = await axios(config2);

      setShowPopup(false);
      setUpdateData((prevState) => prevState + 1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  //Update ads Entry
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
  };
  // GET BRAND DETAILS
  const getBrandData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_BRAND_REQUSTS}/${organizationId}?type=ACCEPTED`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setBrandData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleBrandChange = (e) => {
    if (e.target.value !== "createBrand") {
      setFormValues({
        ...formValues,
        brandId: brandData[e.target.value]?.brandId,
        brandName: brandData[e.target.value]?.name,
      });
    } else {
      setCreateBrand(true);
    }
  };

  return (
    <PopupWrapper visible={showPopup}>
      {createBrand && (
        <CreateBrand
          setCreateBrand={setCreateBrand}
          setFormValues={setFormValues}
          formValues={formValues}
        />
      )}
      <div className={styles.createAdd}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Edit Advertisement</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <div className="flex flex-col gap-[10px] mt-[20px]">
              <label
                htmlFor="participantName"
                className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
              >
                Upload Banner<span className="text-red-600">*</span>
              </label>
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                onChange={handleFileChange}
                accept="image/*,video/*"
              />
              <div
                className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                onClick={handleChoose}
              >
                {source ? (
                  <div>
                    <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                      {data?.type?.startsWith("image") ? (
                        <img src={source} className="w-auto h-[100%]" />
                      ) : (
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          playing={false}
                          controls={true}
                          width="100%"
                          height="100%"
                          url={source}
                        />
                      )}
                    </div>
                    <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                      Change
                    </p>
                  </div>
                ) : (
                  "Select"
                )}
              </div>
            </div>
            {/* thumbanil upload  */}
            {data?.type?.startsWith("video") && progressbar == 100 && (
              <div className="flex flex-col gap-[10px] my-[20px]">
                <label
                  htmlFor="thumbnailUrl"
                  className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                >
                  Upload thumbnail
                </label>
                <input
                  ref={thumbnailRef}
                  className="hidden"
                  type="file"
                  onChange={handleThumbnailChange}
                  accept=".jpg,.jpeg"
                  id="thumbnailUrl"
                />
                <div
                  className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                  onClick={handleThumbnailChoose}
                >
                  {thumbnailUrl ? (
                    <div>
                      <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                        <img src={thumbnailUrl} className="w-auto h-[100%]" />
                      </div>
                      <p className=" font-Inter font-[400] text-[17px] leading-[24px] ">
                        Change
                      </p>
                    </div>
                  ) : (
                    "Select"
                  )}
                </div>
              </div>
            )}

            <>
              {/* FORM  */}
              {/* SELECT BRAND */}
              {/* <div className={styles.form}>
                <p>
                  Brand<span className="text-red-600">*</span>
                </p>
                <select
                  onChange={handleBrandChange}
                  value={brandData?.findIndex(
                    (el) => el?.brandId === formValues?.brandId
                  )}
                  // value={formValues?.brandId}
                >
                  <option value="">--Please choose an option--</option>
                  {brandData?.map((obj, index) => {
                    return (
                      <option key={index} value={index}>
                        {obj.name}
                      </option>
                    );
                  })}
                  <option value="createBrand">Create Brand</option>
                </select>
              </div> */}
              {/*AD NAME */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Ad Name</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="name"
                    className={` ${formErrors.name && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues.name}
                  />
                </div>
                {formErrors.name && (
                  <span className={styles.error}>{formErrors.name}</span>
                )}
              </div>
              {/*BACKGROUND COLOR */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Card Background Color</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="color"
                    id="bgColor"
                    className={` ${formErrors.bgColor && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues.bgColor}
                  />
                </div>
                {formErrors.bgColor && (
                  <span className={styles.error}>{formErrors.bgColor}</span>
                )}
              </div>
              {/* TEXT COLOR */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Text Color</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="color"
                    id="textColor"
                    className={` ${formErrors.textColor && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues.textColor}
                  />
                </div>
                {formErrors.textColor && (
                  <span className={styles.error}>{formErrors.textColor}</span>
                )}
              </div>
              {/*BUTTON TEXT */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Button text</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="shopbuttton"
                    className={` ${
                      formErrors.shopbuttton && styles.inputError
                    }`}
                    onChange={handleChange}
                    value={formValues.shopbuttton}
                  />
                </div>
                {formErrors.shopbuttton && (
                  <span className={styles.error}>{formErrors.shopbuttton}</span>
                )}
              </div>

              {/* BUTTON ACTION */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Button action</p>
                  </div>
                  <div>
                    <div className="flex">
                      <input
                        //   ref={textInputEmail}
                        type="radio"
                        id="showForm"
                        name="goto"
                        className={` ${formErrors.goto && styles.inputError} ${
                          styles.formRadio
                        }`}
                        onChange={() => {
                          setFormValues({ ...formValues, goto: "form" });
                          setGotoLink(false);
                        }}
                      />
                      <label for="showForm"> Show form</label>
                    </div>
                    <div className="flex">
                      <input
                        //   ref={textInputEmail}
                        type="radio"
                        id="toLink"
                        name="goto"
                        className={` ${formErrors.goto && styles.inputError} ${
                          styles.formRadio
                        }`}
                        onChange={() => {
                          setFormValues({ ...formValues, goto: "" });
                          setGotoLink(true);
                        }}
                      />
                      <label for="toLink"> Redirect to link</label>
                    </div>
                  </div>
                </div>
                {formErrors.goto && (
                  <span className={styles.error}>{formErrors.goto}</span>
                )}
              </div>
              {/*GO TO LINK  */}
              {gotoLink && (
                <div>
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}>Link</p>
                    </div>
                    <input
                      //   ref={textInputEmail}
                      type="url"
                      id="goto"
                      className={` ${
                        formErrors.contactTitle && styles.inputError
                      }`}
                      onChange={handleChange}
                      value={formValues.goto}
                    />
                  </div>
                  {formErrors.goto && (
                    <span className={styles.error}>{formErrors.goto}</span>
                  )}
                </div>
              )}
              {/*BUTTTON BACKGROUND COLOR */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Button Background Color</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="color"
                    id="btnColor"
                    className={` ${formErrors.btnColor && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues.btnColor}
                  />
                </div>
                {formErrors.btnColor && (
                  <span className={styles.error}>{formErrors.btnColor}</span>
                )}
              </div>
              {/*BUTTTON TEXT COLOR */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Button Text Color</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="color"
                    id="btnTextColor"
                    className={` ${
                      formErrors.btnTextColor && styles.inputError
                    }`}
                    onChange={handleChange}
                    value={formValues.btnTextColor}
                  />
                </div>
                {formErrors.btnTextColor && (
                  <span className={styles.error}>
                    {formErrors.btnTextColor}
                  </span>
                )}
              </div>
              {/* tagLine  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>Tagline</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="tagLine"
                    className={` ${formErrors.tagLine && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues.tagLine}
                  />
                </div>
                {formErrors.tagLine && (
                  <span className={styles.error}>{formErrors.tagLine}</span>
                )}
              </div>
              {/*description */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      Description
                      {/* <span className="text-red-600">*</span> */}
                    </p>
                  </div>
                  <textarea
                    //   ref={textInputEmail}
                    type="text"
                    id="description"
                    className={` ${
                      formErrors.description && styles.inputError
                    }`}
                    onChange={handleChange}
                    value={formValues.description}
                  />
                </div>
                {formErrors.description && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.description}
                  </span>
                )}
              </div>

              {/* email  */}
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px]  `}>Email ID</p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="text"
                    id="email"
                    className={` ${formErrors.email && styles.inputError}`}
                    onChange={handleChange}
                    value={formValues.email}
                  />
                </div>
                {formErrors.email && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.email}
                  </span>
                )}
              </div>
            </>

            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={patchDetails}
                disabled={loading}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
