import React, { useState } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./UploadPromotionMedia.module.css";
import Close from "../../Assets/close.svg";
import ReactPlayer from "react-player";

export default function UploadPopup({
  showPopup,
  setShowPopup,
  handleChoose,
  handleFileChange,
  getEntrySignedUrl,
  data,
  inputRef,
  source,
  progressbar,
  handleThumbnailChange,
  handleThumbnailChoose,
  thumbnailUrl,
  thumbnailRef,
  handleSave,
  handleSaveYoutube,
}) {
  const [uploadType, setUploadType] = useState("file");
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const handleTypeChange = (e) => {
    setUploadType(e.target.id);
  };
  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.uploadPopup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Add Media</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <div className="flex w-[100%] items-center justify-around">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="type"
                  id="file"
                  onChange={handleTypeChange}
                  value={uploadType}
                  checked={uploadType == "file"}
                  className="h-[20px]"
                />
                <label className="whitespace-nowrap" for="file">
                  Upload file
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="type"
                  id="youtube"
                  value={uploadType}
                  onChange={handleTypeChange}
                  checked={uploadType == "youtube"}
                  className="h-[20px]"
                />
                <label className="whitespace-nowrap" for="youtube">
                  Youtube url
                </label>
              </div>
            </div>
            {uploadType === "file" && (
              <>
                <div className="flex flex-col gap-[10px] mt-[20px]">
                  <label
                    htmlFor="participantName"
                    className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                  >
                    Upload
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    ref={inputRef}
                    className="hidden"
                    type="file"
                    onChange={handleFileChange}
                    // accept=".jpg,.jpeg"
                  />
                  <div
                    className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                    onClick={handleChoose}
                  >
                    {source ? (
                      <div>
                        <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                          {data.type.startsWith("image") ? (
                            <img src={source} className="w-auto h-[100%]" />
                          ) : (
                            <ReactPlayer
                              config={{
                                file: {
                                  attributes: { controlsList: "nodownload" },
                                },
                              }}
                              playing={false}
                              controls={true}
                              width="100%"
                              height="100%"
                              url={source}
                            />
                          )}
                        </div>
                        <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                          Change
                        </p>
                      </div>
                    ) : (
                      "Select"
                    )}
                  </div>
                  {source && (
                    <div>
                      <button
                        onClick={getEntrySignedUrl}
                        className={styles.uploadButton}
                      >
                        upload
                      </button>
                      <progress value={progressbar} min="0" max="100">
                        {progressbar}%
                      </progress>
                    </div>
                  )}
                </div>
                {/* thumbanil upload  */}
                {data?.type?.startsWith("video") && (
                  <div className="flex flex-col gap-[10px] my-[20px]">
                    <label
                      htmlFor="thumbnailUrl"
                      className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                    >
                      Upload thumbnail
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      ref={thumbnailRef}
                      className="hidden"
                      type="file"
                      onChange={handleThumbnailChange}
                      accept=".jpg,.jpeg"
                      id="thumbnailUrl"
                    />
                    <div
                      className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                      onClick={handleThumbnailChoose}
                    >
                      {thumbnailUrl ? (
                        <div>
                          <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                            <img
                              src={thumbnailUrl}
                              className="w-auto h-[100%]"
                            />
                          </div>
                          <p className=" font-Inter font-[400] text-[17px] leading-[24px] ">
                            Change
                          </p>
                        </div>
                      ) : (
                        "Select"
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {uploadType === "youtube" && (
              <div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p className={`mt-[10px] `}>
                      Youtube Link
                      <span className="text-red-600">*</span>
                    </p>
                  </div>
                  <input
                    //   ref={textInputEmail}
                    type="url"
                    onChange={(e) => setYoutubeUrl(e.target.value)}
                    value={youtubeUrl}
                  />
                </div>
              </div>
            )}
          </div>
          {progressbar === 100 && uploadType === "file" && (
            <div className={styles.buttonWrapper}>
              <button className={styles.save} onClick={handleSave}>
                Save
              </button>
            </div>
          )}
          {uploadType === "youtube" && (
            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={() => handleSaveYoutube(youtubeUrl)}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </PopupWrapper>
  );
}
