import React, { useEffect, useRef, useState } from "react";
import music from "../../Assets/music.svg";

function AudioPlayerWithoutDownload({ fileUrl, fileName }) {
  const [isPlaying, setIsplaying] = useState(false);
  const audioElem = useRef();
  useEffect(() => {
    if (isPlaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  }, [isPlaying]);
  const PlayPause = () => {
    setIsplaying(!isPlaying);
  };

  return (
    <>
      <div className="flex gap-2 w-full">
        <div className=" w-full md:max-w-[540px] bg-[#1c1c1c] rounded-full flex items-center justify-between p-[12px]">
          <div className="flex items-center gap-[10px]">
            <div className="h-[33px] w-[33px] bg-[#171717] rounded-full flex  items-center justify-center">
              <img src={music} alt="" className="w-[15px] h-[15px]" />
            </div>
            <p className="text-[14px] text_1Line text-left text-white">
              {fileName}
            </p>
          </div>
          <div className="flex items-center gap-[10px]">
            <div
              className="h-[33px] w-[33px] bg-[#606060] rounded-full flex items-center justify-center shrink-0 cursor-pointer"
              onClick={PlayPause}
            >
              <audio src={fileUrl} ref={audioElem} />

              {isPlaying ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <circle cx="22" cy="22" r="22" fill="#606060" />
                  <path
                    d="M18 13V30M26 13V30"
                    stroke="#EAF0FF"
                    stroke-width="2.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <circle cx="16.5" cy="16.5" r="16.5" fill="#606060" />
                  <path d="M13 8L23 16.5029L13 25V8Z" fill="#EAF0FF" />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AudioPlayerWithoutDownload;
