import styles from "./styles/ContestCard.module.css";
import Timer from "../../../src/Assets/timer.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import ContestMoreDropdown from "../MoreOptionDropdown/ContestMoreDropdown";
import AlertPopup from "../AlertPopups/AlertPopup";
import { useState } from "react";
import EditContest from "../EditContest/EditContest";
import ContestJudges from "../ContestJudges/ContestJudges";
import ContestPrize from "../ContestPrize/ContestPrize";
import EditPosters from "../EditPosters/EditPosters";
import QuizContest from "../QuizContest/QuizContest";

function ContestCard({
  data,
  setClickedContestData,
  campaignContestsDatas,
  setRightPanelData,

  setShowCloseContestPopup,
  setShowOpenContestPopup,
}) {
  const [showDeleteContestPopup, setShowDeleteContestPopup] = useState(false);
  const [showEditContest, setShowEditContest] = useState(false);
  const [editContestData, setEditContestData] = useState({});
  const [showAddJudges, setShowAddJudges] = useState(false);
  const [showPrize, setShowAddPrize] = useState(false);
  const [showEditPosterContest, setShowEditPosterContest] = useState(false);
  const [showEditQuizData, setShowEditQuizData] = useState(false);

  const navigate = useNavigate();
  const handleNavigation = (obj) => {
    const { orgId, campaignId, contestId } = obj;
    const params = { orgId: orgId, cmpId: campaignId, conId: contestId };
    navigate({
      pathname: "/campaigns/contests/contest",
      search: `?${createSearchParams(params)}`,
    });
  };
  const hanldeCloseDeletePopup = () => {
    setShowDeleteContestPopup(false);
  };

  const deletePopUpValues = {
    Icon: "",
    Heading: "Delete the Contest",
    Para: "Are you sure you want to delete this Contest? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Delete this Contest",
    Button2Color: "#D92D20",
  };

  return (
    <>
      {showDeleteContestPopup && (
        <AlertPopup
          visible={showDeleteContestPopup}
          setpopUp={setShowDeleteContestPopup}
          button1Function={hanldeCloseDeletePopup}
          button2Function="some function"
          value={deletePopUpValues}
        />
      )}
      {showEditContest && (
        <EditContest
          setShowEdit={setShowEditContest}
          showEdit={showEditContest}
          data={editContestData}
          campaignContestsDatas={campaignContestsDatas}
        />
      )}
      {showEditPosterContest && (
        <EditPosters
          setShowEdit={setShowEditPosterContest}
          showEdit={showEditPosterContest}
          contestData={editContestData}
        />
      )}{" "}
      {showEditQuizData && (
        <QuizContest
          setShowEdit={setShowEditQuizData}
          showEdit={showEditQuizData}
          contestData={editContestData}
        />
      )}
      {showAddJudges && (
        <ContestJudges
          setShowAddJudges={setShowAddJudges}
          campaignData={campaignContestsDatas}
          data={editContestData}
          campaignContestsDatas={campaignContestsDatas}
        />
      )}
      {showPrize && (
        <ContestPrize setPopup={setShowAddPrize} data={editContestData} />
      )}
      <div className={styles.card} onClick={() => handleNavigation(data)}>
        <div className={styles.timerContainer}>
          {/* <div className={styles.timer}>
            <img src={Timer} alt="" />
            <p>17d 22h 37m</p>
          </div> */}
          <div onClick={(e) => e.stopPropagation()} className="ml-auto">
            <ContestMoreDropdown
              data={data}
              setClickedContestData={setClickedContestData}
              setShowDeleteContestPopup={setShowDeleteContestPopup}
              setShowCloseContestPopup={setShowCloseContestPopup}
              setShowOpenContestPopup={setShowOpenContestPopup}
              setShowEditPosterContest={setShowEditPosterContest}
              setShowEditQuizData={setShowEditQuizData}
              setShowEditContest={setShowEditContest}
              setEditContestData={setEditContestData}
              setShowAddJudges={setShowAddJudges}
              setShowAddPrize={setShowAddPrize}
            />
          </div>
        </div>
        <div className="mt-[20px]">
          <p className={styles.campaingn}>{data?.contestName}</p>
          <div className={styles.capmme}>
            <div>
              {/* <p className="mb-[14px] font-medium text-[16px] text-black">
                Participants
              </p> */}
              {/* <div className={styles.flexmo}>
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
              </div> */}
              <p className={styles.totlaXtra}>
                {data?.participantCounter > 0 ? data?.participantCounter : 0}{" "}
                Participants
              </p>
            </div>
            <div>
              {/* <p className="mb-[14px] font-medium text-[16px] text-black">
                Pending Approvals
              </p> */}
              {/* <div className={styles.flexmo}>
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
                <img
                  className={styles.propik}
                  src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
                  alt=""
                />
              </div> */}
              <p className={styles.totlaXtra}>
                {data?.pendingApproval > 0 ? data?.pendingApproval : 0} Pending
                Approval
              </p>
            </div>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setRightPanelData(data);
              setClickedContestData(data);
            }}
            className={styles.button}
          >
            <p>Overview</p>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setRightPanelData(data);
              window.open(
                `https://app.goviral.world/${data?.orgDomain}/${data?.campaignUniqueText}/${data?.contestUniqueText}?preview=true`
              );
            }}
            className={styles.button}
          >
            <p>View Preview</p>
          </button>
        </div>
      </div>
    </>
  );
}

export default ContestCard;
