import React, { useEffect, useState } from "react";
import styles from "./ContestPrize.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";

import DynamicInput from "../DynamicInput/DynamicInput";
import inputJson from "../../Json/prizeClaimDetails.json";
import CommonRichTextEditor from "../RichtextEditor/CommonRichTextEditor";

export default function ContestPrize({ setPopup, data }) {
  const [prizeDetails, setPrizeDetails] = useState({});
  const initialState = {
    priceDescription: data?.priceDescription || "",
    crowdWinnerAvailable: data?.crowdWinnerAvailable || false,
    judgeWinnerAvailable: data?.judgeWinnerAvailable || false,
    specialWinnerAvailable: data?.specialWinnerAvailable || false,
    prizeObj: data?.prizeObj || {},
    judgementText: data?.judgementText,
  };
  const [contestFormValues, setContestFormValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const { userIdToken, setUpdateData, updateData, setNotAdmin } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  useEffect(() => {
    getGiftDetails();
  }, [updateData]);
  const getGiftDetails = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `https://storage.googleapis.com/goviral-share/${data?.orgId}/${data?.campaignId}/${data?.contestId}/metaData.json`,
      };
      const apiData = await axios(config1);
      setPrizeDetails(apiData?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleCloseClaiming = () => {
    let claimingClosed = !prizeDetails?.claimingClosed;
    handleSave({ claimingClosed });
  };
  const handleSave = async (otherData) => {
    setLoading(true);

    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_UPDATE_GIFT_OPTIONS}/${data?.orgId}/${data?.campaignId}/${data?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: otherData,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setPopup(false);
        setUpdateData((prevState) => prevState + 1);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleUpdateContestDetails = async () => {
    setLoading(true);
    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_CONTEST_DETAILS}/${data?.campaignId}/${data?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: contestFormValues,
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    await handleUpdateContestDetails();
    await handleSave(prizeDetails);
  };
  const handleChange = (e) => {
    if (e.target.type == "checkbox") {
      setPrizeDetails((prevState) => ({
        ...prevState,
        [e.target.id]: !prevState[e.target.id],
      }));
    } else {
      setPrizeDetails((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    }
  };

  const handleChangeType = (e) => {
    setPrizeDetails((prevState) => {
      let claimOptions = prevState?.claimOptions || [];
      let key = e.target.id;
      let isObjectInArray = claimOptions?.some((obj) => {
        // Compare objects based on some criteria, for example, comparing 'id'
        return obj?.prizeType == key;
      });
      if (isObjectInArray) {
        let indexToRemove = claimOptions?.findIndex(
          (obj) => obj?.prizeType == key
        );
        claimOptions?.splice(indexToRemove, 1);
      } else {
        claimOptions.push(inputJson?.prizeCategory[key]);
      }
      return {
        ...prevState,
        claimOptions,
      };
    });
  };
  const isAvailable = (key) => {
    let claimOptions = prizeDetails?.claimOptions || [];
    let isObjectInArray = claimOptions?.some((obj) => {
      return obj?.prizeType == key;
    });
    return isObjectInArray;
  };
  const findPrizeObject = (key) => {
    let claimArray = prizeDetails?.claimOptions;
    let obj = claimArray?.find((el) => el?.prizeType == key);
    return obj;
  };
  const handleChangePrizeOption = (e) => {
    setPrizeDetails((prevState) => {
      let key = e.target.id;
      let value = e.target.value;
      let mainKey = e.target.dataset.customInfo;
      let claimOptions = prevState?.claimOptions;
      let objectInArray = claimOptions?.find((obj) => {
        return obj?.prizeType == mainKey;
      });
      let index = claimOptions?.findIndex((obj) => obj?.prizeType == mainKey);
      objectInArray[key] = value;
      claimOptions[index] = objectInArray;
      return {
        ...prevState,
        claimOptions,
      };
    });
  };
  const handleChangeWinnerType = (e) => {
    setContestFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: !prevState[e.target.id],
    }));
  };
  const handleChangePrizeDetails = (e) => {
    setContestFormValues((prevState) => {
      let key = e.target.id;
      let value = e.target.value;
      let mainKey = e.target.dataset.customInfo;
      let prizeObj = prevState?.prizeObj[mainKey] || {};
      if (value.trim() == "") {
        delete prizeObj[key];
      } else {
        if (prizeObj[key]) {
          prizeObj[key] = {
            ...prizeObj[key],
            prizeWorth: value,
          };
        } else {
          prizeObj[key] = {
            ...inputJson?.prizeSkelton[mainKey][key],
            prizeWorth: value,
          };
        }
      }
      return {
        ...prevState,
        prizeObj: {
          ...prevState?.prizeObj,
          [mainKey]: prizeObj,
        },
      };
    });
  };
  return (
    <div className={styles.popup}>
      <div className={styles.selectAdsPopup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Prize Details</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setPopup(false)} />
          </div>
          <div className={`custom-scrollbar ${styles.body}`}>
            <h3>Prize Details</h3>
            {data.campaignStatus === "UPCOMING" ||
            data.campaignStatus === "REQUEST_TO_PUBLISH" ? (
              <>
                {inputJson?.winnerSelectionFields?.map((winnerField) => {
                  return (
                    <>
                      <DynamicInput
                        handleChange={handleChangeWinnerType}
                        value={contestFormValues[winnerField?.id]}
                        // error={formErrors[field?.id]}
                        field={winnerField}
                      />
                      {contestFormValues[winnerField?.id] &&
                        winnerField?.subOptions?.map((option) => {
                          return (
                            <DynamicInput
                              handleChange={handleChangePrizeDetails}
                              value={
                                contestFormValues?.prizeObj[option?.mainKey] &&
                                contestFormValues?.prizeObj[option?.mainKey][
                                  option?.id
                                ] &&
                                contestFormValues?.prizeObj[option?.mainKey][
                                  option?.id
                                ]?.prizeWorth
                              }
                              // error={formErrors[field?.id]}
                              field={option}
                            />
                          );
                        })}
                      <div className={styles?.line} />
                    </>
                  );
                })}
                {/* priceDescription */}
                <div className={styles.form}>
                  <div className="flex flex-col">
                    <div className="flex items-center relative">
                      <p className={`mt-[10px]  `}>
                        Other Prize Detials
                        <span className="text-red-600">*</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] `}
                      // ref={textInputTandc}
                    >
                      <CommonRichTextEditor
                        setFormValues={setContestFormValues}
                        formValues={contestFormValues}
                        keyToEdit="priceDescription"
                      />
                    </div>
                  </div>
                </div>
                {/* judgementText */}
                <div className={styles.form}>
                  <div className="flex flex-col  mt-[20px] mb-[10px]">
                    <div className="flex items-center relative">
                      <p className={`mt-[10px]  `}>
                        Judgment Rules<span className="text-red-600">*</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px]">
                      <CommonRichTextEditor
                        setFormValues={setContestFormValues}
                        formValues={contestFormValues}
                        keyToEdit="judgementText"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <p>Sent campaign edit request for editing prize detials</p>
            )}
            <div className={styles?.line} />

            {inputJson?.generalDetails?.map((field) => {
              if (field?.ifPresent) {
                if (prizeDetails[field?.ifPresent]) {
                  return (
                    <DynamicInput
                      handleChange={handleChange}
                      value={prizeDetails[field?.id]}
                      // error={formErrors[field?.id]}
                      field={field}
                    />
                  );
                }
              } else {
                return (
                  <DynamicInput
                    handleChange={handleChange}
                    value={prizeDetails[field?.id]}
                    // error={formErrors[field?.id]}
                    field={field}
                  />
                );
              }
            })}
            <div className={styles?.line} />
            <h3>Prize Claim Options</h3>
            <DynamicInput
              handleChange={handleChangeType}
              value={isAvailable("Voucher")}
              // error={formErrors[field?.id]}
              field={inputJson?.prizeOptions?.Voucher}
            />
            {isAvailable("Voucher") && (
              <>
                {inputJson?.prizeOptions?.Voucher?.subOptions?.map((field) => {
                  let prizeObj = findPrizeObject("Voucher");
                  return (
                    <DynamicInput
                      handleChange={handleChangePrizeOption}
                      value={prizeObj[field?.id]}
                      // error={formErrors[field?.id]}
                      field={field}
                    />
                  );
                })}
              </>
            )}
            <div className={styles?.line} />
            <DynamicInput
              handleChange={handleChangeType}
              value={isAvailable("MEMENTO")}
              // error={formErrors[field?.id]}
              field={inputJson?.prizeOptions?.MEMENTO}
            />
            {isAvailable("MEMENTO") && (
              <>
                {inputJson?.prizeOptions?.MEMENTO?.subOptions?.map((field) => {
                  let prizeObj = findPrizeObject("MEMENTO");
                  return (
                    <DynamicInput
                      handleChange={handleChangePrizeOption}
                      value={prizeObj[field?.id]}
                      // error={formErrors[field?.id]}
                      field={field}
                    />
                  );
                })}
              </>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={styles.save}
              disabled={loading}
              onClick={handleCloseClaiming}
            >
              {prizeDetails?.claimingClosed
                ? "Open Claiming"
                : "Close Claiming"}
            </button>
            <button
              className={styles.save}
              onClick={handleSubmit}
              disabled={loading}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
