import styles from "./EditPosters.module.css";
import Close from "../../Assets/close.svg";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import DynamicInput from "../DynamicInput/DynamicInput";
import { useState } from "react";
import { singleFileUploadWithBucket } from "../../utils/SingleFileUpload";
import { posterStorage } from "../../store/firebase-config";

export default function AddBackgrounds({
  contestData,
  selectedIcon,
  showPopup,
  setShowPopup,
  formData,
  setFormData,
  index,
}) {
  const [values, setValues] = useState(
    (formData[selectedIcon] &&
      formData[selectedIcon]?.backgrounds &&
      formData[selectedIcon]?.backgrounds[index]) ||
      {}
  );
  const [loading, setLoading] = useState(false);
  console.log("values", values);
  function hexToRgb(hex) {
    // Remove the hash if it exists
    hex = hex.replace(/^#/, "");
    // Parse the hex value into RGB components
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    // Return the RGB values as an object
    return `(${r}, ${g}, ${b})`;
  }

  function rgbToHex(value) {
    // Convert each RGB component to a hexadecimal string
    const [r, g, b] = value
      ?.slice(1, -1)
      ?.split(",")
      ?.map((component) => parseInt(component?.trim(), 10));
    const componentToHex = (component) => {
      const hex = component?.toString(16);
      return hex?.length == 1 ? "0" + hex : hex;
    };
    // Concatenate the hexadecimal values of each component
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }
  const handleChangeInput = (e) => {
    setValues((prevState) => {
      let key = e.target.id;
      let value = e.target.value;
      return {
        ...prevState,
        [key]: hexToRgb(value),
      };
    });
  };
  const handleChooseFile = (id) => {
    const divElement = document.getElementById(id);
    if (divElement) {
      divElement.click();
    }
  };
  const handleChangeIcon = async (event) => {
    const key = event.target.id;
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    let uploadPath = `/${contestData?.posterContest}/medias/`;
    setLoading(true);
    let uploadFile = await singleFileUploadWithBucket(
      posterStorage,
      "goviral_posters",
      imagedata,
      uploadPath
    );
    setValues((prevState) => {
      let key = event.target.id;
      return {
        ...prevState,
        [key]: uploadFile?.fileUrl,
        sampleUrl: uploadFile?.fileUrl,
      };
    });
    setLoading(false);
  };
  let options = {
    borderColour: {
      name: "Border Colour",
      type: "COLOR",
      id: "borderColour",
    },
    bgUrl: {
      name: "Background",
      type: "MEDIA",
      id: "bgUrl",
      accept: "image/*",
    },
  };
  const handleSave = () => {
    setFormData((prevState) => {
      let selectedCategory = prevState[selectedIcon] || {};
      if (index > -1) {
        let bgArray = selectedCategory?.backgrounds;
        bgArray[index] = values;
        return {
          ...prevState,
          [selectedIcon]: {
            ...selectedCategory,
            backgrounds: bgArray,
          },
        };
      } else {
        return {
          ...prevState,
          [selectedIcon]: {
            ...selectedCategory,
            backgrounds: [...selectedCategory?.backgrounds, values],
          },
        };
      }
    });
    setShowPopup(false);
  };
  return (
    <PopupWrapper visible={showPopup} zIndex={100}>
      <div className={styles.editCampaign}>
        <div className={styles.contentDiv}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <h2>Add / Edit Background</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <div className={`custom-scrollbar ${styles.left}`}>
              <div>
                <DynamicInput
                  handleChange={handleChangeIcon}
                  handleChooseFile={handleChooseFile}
                  value={values?.bgUrl}
                  field={options?.bgUrl}
                />
                <DynamicInput
                  handleChange={handleChangeInput}
                  value={rgbToHex(values?.borderColour || "")}
                  field={options?.borderColour}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button
              disabled={loading}
              className={styles.save}
              onClick={loading ? "" : handleSave}
            >
              {loading ? "Saving" : "Save Changes"}
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
