import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PopupWrapper } from '../../UI/PopupWrapper/PopupWrapper'
import styles from './SelectAdsTemp.module.css'
import axios from 'axios'
import { useState } from 'react'
import Context from '../../Context/Context'
import { useContext } from 'react'
import { useUserAuth } from '../../Context/UserAuthContext'
import adsJson from '../../Assets/adstemplate.json'

export default function SelectAdsTemplate() {
  const navigate = useNavigate()
  const [selectedTemplate, setSelectedTemplate] = useState([])
  const [templateData, setTemplateData] = useState([])
  const { userIdToken, organizationId, verifyUser } = useContext(Context)

  useEffect(() => {
    verifyUser()
    getTemplates()
  }, [])
  const { currentUser } = useUserAuth()
  const [loading, setLoading] = useState(false)
  const tokenData = userIdToken || currentUser?.accessToken

  // GET BRAND DETAILS
  const getTemplates = async () => {
    try {
      setLoading(true)
      // let config1 = {
      //   method: 'GET',
      //   url: `../../Assets/adstemplate.json`,
      // }
      // const setApi = await axios(config1)
      // setSelectedTemplate(setApi?.data?.data)
      setTemplateData(adsJson.templatesAdvertisements)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
      setLoading(false)
    }
  }
  const handleNavigateTemplate = () => {
    if (!selectedTemplate) {
      return
    }

    navigate(`/ads/adstemplates/${selectedTemplate}`)
  }
  console.log('tem', templateData)
  console.log('selectedTemp', selectedTemplate)
  return (
    <div className={styles.createAdd}>
      <div className={`custom-scrollbar ${styles.contentDiv}`}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            {/* <img src={editCampaignHeaderLogo} /> */}
            <h2>Select Advertisement Template</h2>
          </div>
        </div>
        <div className={styles.body}>
          <div className="flex flex-col gap-[10px] mt-[20px]">
            <label
              htmlFor="participantName"
              className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
            >
              <select
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                <option value="">Select an option</option>
                {templateData.map((option) => (
                  <option key={option.templateType} value={option.templateType}>
                    {option.templateDisplayName}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.save} onClick={handleNavigateTemplate}>
              Go To Template Edit View
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
