import React, { useContext, useEffect } from "react";
import * as _ from "lodash";
import Context from "../../Context/Context";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./EditContest.module.css";
import Close from "../../Assets/close.svg";
import editCampaignHeaderLogo from "../../Assets/editCampaignHeaderLogo.png";
import editCampaignLeftArrow from "../../Assets/editCampaignLeftArrow.png";
import { useState } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import AlertPopup from "../AlertPopups/AlertPopup";
import publishIcon from "../../Assets/AlertPopup/publishIcon.svg";
import ContestFormInputs from "./ContestFormInputs";
import { addHoursAndMinutesToUTC } from "../../utils/DateFormator";

export default function EditContest({
  setShowEdit,
  showEdit,
  data,
  campaignContestsDatas,
}) {
  const {
    modalRef,
    userIdToken,
    verifyUser,
    setCampign,
    setUpdateData,
    setCampaignPageNo,
    setNotAdmin,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);

  const initialValues = {
    contestPoster: data?.contestPoster || "",
    contestName: data?.contestName || "",
    contestTagLine: data?.contestTagLine || "",
    description: data?.description || "",
    contestRules: data?.contestRules || "",
    howItsWorks: data?.howItsWorks || "",
    howToParticipate: data?.howToParticipate || "",
    rulesAndRegulations: data?.rulesAndRegulations || "",
    startDate: data?.startDate ? addHoursAndMinutesToUTC(data?.startDate) : "",
    endDate: data?.endDate ? addHoursAndMinutesToUTC(data?.endDate) : "",
    winnerAnounceDate: data?.winnerAnounceDate
      ? addHoursAndMinutesToUTC(data?.winnerAnounceDate)
      : "",
    judgementCompleteDate: data?.judgementCompleteDate
      ? addHoursAndMinutesToUTC(data?.judgementCompleteDate)
      : "",
    buttonColor: data?.buttonColor || "",
    participateBtnClr: data?.participateBtnClr || "",
    buttonText: data?.buttonText || "",
    groupId: data?.groupId || "",
    formAttributes: data?.formAttributes || [],
    displayPlaceHolder: data?.displayPlaceHolder || "",
    multipleUpload: data?.multipleUpload,
    maxFileNumber: data?.maxFileNumber,
    performanceData: data?.performanceData || {},
    specs: data?.specs,
    explanationDoc: data?.explanationDoc || {},
    multipleEntriesLimited: data?.multipleEntriesLimited,
    allowedEntryCount: data?.allowedEntryCount || 1,
    autoApprove: data?.autoApprove || false,
    isPrivate: data?.isPrivate,
    pin: data?.pin,
    privateType: data?.privateType,
  };
  useEffect(() => {
    verifyUser();
  }, []);
  const [formValues, setFormValues] = useState(initialValues);
  console.log("data", formValues);

  const [modifiedFormValues, setModifiedFormValues] = useState({});
  const [contestFormValue, setContestFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [contestFormErrors, setContestFormErrors] = useState({});
  const [showClosePopup, setShowClosePopup] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;

  const handleUpdateCamapignDetails = async () => {
    setLoading(true);
    try {
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_CONTEST_DETAILS}/${campaignContestsDatas?._id}/${data?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: formValues,
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.admin == false) {
          setNotAdmin(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        setSavedChanges(true);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const hanldeContinueEditing = () => {
    setSavedChanges(false);
  };
  const handleCloseEditing = () => {
    setCampign([]);
    setCampaignPageNo(1);
    setShowEdit(false);
    setUpdateData((prevState) => prevState + 1);
    setSavedChanges(false);
  };
  const popUpSaveValues = {
    Icon: publishIcon,
    Heading: "Changes Saved",
    Para: "Are you sure you want to continue editing or close the tab? ",
    Button1: "Edit Contest",
    Button2: "Close",
    Button2Color: "#06B818",
  };

  const handleClosePopup = () => {
    setShowClosePopup(false);
  };
  const handleCloseEditPopup = () => {
    setShowEdit(false);
  };

  const closePopUpValues = {
    Icon: "",
    Heading: "Are you sure?",
    Para: "All your changes will be discarded ",
    Button1: "Cancel",
    Button2: "Close",
    Button2Color: "#F5BF00",
  };
  return (
    <PopupWrapper visible={showEdit}>
      {showClosePopup && (
        <AlertPopup
          visible={showClosePopup}
          setpopUp={setShowClosePopup}
          button1Function={handleClosePopup}
          button2Function={handleCloseEditPopup}
          value={closePopUpValues}
        />
      )}
      {savedChanges && (
        <AlertPopup
          visible={savedChanges}
          setpopUp={setSavedChanges}
          button1Function={hanldeContinueEditing}
          button2Function={handleCloseEditing}
          value={popUpSaveValues}
        />
      )}
      <div className={styles.editCampaign} ref={modalRef}>
        <div className={styles.contentDiv}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <img src={editCampaignHeaderLogo} />
              <h2>Edit your Contest</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowClosePopup(true)} />
          </div>
          <div className={styles.body}>
            <div className={`custom-scrollbar ${styles.left}`}>
              <ContestFormInputs
                contestFormValue={formValues}
                setContestFormValues={setFormValues}
                tokenData={tokenData}
                contestFormErrors={formErrors}
                contestData={data}
                campaignContestsDatas={campaignContestsDatas}
                setLoading={setLoading}
              />
            </div>
            <div className={styles.buttonWrapper}>
              <button className={styles.discard}>Discard Changes</button>

              <button
                className={styles.save}
                onClick={loading ? "" : handleUpdateCamapignDetails}
              >
                {loading ? "Saving" : "Save Changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
