import React from "react";
import { useState } from "react";
import styles from "./AnalyticsPage.module.css";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import { useEffect } from "react";
import { useContext } from "react";
import Context from "../../Context/Context";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import AdsAnalytics from "../../Components/Analytics/AdsAnalytics";
import PromotorsAnalytics from "../../Components/Analytics/PromotorsAnalytics";
import Insight from "../../Components/RightPanel/Insight";

export default function CampaignAnalyticsPage() {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [analyticsData, setAnalyticsData] = useState({});

  const {
    orgDomain,
    userIdToken,
    verifyUser,
    campaignIdToPersist,
    setCampaignIdToPersist,
  } = useContext(Context);
  const { currentUser } = useUserAuth();

  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    if (!isEmpty(orgDomain)) {
      getCampaignDataList();
    }
  }, [orgDomain]);
  const getCampaignDataList = async () => {
    setLoading(true);

    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_DATA_FOR_LEADS}/${orgDomain}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setCampaignList(setApi?.data?.data);
      getAnalyticsData(campaignIdToPersist || campgnData[0]?._id);
      let findCampaign;

      if (campaignIdToPersist) {
        findCampaign = campgnData?.find((el) => el?._id == campaignIdToPersist);
      }
      let filterCampaign = findCampaign || campgnData[0];
      setSelectedCampaign(filterCampaign);
      // setLoading(false);

      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const getAnalyticsData = async (id) => {
    setLoading(true);

    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_ANALYTICS}/${id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);
      setAnalyticsData(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const handleSearch = (el) => {
    setCampaignIdToPersist(el?._id);
    setSelectedCampaign(el);
    getAnalyticsData(el?._id);
  };
  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className={styles.analytics}>
          <div
            className={styles.dropDown}
            onClick={() => {
              setShowDropDown((prevState) => !prevState);
            }}
          >
            <h2>{selectedCampaign?.campaignName}</h2>

            <img src={leadsDropDown} alt="" />
          </div>

          {showDropDown && (
            <div className={styles.dropDownPopup}>
              <>
                <h4>Campaigns</h4>
                {campaignList?.map((el, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleSearch(el);
                        setShowDropDown(false);
                      }}
                    >
                      {el?.campaignName}
                    </p>
                  );
                })}
              </>
            </div>
          )}
          {!isEmpty(analyticsData) && (
            <div className="my-[30px]">
              <Insight
                type="Campaign"
                userCount={analyticsData?.TotalVisitCount}
                likeCount={analyticsData?.TotalLikeCount}
                viewCount={analyticsData?.totalEntryViewCount}
                participantCounter={analyticsData?.participantCounter}
              />
            </div>
          )}

          {!isEmpty(analyticsData?.analytics) && (
            <>
              <h3> Advertisement Analytics</h3>
              {isEmpty(analyticsData?.analytics) ? (
                <div className="flex items-center justify-center w-[100%] h-[100px]">
                  No Data
                </div>
              ) : (
                <AdsAnalytics
                  data={analyticsData?.analytics}
                  campaignId={selectedCampaign?._id}
                />
              )}
              {analyticsData?.promotors && (
                <>
                  <h3 className="mt-[50px]"> Promotors Analytics</h3>
                  <PromotorsAnalytics data={analyticsData?.promotors} />
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
