import React, { useEffect, useState } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import styles from "./RichtextEditor.module.css";
import { isEmpty } from "lodash";

function RichtextEditorWithSubValues({
  setFormValues,
  setModifiedValue,
  formValues,
  objectName,
  keyToEdit,
  change,
}) {
  const [description, setDescription] = useState({
    htmlValue: "",
    editorState: isEmpty(
      formValues[objectName] && formValues[objectName][keyToEdit]
    )
      ? EditorState.createEmpty()
      : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(
              formValues[objectName] && formValues[objectName][keyToEdit]
            )?.contentBlocks
          )
        ),
  });
  useEffect(() => {
    setDescription({
      htmlValue: "",
      editorState: isEmpty(
        formValues[objectName] && formValues[objectName][keyToEdit]
      )
        ? EditorState.createEmpty()
        : EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(
                formValues[objectName] && formValues[objectName][keyToEdit]
              )?.contentBlocks
            )
          ),
    });
  }, [change]);
  const onEditorStateChange = (editorValue) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );

    setDescription({
      htmlValue: editorStateInHtml,
      editorState: editorValue,
    });

    setFormValues((prevState) => ({
      ...prevState,
      [objectName]: {
        ...prevState[objectName],
        [keyToEdit]: JSON.parse(JSON.stringify(editorStateInHtml)),
      },
    }));
    setModifiedValue((prevState) => ({
      ...prevState,
      [objectName]: {
        ...formValues[objectName],
        [keyToEdit]: JSON.parse(JSON.stringify(editorStateInHtml)),
      },
    }));
  };

  return (
    <div>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorState={description.editorState}
        onEditorStateChange={onEditorStateChange}
      />
      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </div>
  );
}

export default RichtextEditorWithSubValues;
