import React from "react";

import styles from "./styles/Share.module.css";
import whatsapp from "../../Assets/whatsapp.svg";
import copy from "../../Assets/Copy.svg";
import download from "../../Assets/documentdownload.svg";
import qr from "../../Assets/qr.svg";
import { useState } from "react";
import { useEffect } from "react";

const ShareCard = ({ setShare, campaignContestsDatas, link }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  let copyText = "";
  if (link) {
    copyText = link;
  } else {
    copyText = `https://app.goviral.world/${campaignContestsDatas?.orgDomain}/${campaignContestsDatas?.campaignUniqueText}`;
  }

  function copyLink() {
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");

    // alert(copyText + "\n copied to clipboard")
    setLinkCopied(true);
    setTimeout(() => {
      setShare(false);
    }, 1000);
  }
  const whatsapplink = () => {
    window.open("whatsapp://send?text= " + copyText);
  };

  const downloadQr = (file) => {
    // window.open(fullData?.qrCodeUrl)
    // e.preventDefault()

    fetch(file, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={`fixed  top-0 left-0 h-[100vh] w-[100%]  flex justify-center items-center ${styles.background} `}
    >
      <div className={`  ${styles.shareDiv}`}>
        <div className="w-[358px]  bg-white rounded-[10px] flex flex-col justify-evenly items-center">
          <p className="w-full py-[10px] px-[20px] break-words text-center">{copyText}</p>
          <div
            className="flex items-center justify-center hover:cursor-pointer py-[20px]"
            onClick={() => whatsapplink()}
          >
            <img
              src={whatsapp}
              alt=""
              className="h-[22px] w-[22px] mr-[8px] "
            />
            <p className="four text-[14px]">Share to WhatsApp</p>
          </div>
          <div className="w-[318px] h-[1px] bg-[#F5F5F5] mx-auto"></div>
          <div
            className="flex items-center justify-center hover:cursor-pointer py-[20px]"
            onClick={() => copyLink()}
          >
            <img src={copy} alt="" className="h-[22px] w-[22px] mr-[8px] " />
            <p className="four text-[14px]">
              {" "}
              {linkCopied ? "Link Copied" : "Copy link"}
            </p>
          </div>
          <div className="w-[318px] h-[1px] bg-[#F5F5F5] mx-auto"></div>
          {campaignContestsDatas?.campaignQrUrl && (
            <div
              className="flex items-center justify-center hover:cursor-pointer py-[20px]"
              onClick={() => downloadQr(campaignContestsDatas?.campaignQrUrl)}
            >
              <img
                src={download}
                alt=""
                className="h-[22px] w-[22px] mr-[8px] "
              />
              <p className="four text-[14px]">Download poster</p>
            </div>
          )}
          {/* <div className="w-[318px] h-[1px] bg-[#F5F5F5] mx-auto"></div>
        <div
          className="flex items-center justify-center hover:cursor-pointer"
          onClick={() => downloadQr(campaignContestsDatas?.campaignQrUrl)}
        >
          <img src={qr} alt="" className="h-[22px] w-[22px] mr-[8px] " />
          <p className="four text-[14px]">Download QR code</p>
        </div> */}
          <div
            className="w-[318px] bg-[#F6F6F6] h-[50px] mx-auto rounded-[10px] flex items-center justify-center hover:cursor-pointer my-[20px]"
            onClick={() => setShare(false)}
          >
            <p className="five text-[16px]">Cancel</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareCard;
