import React from "react";

export default function ColorIndicator({ color, text }) {
  let bg = { background: color };
  return (
    <div className="flex items-center gap-[5px] whitespace-nowrap">
      <div className="w-[15px] h-[15px]" style={bg} />
      <p className="text-[12px] text-black">{text}</p>
    </div>
  );
}
