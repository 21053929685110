import React, { useContext, useRef } from "react";
import styles from "./GiveAccess.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import AlertPopup from "../AlertPopups/AlertPopup";
import { useParams, useSearchParams } from "react-router-dom";

export default function GiveAccess({ setShowPopup, formValues, edit, type }) {
  const initialValues = formValues || {};

  const [userFormValues, setUserFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const { userIdToken, organizationId, setUpdateData, orgDomain } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [brandOwnerValues, setBrandOwnerValues] = useState({
    orgName: orgDomain,
  });
  const [formErrors, setFormErrors] = useState({});
  const [alreadyRequested, setAlreadyRequested] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { promotionId } = useParams();

  const orgId = searchParams.get("orgId");
  const campaignId = searchParams.get("cmpId");
  const [deleteUser, setDeleteUser] = useState(false);
  const handleClose = () => {
    setShowPopup(false);
  };
  // LOGO UPLOAD
  const handleObjChange = (e) => {
    setUserFormValues({
      ...userFormValues,
      [e.target.id]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setBrandOwnerValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSelectUser = (obj) => {
    const { dop, name, orgId, phone, email, _id } = obj;
    setUserFormValues({
      dop,
      name,
      orgId,
      phone,
      email,
      userId: _id,
    });
    setIsDropdownOpen(false);
    setReadOnly(true);
  };
  const searchUsers = async () => {
    try {
      setSearchedUsers(null);
      setLoading(true);
      let config1 = {
        method: "POST",
        url: `${process.env.REACT_APP_SEARCH_USER_FOR_PERMISSION}`,
        data: {
          search: brandOwnerValues?.search,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      if (isEmpty(setApi?.data?.data)) {
        setFormErrors((prevState) => ({
          ...prevState,
          search: "No Result Found, Please Create One",
        }));
        setReadOnly(false);
      } else {
        setIsDropdownOpen(true);
      }
      setSearchedUsers(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleAccess = async () => {
    setLoading(true);
    let url = "";
    if (type == "promotion") {
      url = `${process.env.REACT_APP_GRANT_PRO_ACCESS}/${organizationId}/${promotionId}/${userFormValues?.userId}?promotion=true`;
    } else {
      url = `${process.env.REACT_APP_GRANT_USER_PERMISSION}/${orgId}/${campaignId}/${userFormValues?.userId}`;
    }
    let config = {
      method: "PATCH",
      url: url,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      if (response?.data?.alreadyRequested) {
        setAlreadyRequested(true);
        return;
      }
      setUpdateData((prevState) => prevState + 1);
      setLoading(false);
      setShowPopup(false);
    }
  };
  const handleSubmit = async () => {
    await handleAccess();
  };
  const handleDelete = async () => {
    setDeleteUser(false);
    setLoading(true);
    let url = "";
    if (type == "promotion") {
      url = `${process.env.REACT_APP_DELETE_PRO_ACCESS}/${organizationId}/${promotionId}/${userFormValues?.userId}?promotion=true`;
    } else {
      url = `${process.env.REACT_APP_REMOVE_USER_PERMISSION}/${orgId}/${campaignId}/${userFormValues?.userId}`;
    }
    let config = {
      method: "PATCH",
      url: url,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      if (response?.data?.alreadyRequested) {
        setAlreadyRequested(true);
        return;
      }
      setUpdateData((prevState) => prevState + 1);
      setLoading(false);
      setShowPopup(false);
    }
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Already Requested",
    Para: "You've already sent a connection request to this brand.",
    Button1: "OK",
  };
  const deletePopUpValues = {
    Icon: "",
    Heading: "Deny Access",
    Para: "User cannot access this campaign!",
    Button1: "Cancel",
    Button2: "Deny Access",
    Button2Color: "#d0342c",
  };

  return (
    <>
      {alreadyRequested && (
        <AlertPopup
          visible={alreadyRequested}
          setpopUp={setAlreadyRequested}
          button1Function={() => setAlreadyRequested(false)}
          value={closePopUpValues}
        />
      )}
      {deleteUser && (
        <AlertPopup
          visible={deleteUser}
          setpopUp={setAlreadyRequested}
          button1Function={() => setDeleteUser(false)}
          button2Function={handleDelete}
          value={deletePopUpValues}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.popup}>
          <div className={`custom-scrollbar ${styles.content}`}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                {/* <img src={editCampaignHeaderLogo} /> */}
                <h2>Search Users</h2>
              </div>
              <img src={Close} alt="x" onClick={handleClose} />
            </div>
            <div className={styles.body}>
              {/* search  */}
              {!edit && (
                <div className="relative">
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px]  `}>Search Users</p>{" "}
                      <span className="text-red-600">*</span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <input
                        type="text"
                        id="search"
                        onChange={handleChange}
                        value={brandOwnerValues?.search}
                        placeholder="email id or name"
                      />
                      <button
                        className={styles.search}
                        onClick={searchUsers}
                        disabled={loading}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {formErrors.search && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.search}
                    </span>
                  )}
                  {isDropdownOpen && (
                    <div className={styles.dropdownList}>
                      {searchedUsers?.map((obj) => (
                        <div
                          key={obj?._id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectUser(obj);
                          }}
                          className="flex items-center gap-[10px]"
                        >
                          <div className="w-[30px] h-[20px]">
                            <img src={obj?.dop} className="h-full w-full " />
                          </div>
                          {obj?.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {(searchedUsers || edit) && (
                <>
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}> Name</p>{" "}
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      //   ref={textInputEmail}
                      type="text"
                      id="name"
                      onChange={handleObjChange}
                      value={userFormValues.name}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}>Email</p>{" "}
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      //   ref={textInputEmail}
                      type="text"
                      id="email"
                      onChange={handleObjChange}
                      value={userFormValues.email}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}>Role</p>{" "}
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      //   ref={textInputEmail}
                      type="text"
                      id="role"
                      onChange={handleObjChange}
                      value="Admin"
                      readOnly={true}
                    />
                  </div>
                  <hr className={styles.line} />
                </>
              )}
            </div>

            <div className={styles.buttonWrapper}>
              {edit == false ? (
                <button
                  className={styles.save}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Save
                </button>
              ) : (
                <>
                  {userFormValues?.orgRole !== "Access_denied" ? (
                    <button
                      className={styles.save}
                      onClick={() => setDeleteUser(true)}
                      disabled={loading}
                    >
                      Deny Access
                    </button>
                  ) : (
                    <button
                      className={styles.save}
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      Save
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
