import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import UploadPopup from "./UploadPopup";
import Close from "../../Assets/close.svg";

export default function UploadGalleryItems({
  formValues,
  setFormValues,
  setChangedValues,
  tokenData,
  entryId,
  organizationId,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [progressbar, setProgressbar] = useState();
  const [source, setSource] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const [mediaUrlFromApi, setMediaUrlFromApi] = useState("");
  const [thumbanilUrlFromApi, setThumbanailFromApi] = useState("");
  const inputRef = useRef();
  const thumbnailRef = useRef();
  //   ADD ENTRY
  const handleFileChange = async (event) => {
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);
    setSource(url);
    setData(file);
    setProgressbar(0);

    // setThumbnail(thumbnail);
  };

  const handleChoose = async () => {
    inputRef.current.click();
  };
  const uploadVideo = async (payload, fileUrl) => {
    try {
      //   setUploadStart(true);
      //upload as binary function
      const uploadGcp = (file, url) => {
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-type", file.type);
        xhr.onload = (response) => {};
        xhr.onreadystatechange = async () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              setMediaUrlFromApi(fileUrl);
              console.log("SUCCESS");
            }
          }
        };
        xhr.onerror = function (response) {
          console.log("Response error", response);
        };
        xhr.upload.onprogress = function (evt) {
          // For uploads
          if (evt.lengthComputable) {
            let percentComplete = parseInt((evt.loaded / evt.total) * 100);
            setProgressbar(percentComplete);
            //console.log('progress', percentComplete);
          }
        };
        xhr.send(file);
      };

      const response3 = await uploadGcp(data, payload);
      return response3;
    } catch (error) {
      console.log(error.message);
    }
  };
  const getEntrySignedUrl = async () => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_PROMOTION_MEDIA_SIGNEDURL}/${organizationId}/${entryId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);

      if (response.status === 201) {
        const gcpUrl = response.data.data.videoUploadURL;
        const fileUrl = response.data.data.fileUrl;
        uploadVideo(gcpUrl, fileUrl);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  //   ADD ENTRY
  //updadate thumbanil
  const handleThumbnailChange = async (event) => {
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);
    setThumbnailUrl(url);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_PROMOTION_MEDIA_UPDATE_THUMBNAIL}/${entryId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    console.log("upload", upload);
    if (upload.status === 200) {
      setThumbanailFromApi(upload?.data?.thumbUrl);
      console.log("thumbanil added");
    }
  };

  const handleThumbnailChoose = () => {
    thumbnailRef.current.click();
  };
  //updadate thumbanil

  const handleSave = () => {
    let mediaArray = formValues.galleryItems ? formValues.galleryItems : [];
    let newMediaObj = {};
    if (data?.type.startsWith("image")) {
      newMediaObj = {
        fileType: data?.type,
        fileUrl: mediaUrlFromApi,
        fileName: data?.name,
      };
    } else {
      newMediaObj = {
        fileType: data?.type,
        fileUrl: mediaUrlFromApi,
        thumbanilUrl: thumbanilUrlFromApi,
        fileName: data?.name,
      };
    }
    mediaArray.push(newMediaObj);
    setFormValues({
      ...formValues,
      galleryItems: mediaArray,
    });
    setChangedValues((prevState) => ({
      ...prevState,
      galleryItems: mediaArray,
    }));
    setSource("");
    setThumbnailUrl("");
    setData();
    setShowPopup(false);
  };
  const handleSaveYoutube = (url) => {
    let mediaArray = formValues.galleryItems ? formValues.galleryItems : [];

    let newMediaObj = {
      fileType: "video/youtube",
      fileUrl: url,
      fileName: url,
    };
    mediaArray.push(newMediaObj);
    setFormValues({
      ...formValues,
      galleryItems: mediaArray,
    });
    setChangedValues((prevState) => ({
      ...prevState,
      galleryItems: mediaArray,
    }));
    setSource("");
    setThumbnailUrl("");
    setData();
    setShowPopup(false);
  };
  const handleRemoveMedia = (obj) => {
    let mediaArray = formValues?.galleryItems;
    var index = mediaArray.findIndex((el) => el.fileUrl === obj.fileUrl);
    mediaArray.splice(index, 1);
    setFormValues({
      ...formValues,
      galleryItems: mediaArray,
    });
    setChangedValues((prevState) => ({
      ...prevState,
      galleryItems: mediaArray,
    }));
  };
  return (
    <>
      {showPopup && (
        <UploadPopup
          setShowPopup={setShowPopup}
          showPopup={showPopup}
          handleChoose={handleChoose}
          handleFileChange={handleFileChange}
          getEntrySignedUrl={getEntrySignedUrl}
          data={data}
          inputRef={inputRef}
          source={source}
          progressbar={progressbar}
          handleThumbnailChange={handleThumbnailChange}
          handleThumbnailChoose={handleThumbnailChoose}
          thumbnailUrl={thumbnailUrl}
          thumbnailRef={thumbnailRef}
          handleSave={handleSave}
          handleSaveYoutube={handleSaveYoutube}
        />
      )}
      <p>Upload Gallery images</p>
      <div>
        {formValues?.galleryItems?.map((obj) => (
          <div className="flex ">
            <p className="mr-[5px]">{obj.fileName}</p>
            <img src={Close} alt="x" onClick={() => handleRemoveMedia(obj)} />
          </div>
        ))}
        <h3 onClick={() => setShowPopup(true)}>Add</h3>
      </div>
    </>
  );
}
