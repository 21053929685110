import React from "react";
import { Link } from "react-router-dom";

export default function SponsorHomePage() {
  return (
    <div className="p-[20px] flex flex-col gap-[10px]">
      <Link to="/sp/analytics">
        <button className="commonButton">Analytics</button>
      </Link>
      <Link to="/sp/campaigns">
        <button className="commonButton">Campaigns</button>
      </Link>
      <Link to="/sp/leads">
        <button className="commonButton">Leads</button>
      </Link>
      <Link to="/sp/payment">
        <button className="commonButton">Payments</button>
      </Link>
    </div>
  );
}
