import React, { useContext, useEffect, useRef } from "react";
import styles from "./AcceptBrandCollab.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import AdsAnalytics from "../Analytics/AdsAnalytics";
import LoadingAnimation from "../Loading/LoadingAnimation";

export default function AcceptBrandCollab({ setPopup, data }) {
  const [loading, setLoading] = useState(false);
  const { userIdToken, setUpdateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [associatedCampaigns, setAssociatedCampaigns] = useState();
  const [analytics, setAnalytics] = useState({});
  const [analyticsCoupons, setAnalyticsCoupons] = useState({});

  const handleClose = () => {
    setPopup(false);
  };
  useEffect(() => {
    getCampaignData();
  }, []);
  const handleSubmit = async () => {
    setLoading(true);
    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_APPROVE_SELF_BRANDS}/${data?.orgId}/${data?.brandId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    setLoading(false);
    if (response.data?.status == "success") {
      setPopup(false);
      setUpdateData((prevState) => prevState + 1);
    }
  };
  const getCampaignData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_BRAND_ASSOCIATED_CAMPAIGNS}/${data?.brandId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setAssociatedCampaigns(setApi?.data?.data?.associatedCampaigns);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const getBrandAnalytics = async ({ orgId, campaignId, adsId }, index) => {
    try {
      setLoading(true);
      let config1 = {
        method: "post",
        url: `${process.env.REACT_APP_ASSOCIATED_BRANDS_ANALYTICS}/${orgId}/${campaignId}/${data?.brandId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { adsId },
      };
      const setApi = await axios(config1);
      setAnalytics({ ...setApi?.data?.data, mappingIndex: index });
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const getCouponsAnalytics = async (
    { orgId, campaignId, couponId },
    index
  ) => {
    try {
      setLoading(true);
      let config1 = {
        method: "post",
        url: `${process.env.REACT_APP_ASSOCIATED_BRANDS_COUPON_ANALYTICS}/${orgId}/${campaignId}/${data?.brandId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { couponId },
      };
      const setApi = await axios(config1);
      setAnalyticsCoupons({ ...setApi?.data?.data, mappingIndex: index });
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Brand Details</h2>
            </div>
            <img src={Close} alt="x" onClick={handleClose} />
          </div>
          <div className={styles.body}>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Brand Name
              </p>
              <p>{data?.name}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Logo
              </p>
              <div className="h-[170px] w-full">
                <img
                  src={data?.logo}
                  alt={`Image `}
                  className="h-full object-contain"
                />
              </div>
            </div>
            <hr className={styles.line} />
            <h2 className="my-[10px]">Associated Campaigns</h2>
            {loading ? (
              <LoadingAnimation />
            ) : isEmpty(associatedCampaigns) ? (
              <div>Not Added to Campaigns</div>
            ) : (
              associatedCampaigns?.map((obj, index) => {
                return (
                  <>
                    <div className="flex gap-[20px] items-center my-[10px]">
                      {index + 1}. {obj?.campaignName}
                      <button
                        className={styles.save}
                        onClick={() => {
                          getBrandAnalytics(obj, index);
                          getCouponsAnalytics(obj, index);
                        }}
                        disabled={loading}
                      >
                        View Analytics
                      </button>
                    </div>
                    {!isEmpty(analytics) &&
                      analytics?.mappingIndex == index && (
                        <AdsAnalytics
                          data={analytics}
                          campaignId={obj?.campaignId}
                        />
                      )}
                    {!isEmpty(analyticsCoupons) &&
                      analytics?.mappingIndex == index && (
                        <AdsAnalytics data={analyticsCoupons} />
                      )}
                    <hr className={styles.line} />
                  </>
                );
              })
            )}
          </div>
          <div className={styles.buttonWrapper}>
            {data?.status == "REQUESTED" && (
              <button
                className={styles.save}
                onClick={handleSubmit}
                disabled={loading}
              >
                Accept
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
