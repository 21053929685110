import styles from "./styles/Review.module.css";
import { useState, useContext, useEffect } from "react";
import Star from "../../Assets/star.svg";
import Close from "../../Assets/close.svg";
import axios from "axios";
import Context from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../Context/UserAuthContext";
import ReactPlayer from "react-player";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import Carousel from "../Carousel/Carousel";
import AlertPopup from "../AlertPopups/AlertPopup";
import AudioPlayerWithoutDownload from "../AudioPlayerWithoutDownload/AudioPlayerWithoutDownload";

function ReviewDetails({ setpopUp, popupData, popUp }) {
  const { currentUser } = useUserAuth();
  const [comment, setComments] = useState("");
  const [denyLoading, setDenyLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const campaignUniqueText = window.location.href.split("/")[4];
  const orgDomain = window.location.href.split("/")[5];
  const contestId = window.location.href.split("/")[6];
  const [deny, setDeny] = useState();
  const [formAttributes, setFormAttributes] = useState({});
  const [showApprove, setShowApprove] = useState(false);
  const [showDeny, setShowDeny] = useState(false);

  const navigate = useNavigate();
  const {
    BaseApiUrl,
    verifyUser,
    userIdToken,
    modalRef,
    setUpdateData,
    setNotAdmin,
  } = useContext(Context);
  useEffect(() => {
    verifyUser();
    loadFormAttributes(
      popupData?.orgId,
      popupData?.campaignId,
      popupData?.contestId
    );
  }, []);
  const tokenData = userIdToken || currentUser?.accessToken;
  const loadFormAttributes = async (orgId, campaignId, contestId) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_FORM_ATTRIBUTES}/${orgId}/${campaignId}/${contestId}/formAttributes.json`
      );
      setFormAttributes(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };

  const denyFunction = async (
    orgID,
    campaignId,
    contestId,
    videoId,
    userId
  ) => {
    try {
      setDenyLoading(true);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_DENY_CONTEST_VIDEO}/${orgID}/${campaignId}/${contestId}/${videoId}/${userId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          comment: comment,
        },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setDenyLoading(false);
          return;
        }
        setpopUp(false);
        setDenyLoading(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setDenyLoading(false);
      console.log(err.message);
      setpopUp(false);
    }
  };
  const approvelFunction = async (
    orgID,
    campaignId,
    contestId,
    videoId,
    userId
  ) => {
    try {
      setApproveLoading(true);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_APPROVE_CONTEST_VIDEO}/${orgID}/${campaignId}/${contestId}/${videoId}/${userId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          comment: comment,
        },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setApproveLoading(false);
          return;
        }
        setApproveLoading(false);
        setpopUp(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setApproveLoading(false);
      console.log(err.message);
      setpopUp(false);
    }
  };
  const approveValues = {
    Icon: "",
    Heading: "Approve Entry",
    Para: "Are you sure you want to approve this entry? ",
    Button1: "Cancel",
    Button2: "Approve",
    Button2Color: "#06B818",
  };
  const denyValues = {
    Icon: "",
    Heading: "Decline Entry",
    Para: "Are you sure you want to decline this entry? ",
    Button1: "Cancel",
    Button2: "Decline",
    Button2Color: "#d0342c",
  };

  const handleApproveEntry = () => {
    approvelFunction(
      popupData?.orgId,
      popupData?.campaignId,
      popupData?.contestId,
      popupData?._id,
      popupData?.userId
    );
    handleShowApprove();
  };
  const handleDenyEntry = () => {
    denyFunction(
      popupData?.orgId,
      popupData?.campaignId,
      popupData?.contestId,
      popupData?._id,
      popupData?.userId
    );
    handleShowDeny();
  };
  const handleShowApprove = () => {
    setShowApprove((prev) => !prev);
  };
  const handleShowDeny = () => {
    setShowDeny((prev) => !prev);
  };

  return (
    <PopupWrapper setpopUp={setpopUp} visible={popUp}>
      {showApprove && (
        <AlertPopup
          visible={showApprove}
          setpopUp={setShowApprove}
          button1Function={handleShowApprove}
          button2Function={handleApproveEntry}
          value={approveValues}
        />
      )}
      {showDeny && (
        <AlertPopup
          visible={showDeny}
          setpopUp={setShowDeny}
          button1Function={handleShowDeny}
          button2Function={handleDenyEntry}
          value={denyValues}
        />
      )}

      <div className={styles.container}>
        <div className={styles.grid} ref={modalRef}>
          <div className={styles.box}>
            <div>
              <div className={styles.boxHead}>
                <div className="flexio" style={{ gap: "8px" }}>
                  <img className={styles.imgWid} src={Star} alt="" />
                  <p>{popupData?.participantName}</p>
                </div>
                <img src={Close} alt="" onClick={() => setpopUp(false)} />
              </div>
              <div className="w-[100%]  h-[200px]">
                {!popupData?.fileType?.startsWith("audio") && (
                  <Carousel items={popupData?.fileUrls} />
                )}
                {popupData?.fileType?.startsWith("audio") && (
                  <div className="h-full p-[20px] ">
                    <img
                      src={popupData?.thumbnailUrl}
                      className={`h-[80%] w-[100%] mx-auto object-contain mb-[10px]`}
                    />
                    <AudioPlayerWithoutDownload
                      fileUrl={
                        popupData?.fileUrls &&
                        popupData?.fileUrls[0] &&
                        popupData?.fileUrls[0].fileUrl
                      }
                      fileName="my voice recording"
                    />
                  </div>
                )}
              </div>
              <input
                type="text"
                className={styles.inputland}
                placeholder="Comments..."
                onChange={(e) => {
                  setComments(e.target.value);
                }}
              />
            </div>

            <div className={styles.flexme}>
              <button
                onClick={handleShowDeny}
                className={styles.decline}
                disabled={denyLoading || approveLoading}
              >
                {denyLoading ? "Declining" : "Decline"}
              </button>
              <button
                onClick={handleShowApprove}
                className={styles.accept}
                disabled={approveLoading || denyLoading}
              >
                {approveLoading ? "Approving" : "Approve"}
              </button>
            </div>
          </div>
          <div className={`custom-scrollbar ${styles.entryQuestions}`}>
            <div className="text-black ">
              {popupData?.contestName && (
                <div className="mb-[30px]">
                  <p className="text-description-heading  font-bold ">
                    <strong>Contest Name</strong>
                  </p>
                  <p className={`text-description`}>{popupData?.contestName}</p>
                </div>
              )}
              {popupData?.phone && (
                <div className="mb-[30px]">
                  <p className="text-description-heading  font-bold ">
                    <strong>Number</strong>
                  </p>
                  <p className={`text-description  `}>{popupData?.phone}</p>
                </div>
              )}
              {popupData?.description && (
                <div className="mb-[30px]">
                  <p className="text-description-heading  font-bold ">
                    <strong>Description</strong>
                  </p>
                  <p className={`text-description  `}>
                    {popupData?.description}
                  </p>
                </div>
              )}
              {popupData?.thumbnailUrl && (
                <div className="pb-[30px]">
                  <p className="text-regular-bold ">
                    <strong>Thumbnail</strong>
                  </p>

                  <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px] rounded-[5px] px-[15px] py-[18px]">
                    <img
                      src={popupData?.thumbnailUrl}
                      className="w-auto h-[100%]"
                    />
                  </div>
                </div>
              )}
              {formAttributes?.formAttributes?.map((el) => {
                return (
                  <div className="flex flex-col ">
                    {el?.items?.map((key) => {
                      if (el.attrType == "media") {
                        return (
                          <div className="pb-[30px]">
                            <p className="text-regular-bold ">
                              <strong>{el?.title} </strong>
                            </p>
                            {el?.type?.startsWith("image") && (
                              <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px] rounded-[5px] px-[15px] py-[18px]">
                                <img
                                  src={popupData[key]}
                                  className="w-auto h-[100%]"
                                />
                              </div>
                            )}
                            {el?.type?.startsWith("video") && (
                              <div className="h-[320px] w-[100%] overflow-hidden flex items-center justify-center mb-[15px] rounded-[12px] md:rounded-[30px]">
                                <ReactPlayer
                                  playing={false}
                                  controls={true}
                                  width="100%"
                                  height="100%"
                                  url={popupData[key]}
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <>
                            <p className="text-regular-bold ">
                              <strong>{el?.title} </strong>
                            </p>
                            <p className="text-regular mb-[30px]">
                              {popupData[key]}
                            </p>
                          </>
                        );
                      }
                    })}
                  </div>
                );
              })}
              {popupData?.quizMarksObtained && (
                <div className="mb-[30px]">
                  <p className="text-description-heading  font-bold ">
                    <strong>Mark</strong>
                  </p>
                  <p className={`text-description  `}>
                    {popupData?.quizMarksObtained}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default ReviewDetails;
