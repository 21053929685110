import React, { useContext, useEffect, useState } from "react";
import PromotorsAnalytics from "../../Components/Analytics/PromotorsAnalytics";
import AdsAnalytics from "../../Components/Analytics/AdsAnalytics";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import Context from "../../Context/Context";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import { useUserAuth } from "../../Context/UserAuthContext";
import styles from "./styles/SponsorAnalyticsPage.module.css";
import axios from "axios";
import Insight from "../../Components/RightPanel/Insight";

export default function SponsorAnalyticsPage() {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({});
  const { orgDomain, userIdToken, verifyUser } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignDataList();
  }, []);
  const getCampaignDataList = async () => {
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SPONSOR_CAMPAIGNS}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data?.campaigns;
      setCampaignList(campgnData);
      getAnalyticsData(campgnData[0]?.campaignId);
      setSelectedCampaign(campgnData[0]);

      return campgnData;
    } catch (err) {
      console.log(err.message);
    }
  };
  const getAnalyticsData = async (id) => {
    setLoading(true);

    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SPONSOR_ANALYTICS}/${id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      setAnalyticsData(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const handleSearch = (el) => {
    setSelectedCampaign(el);
    getAnalyticsData(el?.campaignId);
  };

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className="p-[10px]">
          <div
            className={styles.dropDown}
            onClick={() => {
              setShowDropDown((prevState) => !prevState);
            }}
          >
            <h2>{selectedCampaign?.campaignName}</h2>

            <img src={leadsDropDown} alt="" />
          </div>

          {showDropDown && (
            <div className={styles.dropDownPopup}>
              <>
                <h4>Campaigns</h4>
                {campaignList?.map((el, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleSearch(el);
                        setShowDropDown(false);
                      }}
                    >
                      {el?.campaignName}
                    </p>
                  );
                })}
              </>
            </div>
          )}
          {analyticsData && (
            <div className="my-[30px]">
              <Insight
                type="Campaign"
                userCount={analyticsData?.TotalVisitCount}
                likeCount={analyticsData?.TotalLikeCount}
                viewCount={analyticsData?.totalEntryViewCount}
                participantCounter={analyticsData?.participantCounter}
              />
            </div>
          )}
          {analyticsData?.analytics && (
            <>
              <h3> Advertisement Analytics</h3>
              <AdsAnalytics
                data={analyticsData?.analytics}
                campaignId={selectedCampaign?.campaignId}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
