import React, { useContext } from "react";
import styles from "./EditContest.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import { isEmpty } from "lodash";
import DynamicInput from "../DynamicInput/DynamicInput";
import {
  singleFileUpload,
  singleFileUploadWithConfig,
} from "../../utils/SingleFileUpload";
import Context from "../../Context/Context";

export default function PerformanceData({ data, setValue, setPopup }) {
  const [formAttr, setFormAttr] = useState(data || {});
  const [loading, setLoading] = useState(false);
  const { organizationId } = useContext(Context);
  const handleChange = async (event) => {
    const toEdit = event.target.dataset.customInfo;
    const key = event.target.id;
    const value = event.target.value;
    if (key == "fileUrlPerformance") {
      event.preventDefault();
      const thumbnail = new FormData();
      const imagedata = event.target.files[0];
      let url = URL.createObjectURL(imagedata);
      thumbnail.append("image", imagedata);
      let uploadPath = `/${organizationId}/`;
      setLoading(true);
      let uploadFile = await singleFileUploadWithConfig(imagedata, uploadPath);
      setFormAttr((prevState) => ({
        ...prevState,
        ...uploadFile,
      }));
      setLoading(false);
    } else if (key == "thumbnailUrl") {
      event.preventDefault();
      const thumbnail = new FormData();
      const imagedata = event.target.files[0];
      let url = URL.createObjectURL(imagedata);
      thumbnail.append("image", imagedata);
      let uploadPath = `/${organizationId}/`;
      setLoading(true);
      let uploadFile = await singleFileUpload(imagedata, uploadPath);
      setFormAttr((prevState) => ({
        ...prevState,
        thumbnailUrl: uploadFile,
      }));
      setLoading(false);
    } else if (key == "fileUrl") {
      setFormAttr((prevState) => ({
        ...prevState,
        [key]: value,
        fileType: "video/youtube",
      }));
    } else {
      setFormAttr((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleChooseFile = (id) => {
    const divElement = document.getElementById(id);
    if (divElement) {
      divElement.click();
    }
  };
  const handleChangeInput = (e) => {
    setFormAttr((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleAddField = () => {
    setValue((prevState) => ({ ...prevState, performanceData: formAttr }));
    setPopup(false);
  };
  return (
    <div className={styles.formAttrWrapper}>
      <div className={styles.formAttr}>
        <div className={styles.contentDiv}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <h2>Add Reference File</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setPopup(false)} />
          </div>
          <div className={`p-[20px] ${styles.body}`}>
            <div className={`custom-scrollbar ${styles.left}`}>
              <DynamicInput
                handleChange={handleChange}
                value={formAttr?.fileUrl}
                field={{
                  name: "Youtube Video Url",
                  type: "text",
                  id: "fileUrl",
                  mainKey: "performanceData",
                }}
              />
              <div className="w-full flex justify-center my-[10px]">OR</div>
              <DynamicInput
                handleChange={handleChange}
                handleChooseFile={handleChooseFile}
                value={formAttr?.fileUrl}
                fileType={formAttr?.fileType}
                field={{
                  name: "Upload Reference File",
                  type: "MEDIA",
                  id: "fileUrlPerformance",
                  accept: "video/*,audio/*",
                  mainKey: "performanceData",
                }}
              />
              <DynamicInput
                handleChange={handleChange}
                value={formAttr?.fileName}
                field={{
                  name: "File Name",
                  type: "text",
                  id: "fileName",
                  mainKey: "performanceData",
                }}
              />
              <DynamicInput
                handleChange={handleChange}
                handleChooseFile={handleChooseFile}
                value={formAttr?.thumbnailUrl}
                fileType={formAttr?.thumbnailUrl}
                field={{
                  name: "Thumbnail",
                  type: "MEDIA",
                  id: "thumbnailUrl",
                  accept: "image/*",
                  mainKey: "performanceData",
                }}
              />
              <div className={styles.buttonWrapper}>
                <button
                  className={styles.save}
                  onClick={handleAddField}
                  disabled={loading}
                >
                  {loading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
