import React, { useContext, useEffect, useState } from "react";

import styles from "./styles/CartPage.module.css";
import minus from "../../Assets/Cartminus.svg";
import plus from "../../Assets/Cartadd.svg";
import { useNavigate } from "react-router";
import CartBanner from "../../Components/CampaignCreation/CartBanner/CartBanner";
import Context from "../../Context/Context";
export default function CartPage() {
  let initialCartValuesFromLocal = JSON.parse(
    localStorage.getItem(`campaignCreationCart`)
  );
  let initialFormValuesFromLocal = JSON.parse(
    localStorage.getItem(`campaignCreationForm`)
  );
  const initialValues = {
    name: "",
    country: "",
    campaignName: "",
    email: "",
    phone: "",
    address: "",
    description: "",
    giftDetails: "",
    isPrivate: false,
    sponsorShipStatus: "REQUESTED",
    campaignDescription: "",
    contestIds: [],
    campaignIds: [],
    campaignUniqueText: "",
    orgDomain: "",
    privateType: "PIN",
    show: false,
    packDetails: "",
  };
  const [cartMap, setCartMap] = useState(
    initialCartValuesFromLocal ? new Map(initialCartValuesFromLocal) : new Map()
  );
  const { setSelectedContests } = useContext(Context);
  const [count, setCount] = useState(0);
  const [formValues, setFormValues] = useState(
    initialFormValuesFromLocal ? initialFormValuesFromLocal : initialValues
  );
  const navigate = useNavigate();
  useEffect(() => {
    // Get the values from the map
    const values = Array.from(cartMap.values());

    // Calculate the sum of all counts
    const sum = values.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0
    );
    setCount(sum);
  }, [cartMap]);
  const handleAddtoMyCampaign = (contest) => {
    const {
      _id,
      contestId,
      campaignId,
      contestName,
      contestType,
      contestPoster,
      description,
    } = contest;

    const key = contest?._id;
    const value = {
      _id,
      contestId,
      campaignId,
      contestName,
      contestPoster,
      description,
      contestType,
      count: cartMap.get(key)?.count ? cartMap.get(key)?.count + 1 : 1,
    };
    const updatedMap = new Map(cartMap);
    updatedMap.set(key, value);
    localStorage.setItem(
      `campaignCreationCart`,
      JSON.stringify(Array.from(updatedMap))
    );
    setCartMap(updatedMap);
  };

  const handleRemoveOtherContest = (contest) => {
    const {
      _id,
      contestId,
      campaignId,
      contestName,
      contestType,
      contestPoster,
      description,
    } = contest;
    const key = contest?._id;
    const value = {
      _id,
      contestId,
      campaignId,
      contestName,
      contestPoster,
      description,
      contestType,
      count: cartMap.get(key)?.count ? cartMap.get(key)?.count - 1 : 0,
    };
    const updatedMap = new Map(cartMap);
    updatedMap.set(key, value);
    localStorage.setItem(
      `campaignCreationCart`,
      JSON.stringify(Array.from(updatedMap))
    );
    setCartMap(updatedMap);
  };

  const handleRemoveFromCart = (contest) => {
    const key = contest?._id;

    const updatedMap = new Map(cartMap);
    updatedMap.delete(key);
    localStorage.setItem(
      `campaignCreationCart`,
      JSON.stringify(Array.from(updatedMap))
    );
    setCartMap(updatedMap);
  };

  const handleProceed = () => {
    const contestIds = Array.from(cartMap).map(([key, value]) => ({
      contestId: value.contestId,
      count: value.count,
    }));

    const campaignIdsSet = new Set();
    Array.from(cartMap).forEach(([key, value]) => {
      campaignIdsSet.add(value.campaignId);
    });
    const categoryIds = Array.from(campaignIdsSet);

    const templateType = Array.from(cartMap).some(
      ([key, value]) => value.contestType === "WP" || value.contestType === "SP"
    )
      ? "LEAUGE"
      : "NORMAL";
    let campaignsObj = {
      ...formValues,
      contestIds,
      categoryIds,
      templateType,
    };
    setSelectedContests({
      contestIds,
      categoryIds,
      templateType,
    });
    localStorage.setItem(`campaignCreationForm`, JSON.stringify(campaignsObj));
    navigate("/campaigns/create/details");
  };
  return (
    <div className="bg-white pb-[50px] w-[100%] mx-auto text-black min-h-[600px]">
      <div className="min-h-[500px]">
        <div className="w-[100%] max-w-[1280px] mx-auto px-[20px]  pt-[30px]">
          <div>
            <p className={` mb-[16px] ${styles.heading1}`}>Review your Cart</p>
            <p className={`${styles.desciption1} max-w-[550px]`}>
              Kindly spare a moment to carefully review the contents of your
              cart before proceeding.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-y-[40px] mt-[24px] md:mt-[30px] text-black px-[20px] md:px-[15%] ">
          {Array?.from(cartMap)?.map(([key, value]) => {
            return (
              <div className={styles.gridItems}>
                <div className={styles.campaignCard} key={key}>
                  <div className="h-auto w-[119px] min-h-[148px] flex">
                    <img
                      src={value?.contestPoster}
                      alt=""
                      className="h-full w-full min-w-[119px] object-cover rounded-[4px] "
                    />
                  </div>
                  <div className="h-auto w-[100%] p-[12px] flex flex-col justify-between gap-[10px]">
                    <div className="flex flex-col gap-[10px]">
                      <p className="text-large-clash">{value?.contestName}</p>
                      <p className="text_Ell text-smallest text-[#787878]">
                        {value?.description}
                      </p>
                    </div>
                    <div className="flex flex-wrap gap-[10px] justify-between items-center ">
                      <div className="flex gap-[5px] items-center">
                        <img
                          onClick={() => handleRemoveOtherContest(value)}
                          src={minus}
                          className={styles.plusOrMinusButton}
                        />
                        <p className="border-[1px] border-[#E8E8E8]  py-[4px] px-[8px] md:px-[12px] text-small md:text-regular-medium">
                          {cartMap.get(value?._id)?.count
                            ? cartMap?.get(value?._id)?.count
                            : 0}
                        </p>
                        <img
                          onClick={() => handleAddtoMyCampaign(value)}
                          src={plus}
                          className={styles.plusOrMinusButton}
                        />
                      </div>

                      <button
                        onClick={() => handleRemoveFromCart(value)}
                        className="px-[16px] py-[6px] text-[#F93636] border-[#F93636] border-[1px] rounded-[54px] text-small cursor-pointer md:hover:bg-[#F93636] md:hover:text-[#ffffff] transition-colors duration-300 ease-in "
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {count > 0 && <CartBanner onClick={handleProceed} count={count} />}
      </div>
    </div>
  );
}
