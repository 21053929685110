import React, { useContext, useState } from "react";
import styles from "./ViewRegistrationDetails.module.css";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import Close from "../../Assets/close.svg";
import AlertPopup from "../AlertPopups/AlertPopup";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { isEmpty } from "lodash";
import ReactPlayer from "react-player";

export default function ViewRegistrationDetails({
  setpopUp,
  popUp,
  popupData,
  selectedCampaign,
}) {
  const [showApprove, setShowApprove] = useState(false);
  const [showDeny, setShowDeny] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [qrResendLoading, setQrResedLoading] = useState(false);
  const [showQrResend, setShowQrResend] = useState(false);
  const [reason, setReason] = useState("");
  const { userIdToken, setUpdateData, setNotAdmin, setSuccessMessage } =
    useContext(Context);
  const { currentUser } = useUserAuth();

  const tokenData = userIdToken || currentUser?.accessToken;

  const handleShowApprove = () => {
    setShowApprove((prev) => !prev);
  };
  const handleShowDeny = () => {
    setShowDeny((prev) => !prev);
  };
  const handleShowEdit = () => {
    setShowEdit((prev) => !prev);
  };
  const handleShowReminder = () => {
    setShowReminder((prev) => !prev);
  };
  const handleShowResendQr = () => {
    setShowQrResend((prev) => !prev);
  };
  const handleResendEntryPass = async () => {
    try {
      setQrResedLoading(true);
      setShowQrResend(false);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_RESEND_QR_MAIL}/${popupData?.orgId}/${popupData?.promotionId}/${popupData?.regId}?promotion=${popupData?.promotion}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setShowQrResend(false);
          return;
        }
        setShowQrResend(false);
        setpopUp(false);
        setSuccessMessage("Sent Entry Pass");
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setShowQrResend(false);
      console.log(err.message);
      setpopUp(false);
    }
  };
  const hadleApprove = async () => {
    try {
      setApproveLoading(true);
      setShowApprove(false);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_APPROVE_REGISTRATION}/${popupData?.orgId}/${popupData?.promotionId}/${popupData?._id}?promotion=${popupData?.promotion}&regType=${popupData?.regType}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setApproveLoading(false);
          return;
        }
        setApproveLoading(false);
        setpopUp(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setApproveLoading(false);
      console.log(err.message);
      setpopUp(false);
    }
  };

  const handleDeny = async (type) => {
    try {
      setDenyLoading(true);
      setReminderLoading(true);
      setShowDeny(false);
      setShowReminder(false);
      setShowEdit(false);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_REJECT_REGISTRATION}/${popupData?.orgId}/${popupData?.promotionId}/${popupData?.regId}?promotion=${popupData?.promotion}&type=${type}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { reason },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setDenyLoading(false);
          setReminderLoading(true);
          return;
        }
        setDenyLoading(false);
        if (type == "RETRY_PAYMENT") {
          setSuccessMessage("Sent Mail");
        }
        if (type == "EDIT_REQUEST") {
          setSuccessMessage("Sent Edit Request");
        }
        setReminderLoading(true);

        setpopUp(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setDenyLoading(false);
      setReminderLoading(true);

      console.log(err.message);
      setpopUp(false);
    }
  };
  const approveValues = {
    Icon: "",
    Heading: "Approve Registration",
    Para: "Are you sure you want to approve this registration? ",
    Button1: "Cancel",
    Button2: "Approve",
    Button2Color: "#06B818",
  };
  const denyValues = {
    Icon: "",
    Heading: "Deny Registration",
    Para: "Are you sure you want to deny this registration? ",
    Button1: "Cancel",
    Button2: "Deny",
    Button2Color: "#eb4d0b",
  };
  const editValues = {
    Icon: "",
    Heading: "Sent Edit Data Request ",
    Para: "Sent a request asking for editing specified data",
    Button1: "Cancel",
    Button2: "Sent",
    Button2Color: "#24a0ed",
  };
  const reminderValues = {
    Icon: "",
    Heading: "Sent Reminder",
    Para: "Are you sure you want to sent a payment reminder ",
    Button1: "Cancel",
    Button2: "Yes",
    Button2Color: "#24a0ed",
  };
  const qrResendValues = {
    Icon: "",
    Heading: "Resend Entry Pass",
    Para: "",
    Button1: "Cancel",
    Button2: "Sent",
    Button2Color: "#06B818",
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <PopupWrapper setpopUp={setpopUp} visible={popUp}>
      {showApprove && (
        <AlertPopup
          visible={showApprove}
          setpopUp={setShowApprove}
          button1Function={handleShowApprove}
          button2Function={hadleApprove}
          value={approveValues}
        />
      )}
      {showReminder && (
        <AlertPopup
          visible={showReminder}
          setpopUp={setShowReminder}
          button1Function={handleShowReminder}
          button2Function={() => handleDeny("RETRY_PAYMENT")}
          value={reminderValues}
        />
      )}
      {showDeny && (
        <AlertPopup
          visible={showDeny}
          setpopUp={setShowDeny}
          button1Function={handleShowDeny}
          button2Function={() => handleDeny("REJECTED")}
          value={denyValues}
          type="reason"
          handleReasonChange={handleReasonChange}
        />
      )}
      {showEdit && (
        <AlertPopup
          visible={showEdit}
          setpopUp={setShowEdit}
          button1Function={handleShowEdit}
          button2Function={() => handleDeny("EDIT_REQUEST")}
          value={editValues}
          type="reason"
          handleReasonChange={handleReasonChange}
        />
      )}
      {showQrResend && (
        <AlertPopup
          visible={showQrResend}
          setpopUp={setShowQrResend}
          button1Function={handleShowResendQr}
          button2Function={handleResendEntryPass}
          value={qrResendValues}
        />
      )}
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.boxHead}>
            <h4 className="px-[20px] text-large-bold">{popupData?.name}</h4>
            <img
              src={Close}
              className="p-[20px] ml-auto cursor-pointer"
              onClick={() => setpopUp(false)}
            />
          </div>
          <div className={styles?.body}>
            <div className="bg-[#f3f3f3] rounded-[20px] w-full h-full overflow-hidden">
              <img
                src={popupData?.imageUrl}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="bg-[#f3f3f3] rounded-[20px] w-full h-full overflow-auto custom-scrollbar p-[30px]">
              <table className={styles.table}>
                {popupData?.regId && (
                  <tr>
                    <td className="text-regular-bold">Registration Id</td>
                    <td className="text-regular">{popupData?.regId}</td>
                  </tr>
                )}
                {popupData?.checkInStatus && (
                  <tr>
                    <td className="text-regular-bold">
                      Checked in date & time
                    </td>
                    <td className="text-regular">
                      {Object.keys(popupData?.checkInStatus).map((item) => (
                        <div key={item}>
                          {(() => {
                            if (popupData?.checkInStatus[item].time) {
                              const dateTime = new Date(
                                popupData?.checkInStatus[item].time
                              );
                              const date = dateTime.toDateString();
                              const time = dateTime
                                .toTimeString()
                                .split(" ")[0];
                              return `${date} ${time}`;
                            } else {
                              return "";
                            }
                          })()}
                        </div>
                      ))}
                    </td>
                  </tr>
                )}
                {!isEmpty(selectedCampaign?.formAttributes) ? (
                  <>
                    {selectedCampaign?.formAttributes?.map((el) => {
                      if (el?.attrType == "prediction") {
                        if (el?.display && popupData[el?.property]) {
                          return (
                            <tr>
                              <td className="text-regular-bold">
                                {el?.title}{" "}
                              </td>
                              <td className="text-regular">
                                {popupData[el?.property]}
                              </td>
                            </tr>
                          );
                        }
                      } else {
                        return (
                          <>
                            {el?.items?.map((key) => {
                              if (popupData[key] && el?.display) {
                                if (el.attrType == "media") {
                                  return (
                                    <div className="pb-[30px]">
                                      <p className="text-regular-medium mb-[12px]">
                                        {el?.title}{" "}
                                      </p>
                                      {el?.type?.startsWith("image") && (
                                        <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px] rounded-[5px] bg-[#1c1c1c] px-[15px] py-[18px]">
                                          <img
                                            src={popupData[key]}
                                            className="w-auto h-[100%]"
                                          />
                                        </div>
                                      )}
                                      {el?.type?.startsWith("video") && (
                                        <div className="h-[320px] w-[100%] overflow-hidden flex items-center justify-center mb-[15px] bg-[#1c1c1c]">
                                          <ReactPlayer
                                            playing={false}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            url={popupData[key]}
                                            style={{ objectFit: "contain" }}
                                            playsinline
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <tr>
                                      <td className="text-regular-bold">
                                        {el?.title}{" "}
                                      </td>
                                      <td className="text-regular">
                                        {popupData[key]}
                                      </td>
                                    </tr>
                                  );
                                }
                              }
                            })}
                          </>
                        );
                      }
                    })}
                  </>
                ) : (
                  <>
                    <tr>
                      <td className="text-regular-bold">Name</td>
                      <td className="text-regular">{popupData?.name}</td>
                    </tr>
                    {popupData?.regType && (
                      <tr>
                        <td className="text-regular-bold">Registration Type</td>
                        <td className="text-regular">{popupData?.regType}</td>
                      </tr>
                    )}
                    {popupData?.wardName && (
                      <tr>
                        <td className="text-regular-bold">Ward Name</td>
                        <td className="text-regular">{popupData?.wardName}</td>
                      </tr>
                    )}
                    {popupData?.relation && (
                      <tr>
                        <td className="text-regular-bold">
                          Relation with Ward
                        </td>
                        <td className="text-regular">{popupData?.relation}</td>
                      </tr>
                    )}
                    {popupData?.collegeName && (
                      <tr>
                        <td className="text-regular-bold">College Name</td>
                        <td className="text-regular">
                          {popupData?.collegeName}
                        </td>
                      </tr>
                    )}
                    {popupData?.collegeIdNumber && (
                      <tr>
                        <td className="text-regular-bold">College Id</td>
                        <td className="text-regular">
                          {popupData?.collegeIdNumber}
                        </td>
                      </tr>
                    )}
                    {popupData?.collegeIdImage && (
                      <tr>
                        <td className="text-regular-bold capitalize">
                          College Id Image
                        </td>
                        <td className="text-regular">
                          <div className="bg-[#f3f3f3] rounded-[20px] w-full h-[250px] overflow-hidden">
                            <img
                              src={popupData?.collegeIdImage}
                              className="w-full h-full object-contain"
                            />
                          </div>
                        </td>
                      </tr>
                    )}

                    {popupData?.idProofNumber && (
                      <tr>
                        <td className="text-regular-bold capitalize">
                          Aadhaar Number
                        </td>
                        <td className="text-regular">
                          {popupData?.idProofNumber}
                        </td>
                      </tr>
                    )}
                    {popupData?.idProofImage && (
                      <tr>
                        <td className="text-regular-bold capitalize">
                          Aadhaar Image
                        </td>
                        <td className="text-regular">
                          <div className="bg-[#f3f3f3] rounded-[20px] w-full h-[250px] overflow-hidden">
                            <img
                              src={popupData?.idProofImage}
                              className="w-full h-full object-contain"
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                    {popupData?.userEmail && (
                      <tr>
                        <td className="text-regular-bold">Contact Email</td>
                        <td className="text-regular">{popupData?.userEmail}</td>
                      </tr>
                    )}
                    {popupData?.phone && (
                      <tr>
                        <td className="text-regular-bold">Contact Number</td>
                        <td className="text-regular">{popupData?.phone}</td>
                      </tr>
                    )}
                  </>
                )}

                {popupData?.status && (
                  <tr>
                    <td className="text-regular-bold">Status</td>
                    <td className="text-regular">
                      {popupData?.status == "CREATED" && "Pending Approval"}
                      {popupData?.status == "APPROVED" && "Approved"}
                    </td>
                  </tr>
                )}
                {popupData?.paymentStatus && (
                  <tr>
                    <td className="text-regular-bold">Payment Status</td>
                    <td className="text-regular">{popupData?.paymentStatus}</td>
                  </tr>
                )}
                {popupData?.status == "APPROVED" && (
                  <tr>
                    <td className="text-regular-bold capitalize">Entry Pass</td>
                    <td className="text-regular">
                      {popupData?.qrUrl ? (
                        <div className="bg-[#f3f3f3] rounded-[20px] w-full h-[250px] overflow-hidden">
                          <img
                            src={popupData?.qrUrl}
                            className="w-full h-full object-contain"
                          />
                        </div>
                      ) : (
                        "Error in generating Entry Pass, Please resend "
                      )}
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div className="w-full flex justify-end px-[20px] gap-[10px]">
            {(popupData?.status == "CREATED" ||
              popupData?.status == "FREE_ENTRY_CREATED") && (
              <>
                {popupData?.paymentStatus !== "PAYMENT_SUCCESS" &&
                  popupData?.status != "FREE_ENTRY_CREATED" && (
                    <button
                      onClick={handleShowReminder}
                      disabled={
                        reminderLoading || denyLoading || approveLoading
                      }
                      style={{ background: "#24a0ed" }}
                    >
                      {denyLoading ? (
                        <LoadingCircle />
                      ) : (
                        "Sent Payment Reminder"
                      )}
                    </button>
                  )}
                <button
                  onClick={handleShowEdit}
                  disabled={denyLoading || approveLoading || reminderLoading}
                  style={{ background: "#24a0ed" }}
                >
                  {denyLoading ? <LoadingCircle /> : "Sent Edit Data Request"}
                </button>
                <button
                  onClick={handleShowDeny}
                  disabled={denyLoading || approveLoading || reminderLoading}
                  style={{ background: "#eb4d0b" }}
                >
                  {denyLoading ? <LoadingCircle /> : "Decline"}
                </button>

                <button
                  onClick={handleShowApprove}
                  disabled={approveLoading || denyLoading || reminderLoading}
                >
                  {approveLoading ? <LoadingCircle /> : "Approve"}
                </button>
              </>
            )}
            {popupData?.status == "APPROVED" && (
              <button onClick={handleShowResendQr} disabled={qrResendLoading}>
                {qrResendLoading ? <LoadingCircle /> : "Resend Entry Pass"}
              </button>
            )}
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
