import React, { useContext, useState } from "react";
import styles from "./ViewPurchaseDetails.module.css";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import Close from "../../Assets/close.svg";
import AlertPopup from "../AlertPopups/AlertPopup";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

export default function ViewPurchaseDetails({ setpopUp, popUp, popupData }) {
  const [showApprove, setShowApprove] = useState(false);
  const [showDeny, setShowDeny] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [qrResendLoading, setQrResedLoading] = useState(false);
  const [showQrResend, setShowQrResend] = useState(false);
  const [reason, setReason] = useState("");
  const [showCheckin, setShowCheckin] = useState(false);
  const { userIdToken, setUpdateData, setNotAdmin, setSuccessMessage } =
    useContext(Context);
  const { currentUser } = useUserAuth();

  const tokenData = userIdToken || currentUser?.accessToken;

  const handleShowApprove = () => {
    setShowApprove((prev) => !prev);
  };
  const handleShowDeny = () => {
    setShowDeny((prev) => !prev);
  };
  const handleShowEdit = () => {
    setShowEdit((prev) => !prev);
  };
  const handleShowReminder = () => {
    setShowReminder((prev) => !prev);
  };
  const handleShowResendQr = () => {
    setShowQrResend((prev) => !prev);
  };
  const handleResendEntryPass = async () => {
    try {
      setQrResedLoading(true);
      setShowQrResend(false);
      let config1 = {
        method: "POST",
        url: `${process.env.REACT_APP_REGENERATE_PURCHASE_PDF}/${popupData?.orgId}/${popupData?.engagementId}/${popupData?.orderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setShowQrResend(false);
          return;
        }
        setShowQrResend(false);
        setpopUp(false);
        setSuccessMessage("Sent Receipt");
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setShowQrResend(false);
      console.log(err.message);
      setpopUp(false);
    }
  };
  const hadleUpdateDetails = async (body) => {
    try {
      setApproveLoading(true);
      setShowApprove(false);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_PURCHASE_DETAILS}/${popupData?.orgId}/${popupData?.engagementId}/${popupData?.orderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: body,
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setApproveLoading(false);
          return;
        }
        setApproveLoading(false);
        setpopUp(false);
        setSuccessMessage("Updated!");
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setApproveLoading(false);
      console.log(err.message);
      setpopUp(false);
    }
  };
  const handleCheckin = async (body) => {
    try {
      setApproveLoading(true);
      setShowApprove(false);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_CHANGE_PURCHASE_CHECKIN_STATUS}/${popupData?.orgId}/${popupData?.engagementId}/${popupData?.orderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: body,
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setApproveLoading(false);
          return;
        }
        setApproveLoading(false);
        setpopUp(false);
        setSuccessMessage("Updated!");
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setApproveLoading(false);
      console.log(err.message);
      setpopUp(false);
    }
  };
  const handleDeny = async (type) => {
    try {
      setDenyLoading(true);
      setReminderLoading(true);
      setShowDeny(false);
      setShowReminder(false);
      setShowEdit(false);
      let config1 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_REJECT_REGISTRATION}/${popupData?.orgId}/${popupData?.promotionId}/${popupData?.regId}?promotion=${popupData?.promotion}&type=${type}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { reason },
      };
      const setApi = await axios(config1);
      if (setApi.status === 200) {
        if (setApi?.data?.admin == false) {
          setNotAdmin(true);
          setDenyLoading(false);
          setReminderLoading(true);
          return;
        }
        setDenyLoading(false);
        if (type == "RETRY_PAYMENT") {
          setSuccessMessage("Sent Mail");
        }
        if (type == "EDIT_REQUEST") {
          setSuccessMessage("Sent Edit Request");
        }
        setReminderLoading(true);

        setpopUp(false);
        setUpdateData((prevState) => prevState + 1);
      }
    } catch (err) {
      setDenyLoading(false);
      setReminderLoading(true);

      console.log(err.message);
      setpopUp(false);
    }
  };
  const approveValues = {
    Icon: "",
    Heading: "Payment Received?",
    Para: "Are you sure you want to change payment status to received?",
    Button1: "Cancel",
    Button2: "Yes",
    Button2Color: "#06B818",
  };
  const denyValues = {
    Icon: "",
    Heading: "Payment Not Received?",
    Para: "Are you sure you want to change payment status to not received?",
    Button1: "Cancel",
    Button2: "Yes",
    Button2Color: "#eb4d0b",
  };
  const editValues = {
    Icon: "",
    Heading: "Sent Edit Data Request ",
    Para: "Sent a request asking for editing specified data",
    Button1: "Cancel",
    Button2: "Sent",
    Button2Color: "#24a0ed",
  };
  const reminderValues = {
    Icon: "",
    Heading: "Sent Reminder",
    Para: "Are you sure you want to sent a payment reminder ",
    Button1: "Cancel",
    Button2: "Yes",
    Button2Color: "#24a0ed",
  };
  const qrResendValues = {
    Icon: "",
    Heading: "Resend Receipt",
    Para: "",
    Button1: "Cancel",
    Button2: "Sent",
    Button2Color: "#06B818",
  };
  const checkinValues = {
    Icon: "",
    Heading: "Receipt Issued?",
    Para: "Are you sure you want to change status to issued?",
    Button1: "Cancel",
    Button2: "Yes",
    Button2Color: "#06B818",
  };
  const noCheckinValues = {
    Icon: "",
    Heading: "Receipt not Issued?",
    Para: "Are you sure you want to change status to not issued?",
    Button1: "Cancel",
    Button2: "Yes",
    Button2Color: "#eb4d0b",
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <PopupWrapper setpopUp={setpopUp} visible={popUp}>
      {showApprove && (
        <AlertPopup
          visible={showApprove}
          setpopUp={setShowApprove}
          button1Function={handleShowApprove}
          button2Function={() =>
            hadleUpdateDetails({
              paymentStatus: "PAYMENT_SUCCESS",
            })
          }
          value={approveValues}
        />
      )}
      {showCheckin && (
        <AlertPopup
          visible={showCheckin}
          setpopUp={setShowCheckin}
          button1Function={() => setShowCheckin(false)}
          button2Function={() =>
            handleCheckin({
              checkIn: !popupData?.checkIn,
            })
          }
          value={popupData?.checkIn ? noCheckinValues : checkinValues}
        />
      )}
      {showReminder && (
        <AlertPopup
          visible={showReminder}
          setpopUp={setShowReminder}
          button1Function={handleShowReminder}
          button2Function={() => handleDeny("RETRY_PAYMENT")}
          value={reminderValues}
        />
      )}
      {showDeny && (
        <AlertPopup
          visible={showDeny}
          setpopUp={setShowDeny}
          button1Function={handleShowDeny}
          button2Function={() =>
            hadleUpdateDetails({
              paymentStatus: "PAYMENT_PENDING",
            })
          }
          value={denyValues}
        />
      )}
      {showEdit && (
        <AlertPopup
          visible={showEdit}
          setpopUp={setShowEdit}
          button1Function={handleShowEdit}
          button2Function={() => handleDeny("EDIT_REQUEST")}
          value={editValues}
          type="reason"
          handleReasonChange={handleReasonChange}
        />
      )}
      {showQrResend && (
        <AlertPopup
          visible={showQrResend}
          setpopUp={setShowQrResend}
          button1Function={handleShowResendQr}
          button2Function={handleResendEntryPass}
          value={qrResendValues}
        />
      )}
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.boxHead}>
            <h4 className="px-[20px] text-large-bold">{popupData?.orderId}</h4>
            <img
              src={Close}
              className="p-[20px] ml-auto cursor-pointer"
              onClick={() => setpopUp(false)}
            />
          </div>
          <div className={styles?.body}>
            <div className="bg-[#f3f3f3] rounded-[20px] w-full h-full overflow-auto custom-scrollbar p-[30px]">
              <h4 className="text-large-bold mb-[20px]">Summary</h4>
              <table className={styles.table}>
                {popupData?.product_purchased?.map((product) => (
                  <>
                    {product?.count > 0 && (
                      <tr>
                        <td className="text-small">{product?.productTitle}</td>
                        <td className="text-center text-small">
                          {product?.customerDetails?.map((el) => (
                            <div className="flex gap-[5px]">
                              <p>{el?.customerName}</p>
                              <p>{el?.birthStar}</p>
                            </div>
                          ))}
                        </td>
                        <td className="text-center text-small">
                          {product?.count}
                        </td>
                        <td className="text-small">
                          ₹{product.price * product.count}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
                <tr>
                  <td style={{ padding: 0 }}>
                    <div className="seperator-black w-full" />
                  </td>
                  <td style={{ padding: 0 }}>
                    <div className="seperator-black w-full" />
                  </td>
                </tr>
                <tr>
                  <td className="text-small-bold">Total:</td>
                  <td className="text-small-bold"></td>
                  <td className="text-small-bold text-center">
                    {popupData?.totalCount}
                  </td>
                  <td className="text-small-bold">₹{popupData?.totalAmt}</td>
                </tr>
              </table>
            </div>
            <div className="bg-[#f3f3f3] rounded-[20px] w-full h-full overflow-auto custom-scrollbar p-[30px]">
              <table className={styles.table}>
                <tr>
                  <td className="text-regular-bold">Order Id</td>
                  <td className="text-regular">{popupData?.orderId}</td>
                </tr>
                {popupData?.paymentSuccessMerchantId && (
                  <tr>
                    <td className="text-regular-bold">Transaction Id</td>
                    <td className="text-regular">
                      {popupData?.paymentSuccessMerchantId}
                    </td>
                  </tr>
                )}
                {popupData?.userEmail && (
                  <tr>
                    <td className="text-regular-bold">Contact Email</td>
                    <td className="text-regular">{popupData?.userEmail}</td>
                  </tr>
                )}
                {popupData?.phone && (
                  <tr>
                    <td className="text-regular-bold">Whatsapp Number</td>
                    <td className="text-regular">{popupData?.phone}</td>
                  </tr>
                )}
                {popupData?.totalAmt && (
                  <tr>
                    <td className="text-regular-bold">Total</td>
                    <td className="text-regular">{popupData?.totalAmt}</td>
                  </tr>
                )}
                {popupData?.paymentStatus && (
                  <tr>
                    <td className="text-regular-bold">Payment Status</td>
                    <td className="text-regular">{popupData?.paymentStatus}</td>
                  </tr>
                )}
                {popupData?.actualPaymentMode && (
                  <tr>
                    <td className="text-regular-bold">Payment Mode</td>
                    <td className="text-regular">
                      {popupData?.actualPaymentMode}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="text-regular-bold">Manual Receipt Status</td>
                  <td className="text-regular">
                    {popupData?.checkIn ? "Issued" : "Not Issued"}
                  </td>
                </tr>

                {popupData?.recieptUrl && (
                  <tr>
                    <td className="text-regular-bold capitalize">Reciept</td>
                    <td className="text-regular">
                      {popupData?.recieptUrl
                        ? "Receipt generated successfully"
                        : "Error in generating Receipt, Please resend "}
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div className="w-full flex justify-end px-[20px] gap-[10px]">
            {popupData?.paymentStatus == "PAYMENT_SUCCESS" ? (
              <button
                onClick={handleShowDeny}
                disabled={denyLoading || approveLoading || reminderLoading}
                style={{ background: "#eb4d0b" }}
              >
                {denyLoading ? <LoadingCircle /> : "Change Payment Status"}
              </button>
            ) : (
              <button
                onClick={handleShowApprove}
                disabled={approveLoading || denyLoading || reminderLoading}
              >
                {approveLoading ? <LoadingCircle /> : "Change Payment Status"}
              </button>
            )}
            {!popupData?.recieptUrl && (
              <button onClick={handleShowResendQr} disabled={qrResendLoading}>
                {qrResendLoading ? <LoadingCircle /> : "Resend Receipt"}
              </button>
            )}
            <button
              onClick={() => setShowCheckin(true)}
              disabled={approveLoading || denyLoading || reminderLoading}
            >
              {approveLoading ? <LoadingCircle /> : "Change Receipt Status"}
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
