import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./SendEmail.module.css";
import Close from "../../Assets/close.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useContext } from "react";
import Context from "../../Context/Context";
import CommonRichTextEditor from "../RichtextEditor/CommonRichTextEditor";
import axios from "axios";
import AlertPopup from "../AlertPopups/AlertPopup";

export default function SendEmail({
  showPopup,
  setShowPopup,
  data,
  type,
  toAddress,
}) {
  const { userIdToken, organizationId, verifyUser, setNotAdmin } =
    useContext(Context);
  const initialValues = { toAddress: data?.email };
  const [formValues, setFormValues] = useState(initialValues);
  const [mailTemplates, setEmailTemplates] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  let orgId = data?.orgId || "idNull";
  let cmpId = data?.campaignId || "idNull";
  let cntstid = data?.contestId || "idNull";
  let videoId = data?._id || "idNull";
  useEffect(() => {
    verifyUser();
    loadEmailTemplate();
  }, []);
  const { currentUser } = useUserAuth();

  const token = userIdToken || currentUser?.accessToken;

  const handleChangeInput = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const loadEmailTemplate = async () => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviralcampaign/cmpEmailTemplates.json`
      );
      setEmailTemplates(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const handleSentMail = async () => {
    let data = formValues;
    setConfirmPopup(false);
    setLoading(true);
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_SENT_EMAIL}/${orgId}/${cmpId}/${cntstid}/${videoId}?type=${type}`,

        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      if (response?.data?.admin == false) {
        setNotAdmin(true);
        setLoading(false);
        return;
      }
      if (response?.data?.status == "success") {
        setLoading(false);
        setShowPopup(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleSubmit = () => {
    setConfirmPopup(true);
  };
  const handleClose = () => {
    setConfirmPopup(false);
  };
  const confirmValues = {
    Icon: "",
    Heading: "Sent Email",
    Para: "Are you sure you want to continue? ",
    Button1: "Cancel",
    Button2: "Sent",
    Button2Color: "#06B818",
  };

  const handleChangeTemplate = (e) => {
    const customName = data?.participantName;
    const customContestName = data?.contestName;
    const customCampaignName = data?.campaignName;
    console.log("data", data);
    let mailBodyWithCustomValues = JSON.parse(
      e.target.value
    )?.mailBody?.replace("[participantName]", customName);

    mailBodyWithCustomValues = mailBodyWithCustomValues?.replace(
      "[contestName]",
      customContestName
    );
    mailBodyWithCustomValues = mailBodyWithCustomValues?.replace(
      "[campaignName]",
      customCampaignName
    );

    setFormValues((prevState) => ({
      ...prevState,
      subject: JSON.parse(e.target.value)?.subject,
      mailBody: mailBodyWithCustomValues,
    }));

    setChange((prev) => !prev);
  };
  return (
    <PopupWrapper visible={showPopup}>
      {confirmPopup && (
        <AlertPopup
          visible={confirmPopup}
          setpopUp={setConfirmPopup}
          button1Function={handleClose}
          button2Function={handleSentMail}
          value={confirmValues}
        />
      )}
      <div className={styles.parent}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Sent Email</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <form>
              <div>
                {toAddress && (
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p>To Address</p>
                    </div>
                    <input
                      type="text"
                      id="toAddress"
                      onChange={handleChangeInput}
                      value={formValues?.toAddress}
                    />
                  </div>
                )}
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <div className="flex items-center relative">
                      <p>Email Template</p>
                    </div>
                  </div>
                  <div className={styles.dropDown}>
                    <select onChange={handleChangeTemplate}>
                      <option selected disabled>
                        Select an Option
                      </option>
                      {mailTemplates?.emailTemplate?.map((menu) => (
                        <option
                          key={menu?.tempType}
                          value={JSON.stringify(menu)}
                        >
                          {menu?.tempType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <p>Subject</p>
                  </div>
                  <input
                    type="text"
                    id="subject"
                    onChange={handleChangeInput}
                    value={formValues?.subject}
                  />
                </div>

                <div className={styles.form}>
                  <div className="flex items-center relative">
                    <div className="flex items-center relative">
                      <p>Content</p>
                    </div>
                  </div>
                  <div
                    className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px]`}
                  >
                    <CommonRichTextEditor
                      setFormValues={setFormValues}
                      formValues={formValues}
                      keyToEdit="mailBody"
                      change={change}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Loading" : "Sent"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
