import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./WinnerPopup.module.css";
import Close from "../../Assets/close.svg";
import ReactPlayer from "react-player";
import Carousel from "../Carousel/Carousel";
import AudioPlayerWithoutDownload from "../AudioPlayerWithoutDownload/AudioPlayerWithoutDownload";

export default function WinnerPopup({ setpopUp, popUp, popupData }) {
  return (
    <PopupWrapper setpopUp={setpopUp} visible={popUp}>
      <div className={styles.box}>
        <div className={styles.boxHead}>
          <div className="flexio" style={{ gap: "8px" }}>
            <p>{popupData?.participantName}</p>
          </div>
          <img src={Close} alt="" onClick={() => setpopUp(false)} />
        </div>
        <div className="w-[100%]  h-[174px]">
          {!popupData?.fileType?.startsWith("audio") && (
            <Carousel items={popupData?.fileUrls} />
          )}
          {popupData?.fileType?.startsWith("audio") && (
            <div className="h-full p-[20px] ">
              <img
                src={popupData?.thumbnailUrl}
                className={`h-[80%] w-[100%] mx-auto object-contain mb-[10px]`}
              />
              <AudioPlayerWithoutDownload
                fileUrl={
                  popupData?.fileUrls &&
                  popupData?.fileUrls[0] &&
                  popupData?.fileUrls[0].fileUrl
                }
                fileName="my voice recording"
              />
            </div>
          )}
        </div>
      </div>
    </PopupWrapper>
  );
}
