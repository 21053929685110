import { useContext, useEffect, useState } from "react";
import Context from "../../Context/Context";
import axios from "axios";
import styles from "./EventPage.module.css";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";
import { Link } from "react-router-dom";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";

export default function EventPage() {
  const { organizationId, token, setUpdateData, updateData } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (organizationId) {
      getEvents();
    }
  }, [organizationId, updateData]);
  const getEvents = async () => {
    setLoading(true);
    try {
      let config1 = {
        method: "get",
        url: `${process.env.REACT_APP_LIST_EVENTS}/${organizationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };
  const handleRefresh = () => {
    setUpdateData((prevState) => prevState + 1);
  };

  return (
    <div className="p-[20px]">
      <div className="flex items-center w-[100%] mb-[30px]">
        <p className="fifaHead">Events</p>
        <div className="flex gap-4 items-center ml-[auto]">
          <RefreshButton handleRefresh={handleRefresh} />
        </div>
      </div>
      {data?.map((obj) => (
        <Link to={`/events/${obj?._id}`}>
          <div className={styles.card}>
            <h3>{obj?.eventName}</h3>
            <button>View event</button>
          </div>
        </Link>
      ))}
      {loading && <LoadingAnimation />}
    </div>
  );
}
