import { ref, uploadBytesResumable } from "firebase/storage";
import { influencerStorage, organizerStorage } from "../store/firebase-config";

export const singleFileUpload = async (file, storagePath) => {
  try {
    let randomId = Math.floor(Date.now() * Math.random());
    let filePath = `${storagePath}${randomId}`;
    const storageRef = ref(organizerStorage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask;

    // const url = await getDownloadURL(uploadTask.snapshot.ref);
    const data = "https://storage.googleapis.com/organizer_medias" + filePath;

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const singleFileUploadWithConfig = async (file, storagePath) => {
  try {
    let randomId = Math.floor(Date.now() * Math.random());
    let filePath = `${storagePath}${randomId}`;
    const storageRef = ref(organizerStorage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask;

    const data = {
      fileType: file.type,
      fileUrl: "https://storage.googleapis.com/organizer_medias" + filePath,
    };

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const singleFileUploadWithBucket = async (
  bucketRef,
  bucketName,
  file,
  storagePath
) => {
  try {
    let randomId = Math.floor(Date.now() * Math.random());
    const fileName = `${file?.name?.replace(/ /g, "")}`;
    let filePath = `${storagePath}${randomId}_${fileName}`;
    const storageRef = ref(bucketRef, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask;

    const data = {
      fileType: file.type,
      fileUrl: `https://storage.googleapis.com/${bucketName}${filePath}`,
    };

    return data;
  } catch (error) {
    console.error(error);
  }
};
export const singleFileUploadWithoutName = async (file, storagePath) => {
  try {
    let filePath = `${storagePath}`;
    const storageRef = ref(organizerStorage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask;

    const data = {
      fileType: file.type,
      fileUrl: "https://storage.googleapis.com/organizer_medias" + filePath,
    };

    return data;
  } catch (error) {
    console.error(error);
  }
};
export const singleFileUploadInf = async (file, storagePath) => {
  try {
    let randomId = Math.floor(Date.now() * Math.random());
    let filePath = `${storagePath}${randomId}`;
    const storageRef = ref(influencerStorage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask;

    // const url = await getDownloadURL(uploadTask.snapshot.ref);
    const data = "https://storage.googleapis.com/goviralinfluencers" + filePath;

    return data;
  } catch (error) {
    console.error(error);
  }
};
