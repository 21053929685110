import React from "react";
import styles from "./HomeSidebar.module.css";
import CampaignIcon from "../../Assets/campaign.svg";
import LeadIcon from "../../Assets/leads.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";

function HomeSidebar({ LeftMenu }) {
  const navigate = useNavigate();
  const { closeNav, leftSidebarRef, setCampign, setCampaignPageNo } =
    useContext(Context);
  const path = window.location.pathname;
  // const handleSideFilterClick = (sideFilterName) => {
  //   localStorage.setItem("sideFilterName", sideFilterName);
  //   setSideFilter(sideFilterName);
  // };
  const getMenuItemClassName = (menuLink) => {
    if (
      (menuLink === "/" && path !== "/") ||
      (menuLink === "/inf" && path !== "/inf") ||
      (menuLink === "/sp" && path !== "/sp")
    ) {
      return styles.oneMenu;
    } else if (path === "/" || path === "/inf" || path === "/sp") {
      return menuLink === "/" || menuLink === "/inf" || menuLink === "/sp"
        ? styles.oneMenuActive
        : styles.oneMenu;
    } else {
      return path.startsWith(menuLink) ? styles.oneMenuActive : styles.oneMenu;
    }
  };
  const getSubMenuItemClassName = (menuLink) => {
    if (menuLink === "/" && path !== "/") {
      return styles.withSubMenu;
    } else if (path === "/") {
      return menuLink === "/" ? styles.withSubmenuActive : styles.withSubMenu;
    } else {
      return path.startsWith(menuLink)
        ? styles.withSubmenuActive
        : styles.withSubMenu;
    }
  };

  return (
    <div
      id="mySidebar "
      class={`${styles.sidebar} left-sidebar`}
      ref={leftSidebarRef}
    >
      {/* <a href="javascript:void(0)" class="closebtn" onclick={()=>closeNav()}></a> */}
      <div className={`custom-scrollbar ${styles.fixedDivSidebar}`}>
        {/* <div
          className={`w-[100%] flex justify-end px-[16px] ${styles.closebutton}`}
        >
          <div onClick={closeNav} className={`text-[48px] text-right`}>
            ×
          </div>
        </div> */}
        {LeftMenu?.menu.map((obj, i) => {
          if (obj?.subMenu?.length > 0) {
            return (
              <div className={styles.withSubMenu}>
                <div className="flex gap-[15px]">
                  <img className={styles.icon} src={obj?.icon} alt="" />
                  <p>{obj?.menuName}</p>
                </div>
                <ui>
                  {obj?.subMenu.map((el) => {
                    return (
                      <li
                        onClick={() => {
                          navigate(el?.path);
                        }}
                        className={getSubMenuItemClassName(el?.path)}
                      >
                        {el.menuName}{" "}
                      </li>
                    );
                  })}
                </ui>
              </div>
            );
          } else {
            return (
              <div
                className={getMenuItemClassName(obj?.path)}
                onClick={() => {
                  setCampign([]);
                  setCampaignPageNo(1);

                  navigate(obj?.path);
                }}
              >
                <img className={styles.icon} src={obj?.icon} alt="" />

                <p>{obj?.menuName}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default HomeSidebar;
