import React, { useContext, useEffect, useState } from "react";
import PromotorsAnalytics from "../../Components/Analytics/PromotorsAnalytics";
import AdsAnalytics from "../../Components/Analytics/AdsAnalytics";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import Context from "../../Context/Context";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import { useUserAuth } from "../../Context/UserAuthContext";
import styles from "./styles/SponsorLeadsPage.module.css";
import axios from "axios";
import Insight from "../../Components/RightPanel/Insight";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";
import LeadsListingSponsor from "../../Components/Leads/LeadsListingSponsor";
import Pagination from "../../Components/Pagination/Pagination";

export default function SponsorLeadsPage() {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userIdToken, verifyUser, setUpdateData, updateData } =
    useContext(Context);
  const [leadsData, setLeadsData] = useState([]);
  const [totalLeads, setTotalLeads] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [sorting, setSorting] = useState("notCreatedAt ");
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignDataList();
  }, []);
  useEffect(() => {
    if (selectedCampaign)
      getLeadsData(
        selectedCampaign?.orgId,
        selectedCampaign?.campaignId,
        false
      );
  }, [sorting, pageNumber, updateData]);
  const getCampaignDataList = async () => {
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SPONSOR_CAMPAIGNS}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data?.campaigns;
      setCampaignList(campgnData);
      getLeadsData(campgnData?.orgId, campgnData?.campaignId, false);
      setSelectedCampaign(campgnData[0]);

      return campgnData;
    } catch (err) {
      console.log(err.message);
    }
  };
  const getLeadsData = async (orgId, cmpId, promotion) => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_LEADS_DATA}/${orgId}/${cmpId}?page=${pageNumber}&limit=10&sort=${sorting}&promotion=${promotion}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setLeadsData(campgnData);
      setTotalLeads(setApi?.data?.totalCount);
      setLoading(false);
      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsData([]);
      setLoading(false);
    }
  };
  const handleSearch = (el) => {
    setSelectedCampaign(el);
    getLeadsData(el?.orgId, el?.campaignId, false);
  };
  const handleRefresh = () => {
    setUpdateData((prevState) => prevState + 1);
  };
  return (
    <>
      <div className="flex justify-end w-full p-[20px] ">
        <RefreshButton handleRefresh={handleRefresh} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className="p-[10px]">
          <div
            className={styles.dropDown}
            onClick={() => {
              setShowDropDown((prevState) => !prevState);
            }}
          >
            <h2>{selectedCampaign?.campaignName}</h2>

            <img src={leadsDropDown} alt="" />
          </div>

          {showDropDown && (
            <div className={styles.dropDownPopup}>
              <>
                <h4>Campaigns</h4>
                {campaignList?.map((el, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleSearch(el);
                        setShowDropDown(false);
                      }}
                    >
                      {el?.campaignName}
                    </p>
                  );
                })}
              </>
            </div>
          )}
          <LeadsListingSponsor
            leadsData={leadsData}
            leadsDataLoading={loading}
          />
          <div className="p-[20px]">
            <Pagination
              totalData={totalLeads}
              pageLimit="10"
              setPageNumber={setPageNumber}
              currentPage={pageNumber}
            />
          </div>
        </div>
      )}
    </>
  );
}
