import React from "react";
import styles from "./CouponPage.module.css";
import Plus from "../../Assets/plus.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import { isEmpty } from "lodash";
import CreateCoupon from "../../Components/CreateCoupon/CreateCoupon";

export default function CouponPage() {
  const navigate = useNavigate();
  const [adsData, setAdsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { organizationId, userIdToken, updateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    if (organizationId) getAdsData();
  }, [organizationId, updateData]);
  const handleAddClick = () => {
    setShowPopup(true);
  };
  const getAdsData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_COUPONS}/${organizationId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setAdsData(setApi?.data?.data?.discountCoupons);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return (
    <div className="p-[20px]">
      {showPopup && (
        <CreateCoupon showPopup={showPopup} setShowPopup={setShowPopup} />
      )}
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Coupons</p>
        <div className="flex gap-4 items-center ml-[auto]">
          <div className={styles.addNew} onClick={handleAddClick}>
            <img src={Plus} alt="" />
            <p className={styles.new}>Add New</p>
          </div>
        </div>
      </div>
      <div>
        {!loading && isEmpty(adsData) && (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        )}
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className={styles.promotionListGrid}>
            {adsData?.map((obj) => {
              return (
                <div className={styles.entry}>
                  <img src={obj?.bgImage} />
                  <p>{obj?.discountName}</p>
                  <p>{obj?.couponCode}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
