import axios from "axios";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Leads from "../../Components/Leads/Leads";
import Pagination from "../../Components/Pagination/Pagination";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";
import { Link } from "react-router-dom";
import DownloadDataPopup from "../../Components/DownloadDataPopup/DownloadDataPopup";
import Srch from "../../Assets/srch.svg";
let cancelTokenSource = null;
function PromotionRegisterPage() {
  const {
    orgDomain,
    verifyUser,
    userIdToken,
    orgDomainLoading,
    updateData,
    setUpdateData,
    setNotAdmin,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [leadsData, setLeadsData] = useState([]);
  const [mainDataLoading, setMainDataLoading] = useState(false);
  const [leadsCampaignData, setLeadsCampaignData] = useState([]);
  const [leadsPromotionData, setLeadsPromotionData] = useState([]);
  const [leadsType, setLeadsType] = useState("promotion");
  const [leadsDataLoading, setLeadsDataLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [sorting, setSorting] = useState("notCreatedAt");
  const [filter, setFilter] = useState("PAYMENT_SUCCESS");
  const tokenData = userIdToken || currentUser?.accessToken;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLeads, setTotalLeads] = useState();
  const [download, setDownload] = useState(false);
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    if (!isEmpty(orgDomain)) {
      getPromotionDataForLeads();
    }
  }, [orgDomain]);

  const getPromotionDataForLeads = async () => {
    try {
      setMainDataLoading(true);

      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_PROMOTION_DATA_FOR_LEADS}/${orgDomain}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const promotionData = setApi?.data?.data;
      setLeadsPromotionData(promotionData);
      getLeadsData(
        promotionData[0]?.organizationId,
        promotionData[0]?._id,
        true
      );
      setSelectedCampaign(promotionData[0]);
      setMainDataLoading(false);
      return promotionData;
    } catch (err) {
      setMainDataLoading(false);
      console.log(err.message);
    }
  };

  const getLeadsData = async (orgId, cmpId, promotion) => {
    try {
      setLeadsDataLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_REGISTRATION_DATA}/${orgId}/${cmpId}?page=${pageNumber}&limit=10&promotion=${promotion}&type=${filter}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      if (setApi?.data?.admin == false) {
        setNotAdmin(true);
        setLeadsDataLoading(false);
        return;
      }
      const campgnData = setApi?.data?.data;
      setLeadsData(campgnData);
      setTotalLeads(setApi?.data?.totalCount);
      setLeadsDataLoading(false);
      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsData([]);
      setLeadsDataLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCampaign)
      getLeadsData(
        selectedCampaign?.organizationId,
        selectedCampaign?._id,
        true
      );
  }, [sorting, filter, pageNumber, updateData]);

  const handleLeadsSearch = (obj) => {
    setSelectedCampaign(obj);
    setLeadsType("promotion");
    getLeadsData(obj?.organizationId, obj?._id, true);
  };

  const handleRefresh = () => {
    setUpdateData((prevState) => prevState + 1);
  };

  const handleSearch = async (value) => {
    if (cancelTokenSource !== null) {
      cancelTokenSource.cancel("Request canceled due to button click.");
    }
    if (value?.replace(/\s/g, "") == "") {
      handleRefresh();
      return;
    }
    try {
      setLeadsDataLoading(true);
      cancelTokenSource = axios.CancelToken.source();
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_SEARCH_REG_DETAILS}/${selectedCampaign?.organizationId}/${selectedCampaign?._id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          searchKey: value,
        },
        cancelToken: cancelTokenSource.token,
      };
      const response = await axios(config);
      setLeadsData(response?.data?.data);
      setTotalLeads();
      if (response?.data?.status == "success") {
        setLeadsDataLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.log("Error:", error.message);
        setLeadsDataLoading(false);
      }
    }
  };

  return (
    <>
      {download && (
        <DownloadDataPopup
          visible={download}
          setpopUp={setDownload}
          api={`${process.env.REACT_APP_DOWNLOAD_REG_DETAILS}/${selectedCampaign?.organizationId}/${selectedCampaign?._id}`}
        />
      )}
      <div className="flex justify-end w-full pt-[20px] px-[20px] gap-[10px]">
        {selectedCampaign?.organizationId && selectedCampaign?._id && (
          <>
            <div className="searchBox">
              <img src={Srch} alt="" />
              <input
                className="w-[100%]"
                style={{ backgroundColor: "transparent" }}
                type="text"
                placeholder="Search Name or Registration Id"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <button
              className="secondry-button"
              onClick={() => setDownload(true)}
            >
              Download
            </button>
            <Link
              to={`/register/promotions/referals?orgId=${selectedCampaign?.organizationId}&proId=${selectedCampaign?._id}`}
            >
              <button className="secondry-button"> Referrals</button>
            </Link>
          </>
        )}
        <RefreshButton handleRefresh={handleRefresh} />
      </div>
      <Leads
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        leadsCampaignData={leadsCampaignData}
        leadsPromotionData={leadsPromotionData}
        handleLeadsSearch={handleLeadsSearch}
        leadsDataLoading={leadsDataLoading}
        orgDomainLoading={orgDomainLoading}
        mainDataLoading={mainDataLoading}
        leadsData={leadsData}
        setSorting={setSorting}
        sorting={sorting}
        filter={filter}
        setFilter={setFilter}
        leadsType={leadsType}
        leadType="REGISTER"
      />
      <div className="p-[20px]">
        {totalLeads && (
          <Pagination
            totalData={totalLeads}
            pageLimit="10"
            setPageNumber={setPageNumber}
            currentPage={pageNumber}
          />
        )}
      </div>
    </>
  );
}

export default PromotionRegisterPage;
