import React, { useContext, useState } from "react";
import styles from "./SponsorProfileDetails.module.css";
import DynamicInput from "../DynamicInput/DynamicInput";
import inputJson from "../../Json/EditProfile.json";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";

export default function SponsorProfileDetails({
  data,
  handleChange,
  handleChooseFile,
}) {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userIdToken } = useContext(Context);
  const { currentUser } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  const handleSave = async () => {
    try {
      setLoading(true);
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_SP_PROFILE}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios(config);
      setEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <h3 className={styles.heading}>
        Sponsor Details
        {!edit && (
          <button className={styles.button} onClick={() => setEdit(true)}>
            Edit
          </button>
        )}
      </h3>

      {inputJson?.sp?.map((field) => {
        return (
          <DynamicInput
            handleChange={handleChange}
            handleChooseFile={handleChooseFile}
            value={data[field?.id]}
            field={field}
            readOnly={!edit}
          />
        );
      })}
      {edit && (
        <div className="w-full flex justify-end">
          <button
            className={styles.button}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving" : "Save"}
          </button>
        </div>
      )}
    </>
  );
}
