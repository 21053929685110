import React from "react";
import styles from "./MyBrandPage.module.css";
import Plus from "../../Assets/plus.svg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import { isEmpty } from "lodash";
import filterJson from "../../Json/myBrandFilter.json";
import CreateBrand from "../../Components/CreateBrand/CreateBrand";
import AcceptBrandCollab from "../../Components/AcceptBrandCollab/AcceptBrandCollab";

export default function MyBrandPage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [brandData, setBrandData] = useState({});
  const [brandPopup, setBrandPopup] = useState(false);

  const { organizationId, userIdToken, updateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [status, setStatus] = useState("MY_BRANDS");

  useEffect(() => {
    if (organizationId) {
      getData();
    }
  }, [organizationId, updateData, status]);
  const handleAddClick = () => {
    setShowPopup(true);
  };
  const getData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SELF_BRANDS}?type=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const handleOpenBrand = (obj) => {
    setBrandData(obj);
    setBrandPopup(true);
  };
  return (
    <div className="p-[20px]">
      {showPopup && (
        <CreateBrand setCreateBrand={setShowPopup} creationType="SELF" />
      )}
      {brandPopup && (
        <AcceptBrandCollab setPopup={setBrandPopup} data={brandData} />
      )}

      <div className="flex items-center w-[100%]">
        <p className="fifaHead">My Brands</p>
        <div className="flex gap-4 items-center ml-[auto]">
          <div className={styles.addNew} onClick={handleAddClick}>
            <img src={Plus} alt="" />
            <p className={styles.new}>Add New</p>
          </div>
        </div>
      </div>
      <div className={styles.filterChips}>
        {filterJson?.options?.map((obj) => {
          return (
            <div
              onClick={() => {
                setData([]);
                setStatus(obj?.key);
              }}
              className={
                status == obj?.key ? styles.eachChips : styles.eachChipsDark
              }
            >
              <p>{obj?.optionName}</p>
            </div>
          );
        })}
      </div>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : isEmpty(data) ? (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        ) : (
          <div className={styles.promotionListGrid}>
            {data?.map((obj) => {
              return (
                <div
                  className={styles.entry}
                  onClick={() => handleOpenBrand(obj)}
                >
                  <img src={obj?.logo} />
                  <p>{obj?.name}</p>
                  {obj?.orgName && (
                    <div className="p-[10px] flex flex-col gap-[5px]">
                      Requsted by: {obj?.orgName}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
