import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

export default function CampaignPage() {
  const navigate = useNavigate();
  useEffect(() => {
    loadFilterData();
  }, []);
  const loadFilterData = async () => {
    try {
      const setdata = await axios.get(
        `https://storage.googleapis.com/campaign_templates/campaignMedia/filterChips.json`
      );
      navigate(setdata?.data?.data[0]?.json_path);
    } catch (err) {
      console.log(err.message);
    }
  };
  return <div></div>;
}
