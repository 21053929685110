import React from "react";

import styles from "./RefreshButton.module.css";

export default function RefreshButton({ handleRefresh }) {
  return (
    <button className={styles.refresh} onClick={handleRefresh}>
      Refresh
    </button>
  );
}
