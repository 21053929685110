import React from "react";
import AdsAnalytics from "./AdsAnalytics";
import { isEmpty } from "lodash";

export default function PromotorsAnalytics({ data }) {
  return (
    <div>
      {data?.map((obj) => {
        if (obj?.status == "APPROVED") {
          return (
            <div key={obj?._id}>
              <h4 className="mt-[30px]">{obj?.promotorName}</h4>
              {!isEmpty(obj?.analytics?.hits?.ads) && (
                <AdsAnalytics data={obj?.analytics} />
              )}
              <p>Likes : {obj?.likesAchieved}</p>
              {obj?.analytics?.hits?.lead?.counter && (
                <p className="mb-[20px]">
                  Action Button Hits: : {obj?.analytics?.hits?.lead?.counter}
                </p>
              )}
            </div>
          );
        }
      })}
    </div>
  );
}
