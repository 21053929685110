import React, { useContext, useEffect, useRef } from "react";
import styles from "./AccceptInfAdsRequest.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";

import { useParams } from "react-router-dom";
import Template6 from "../AdsTemplate/Template6/Template6";
import Template5 from "../AdsTemplate/Template5/Template5";
import Template4 from "../AdsTemplate/Template4/Template4";
import Template3 from "../AdsTemplate/Template3/Template3";
import Template2 from "../AdsTemplate/Template2/Template2";
import Template1 from "../AdsTemplate/Template1/Template1";
import { isEmpty } from "lodash";

export default function AccceptInfAdsRequest({ setPopup, data }) {
  const [loading, setLoading] = useState(false);
  const { promotionId } = useParams();
  const { userIdToken, setUpdateData, organizationId, campaignId } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  const handleClose = () => {
    setPopup(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let url = "";
    url = `${process.env.REACT_APP_APPROVE_ADS_ORG}/${organizationId}/${campaignId}/${data?.templateId}`;
    let config = {
      method: "PATCH",
      url: url,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    setLoading(false);
    if (response.data?.status == "success") {
      setPopup(false);
      setUpdateData((prevState) => prevState + 1);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <h2> Details</h2>
            </div>
            <img src={Close} alt="x" onClick={handleClose} />
          </div>
          <div className={styles.body}>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Inflencer Name
              </p>
              <p>{data?.name}</p>
            </div>

            {data?.ads?.map((carousel, index) => {
              return (
                <div className="relative ">
                  {isEmpty(carousel.tempType) && (
                    <Template1 carousel={carousel} />
                  )}
                  {carousel.tempType === "TEMP_2" && (
                    <Template2 carousel={carousel} />
                  )}

                  {carousel.tempType === "TEMP_3" && (
                    <Template3 carousel={carousel} />
                  )}
                  {carousel.tempType === "TEMP_4" && (
                    <Template4 carousel={carousel} />
                  )}
                  {carousel.tempType === "TEMP_5" && (
                    <Template5 carousel={carousel} />
                  )}
                  {carousel.tempType === "TEMP_6" && (
                    <Template6 carousel={carousel} />
                  )}
                </div>
              );
            })}
          </div>

          <div className={styles.buttonWrapper}>
            {data?.status == "REQUESTED" && (
              <button
                className={styles.save}
                onClick={handleSubmit}
                disabled={loading}
              >
                Accept
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
