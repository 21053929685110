import React from "react";
import { useState } from "react";
import styles from "./SelectedWinners.module.css";
import WinnerPopup from "./WinnerPopup";

export default function SelectedWinners({ data, type }) {
  const [clickedObj, setClickedObj] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const handleShowPopup = (obj) => {
    setShowPopup(true);
    setClickedObj(obj);
  };
  return (
    <div className={styles.selectedWinners}>
      {showPopup && (
        <WinnerPopup
          setpopUp={setShowPopup}
          popUp={showPopup}
          popupData={clickedObj}
        />
      )}
      <div>
        <h3 className="">{type}</h3>
        <div className={styles.winnersGrid}>
          {data?.map((obj) => {
            return (
              <div
                className={styles.entry}
                onClick={() => handleShowPopup(obj)}
              >
                <img src={obj?.thumbnailUrl} />
                <p>{obj?.participantName}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
