import styles from "./EditPosters.module.css";
import Close from "../../Assets/close.svg";
import { useContext, useEffect, useRef, useState } from "react";
import Context from "../../Context/Context";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import axios from "axios";
import DynamicInput from "../DynamicInput/DynamicInput";
import { singleFileUploadWithBucket } from "../../utils/SingleFileUpload";
import { posterStorage } from "../../store/firebase-config";
import AddBackgrounds from "./AddBackgrounds";
import { useUserAuth } from "../../Context/UserAuthContext";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import publishIcon from "../../Assets/AlertPopup/publishIcon.svg";
import AlertPopup from "../AlertPopups/AlertPopup";
import closebutton from "../../Assets/closeButton.png";
import { ref, uploadBytesResumable } from "firebase/storage";
import { isEmpty } from "lodash";

export default function EditPosters({ showEdit, setShowEdit, contestData }) {
  const { modalRef, userIdToken } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState();
  const [source, setSource] = useState({});
  const [files, setFiles] = useState({});
  const [addBg, setAddBg] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [savedChanges, setSavedChanges] = useState(false);
  const inputRef = useRef();

  const template = {
    coverImage: "",
    title: "",
    description: contestData?.description,
    posterUrl: contestData?.contestPoster,
    contestLink: `https://app.goviral.world/SEEMATTI/ModellingContest/contest_1`,
    orgId: contestData?.orgId,
    campaignId: contestData?.campaignId,
    contestId: contestData?.contestId,
    orgDomain: contestData?.orgDomain,
    campaignUniqueText: contestData?.campaignUniqueText,
    contestUniqueText: contestData?.contestUniqueText,
    participateInContest: true,
    participateWithoutShare: true,
    apis: [
      "https://us-central1-tripsmagic.cloudfunctions.net/changeBackgroundImage",
      "https://asia-south1-tripsmagic.cloudfunctions.net/changeBackgroundImageVersion2",
      "https://asia-south1-tripsmagic.cloudfunctions.net/changeBackgroundImageVersion3",
    ],
    contests: [],
    backgrounds: [],
    loadingDatas: {
      slideImages: [],
      song: "",
    },
  };
  const [formData, setFormData] = useState({});
  useEffect(() => {
    getPosterDetails();
  }, []);
  const getPosterDetails = async () => {
    try {
      let config = {
        method: "GET",
        url: `https://storage.googleapis.com/goviral_posters/${contestData?.posterContest}/data.json`,
      };
      const response = await axios(config);
      setFormData(response?.data);
      setSelectedIcon(
        response?.data?.events[0] && response?.data?.events[0].key
      );
      setSource(response?.data);
    } catch (error) {
      console.log(error?.message);
    }
  };
  const createRandomId = () => {
    return Math.random().toString(36).substring(2);
  };
  const handleAddNewICon = (e) => {
    setFormData((prevState) => {
      let eventArray = prevState?.events || [];
      let key = createRandomId();
      let defaultIcon = prevState?.default || key;
      setSelectedIcon(key);
      let eventObj = { icon: "", key };
      eventArray?.push(eventObj);
      return {
        ...prevState,
        default: defaultIcon,
        events: eventArray,
        [key]: template,
      };
    });
  };
  const handleChoose = async () => {
    inputRef.current.click();
  };

  const handleMediaSelect = async (event) => {
    const fileList = {
      ...files,
      [selectedIcon]: [...(files[selectedIcon] || []), ...event.target.files],
    };
    setFiles(fileList);

    setSource((prevState) => {
      return {
        ...prevState,
        [selectedIcon]: {
          loadingDatas: {
            slideImages: [
              ...formData[selectedIcon]?.loadingDatas?.slideImages,
              ...(fileList[selectedIcon] &&
                fileList[selectedIcon]?.map((file) =>
                  URL.createObjectURL(file)
                )),
            ],
          },
        },
      };
    });
  };
  const handleChooseFile = (id) => {
    const divElement = document.getElementById(id);
    if (divElement) {
      divElement.click();
    }
  };
  const handleChangeIcon = async (event) => {
    const key = event.target.id;
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    let uploadPath = `/${contestData?.posterContest}/medias/`;
    setLoading(true);
    let uploadFile = await singleFileUploadWithBucket(
      posterStorage,
      "goviral_posters",
      imagedata,
      uploadPath
    );
    switch (key) {
      case "icon":
        setFormData((prevState) => {
          let eventArray = prevState?.events;
          let eventIndex = prevState?.events?.findIndex(
            (el) => el?.key == selectedIcon
          );
          let eventObj = prevState?.events[eventIndex];
          eventObj.icon = uploadFile?.fileUrl;
          eventArray[eventIndex] = eventObj;
          return {
            ...prevState,
            events: eventArray,
          };
        });
        break;
      case "coverImage":
        setFormData((prevState) => {
          let key = event.target.id;
          let mainKey = event.target.dataset.customInfo;
          let bgObj = prevState[mainKey] || {};
          return {
            ...prevState,
            [mainKey]: { ...bgObj, [key]: uploadFile?.fileUrl },
          };
        });
        break;
      case "song":
        setFormData((prevState) => {
          let key = event.target.id;
          let mainKey = event.target.dataset.customInfo;
          let bgObj = prevState[mainKey] || {};
          return {
            ...prevState,
            [mainKey]: {
              ...bgObj,
              loadingDatas: {
                ...bgObj?.loadingDatas,
                song: uploadFile?.fileUrl,
              },
            },
          };
        });
        break;
    }

    setLoading(false);
  };

  const handleChangeInput = (e) => {
    setFormData((prevState) => {
      let key = e.target.id;
      let value = e.target.value;
      let mainKey = e.target.dataset.customInfo;
      let bgObj = prevState[mainKey] || {};
      return {
        ...prevState,
        [mainKey]: { ...bgObj, [key]: value },
      };
    });
  };
  const getIconValue = () => {
    const event = formData?.events?.find((event) => event?.key == selectedIcon);
    return event ? event?.icon : "";
  };

  let options = {
    icon: {
      name: "Logo",
      type: "MEDIA",
      id: "icon",
      mainKey: selectedIcon,
      accept: "image/*",
    },
    title: {
      name: "Title",
      type: "text",
      id: "title",
      mainKey: selectedIcon,
    },
    coverImage: {
      name: "Cover Image",
      type: "MEDIA",
      id: "coverImage",
      mainKey: selectedIcon,
      accept: "image/*",
    },
    song: {
      name: "Song",
      type: "MEDIA",
      id: "song",
      mainKey: selectedIcon,
      accept: "audio/*",
    },
  };
  const uploadGoviralMedias = async () => {
    setLoading(true);
    try {
      const keys = Object.keys(files);
      let uploadedFiles = {};
      for (let i = 0; i < keys?.length; i++) {
        const key = keys[i];
        const filesToUpload = files[key]; // Array of files corresponding to the current key

        for (let j = 0; j < filesToUpload?.length; j++) {
          const file = filesToUpload[j];

          // Perform operations for each file
          let randomId = Math.floor(Date.now() * Math.random());
          const folderPath = `${contestData?.posterContest}/medias/${randomId}`;
          const storageRef = ref(posterStorage, folderPath);

          const uploadTask = uploadBytesResumable(storageRef, file);

          // Wait for the upload task to complete before proceeding to the next file
          await uploadTask;

          let uploadedFile = `https://storage.googleapis.com/goviral_posters/${folderPath}`;

          uploadedFiles = {
            [key]: [...(uploadedFiles[key] || []), uploadedFile],
          };
        }
      }
      return uploadedFiles;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const handleSave = async (uplodedMedia) => {
    try {
      setLoading(true);
      let dataToUpload = {};
      if (!isEmpty(uplodedMedia)) {
        for (let i = 0; i < formData?.events?.length; i++) {
          let key = formData?.events[i] && formData?.events[i].key;
          dataToUpload = {
            ...formData,
            [key]: {
              ...formData[key],
              loadingDatas: {
                slideImages: [
                  ...(formData[key]?.loadingDatas?.slideImages || []),
                  ...(uplodedMedia[key] || []),
                ],
              },
            },
          };
        }
      } else {
        dataToUpload = formData;
      }

      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_UPDATE_POSTER_JSON}/${contestData?.posterContest}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: dataToUpload,
      };
      const response = await axios(config);
      setSavedChanges(true);
      setLoading(false);
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };
  const popUpSaveValues = {
    Icon: publishIcon,
    Heading: "Continue Editing?",
    Para: "Are you sure you want to continue editing or close the tab? ",
    Button1: "Edit Posters",
    Button2: "Close",
    Button2Color: "#06B818",
  };
  const hanldeContinueEditing = () => {
    setSavedChanges(false);
  };
  const handleCloseEditing = () => {
    setShowEdit(false);
    setSavedChanges(false);
  };
  const handleRemoveBg = (index) => {
    setFormData((prevState) => {
      let bgArray = prevState[selectedIcon]?.backgrounds;
      bgArray?.splice(index, 1);
      return {
        ...prevState,
        [selectedIcon]: {
          ...prevState[selectedIcon],
          backgrounds: bgArray,
        },
      };
    });
  };
  const handleRemoveCategory = (key, index) => {
    setFormData((prevState) => {
      let prevObj = prevState;
      let eventArray = prevObj?.events;
      eventArray?.splice(index, 1);
      delete prevObj[key];
      if (prevState?.default == key) {
        if (eventArray[0]?.key) {
          setSelectedIcon(eventArray[0]?.key);
          prevObj["default"] = eventArray[0]?.key;
        } else {
          delete prevObj["default"];
        }
      }

      return {
        ...prevObj,
        events: eventArray,
      };
    });
  };
  const handleDelete = (url) => {
    const index =
      formData[selectedIcon]?.loadingDatas?.slideImages?.indexOf(url);
    const newFileList = files;
    newFileList[selectedIcon]?.splice(index, 1);
    setFiles(newFileList);
    setFormData((prevState) => {
      let fileArray = prevState[
        selectedIcon
      ]?.loadingDatas?.slideImages?.filter((fileUrl) => fileUrl !== url);
      return {
        ...prevState,
        [selectedIcon]: {
          ...(prevState[selectedIcon] || []),
          loadingDatas: {
            slideImages: fileArray,
          },
        },
      };
    });
    setSource((prevState) => {
      let fileArray = prevState[
        selectedIcon
      ]?.loadingDatas?.slideImages?.filter((fileUrl) => fileUrl !== url);
      return {
        ...prevState,
        [selectedIcon]: {
          ...(prevState[selectedIcon] || []),
          loadingDatas: {
            slideImages: fileArray,
          },
        },
      };
    });
    URL.revokeObjectURL(url);
  };

  const handleSubmit = async () => {
    const response = await uploadGoviralMedias();
    handleSave(response);
  };
  return (
    <>
      {addBg && (
        <AddBackgrounds
          contestData={contestData}
          selectedIcon={selectedIcon}
          showPopup={addBg}
          index={editIndex}
          setShowPopup={setAddBg}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {savedChanges && (
        <AlertPopup
          visible={savedChanges}
          setpopUp={setSavedChanges}
          button1Function={hanldeContinueEditing}
          button2Function={handleCloseEditing}
          value={popUpSaveValues}
        />
      )}
      <PopupWrapper visible={showEdit}>
        <div className={styles.editCampaign} ref={modalRef}>
          <div className={styles.contentDiv}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <h2>Edit Poster Contest Details</h2>
              </div>
              <img src={Close} alt="x" onClick={() => setSavedChanges(true)} />
            </div>
            <div className={styles.body}>
              <div className={`custom-scrollbar ${styles.left}`}>
                <div className="flex items-center gap-[10px]">
                  {formData?.events?.map((obj, index) => (
                    <div
                      onClick={() => setSelectedIcon(obj?.key)}
                      className={`${styles.icon} ${
                        selectedIcon == obj?.key && styles.iconSelected
                      }`}
                    >
                      <div
                        className="z-10 flex items-center justify-center absolute right-[-10px] top-[-10px] h-[20px] w-[20px] rounded-full bg-[#363636]  cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveCategory(obj?.key, index);
                        }}
                      >
                        <img src={closebutton} alt="x" />
                      </div>
                      {obj?.icon ? (
                        <img
                          src={obj?.icon}
                          className="w-full h-full object-cover rounded-[12px]"
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ))}
                  <button className={styles.addNew} onClick={handleAddNewICon}>
                    ADD NEW
                  </button>
                </div>

                {formData[selectedIcon] && (
                  <div>
                    <DynamicInput
                      handleChange={handleChangeIcon}
                      handleChooseFile={handleChooseFile}
                      value={getIconValue()}
                      field={options?.icon}
                    />
                    <DynamicInput
                      handleChange={handleChangeInput}
                      value={formData[selectedIcon]?.title}
                      field={options?.title}
                    />
                    <DynamicInput
                      handleChange={handleChangeIcon}
                      handleChooseFile={handleChooseFile}
                      value={formData[selectedIcon]?.coverImage}
                      field={options?.coverImage}
                    />
                    <div className={styles.form}>
                      <p className="mt-[10px]">Backgrounds</p>
                      <div className="flex items-center gap-[10px]">
                        {formData[selectedIcon] &&
                          formData[selectedIcon]?.backgrounds?.map(
                            (obj, index) => (
                              <div
                                onClick={() => {
                                  setAddBg(true);
                                  setEditIndex(index);
                                }}
                                className={`${styles.icon} `}
                              >
                                <div
                                  className="z-10 flex items-center justify-center absolute right-[-10px] top-[-10px] h-[20px] w-[20px] rounded-full bg-[#363636]  cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveBg(index);
                                  }}
                                >
                                  <img src={closebutton} alt="x" />
                                </div>
                                {obj?.bgUrl ? (
                                  <img
                                    src={obj?.bgUrl}
                                    className="w-full h-full object-cover rounded-[12px]"
                                  />
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            )
                          )}
                        <button
                          className={styles.addNew}
                          onClick={() => {
                            setAddBg(true);
                            setEditIndex(-1);
                          }}
                        >
                          ADD NEW
                        </button>
                      </div>
                    </div>
                    {/* MEDIAS */}
                    <div className={styles.form}>
                      <label
                        htmlFor="media"
                        className="align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                      >
                        <input
                          ref={inputRef}
                          className="hidden"
                          type="file"
                          multiple
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="media"
                        />
                        Upload Loading Medias
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]">
                        <div className="grid grid-cols-3 gap-[18px]">
                          {source[selectedIcon] &&
                            source[
                              selectedIcon
                            ]?.loadingDatas?.slideImages?.map((obj) => {
                              return (
                                <div className="pb-[20px] w-[100%] relative h-[120px]">
                                  <div
                                    className="flex items-center justify-center absolute right-[-10px] top-[-10px] h-[20px] w-[20px] rounded-full bg-[#363636] cursor-pointer"
                                    onClick={() => handleDelete(obj)}
                                  >
                                    <img src={closebutton} alt="x" />
                                  </div>
                                  <div className="h-[100%] w-[100%] overflow-hidden flex items-start justify-center">
                                    <img
                                      src={obj}
                                      className="outline-none w-auto h-[100%] object-contain"
                                    />
                                  </div>
                                </div>
                              );
                            })}

                          <button
                            onClick={handleChoose}
                            className={styles.addNew}
                          >
                            {isEmpty(source) ? "Select" : "Add more"}
                          </button>
                        </div>
                      </div>
                    </div>
                    <DynamicInput
                      handleChange={handleChangeIcon}
                      handleChooseFile={handleChooseFile}
                      value={
                        formData[selectedIcon] &&
                        formData[selectedIcon]?.loadingDatas?.song
                      }
                      field={options?.song}
                      fileType="audio"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={loading ? "" : handleSubmit}
                disabled={loading}
              >
                {loading ? <LoadingCircle /> : "Save Changes"}
              </button>
            </div>
          </div>
        </div>
      </PopupWrapper>
    </>
  );
}
