import React, { useContext, useEffect, useState } from "react";
import Leads from "../../Components/Leads/Leads";
import Pagination from "../../Components/Pagination/Pagination";
import { useUserAuth } from "../../Context/UserAuthContext";
import Context from "../../Context/Context";
import { isEmpty } from "lodash";
import axios from "axios";

export default function InfluencerLeadsPage() {
  const { orgDomain, verifyUser, userIdToken, orgDomainLoading, updateData } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const [leadsData, setLeadsData] = useState([]);
  const [leadsCampaignData, setLeadsCampaignData] = useState([]);
  const [leadsPromotionData, setLeadsPromotionData] = useState([]);
  const [leadsType, setLeadsType] = useState("promotion");
  const [leadsDataLoading, setLeadsDataLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [sorting, setSorting] = useState("notCreatedAt");
  const tokenData = userIdToken || currentUser?.accessToken;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLeads, setTotalLeads] = useState();
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getPromotionDataForLeads();
  }, []);

  const getPromotionDataForLeads = async () => {
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_PROMOTIONS}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const promotionData = setApi?.data?.data?.promotions;
      setLeadsPromotionData(promotionData);
      getLeadsData(promotionData[0]?.promotionId);
      setSelectedCampaign(promotionData[0]);

      return promotionData;
    } catch (err) {
      console.log(err.message);
    }
  };

  const getLeadsData = async (promotionId) => {
    try {
      setLeadsDataLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_LEADS}/${promotionId}?type=PROMOTION`,
        // url: `${process.env.REACT_APP_GET_INFLUENCER_LEADS}/${promotionId}?page=${pageNumber}&limit=10&sort=${sorting}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setLeadsData(campgnData);
      setTotalLeads(setApi?.data?.totalCount);
      setLeadsDataLoading(false);
      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsData([]);
      setLeadsDataLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCampaign?.promotionId) {
      getLeadsData(selectedCampaign?.promotionId);
    }
  }, [sorting, pageNumber, updateData]);
  const handleLeadsSearch = (obj) => {
    setSelectedCampaign(obj);
    setLeadsType("promotion");
    getLeadsData(obj?.promotionId);
  };
  return (
    <>
      <Leads
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        leadsCampaignData={leadsCampaignData}
        leadsPromotionData={leadsPromotionData}
        handleLeadsSearch={handleLeadsSearch}
        leadsDataLoading={leadsDataLoading}
        orgDomainLoading={orgDomainLoading}
        leadsData={leadsData}
        setSorting={setSorting}
        sorting={sorting}
        leadsType={leadsType}
        leadType="campaign"
      />
      <div className="p-[20px]">
        <Pagination
          totalData={totalLeads}
          pageLimit="10"
          setPageNumber={setPageNumber}
          currentPage={pageNumber}
        />
      </div>
    </>
  );
}
