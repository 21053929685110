import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useUserAuth } from "./Context/UserAuthContext";

function ProtectedRoute(props) {
  const { Component } = props;
  const navigate = useNavigate();
  const { currentUser, userLoading } = useUserAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  
  useEffect(() => {
    if (!currentUser && !userLoading) {
      const paramsObject = Object.fromEntries(searchParams.entries());
      navigate(`/signup`, {
        state: {
          previousLocation: location?.pathname,
          searchParams: paramsObject,
        },
      });
    }
  });
  return <div>{!userLoading && <Component />}</div>;
}

export default ProtectedRoute;
