import React, { useEffect } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./CreateCoupon.module.css";
import Close from "../../Assets/close.svg";
import ReactPlayer from "react-player";
import axios from "axios";
import { useRef } from "react";
import { useState } from "react";
import Context from "../../Context/Context";
import { useContext } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import CreateBrand from "../CreateBrand/CreateBrand";
import { ref, uploadBytesResumable } from "firebase/storage";
import { organizerStorage } from "../../store/firebase-config";
import DynamicInput from "../DynamicInput/DynamicInput";
import inputJson from "../../Json/createCoupon.json";
import TextAdd from "../TextAdd/TextAdd";

export default function CreateCoupon({ showPopup, setShowPopup }) {
  const inputRef = useRef();
  const thumbnailRef = useRef();
  const [source, setSource] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [uploadStart, setUploadStart] = useState(false);
  const [createBrand, setCreateBrand] = useState(false);
  const [progressbar, setProgressbar] = useState();
  const [data, setData] = useState("");
  const [videoName, setVideoName] = useState();
  const [gotoLink, setGotoLink] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const { userIdToken, organizationId, updateData, setUpdateData, verifyUser } =
    useContext(Context);
  useEffect(() => {
    verifyUser();
  }, [createBrand]);
  useEffect(() => {
    getBrandData();
  }, [updateData]);
  const { currentUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  const initialValue = {
    cpnUsability: [
      {
        type: "online",
        isUsable: false,
        id: "online",
      },
      {
        type: "offline store",
        isUsable: false,
        id: "offline",
      },
    ],
  };
  const [formValues, setFormValues] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  //   ADD ENTRY

  const handleFileChange = async (event) => {
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);
    setSource(url);
    setData(file);
    setProgressbar(0);

    // setThumbnail(thumbnail);
  };

  const handleChoose = async () => {
    inputRef.current.click();
  };

  //Update ads Entry
  const patchDetails = async (banner) => {
    try {
      let config2 = {
        method: "POST",
        url: `${process.env.REACT_APP_CREATE_COUPONS}/${organizationId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { ...formValues, bgImage: banner },
      };
      const response2 = await axios(config2);

      setShowPopup(false);
      setUpdateData((prevState) => prevState + 1);
    } catch (error) {
      console.log(error.message);
    }
  };
  //Update  Entry
  console.log("formValues", formValues);

  const handleChange = (e) => {
    if (e.target.id == "couponType") {
      if (e.target.value == "SCRATCH") {
        setFormValues((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value,
          showPopUp: true,
        }));
      } else {
        setFormValues((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value,
          showPopUp: false,
        }));
      }
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    } else if (e.target.id == "cpnUsability") {
      setFormValues((prevState) => {
        let usableArray = prevState?.cpnUsability;
        let changedObjIndex = usableArray?.findIndex(
          (el) => el?.id == e.target.value
        );
        let changedObj = usableArray?.find((el) => el?.id == e.target.value);
        changedObj["isUsable"] = !changedObj?.isUsable;
        usableArray[changedObjIndex] = changedObj;
        return { ...prevState, cpnUsability: usableArray };
      });
    } else if (e.target.id == "limited") {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.id]: !prevState[e.target.id],
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    }
  };
  const handleChangeType = (e) => {
    setFormValues((prevState) => {
      let usableArray = prevState?.cpnUsability;
      let changedObjIndex = usableArray?.findIndex(
        (el) => el?.id == e.target.id
      );
      let changedObj = usableArray?.find((el) => el?.id == e.target.id);
      changedObj["link"] = e.target.value;
      usableArray[changedObjIndex] = changedObj;
      return { ...prevState, cpnUsability: usableArray };
    });
  };
  // GET BRAND DETAILS
  const getBrandData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_BRAND_REQUSTS}/${organizationId}?type=ACCEPTED`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setBrandData(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleBrandChange = (e) => {
    if (e.target.value !== "createBrand") {
      setFormValues({
        ...formValues,
        brandId: brandData[e.target.value]?.brandId,
        brandName: brandData[e.target.value]?.name,
      });
    } else {
      setCreateBrand(true);
    }
  };

  const uploadBanner = async () => {
    setLoading(true);

    try {
      const file = data;

      // add goviralMediaFiles path
      let randomId = Math.floor(Date.now() * Math.random());
      const folderPath = `/${organizationId}/coupons/${randomId}`;
      const storageRef = ref(organizerStorage, folderPath);

      const uploadTask = uploadBytesResumable(storageRef, file);

      // Wait for the upload task to complete before proceeding to the next file
      await uploadTask;

      return "https://storage.googleapis.com/organizer_medias" + folderPath;
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const hadleSubmit = async () => {
    const response = await uploadBanner();
    patchDetails(response);
  };
  const getFormValue = (field) => {
    if (field?.id == "cpnUsability") {
      return formValues?.cpnUsability?.isUsable;
    } else {
      return formValues[field?.id];
    }
  };
  const couponTypeValues = {
    name: "Coupon Type",
    type: "RADIO",
    id: "couponType",
    options: [
      {
        displayText: "Scratch Card",
        value: "SCRATCH",
      },
      {
        displayText: "Normal",
        value: "",
      },
    ],
  };

  const responseTextValues = {
    name: "Coupon Claimed response text",
    type: "text",
    id: "responseText",
  };

  return (
    <PopupWrapper visible={showPopup}>
      {createBrand && (
        <CreateBrand
          setCreateBrand={setCreateBrand}
          setFormValues={setFormValues}
          formValues={formValues}
        />
      )}
      <div className={styles.createAdd}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Create Coupons</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <div className="flex flex-col gap-[10px] mt-[20px]">
              <label
                htmlFor="participantName"
                className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
              >
                Upload Banner Image<span className="text-red-600">*</span>
              </label>
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                onChange={handleFileChange}
                // accept=".jpg,.jpeg"
              />
              <div
                className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                onClick={handleChoose}
              >
                {source ? (
                  <div>
                    <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                      <img src={source} className="w-auto h-[100%]" />
                    </div>
                    <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                      Change
                    </p>
                  </div>
                ) : (
                  "Select"
                )}
              </div>
            </div>

            <>
              {/* FORM  */}
              {/* SELECT BRAND */}
              <div className={styles.form}>
                <p>
                  Brand<span className="text-red-600">*</span>
                </p>
                <select
                  onChange={handleBrandChange}
                  value={brandData?.findIndex(
                    (el) => el?.brandId === formValues?.brandId
                  )}
                  // value={formValues?.brandId}
                >
                  <option value="">--Please choose an option--</option>
                  {brandData?.map((obj, index) => {
                    return (
                      <option key={index} value={index}>
                        {obj.name}
                      </option>
                    );
                  })}
                  <option value="createBrand">Create Brand</option>
                </select>
              </div>
              <DynamicInput
                handleChange={handleChange}
                value={getFormValue(couponTypeValues)}
                // error={formErrors[field?.id]}
                field={couponTypeValues}
              />
              {formValues?.couponType == "SCRATCH" && (
                <>
                  <DynamicInput
                    handleChange={handleChange}
                    value={getFormValue(responseTextValues)}
                    // error={formErrors[field?.id]}
                    field={responseTextValues}
                  />
                  <div className="my-[20px]">
                    <div className={styles.form}>
                      <div className="flex items-center relative">
                        <p className={``}>Discount Amounts</p>
                      </div>
                      <TextAdd
                        setData={setFormValues}
                        data={formValues?.price || []}
                        keyName="price"
                        type="number"
                        name="Random Amount"
                      />
                    </div>
                    {formErrors.endDate && (
                      <span className={styles.error}>
                        <span className="text-red-600">*</span>
                        {formErrors.endDate}
                      </span>
                    )}
                  </div>
                </>
              )}
              {inputJson?.field?.map((field) => {
                return (
                  <DynamicInput
                    handleChange={handleChange}
                    value={getFormValue(field)}
                    // error={formErrors[field?.id]}
                    field={field}
                  />
                );
              })}

              {formValues?.cpnUsability?.map((type) => {
                if (type?.isUsable) {
                  return (
                    <DynamicInput
                      handleChange={handleChangeType}
                      value={type?.link}
                      field={{
                        name: `${type?.type} Link`,
                        type: "text",
                        id: type?.id,
                      }}
                    />
                  );
                }
              })}
            </>

            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={hadleSubmit}
                disabled={loading}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
