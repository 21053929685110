import React, { useEffect, useState } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import styles from "./RichtextEditor.module.css";
import { isEmpty } from "lodash";

function CommonRichTextEditor({
  setFormValues,
  formValues,
  keyToEdit,
  change,
}) {
  const [description, setDescription] = useState({
    htmlValue: "",
    editorState: isEmpty(formValues[keyToEdit])
      ? EditorState.createEmpty()
      : EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(formValues[keyToEdit])?.contentBlocks
          )
        ),
  });
  useEffect(() => {
    setDescription({
      htmlValue: "",
      editorState: isEmpty(formValues[keyToEdit])
        ? EditorState.createEmpty()
        : EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(formValues[keyToEdit])?.contentBlocks
            )
          ),
    });
  }, [formValues?.contestId, formValues?.tempType, change]);

  const onEditorStateChange = (editorValue) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );

    setDescription({
      htmlValue: editorStateInHtml,
      editorState: editorValue,
    });

    setFormValues({
      ...formValues,
      // tandc: editorStateInHtml,
      [keyToEdit]: JSON.parse(JSON.stringify(editorStateInHtml)),
    });
  };

  return (
    <div>
      <Editor
        // toolbarOnFocus
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorState={description.editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "colorPicker",
            "emoji",
          ],
          inline: {
            classname: `${styles.inlineClass}`,
          },
        }}
      />
      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </div>
  );
}

export default CommonRichTextEditor;
