import React, { useEffect, useRef, useState } from "react";
import styles from "./PromotionEditPage.module.css";
import Close from "../../Assets/close.svg";
import { useContext } from "react";
import Context from "../../Context/Context";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import PromotionForm from "../../Components/CreatePromotion/PromotionForm";
import StatusChangerLoading from "../../Components/Loading/StatusChangerLoading";
import { singleFileUploadWithBucket } from "../../utils/SingleFileUpload";
import { promotionStorage } from "../../store/firebase-config";

export default function PromotionEditPage() {
  const inputRef = useRef();
  const inputRefMobile = useRef();
  const thumbnailRef = useRef();
  const [source, setSource] = useState();
  const [mobileSource, setMobileSource] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [uploadStart, setUploadStart] = useState(false);

  const [data, setData] = useState("");
  const [mobileData, setMobileData] = useState("");
  const [entryId, setEntryId] = useState();
  const { userIdToken, orgDomain, organizationId } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const { promotionId } = useParams();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [formValues, setFormValues] = useState({});
  const [changedValues, setChangedValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    getPromotionDetails();
  }, []);
  const getPromotionDetails = async () => {
    try {
      setLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_PROMOTION_GET_DETAILS}/${promotionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      const promoData = response?.data?.data;
      setFormValues(promoData);
      setSource(promoData?.bannerWeb || promoData?.fileUrl);
      setData({ type: promoData?.fileType });
      setMobileSource(promoData?.bannerMobile);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleFileChange = async (event) => {
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);
    setSource(url);
    setData(file);
    let uploadPath = `/${formValues?.organizationId}/${formValues?._id}/web/${formValues?.organizationId}_${formValues?._id}_`;
    setLoading(true);
    let uploadFile = await singleFileUploadWithBucket(
      promotionStorage,
      "goviral_promotions",
      file,
      uploadPath
    );
    setFormValues((prevState) => ({
      ...prevState,
      bannerWeb: uploadFile?.fileUrl,
      fileUrl: uploadFile?.fileUrl,
      fileType: uploadFile?.fileType,
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      bannerWeb: uploadFile?.fileUrl,
      fileUrl: uploadFile?.fileUrl,
      fileType: uploadFile?.fileType,
    }));
    setLoading(false);
  };
  const handleFileChangeMobile = async (event) => {
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);
    setMobileSource(url);
    setMobileData(file);
    let uploadPath = `/${formValues?.organizationId}/${formValues?._id}/otherMedias/${formValues?.organizationId}_${formValues?._id}_`;
    setLoading(true);
    let uploadFile = await singleFileUploadWithBucket(
      promotionStorage,
      "goviral_promotions",
      file,
      uploadPath
    );
    setFormValues((prevState) => ({
      ...prevState,
      bannerMobile: uploadFile?.fileUrl,
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      bannerMobile: uploadFile?.fileUrl,
    }));
    setLoading(false);
  };

  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleMobileEntryChoose = async () => {
    inputRefMobile.current.click();
  };

  //   ADD ENTRY

  //updadate thumbanil
  const handleThumbnailChange = async (event) => {};

  const handleThumbnailChoose = () => {
    thumbnailRef.current.click();
  };
  //updadate thumbanil

  //Update promotion Entry
  const patchDetails = async () => {
    try {
      setLoading(true);
      let config2 = {
        method: "patch",
        url: `${process.env.REACT_APP_PROMOTION_PATCH_DETAILS}/${promotionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: changedValues,
      };
      const response2 = await axios(config2);
      if (response2?.data?.status == "success") {
        navigate(`/promotions/list/${promotionId}`);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <div className={styles.createPromotion}>
      {loading ? (
        <StatusChangerLoading />
      ) : (
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <h2>Edit Promotion</h2>

          <div className={styles.body}>
            {/* UPLOAD ENTRY  */}
            <div className="flex flex-col gap-[10px] mt-[20px]">
              <label
                htmlFor="participantName"
                className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
              >
                Upload Your Entry (desktop version)
                <span className="text-red-600">*</span>
              </label>
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                id="desktop"
                onChange={handleFileChange}
                // accept=".jpg,.jpeg"
              />
              <div
                className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                onClick={handleChoose}
              >
                {source ? (
                  <div>
                    <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                      {data.type.startsWith("image") ? (
                        <img src={source} className="w-auto h-[100%]" />
                      ) : (
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          playing={false}
                          controls={true}
                          width="100%"
                          height="100%"
                          url={source}
                        />
                      )}
                    </div>
                    <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                      Change
                    </p>
                  </div>
                ) : (
                  "Select"
                )}
              </div>
            </div>

            {data?.type?.startsWith("image") && (
              <div className="flex flex-col gap-[10px] mt-[20px]">
                <label className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] ">
                  Upload Your Entry (Mobile version)
                  <span className="text-red-600">*</span>
                </label>
                <input
                  ref={inputRefMobile}
                  className="hidden"
                  type="file"
                  id="mobile"
                  onChange={handleFileChangeMobile}
                  // accept=".jpg,.jpeg"
                />
                <div
                  className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                  onClick={handleMobileEntryChoose}
                >
                  {mobileSource ? (
                    <div>
                      <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                        <img src={mobileSource} className="w-auto h-[100%]" />
                      </div>
                      <p className="text-[#B5B5B5] font-Inter font-[400] text-[17px] leading-[24px] ">
                        Change
                      </p>
                    </div>
                  ) : (
                    "Select"
                  )}
                </div>
              </div>
            )}

            {/* thumbanil upload  */}
            {data?.type?.startsWith("video") && (
              <div className="flex flex-col gap-[10px] my-[20px]">
                <label
                  htmlFor="thumbnailUrl"
                  className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                >
                  Upload thumbnail
                  <span className="text-red-600">*</span>
                </label>
                <input
                  ref={thumbnailRef}
                  className="hidden"
                  type="file"
                  onChange={handleThumbnailChange}
                  accept=".jpg,.jpeg,.png"
                  id="thumbnailUrl"
                />
                <div
                  className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                  onClick={handleThumbnailChoose}
                >
                  {thumbnailUrl ? (
                    <div>
                      <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                        <img src={thumbnailUrl} className="w-auto h-[100%]" />
                      </div>
                      <p className=" font-Inter font-[400] text-[17px] leading-[24px] ">
                        Change
                      </p>
                    </div>
                  ) : (
                    "Select"
                  )}
                </div>
              </div>
            )}

            {/* FORM DETAILS  */}

            <PromotionForm
              formValues={formValues}
              setFormValues={setFormValues}
              setChangedValues={setChangedValues}
              formErrors={formErrors}
              entryId={promotionId}
              tokenData={tokenData}
              organizationId={organizationId}
            />

            <div className={styles.buttonWrapper}>
              <button
                className={styles.save}
                onClick={patchDetails}
                disabled={loading}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
