import React, { useContext, useState } from "react";
import styles from "./Carousel.module.css";
import Right from "../../Assets/ryt.svg";
import Left from "../../Assets/lftw.svg";
import FullScreenIcon from "../../Assets/svg/FullScreenIcon";
import FullScreenViewer from "../FullScreenViewer/FullScreenViewer";
import ReactPlayer from "react-player";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import AlertPopup from "../AlertPopups/AlertPopup";

function Carousel({ items }) {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [compressFile, setCompressFile] = useState({ show: false });
  const [reportLoading, setReportLoading] = useState(false);
  const [successReport, setSuccessReport] = useState(false);
  const { currentUser } = useUserAuth();
  const { userIdToken } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;

  const prevItem = () => {
    setCurrentItem((prev) => (prev === 0 ? items?.length - 1 : prev - 1));
    handleLoad(currentItem, false);
  };

  const nextItem = () => {
    setCurrentItem((prev) => (prev === items?.length - 1 ? 0 : prev + 1));
    handleLoad(currentItem, false);
  };

  const isPrevDisabled = currentItem === 0;
  const isNextDisabled = currentItem === items?.length - 1;
  const handleLoad = (index, show) => {
    setCompressFile({ index, show });
  };
  const handleReport = async (file) => {
    setReportLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_COMPRESS_FILE}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { fileUrls: [file] },
      };
      const response = await axios(config);
      setReportLoading(false);
      setSuccessReport(true);
    } catch (error) {
      console.log(error.message);
      setReportLoading(false);
      //navigate("/error")
    }
  };
  const handleCloseReportPoup = () => {
    setSuccessReport(false);
    window.location.reload();
  };
  const reportValues = {
    Icon: "",
    Heading: "Request Sent Successfully",
    Para: "Your request to correct this file has been successfully sent. Please check back later for updates.",
    Button2: "OK",
    Button2Color: "#06B818",
  };
  return (
    <>
      {successReport && (
        <AlertPopup
          visible={successReport}
          setpopUp={setSuccessReport}
          button2Function={handleCloseReportPoup}
          value={reportValues}
        />
      )}
      <div className={styles.carouselContainer}>
        <div className={styles.carouselSlide}>
          {items &&
            items[currentItem]?.fileType.startsWith("image") &&
            (compressFile?.show ? (
              <div className="w-full h-full flex items-center justify-center">
                <button
                  className={styles.reportButton}
                  onClick={() => handleReport(items[currentItem])}
                  disabled={reportLoading}
                >
                  {reportLoading ? "Loading" : "Report"}
                </button>
              </div>
            ) : (
              <img
                src={items[currentItem]?.fileUrl}
                alt={`Image `}
                className={styles.active}
                onError={() => handleLoad(currentItem, true)}
                onLoad={() => handleLoad(currentItem, false)}
              />
            ))}
          {items && items[currentItem]?.fileType.startsWith("video") && (
            <ReactPlayer
              playing={false}
              controls={true}
              width="100%"
              height="100%"
              url={items[currentItem]?.fileUrl}
              style={{ objectFit: "contain" }}
            />
          )}
        </div>

        <button
          onClick={prevItem}
          disabled={isPrevDisabled}
          className={`left-0 ${styles.button}`}
        >
          <img src={Left} />
        </button>
        <button
          onClick={nextItem}
          disabled={isNextDisabled}
          className={`right-0 ${styles.button}`}
        >
          <img src={Right} />
        </button>
        <button
          className="absolute bottom-[12px] right-[20px]"
          onClick={() => setShowFullScreen(true)}
        >
          <FullScreenIcon />
        </button>
        {showFullScreen && (
          <FullScreenViewer
            setShowPopup={setShowFullScreen}
            items={items}
            index={currentItem}
          />
        )}
      </div>
    </>
  );
}

export default Carousel;
