export function addHoursAndMinutesToUTC(utcDateStr) {
  // Create a Date object from the UTC string
  const date = new Date(utcDateStr);

  // Get the timezone offset in minutes
  const timezoneOffset = date.getTimezoneOffset(); // in minutes

  // Calculate hours and minutes
  const offsetHours = Math.floor(Math.abs(timezoneOffset / 60)); // Should give 5 for -330
  const offsetMinutes = Math.abs(timezoneOffset % 60); // This will give 30

  // Add hours and minutes
  if (timezoneOffset < 0) {
    date.setHours(date.getHours() + offsetHours);
    date.setMinutes(date.getMinutes() + offsetMinutes);
  } else {
    date.setHours(date.getHours() - offsetHours);
    date.setMinutes(date.getMinutes() - offsetMinutes);
  }
  const currentTimezone = getCurrentTimezone();

  // Format the date back to the desired output
  const outputDateStr = date?.toISOString()?.replace("Z", "") + currentTimezone;
  return outputDateStr; // Output in format: YYYY-MM-DDTHH:mm±HH:mm
}

export function getCurrentTimezone() {
  // Get the current date
  const date = new Date();

  // Get the timezone offset in minutes
  const timezoneOffset = date.getTimezoneOffset(); // in minutes

  // Calculate hours and minutes
  const offsetHours = Math.floor(Math.abs(timezoneOffset / 60)); // Should give 5 for -330
  const offsetMinutes = Math.abs(timezoneOffset % 60); // This will give 30

  // Format the offset as '+HH:mm'
  const sign = timezoneOffset < 0 ? "+" : "-";
  const formattedOffset = `${sign}${String(offsetHours).padStart(
    2,
    "0"
  )}:${String(offsetMinutes).padStart(2, "0")}`;

  return formattedOffset;
}
