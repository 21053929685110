import React from "react";
import styles from "./OrganizerPage.module.css";
import Plus from "../../Assets/plus.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import CreateAdd from "../../Components/CreateAdd/CreateAdd";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import CreateSponsor from "../../Components/CreateSponsor/CreateSponsor";
import { isEmpty } from "lodash";
import EditSponser from "../../Components/EditSponser/EditSponser";

export default function OrganizerPage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const { organizationId, userIdToken, updateData } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    if (organizationId) {
      getSponsorData();
    }
  }, [organizationId, updateData]);
  const handleAddClick = () => {
    setShowPopup(true);
  };
  const getSponsorData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CAMPAIGN_GUESTS}/${organizationId}?type=organizers`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setData(setApi?.data?.data?.organizer);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleEdit = (event, data) => {
    event.stopPropagation();
    setEdit(true);
    setEditData(data);
  };
  return (
    <div className="p-[20px]">
      {showPopup && (
        <CreateSponsor
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          type="organizers"
        />
      )}
      {edit && (
        <EditSponser
          showPopup={edit}
          setShowPopup={setEdit}
          editData={editData}
          type=""
          editType="organizers"
          setSponsors={setData}
        />
      )}
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Organizers</p>
        <div className="flex gap-4 items-center ml-[auto]">
          <div className={styles.addNew} onClick={handleAddClick}>
            <img src={Plus} alt="" />
            <p className={styles.new}>Add New</p>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : isEmpty(data) ? (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        ) : (
          <div className={styles.promotionListGrid}>
            {data?.map((obj) => {
              return (
                <div className={styles.entry}>
                  <img src={obj?.logo} />
                  <p>{obj?.name}</p>
                  <div className="p-[10px] flex flex-col gap-[5px]">
                    <button
                      className={styles.editButton}
                      onClick={(e) => handleEdit(e, obj)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
