import React, { useContext, useEffect, useState } from "react";
import PromotorsAnalytics from "../../Components/Analytics/PromotorsAnalytics";
import AdsAnalytics from "../../Components/Analytics/AdsAnalytics";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import Context from "../../Context/Context";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import { useUserAuth } from "../../Context/UserAuthContext";
import styles from "./styles/InfluencerAnalyticsPage.module.css";
import axios from "axios";

export default function InfluencerAnalyticsPage() {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [promotionList, setPromotionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { orgDomain, userIdToken, verifyUser } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getPromotionDataList();
  }, []);
  const getPromotionDataList = async () => {
    setLoading(true);
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_PROMOTIONS}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const promotionData = setApi?.data?.data?.promotions;
      setPromotionList(promotionData);
      //   getLeadsData(promotionData[0]?.organizationId, promotionData[0]?.promotionId);
      getAnalyticsData(promotionData[0]?.promotionId);
      setSelectedPromotion(promotionData[0]);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const getAnalyticsData = async (promotionId) => {
    setLoading(true);

    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_ANALYTICS}/${promotionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);
      setAnalyticsData(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const handleSearch = (el) => {
    setSelectedPromotion(el);
    getAnalyticsData(el?.promotionId);
  };
  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className="p-[10px]">
          <div
            className={styles.dropDown}
            onClick={() => {
              setShowDropDown((prevState) => !prevState);
            }}
          >
            <h2>{selectedPromotion?.promotionHeader?.promotionName}</h2>

            <img src={leadsDropDown} alt="" />
          </div>

          {showDropDown && (
            <div className={styles.dropDownPopup}>
              <>
                <h4>Promotions</h4>
                {promotionList?.map((el, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleSearch(el);
                        setShowDropDown(false);
                      }}
                    >
                      {el?.promotionHeader?.promotionName}
                    </p>
                  );
                })}
              </>
            </div>
          )}

          {analyticsData && (
            <>
              <h3 className="mt-[50px]"> Promotors Analytics</h3>
              <PromotorsAnalytics data={analyticsData} />
            </>
          )}
        </div>
      )}
    </>
  );
}
