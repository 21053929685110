import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styles from "./MoreOptionDropDown.module.css";
import Union from "../../../src/Assets/Union.svg";
import ShareTube from "../../../src/Assets/sharetube.svg";

function AllEntryMoreOption({
  setShowLikes,
  setShowPrizeClaimDetails,
  data,
  setpopUp,
  setpopUpData,
  setShowFeatureDetails,
  setSendEmail,
}) {
  const [dropDown, setDropDown] = useState(false);

  const modalRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropDown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown]);
  return (
    <div
      onClick={() => {
        setDropDown(!dropDown);
      }}
      className={styles.dotInLeads}
      ref={modalRef}
    >
      <img src={Union} alt="" />
      {dropDown === true && (
        <div className={styles.bigDropme}>
          <p>More Options</p>
          <button
            className="flex gap-4 mt-[20px] mb-[24px] "
            onClick={() => {
              setpopUp((prev) => !prev);
              setpopUpData(data);
            }}
          >
            <img src={ShareTube} alt="" />
            View Entry
          </button>
          <button
            className="flex gap-4 mt-[20px] mb-[24px] "
            onClick={() => {
              setShowLikes(true);
            }}
          >
            <img src={ShareTube} alt="" />
            Likes
          </button>
          {data?.giftClaimStatus && (
            <button
              className="flex gap-4 mt-[20px] mb-[24px] "
              onClick={() => {
                setShowPrizeClaimDetails(true);
              }}
            >
              <img src={ShareTube} alt="" />
              Claim Detials
            </button>
          )}
          {data?.prizeClaimDetails?.feature && (
            <button
              className="flex gap-4 mt-[20px] mb-[24px] "
              onClick={() => {
                setShowFeatureDetails(true);
              }}
            >
              <img src={ShareTube} alt="" />
              Featuring Detials
            </button>
          )}

          <button
            className="flex gap-4 mt-[20px] mb-[24px] "
            onClick={() => {
              setSendEmail(true);
            }}
          >
            <img src={ShareTube} alt="" />
            Send Email
          </button>
        </div>
      )}
    </div>
  );
}

export default AllEntryMoreOption;
