import axios from "axios";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Leads from "../../Components/Leads/Leads";
import Pagination from "../../Components/Pagination/Pagination";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";
import { Link } from "react-router-dom";
import SortByFilter from "../../Components/Filters/SortByFilter";
import RegistrationListing from "../../Components/Leads/RegistrationListing";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import Filter from "../../Components/Filters/Filter";

function PromotionGateKeeperPage() {
  const {
    orgDomain,
    verifyUser,
    userIdToken,
    orgDomainLoading,
    updateData,
    setUpdateData,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const [leadsData, setLeadsData] = useState([]);
  const [mainDataLoading, setMainDataLoading] = useState(false);
  const [leadsCampaignData, setLeadsCampaignData] = useState([]);
  const [leadsPromotionData, setLeadsPromotionData] = useState([]);
  const [leadsType, setLeadsType] = useState("promotion");
  const [leadsDataLoading, setLeadsDataLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const currentDate = new Date(Date.now());
  const dateOnly = currentDate.toISOString().split("T")[0];
  const [date, setDate] = useState(dateOnly);
  const [filter, setFilter] = useState({
    filter: "ALL",
  });
  const [regMode, setRegMode] = useState({
    filter: "ONLINE",
  });

  const tokenData = userIdToken || currentUser?.accessToken;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLeads, setTotalLeads] = useState();
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    if (!isEmpty(orgDomain)) {
      getPromotionDataForLeads();
    }
  }, [orgDomain]);

  const getPromotionDataForLeads = async () => {
    try {
      setMainDataLoading(true);

      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_PROMOTION_DATA_FOR_LEADS}/${orgDomain}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const promotionData = setApi?.data?.data;
      setLeadsPromotionData(promotionData);
      getLeadsData(
        promotionData[0]?.organizationId,
        promotionData[0]?._id,
        true
      );
      setSelectedCampaign(promotionData[0]);
      setMainDataLoading(false);
      return promotionData;
    } catch (err) {
      setMainDataLoading(false);
      console.log(err.message);
    }
  };

  const getLeadsData = async (orgId, cmpId, promotion) => {
    try {
      setLeadsDataLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_REGISTRATION_CHECKEDIN_DATA}/${orgId}/${cmpId}?page=${pageNumber}&limit=10&promotion=${promotion}&regMode=${regMode?.filter}&type=${filter?.filter}&date=${date}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);

      const campgnData = setApi?.data?.data;
      setLeadsData(campgnData);
      setTotalLeads(setApi?.data?.totalCount);
      setLeadsDataLoading(false);
      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLeadsData([]);
      setLeadsDataLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCampaign)
      getLeadsData(
        selectedCampaign?.organizationId,
        selectedCampaign?._id,
        true
      );
  }, [date, filter, regMode, pageNumber, updateData]);
  const handleLeadsSearch = (obj) => {
    setSelectedCampaign(obj);
    setLeadsType("promotion");
    getLeadsData(obj?.organizationId, obj?._id, true);
  };
  const handleRefresh = () => {
    setUpdateData((prevState) => prevState + 1);
  };
  const sortingOption = [
    {
      optionName: "All",
      key: "ALL",
    },
    {
      optionName: "Filter By Day",
      key: "DATE_FILTER",
    },
  ];
  const regModeOption = [
    {
      optionName: "Online",
      key: "ONLINE",
    },
    {
      optionName: "Prepaid",
      key: "PREPAID_COUPON",
    },
  ];
  return (
    <>
      <div className="w-full flex items-center justify-between my-[20px] px-[10px]">
        <div className="flex gap-[10px] items-center">
          <Filter
            sorting={regMode}
            setSorting={setRegMode}
            options={regModeOption}
            setPageNumber={setPageNumber}
          />
          <Filter
            sorting={filter}
            setSorting={setFilter}
            options={sortingOption}
            setPageNumber={setPageNumber}
          />
          {filter?.filter == "DATE_FILTER" && (
            <input
              type="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          )}
        </div>
        <div className="flex items-center gap-[5px]">
          <RefreshButton handleRefresh={handleRefresh} />
        </div>
      </div>
      {(leadsDataLoading || orgDomainLoading) && <LoadingAnimation />}

      <RegistrationListing
        leadsData={leadsData}
        leadsDataLoading={leadsDataLoading}
        orgDomainLoading={orgDomainLoading}
        mainDataLoading={mainDataLoading}
        type="GATE_KEEPER"
      />
      <div className="p-[20px]">
        <Pagination
          totalData={totalLeads}
          pageLimit="10"
          setPageNumber={setPageNumber}
          currentPage={pageNumber}
        />
      </div>
    </>
  );
}

export default PromotionGateKeeperPage;
