import React, { useEffect, useState } from "react";
import closebutton from "../../Assets/closebutton.svg";
import styles from "./FullScreenViewer.module.css";

import ReactPlayer from "react-player";
import Right from "../../Assets/ryt.svg";
import Left from "../../Assets/lftw.svg";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../Assets/loading.json";

export default function FullScreenViewer({ setShowPopup, items, index }) {
  const [enter, setEnter] = useState(false);  
  useEffect(() => {
    setEnter(true);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  const [currentItem, setCurrentItem] = useState(index ? index : 0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    setIsFading(true);
    const fadeTimeout = setTimeout(() => {
      setIsFading(false);
    }, 500); // Adjust the duration to your preference (in milliseconds)
    return () => clearTimeout(fadeTimeout);
  }, [items?.[currentItem]?.fileUrl]);

  const prevItem = () => {
    setCurrentItem((prev) => (prev === 0 ? items?.length - 1 : prev - 1));
  };

  const nextItem = () => {
    setCurrentItem((prev) => (prev === items?.length - 1 ? 0 : prev + 1));
  };

  const isPrevDisabled = currentItem === 0;
  const isNextDisabled = currentItem === items?.length - 1;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (currentItem != 0) {
      setIsLoading(true);
    }
  }, [currentItem]);
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <div className={styles.container}>
      <div className={`${styles.parentDiv} ${enter ? styles.enter : ""}`}>
        <div className="flex justify-between items-center  w-[100%] px-[20px] mb-[20px]">
          <p className="text-regular-bold text-[#CDCDCD]"></p>
          <div
            onClick={handleClose}
            className="bg-[#252525] p-[12px] rounded-full"
          >
            <img
              src={closebutton}
              className=" hover:cursor-pointer w-[15px] h-[17px]"
            />
          </div>
        </div>
        <div className=" w-full h-[85%]  ">
          <div className={styles.carouselContainer}>
            <div className={styles.carouselSlide}>
              {isLoading &&
                items &&
                items[currentItem]?.fileType.startsWith("image") && (
                  <div className="w-full h-full flex items-center justify-center">
                    <Lottie
                      loop
                      animationData={loadingAnimation}
                      play
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                )}
              {items && items[currentItem]?.fileType.startsWith("image") && (
                <img
                  src={items[currentItem]?.fileUrl}
                  alt={`Image `}
                  className={`${styles.active} ${
                    isFading ? styles.fadeOut : ""
                  } `}
                  style={{
                    display: isLoading ? "none" : "block",
                  }}
                  onLoad={handleImageLoad}
                />
              )}
              {items && items[currentItem]?.fileType.startsWith("video") && (
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                    youtube: {
                      playerVars: { controls: 1 },
                    },
                  }}
                  playing={false}
                  controls={true}
                  width="100%"
                  height="100%"
                  url={items[currentItem]?.fileUrl}
                  className={`${styles.active} ${
                    isFading ? styles.fadeOut : ""
                  }`}
                />
              )}
            </div>

            <button
              onClick={prevItem}
              disabled={isPrevDisabled}
              className={`left-0 ${styles.button}`}
            >
              <img src={Left} />
            </button>
            <button
              onClick={nextItem}
              disabled={isNextDisabled}
              className={`right-0 ${styles.button}`}
            >
              <img src={Right} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
