import "./App.css";
import { UserAuthContextProvider } from "../src/Context/UserAuthContext";
import SignupPage from "../src/Pages/SignupPage";
import { Route, Routes } from "react-router-dom";
import PollCampaign from "./Components/PollContest/PollCampaign";
import PollContest from "./Components/PollContest/PollContest";
import AdvertisementsPanel from "./Components/AdvertisementsPanel/AdvertisementsPanel";
import { ContextProvider } from "./Context/Context";
import PendingDetailsPage from "./Components/PollContest/PendingDetailsPage";
import SelectAdsTemplate from "./Components/SelectAdsTemplate/SelectAdsTemplate";
import Declaration from "./Components/PollContest/Declaration";
import Luckydraw from "./Components/PollContest/Luckydraw";
import ProtectedRoute from "./ProtectedRoute";
import ContestDetailsPage from "./Pages/ContestDetailsPage/ContestDetailsPage";
import Layout from "./UI/Layout/Layout";
import LeadsPage from "./Pages/LeadsPage/LeadsPage";
import CampaignPendingDetailsPage from "./Components/PollContest/CampaignPendingDetailsPage";
import HomePage from "./Pages/HomePage/HomePage";
import PromotionPage from "./Pages/PromotionPage/PromotionPage";
import PromotionCreatePage from "./Pages/PromotionCreatePage/PromotionCreatePage";
import AdsPage from "./Pages/AdsPage/AdsPage";
import PromotionsLeadsPage from "./Pages/LeadsPage/PromotionsLeadsPage";
import PromotionAnalyticsPage from "./Pages/AnalyticsPage/PromotionAnalyticsPage";
import CampaignAnalyticsPage from "./Pages/AnalyticsPage/CampaignAnalyticsPage";
import PromotionDetailPage from "./Pages/PromotionDetailPage/PromotionDetailPage";
import SponsorPage from "./Pages/SponsorPage/SponsorPage";
import JudgesPage from "./Pages/JudgesPage/JudgesPage";
import InfluencerHomePage from "./Pages/InfluencerPages/InfluencerHomePage";
import InfluencerLeadsPage from "./Pages/InfluencerPages/InfluencerLeadsPage";
import InfluencerAnalyticsPage from "./Pages/InfluencerPages/InfluencerAnalyticsPage";
import SponsorAnalyticsPage from "./Pages/SponsorPages/SponsorAnalyticsPage";
import SponsorHomePage from "./Pages/SponsorPages/SponsorHomePage";
import AllEntriesDetailPage from "./Components/PollContest/AllEntriesDetailPage";
import OrganizerPage from "./Pages/OrganizerPage/OrganizerPage";
import SpecialGuestPage from "./Pages/SpecialGuestPage/SpecialGuestPage";
import CampaignAllEntriesDetailPage from "./Components/PollContest/CampaignAllEntriesDetailPage";
import CampaignDeclinedEntriesDetailPage from "./Components/PollContest/CampaignDeclinedEntriesDetailPage";
import ContestDeclinedEntriesDetailPage from "./Components/PollContest/ContestDeclinedEntriesDetailPage";
import ContestDeletedEntriesDetailPage from "./Components/PollContest/ContestDeletedEntriesDetailPage";
import CampaignDeletedEntriesDetailPage from "./Components/PollContest/CampaignDeletedEntriesDetailPage";
import BrandPage from "./Pages/BrandPage/BrandPage";
import MyBrandPage from "./Pages/MyBrandPage/MyBrandPage";
import SponsorRequestPage from "./Pages/SponsorRequestPage/SponsorRequestPage";
import SponsorCampaigns from "./Pages/SponsorCampaigns/SponsorCampaigns";
import InfluencerRequestPage from "./Pages/InfluencerRequestPage/InfluencerRequestPage";
import InfluencerCampaigns from "./Pages/InfluencerCampaigns/InfluencerCampaigns";
import InfluencerPromotions from "./Pages/InfluencerPromotions/InfluencerPromotions";
import InfluencerCmpAnalytics from "./Pages/InfluencerPages/InfluencerCmpAnalytics";
import CampaignListingPage from "./Pages/CamapignCreationPage/CampaignListingPage";
import CampaignListing from "./Components/CampaignListing/CampaignListing";
import CampaignPage from "./Pages/CampaignPage";
import CartPage from "./Pages/CamapignCreationPage/CartPage";
import CampaignCreatePage from "./Pages/CamapignCreationPage/CampaignCreatePage";
import CampaignCreationSuccessPage from "./Pages/CamapignCreationPage/CampaignCreationSuccessPage";
import CouponPage from "./Pages/CouponPage/CouponPage";
import InfAdsPage from "./Pages/AdsPage/InfAdsPage";
import AdsApprovePage from "./Pages/AdsApprovePage/AdsApprovePage";
import InfluencerCmpLeadsPage from "./Pages/InfluencerPages/InfluencerCmpLeadsPage";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import SupportedByPage from "./Pages/SupportedByPage/SupportedByPage";
import CampaignAccessPage from "./Pages/CampaignAccessPage/CampaignAccessPage";
import PromotionEditPage from "./Pages/PromotionEditPage/PromotionEditPage";
import RegisterPage from "./Pages/RegisterPage/RegisterPage";
import PromotionRegisterPage from "./Pages/RegisterPage/PromotionRegisterPage";
import ReferalPromotionRegisterPage from "./Pages/RegisterPage/ReferalPromotionRegisterPage";
import PromotionAccessPage from "./Pages/PromotionAccessPage/PromotionAccessPage";
import PromotionGateKeeperPage from "./Pages/GateKeeperPage/PromotionGateKeeperPage";
import SponsorPaymentPage from "./Pages/SponsorPages/SponsorPaymentPage";
import OrganizerPaymentPage from "./Pages/OrganizerPaymentPage";
import SponsorLeadsPage from "./Pages/SponsorPages/SponsorLeadsPage";
import PuchasePage from "./Pages/PuchasePage/PuchasePage";
import EventPage from "./Pages/EventPage/EventPage";
import CertificatePage from "./Pages/CertificatePage/CertificatePage";

function App() {
  return (
    <div className="App">
      <UserAuthContextProvider>
        <ContextProvider>
          <Routes>
            {/*Route for Login*/}
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/" element={<Layout />}>
              <Route index element={<ProtectedRoute Component={HomePage} />} />
              <Route path="/profile" element={<ProfilePage />} />

              {/* All Campaigns of a organiser  On this page i called api for getting all campaigns of the logined organiser*/}
              <Route
                path="/events"
                element={<ProtectedRoute Component={EventPage} />}
              />
              <Route
                path="/events/:eventId"
                element={<ProtectedRoute Component={CertificatePage} />}
              />
              <Route
                path="/campaigns"
                element={<ProtectedRoute Component={PollCampaign} />}
              />
              <Route
                path="/campaigns/create"
                element={<ProtectedRoute Component={CampaignPage} />}
              />
              <Route
                path="/campaigns/create/cart"
                element={<ProtectedRoute Component={CartPage} />}
              />
              <Route
                path="/campaigns/create/:category"
                element={<ProtectedRoute Component={CampaignListingPage} />}
              />
              <Route
                path="/campaigns/create/details"
                element={<ProtectedRoute Component={CampaignCreatePage} />}
              />
              <Route
                path="/campaigns/create/success"
                element={<CampaignCreationSuccessPage />}
              />
              <Route
                path="/campaigns/campaign-pending-approvals"
                element={
                  <ProtectedRoute Component={CampaignPendingDetailsPage} />
                }
              />
              {/* All Contests of a particular orgdomain of the campiagn */}
              <Route
                path="/campaigns/access"
                element={<ProtectedRoute Component={CampaignAccessPage} />}
              />
              <Route
                path="/campaigns/contests"
                element={<ProtectedRoute Component={PollContest} />}
              />
              <Route
                path="/campaigns/contests/campaign-all-entries"
                element={
                  <ProtectedRoute Component={CampaignAllEntriesDetailPage} />
                }
              />
              <Route
                path="/campaigns/contests/campaign-declined-entries"
                element={
                  <ProtectedRoute
                    Component={CampaignDeclinedEntriesDetailPage}
                  />
                }
              />
              <Route
                path="/campaigns/contests/campaign-deleted-entries"
                element={
                  <ProtectedRoute
                    Component={CampaignDeletedEntriesDetailPage}
                  />
                }
              />
              <Route
                path="/campaigns/contests/contest/declined-entries"
                element={
                  <ProtectedRoute
                    Component={ContestDeclinedEntriesDetailPage}
                  />
                }
              />
              <Route
                path="/campaigns/contests/contest/deleted-entries"
                element={
                  <ProtectedRoute Component={ContestDeletedEntriesDetailPage} />
                }
              />

              {/*contest status of a selected contest*/}
              <Route
                path="/campaigns/contests/contest"
                element={<ProtectedRoute Component={ContestDetailsPage} />}
              />

              {/*pending approvals of a selected contest*/}
              <Route
                path="/campaigns/contests/contest/contest-pending-approvals"
                element={<ProtectedRoute Component={PendingDetailsPage} />}
              />
              {/*all entries of a selected contest*/}
              <Route
                path="/campaigns/contests/contest/contest-all-entries"
                element={<ProtectedRoute Component={AllEntriesDetailPage} />}
              />

              <Route
                path="/contest/:campaignId/:orgId/:contestId/winner"
                element={<ProtectedRoute Component={Declaration} />}
              />
              <Route
                path="campaigns/contests/contest/winner-selection"
                element={<ProtectedRoute Component={Luckydraw} />}
              />
              <Route
                path="/leads/campaigns"
                element={<ProtectedRoute Component={LeadsPage} />}
              />
              <Route
                path="/leads/promotions"
                element={<ProtectedRoute Component={PromotionsLeadsPage} />}
              />
              <Route
                path="/register/campaigns"
                element={<ProtectedRoute Component={RegisterPage} />}
              />
              <Route
                path="/register/promotions"
                element={<ProtectedRoute Component={PromotionRegisterPage} />}
              />
              <Route
                path="/purchase"
                element={<ProtectedRoute Component={PuchasePage} />}
              />
              <Route
                path="/gateKeeper/campaigns"
                element={<ProtectedRoute Component={PromotionGateKeeperPage} />}
              />
              <Route
                path="/gateKeeper/promotions"
                element={<ProtectedRoute Component={PromotionGateKeeperPage} />}
              />

              <Route
                path="/register/promotions/referals"
                element={
                  <ProtectedRoute Component={ReferalPromotionRegisterPage} />
                }
              />
              <Route
                path="/promotions/list"
                element={<ProtectedRoute Component={PromotionPage} />}
              />
              <Route
                path="/promotions/create"
                element={<ProtectedRoute Component={PromotionCreatePage} />}
              />
              <Route
                path="/promotions/list/:promotionId"
                element={<ProtectedRoute Component={PromotionDetailPage} />}
              />
              <Route
                path="/promotions/list/:promotionId/edit"
                element={<ProtectedRoute Component={PromotionEditPage} />}
              />
              <Route
                path="/promotions/list/:promotionId/access"
                element={<ProtectedRoute Component={PromotionAccessPage} />}
              />
              <Route
                path="/influencers/requests"
                element={<ProtectedRoute Component={InfluencerRequestPage} />}
              />
              <Route
                path="/ads"
                element={<ProtectedRoute Component={AdsPage} />}
              />
              <Route
                path="/ads/influencers"
                element={<ProtectedRoute Component={AdsApprovePage} />}
              />
              <Route
                path="/coupons"
                element={<ProtectedRoute Component={CouponPage} />}
              />
              <Route
                path="/ads/adstemplates"
                element={<ProtectedRoute Component={SelectAdsTemplate} />}
              />
              <Route
                path="/ads/adstemplates/:templateId"
                element={<ProtectedRoute Component={AdvertisementsPanel} />}
              />
              <Route
                path="/analytics/campaigns"
                element={<ProtectedRoute Component={CampaignAnalyticsPage} />}
              />
              <Route
                path="/analytics/promotions"
                element={<ProtectedRoute Component={PromotionAnalyticsPage} />}
              />
              <Route
                path="/brands/self"
                element={<ProtectedRoute Component={MyBrandPage} />}
              />
              <Route
                path="/brands/collab"
                element={<ProtectedRoute Component={BrandPage} />}
              />
              <Route
                path="/supportedBy"
                element={<ProtectedRoute Component={SupportedByPage} />}
              />
              <Route
                path="/cmp-sponsers"
                element={<ProtectedRoute Component={SponsorPage} />}
              />
              <Route
                path="/cmp-sponsers/requests"
                element={<ProtectedRoute Component={SponsorRequestPage} />}
              />
              <Route
                path="/judges"
                element={<ProtectedRoute Component={JudgesPage} />}
              />
              <Route
                path="/specialGuests"
                element={<ProtectedRoute Component={SpecialGuestPage} />}
              />
              <Route
                path="/organizers"
                element={<ProtectedRoute Component={OrganizerPage} />}
              />

              <Route
                path="/payment"
                element={<ProtectedRoute Component={OrganizerPaymentPage} />}
              />

              {/* INFLUENCER ROUTES */}
              <Route
                path="/inf"
                element={<ProtectedRoute Component={InfluencerHomePage} />}
              />
              <Route
                path="/inf/leads"
                element={<ProtectedRoute Component={InfluencerLeadsPage} />}
              />
              <Route
                path="/inf/leads/campaigns"
                element={<ProtectedRoute Component={InfluencerCmpLeadsPage} />}
              />
              <Route
                path="/inf/leads/promotions"
                element={<ProtectedRoute Component={InfluencerLeadsPage} />}
              />
              <Route
                path="/inf/analytics/promotions"
                element={<ProtectedRoute Component={InfluencerAnalyticsPage} />}
              />
              <Route
                path="/inf/analytics/campaigns"
                element={<ProtectedRoute Component={InfluencerCmpAnalytics} />}
              />
              <Route
                path="/inf/campaigns"
                element={<ProtectedRoute Component={InfluencerCampaigns} />}
              />
              <Route
                path="/inf/promotions"
                element={<ProtectedRoute Component={InfluencerPromotions} />}
              />
              <Route
                path="/inf/brands"
                element={<ProtectedRoute Component={MyBrandPage} />}
              />
              <Route
                path="/inf/ads"
                element={<ProtectedRoute Component={InfAdsPage} />}
              />
              {/* SPONSOR ROUTES */}
              <Route
                path="/sp"
                element={<ProtectedRoute Component={SponsorHomePage} />}
              />
              <Route
                path="/sp/campaigns"
                element={<ProtectedRoute Component={SponsorCampaigns} />}
              />
              <Route
                path="/sp/analytics"
                element={<ProtectedRoute Component={SponsorAnalyticsPage} />}
              />
              <Route
                path="/sp/leads"
                element={<ProtectedRoute Component={SponsorLeadsPage} />}
              />
              <Route
                path="/sp/payment"
                element={<ProtectedRoute Component={SponsorPaymentPage} />}
              />
            </Route>
          </Routes>
        </ContextProvider>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
