import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AlertPopup from "../../Components/AlertPopups/AlertPopup";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import InfApproveTable from "../../Components/InfApproveTable/InfApproveTable";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import { isEmpty } from "lodash";
import styles from "./PromotionDetailPage.module.css";
export default function PromotionDetailPage() {
  const { orgDomain, userIdToken, verifyUser, updateData, setUpdateData } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const [promotorsList, setPromotorsList] = useState([]);
  const [approvePopup, setApprovePopup] = useState(false);
  const [selectedPromoter, setSelectedPromoter] = useState({});
  const [loading, setLoading] = useState(false);
  const { promotionId } = useParams();
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getPendingPromotors();
  }, [updateData]);
  const getPendingPromotors = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_PENDING_PROMOTORS}/${promotionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);

      const promotorsData = setApi?.data?.data;
      setPromotorsList(promotorsData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  return (
    <div className="p-[20px]">
      <div className="flex items-center w-[100%]">
        <p className="fifaHead">Inflencer Requests</p>
        <div className="flex gap-4 items-center ml-[auto]">
          <Link to={`/promotions/list/${promotionId}/edit`}>
            <button className={styles.addNew}>
              <p className={styles.new}>Edit</p>
            </button>
          </Link>
          <Link to={`/promotions/list/${promotionId}/access`}>
            <button className={styles.addNew}>
              <p className={styles.new}>Access</p>
            </button>
          </Link>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : isEmpty(promotorsList) ? (
          <div className="flex items-center justify-center w-[100%] h-[100px]">
            No Data
          </div>
        ) : (
          <table className=" border-collapse    border border-[#E5EAFF]  w-[100%] min-w-full mt-[30px]">
            {promotorsList?.map((obj) => {
              return <InfApproveTable obj={obj} type="promotion" />;
            })}
          </table>
        )}
      </div>
    </div>
  );
}
