import React, { useContext, useEffect, useState } from "react";
import PromotorsAnalytics from "../../Components/Analytics/PromotorsAnalytics";
import AdsAnalytics from "../../Components/Analytics/AdsAnalytics";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import Context from "../../Context/Context";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import { useUserAuth } from "../../Context/UserAuthContext";
import styles from "./styles/InfluencerCmpAnalytics.module.css";
import axios from "axios";
import Insight from "../../Components/RightPanel/Insight";
import { isEmpty } from "lodash";

export default function InfluencerCmpAnalytics() {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({});
  const [analyticsDataLoading, setAnalyticsDataLoading] = useState(false);
  const [analyticsOtherDataLoading, setAnalyticsOtherDataLoading] =
    useState(false);
  const [otherAdsAnalytics, setOtherAdsAnalytics] = useState({});
  const {
    orgDomain,
    userIdToken,
    verifyUser,
    campaignIdToPersist,
    setCampaignIdToPersist,
  } = useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignDataList();
  }, []);
  const getCampaignDataList = async () => {
    setLoading(true);

    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_REQUESTED_CAMPAIGNS}?type=CAMPAIGNS&status=APPROVED`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const campgnData = setApi?.data?.data;
      setCampaignList(campgnData);
      let findCampaign;
      if (campaignIdToPersist) {
        findCampaign = campgnData?.find(
          (el) => el?.campaignId == campaignIdToPersist
        );
      }
      let filterCampaign = findCampaign || campgnData[0];

      getAnalyticsData(
        filterCampaign?.organizationId,
        filterCampaign?.campaignId,
        filterCampaign?.adsRequests?.brandIds
      );
      getAnalyticsDataOther(
        filterCampaign?.organizationId,
        filterCampaign?.campaignId
      );
      setSelectedCampaign(filterCampaign);
      setLoading(false);

      return campgnData;
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const getAnalyticsData = async (organizationId, campaignId, brandIds) => {
    setAnalyticsDataLoading(true);

    try {
      let config1 = {
        method: "POST",
        url: `${process.env.REACT_APP_GET_INFLUENCER_CAMPAIGN_ANALYTICS}/${organizationId}/${campaignId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: { brandIds },
      };
      const setApi = await axios(config1);

      setAnalyticsData(setApi?.data?.data);
      setAnalyticsDataLoading(false);
    } catch (err) {
      console.log(err.message);
      setAnalyticsDataLoading(false);
    }
  };
  const getAnalyticsDataOther = async (organizationId, campaignId) => {
    setAnalyticsOtherDataLoading(true);
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_INFLUENCER_ANALYTICS_OTHER}/${organizationId}/${campaignId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      setOtherAdsAnalytics(setApi?.data?.data);
      setAnalyticsOtherDataLoading(false);
    } catch (err) {
      console.log(err.message);
      setAnalyticsOtherDataLoading(false);
    }
  };
  const handleSearch = (el) => {
    setCampaignIdToPersist(el?.campaignId);
    selectedCampaign(el);
    getAnalyticsData(
      el?.organizationId,
      el?.campaignId,
      el?.adsRequests?.brandIds
    );
    getAnalyticsDataOther(el?.organizationId, el?.campaignId);
  };

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className="p-[10px]">
          <div
            className={styles.dropDown}
            onClick={() => {
              setShowDropDown((prevState) => !prevState);
            }}
          >
            <h2>{selectedCampaign?.campaignName}</h2>

            <img src={leadsDropDown} alt="" />
          </div>

          {showDropDown && (
            <div className={styles.dropDownPopup}>
              <>
                <h4>Campaigns</h4>
                {campaignList?.map((el, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleSearch(el);
                        setShowDropDown(false);
                      }}
                    >
                      {el?.campaignName}
                    </p>
                  );
                })}
              </>
            </div>
          )}

          {analyticsDataLoading ? (
            <LoadingAnimation />
          ) : (
            <>
              {analyticsData && (
                <div className="my-[30px]">
                  <Insight
                    type="Campaign"
                    userCount={analyticsData?.TotalVisitCount}
                    likeCount={analyticsData?.TotalLikeCount}
                    viewCount={analyticsData?.totalEntryViewCount}
                    participantCounter={analyticsData?.participantCounter}
                    participantsJoined={analyticsData?.participantsJoined}
                  />
                </div>
              )}
              {!isEmpty(analyticsData?.analytics) && (
                <>
                  <h3> Advertisement Analytics</h3>
                  <AdsAnalytics
                    data={analyticsData?.analytics}
                    campaignId={selectedCampaign?.campaignId}
                  />
                </>
              )}
            </>
          )}
          {analyticsOtherDataLoading ? (
            <LoadingAnimation />
          ) : (
            !isEmpty(otherAdsAnalytics) && (
              <>
                <h3>Organizer Advertisement Analytics</h3>
                <AdsAnalytics
                  data={otherAdsAnalytics}
                  campaignId={selectedCampaign?.campaignId}
                  type="influencer"
                />
              </>
            )
          )}
        </div>
      )}
    </>
  );
}
