import React from "react";
import { useState } from "react";

export default function FilterChips({ data, setData }) {
  const [displayText, setDisplayText] = useState("");

  const createRandomId = () => {
    return Math.random().toString(36).substring(2);
  };

  const handleCreateObject = () => {
    const newObject = {
      displayText: displayText,
      id: createRandomId(),
    };
    setData((prevState) => {
      return { ...prevState, filterChips: [...data, newObject] };
    });
    setDisplayText(""); // Clear the input field after creating the object
  };
  const deleteFilter = (id) => {
    const updatedData = data?.filter((item) => item?.id !== id);
    setData((prevState) => {
      return { ...prevState, filterChips:updatedData };
    });
  };
  return (
    <div>
      {data?.map((obj) => {
        return (
          <div className="flex gap-[10px]">
            <p>{obj?.displayText}</p>
            <button onClick={() => deleteFilter(obj?.id)}>Delete</button>
          </div>
        );
      })}
      <div className="flex gap-[10px]">
        <input
          type="text"
          placeholder="Enter displayText"
          value={displayText}
          onChange={(e) => setDisplayText(e.target.value)}
        />
        <button onClick={handleCreateObject}>Create</button>
      </div>
    </div>
  );
}
