import EventCard from "./EventCard";
import { useContext, useRef, useCallback, useState, useEffect } from "react";
import styles from "./styles/PollCampaign.module.css";
import Context from "../../Context/Context";
import loadingAnimation from "../../Assets/loadingNew.json";
import Lottie from "react-lottie-player";
import LoadingAnimation from "../Loading/LoadingAnimation";
import * as _ from "lodash";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import ShareCard from "./ShareCard";

import Leads from "../Leads/Leads";
import CampaignListing from "../CampaignListing/CampaignListing";

import RightPanel from "../RightPanel/RightPanel";
import { closeCampaign, openCampaign } from "../../utils/StatusChanger";
import useGetCampaignPendingArrovalList from "../../Hooks/useGetDetails";
import AlertPopup from "../AlertPopups/AlertPopup";

function PollCampaign() {
  const {
    campign,
    setStatus,
    status,
    setCampign,
    campaignHasMore,
    campaignDataLoading,
    campaignPageNo,
    setCampaignPageNo,
    verifyUser,
    userIdToken,
    sideFilter,
    statusChangeLoading,
    setStatusChangeLoading,
    organizerListOption,
    CampignDataFunction,
    updateData,
    organizerList,
    setOrganizationId,
  } = useContext(Context);
  const { currentUser } = useUserAuth();

  const observer = useRef();

  // const [leadsData, setLeadsData] = useState([]);
  // const [leadsCampaignData, setLeadsCampaignData] = useState([]);
  // const [leadsDataLoading, setLeadsDataLoading] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [orgId, setOrgId] = useState(null);
  const [cmpId, setCmpId] = useState(null);
  // const [selectedCampaign, setSelectedCampaign] = useState();
  const [share, setShare] = useState();
  const [rightPanelData, setRightPanelData] = useState({});
  const [showCloseContestPopup, setShowCloseContestPopup] = useState(false);
  const [showOpenContestPopup, setShowOpenContestPopup] = useState(false);

  useEffect(() => {
    verifyUser();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(organizerList)) {
      CampignDataFunction(
        status,
        organizerList[organizerListOption]?.orgDomain
      );
      // setOrgDomain(organizerList[organizerListOption]?.orgDomain);
      setOrganizationId(organizerList[organizerListOption]?.orgId);
    }
  }, [status, campaignPageNo, organizerListOption, organizerList, updateData]);

  const lastElementRef = useCallback(
    (node) => {
      if (campaignDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && campaignHasMore) {
          setCampaignPageNo((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [campaignDataLoading, campaignHasMore]
  );

  // const getCampaignDataForLeads = async () => {
  //   try {
  //     let config1 = {
  //       method: "GET",
  //       url: `${process.env.REACT_APP_GET_CAMPAIGN_DATA_FOR_LEADS}/${orgDomain}`,
  //       headers: {
  //         Authorization: `Bearer ${tokenData}`,
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     const setApi = await axios(config1);

  //     const campgnData = setApi?.data?.data;
  //     setLeadsCampaignData(setApi?.data?.data);
  //     setSelectedCampaign({
  //       obj: setApi?.data?.data[0],
  //     });

  //     return campgnData;
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const getLeadsData = async (orgId, cmpId) => {
  //   try {
  //     setLeadsDataLoading(true);
  //     let config1 = {
  //       method: "GET",
  //       url: `${process.env.REACT_APP_GET_LEADS_DATA}/${orgId}/${cmpId}`,
  //     };
  //     const setApi = await axios(config1);

  //     const campgnData = setApi?.data?.data;
  //     setLeadsData(setApi?.data?.data);
  //     setLeadsDataLoading(false);
  //     return campgnData;
  //   } catch (err) {
  //     console.log(err.message);
  //     setLeadsData([]);
  //     setLeadsDataLoading(false);
  //   }
  // };

  // const handleLeadsSearch = () => {
  //   getLeadsData(
  //     selectedCampaign?.obj?.orgId,
  //     selectedCampaign?.obj?.campaignId
  //   );
  // };
  const handleOpenCampaignClick = () => {
    setShowOpenContestPopup(true);
    // const { orgId, campaignId, orgDomain, campaignUniqueText } = rightPanelData;
    // openCampaign(
    //   orgId,
    //   campaignId,
    //   orgDomain,
    //   campaignUniqueText,
    //   tokenData,
    //   setStatusChangeLoading
    // );
  };
  const handleCloseCampaignClick = () => {
    const { orgId, campaignId, orgDomain, campaignUniqueText } = rightPanelData;
    closeCampaign(
      orgId,
      campaignId,
      orgDomain,
      campaignUniqueText,
      tokenData,
      setStatusChangeLoading
    );
  };

  const { data } = useGetCampaignPendingArrovalList(
    1,
    5,
    tokenData,
    rightPanelData?.orgId,
    rightPanelData?.campaignId,
    [rightPanelData?._id]
  );
  const hanldeCloseStatusChangerPopup = () => {
    setShowCloseContestPopup(false);
  };
  const handleCloseOpenContentPopup = () => {
    setShowOpenContestPopup(false);
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Close the Campaign",
    Para: "Are you sure you want to close this Campaign? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Close this Camapign",
    Button2Color: "#F5BF00",
  };
  const openPopUpValues = {
    Icon: "",
    Heading: "Contact",
    Para: "Contact admin for opening this campaign! ",
    Button1: "Close",
    Button2: "",
    Button2Color: "#06B818",
  };

  return (
    <div className="content-wrapper">
      {showCloseContestPopup && (
        <AlertPopup
          visible={showCloseContestPopup}
          setpopUp={setShowCloseContestPopup}
          button1Function={hanldeCloseStatusChangerPopup}
          button2Function={handleCloseCampaignClick}
          value={closePopUpValues}
        />
      )}
      {showOpenContestPopup && (
        <AlertPopup
          visible={showOpenContestPopup}
          setpopUp={setShowOpenContestPopup}
          button1Function={handleCloseOpenContentPopup}
          button2Function={handleOpenCampaignClick}
          value={openPopUpValues}
        />
      )}
      <div className={` ${styles.outLine} ${styles.main} center`}>
        <CampaignListing
          setCampign={setCampign}
          setCampaignPageNo={setCampaignPageNo}
          setStatus={setStatus}
          status={status}
          campaignDataLoading={campaignDataLoading}
          campign={campign}
          lastElementRef={lastElementRef}
          setShare={setShare}
          setRightPanelData={setRightPanelData}
          handleOpenCampaignClick={handleOpenCampaignClick}
          handleCloseCampaignClick={handleCloseCampaignClick}
        />

        {/* {sideFilter == "leads" && (
          <Leads
            setSelectedCampaign={setSelectedCampaign}
            leadsCampaignData={leadsCampaignData}
            handleLeadsSearch={handleLeadsSearch}
            leadsDataLoading={leadsDataLoading}
            leadsData={leadsData}
          />
        )} */}
      </div>
      {!_.isEmpty(rightPanelData) && (
        <div className=" right-sidebar">
          <RightPanel
            cardTitile="Campaign details"
            title={rightPanelData.campaignName}
            type="Campaign"
            description={rightPanelData.description}
            rightPanelData={rightPanelData}
            handleOpenClick={setShowOpenContestPopup}
            handleCloseClick={setShowCloseContestPopup}
            pendingApprovalList={data}
            pendingArrovalCount={rightPanelData.pendingApproval}
            status={rightPanelData.campaignStatus}
            likeCount={rightPanelData?.TotalLikeCount}
            userCount={rightPanelData?.TotalVisitCount}
            viewCount={rightPanelData?.totalEntryViewCount}
            participantCounter={rightPanelData?.participantCounter}
          />
        </div>
      )}

      {share && (
        <>
          <ShareCard setShare={setShare} campaignContestsDatas={campign[0]} />
        </>
      )}
    </div>
  );
}

export default PollCampaign;
