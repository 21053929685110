import React from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import styles from "./styles/AlertBox.module.css";

function AlertBox({
  rightPanelData,
  status,
  handleOpenClick,
  handleCloseClick,
  handleCloseLike,
}) {
  const navigate = useNavigate();
  const navigateWinnerSlection = () => {
    const params = {
      orgId: rightPanelData?.orgId,
      cmpId: rightPanelData?.campaignId,
      conId: rightPanelData?.contestId,
    };
    if (rightPanelData?.winnerSelected) {
      navigate({
        pathname: `/campaigns/contests/contest`,
        search: `?${createSearchParams(params)}`,
      });
    } else {
      navigate({
        pathname: `/campaigns/contests/contest/winner-selection`,
        search: `?${createSearchParams(params)}`,
      });
    }
  };
  return (
    <div className={styles.box}>
      <p className={styles.closing}>
        {status == "OPEN" && `Close Contest`}
        {status == "CLOSED" && (
          <>
            {!rightPanelData?.likeClosed ? (
              "Close Accepting Likes"
            ) : (
              <>
                {rightPanelData?.winnerSelected && `View Winners`}
                {!rightPanelData?.winnerSelected && `Winner selection`}
              </>
            )}
          </>
        )}
        {status == "UPCOMING" && `Open Contest`}
      </p>
      <p className={styles.closingText}>
        Open this contest for participant to upload entries
      </p>

      {status == "OPEN" && (
        <button
          className={styles.buttun}
          onClick={() => handleCloseClick(true)}
        >
          Close Contest
        </button>
      )}
      {status == "UPCOMING" && (
        <button className={styles.buttun} onClick={() => handleOpenClick(true)}>
          Open Contest
        </button>
      )}
      {status == "CLOSED" && (
        <>
          {!rightPanelData?.likeClosed ? (
            <button
              className={styles.buttun}
              onClick={() => handleCloseLike(true)}
            >
              Close Like
            </button>
          ) : (
            <button
              className={styles.buttun}
              onClick={() => navigateWinnerSlection()}
            >
              {!rightPanelData?.winnerSelected && "Winner Selection"}
              {rightPanelData?.winnerSelected && "View Winners"}
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default AlertBox;
