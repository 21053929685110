import React from "react";
import styles from "./TopBanner.module.css";
import shareIcon from "../../Assets/shareIcon.png";
import { useState } from "react";
import ShareCard from "../PollContest/ShareCard";
import Breadcrumb from "../BreadCrumb/BreadCrumb";

function TopBanner({ backgroundImage, heading, data, breadCrumbItem }) {
  const [share, setShare] = useState(false);
  var coverStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), 
                 linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), 
                 url(${backgroundImage}) center center/cover no-repeat`,
    filter: "drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.4))",
  };
  return (
    <>
      {share && <ShareCard setShare={setShare} campaignContestsDatas={data} />}
      <div className={styles.topBannerParent} style={coverStyle}>
        <div className={styles.breadcrumb}>
          <Breadcrumb items={breadCrumbItem} />
        </div>
        <div className={styles.content}>
          <h1>{heading}</h1>
          {/* <p>Festivity Launch Promotion</p> */}
        </div>
        <div className={styles.shareButton} onClick={() => setShare(true)}>
          <img src={shareIcon} alt="" />
          <p>Share Campaign</p>
        </div>
      </div>
    </>
  );
}

export default TopBanner;
