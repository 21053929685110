import React from "react";
import { useState } from "react";
import styles from "./AnalyticsPage.module.css";
import leadsDropDown from "../../Assets/leadsDropDown.png";
import { useEffect } from "react";
import { useContext } from "react";
import Context from "../../Context/Context";
import axios from "axios";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import AdsAnalytics from "../../Components/Analytics/AdsAnalytics";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import PromotorsAnalytics from "../../Components/Analytics/PromotorsAnalytics";

export default function PromotionAnalyticsPage() {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [analyticsData, setAnalyticsData] = useState({});

  const [promotionList, setPromotionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { orgDomain, userIdToken, verifyUser, setNotAdmin } =
    useContext(Context);
  const { currentUser } = useUserAuth();

  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    if (!isEmpty(orgDomain)) {
      getPromotionDataList();
    }
  }, [orgDomain]);
  const getPromotionDataList = async () => {
    setLoading(true);
    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_PROMOTION_DATA_FOR_LEADS}/${orgDomain}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);
      if (setApi?.data?.admin == false) {
        setNotAdmin(true);
        return;
      }
      const promotionData = setApi?.data?.data;
      setPromotionList(promotionData);
      //   getLeadsData(promotionData[0]?.organizationId, promotionData[0]?._id);
      getAnalyticsData(promotionData[0]?._id);
      setSelectedPromotion(promotionData[0]);
      // setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const getAnalyticsData = async (promotionId) => {
    setLoading(true);

    try {
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_PROMOTION_ANALYTICS}/${promotionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const setApi = await axios(config1);
      setAnalyticsData(setApi?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const handleSearch = (el) => {
    setSelectedPromotion(el);
    getAnalyticsData(el?._id);
  };
  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className={styles.analytics}>
          <div
            className={styles.dropDown}
            onClick={() => {
              setShowDropDown((prevState) => !prevState);
            }}
          >
            <h2>{selectedPromotion?.promotionHeader?.promotionName}</h2>

            <img src={leadsDropDown} alt="" />
          </div>

          {showDropDown && (
            <div className={styles.dropDownPopup}>
              <>
                <h4>Promotions</h4>
                {promotionList?.map((el, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleSearch(el);
                        setShowDropDown(false);
                      }}
                    >
                      {el?.promotionHeader?.promotionName}
                    </p>
                  );
                })}
              </>
            </div>
          )}

          <h3 className="mt-[40px]"> Advertisement Analytics</h3>
          {isEmpty(analyticsData?.analytics) ? (
            <div className="flex items-center justify-center w-[100%] h-[100px]">
              No Data
            </div>
          ) : (
            <>
              <AdsAnalytics data={analyticsData?.analytics} />
              {analyticsData?.analytics?.hits?.lead?.counter && (
                <p className="mb-[20px]">
                  Action Button Hits:{" "}
                  {analyticsData?.analytics?.hits?.lead?.counter}
                </p>
              )}
            </>
          )}

          <h3 className="mt-[50px]"> Promotors Analytics</h3>

          {isEmpty(analyticsData?.promotors) ? (
            <div className="flex items-center justify-center w-[100%] h-[100px]">
              No Data
            </div>
          ) : (
            <PromotorsAnalytics data={analyticsData?.promotors} />
          )}
        </div>
      )}
    </>
  );
}
