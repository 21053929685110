import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./LikedUsers.module.css";
import Close from "../../Assets/close.svg";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useContext } from "react";
import Context from "../../Context/Context";
import LoadingAnimation from "../Loading/LoadingAnimation";
import user from "../../Assets/user.svg";

export default function LikedUsers({ showPopup, setShowPopup, data }) {
  const [likedUserList, setLikedUserList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataloading, setDataLoading] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const [hasMore, setHasMore] = useState(false);
  const { userIdToken, organizationId, verifyUser } = useContext(Context);
  useEffect(() => {
    verifyUser();
  }, []);
  const { currentUser } = useUserAuth();

  const token = userIdToken || currentUser?.accessToken;

  let orgId = data?.orgId;
  let cmpId = data?.campaignId;
  let cntstid = data?.contestId;
  let videoId = data?._id;

  useEffect(() => {
    setDataLoading(true);
    let cancel;
    let url = `${process.env.REACT_APP_ENTRY_LIKES}/${orgId}/${cmpId}/${cntstid}/${videoId}?page=${pageNumber}&limit=10`;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setLikeCount(res?.data?.data?.likesCount);
        setLikedUserList((prevContestData) => {
          const newData = Array.isArray(res?.data?.data?.videoLikes)
            ? res.data.data.videoLikes
            : [];
          return [...new Set([...prevContestData, ...newData])];
        });
        setHasMore(res?.data?.data?.videoLikes?.length > 0);
        setDataLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setDataLoading(false);
      });

    return () => cancel();
  }, [pageNumber]);
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (dataloading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [dataloading, hasMore]
  );
  const getProfilePicture = (uid) => {
    let url = `https://storage.googleapis.com/goviralprofiledatas/${uid}`;
    return url;
  };
  return (
    <PopupWrapper visible={showPopup}>
      <div className={styles.parent}>
        <div className={`custom-scrollbar ${styles.contentDiv}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2>Likes {likeCount && `(${likeCount})`}</h2>
            </div>
            <img src={Close} alt="x" onClick={() => setShowPopup(false)} />
          </div>
          <div className={styles.body}>
            <div className="px-[20px]">
              {likedUserList &&
                likedUserList?.map((obj, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        ref={
                          index === likedUserList.length - 1
                            ? lastElementRef
                            : null
                        }
                        className="flex items-center gap-[10px] py-[10px]"
                      >
                        <img
                          src={getProfilePicture(obj?.uid) || user}
                          alt=""
                          className="h-[40px] w-[40px] rounded-full overflow-hidden  object-cover"
                          onError={(e) => {
                            e.target.src = user;
                          }}
                        />
                        <p>{obj?.userName || obj?.phone}</p>
                      </div>
                      <div className="seperator" />
                    </>
                  );
                })}
            </div>
            {dataloading && <LoadingAnimation />}
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}
