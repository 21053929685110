import React, { useContext, useEffect, useRef } from "react";
import styles from "./AcceptInfRequest.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";

export default function AcceptInfRequest({ setPopup, data, type }) {
  const [loading, setLoading] = useState(false);
  const { promotionId } = useParams();
  const { userIdToken, setUpdateData, organizationId, campaignId } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;

  const handleClose = () => {
    setPopup(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let url = "";
    if (type == "promotion") {
      url = `${process.env.REACT_APP_APPROVE_PENDING_PROMOTORS}/${promotionId}/${data?.promoterUserId}`;
    } else {
      url = `${process.env.REACT_APP_APPROVE_CAMPAIGN_INFLUENCERS}/${organizationId}/${campaignId}/${data?.promoterUserId}`;
    }
    let config = {
      method: "PATCH",
      url: url,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    setLoading(false);
    if (response.data?.status == "success") {
      setPopup(false);
      setUpdateData((prevState) => prevState + 1);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <div className={`custom-scrollbar ${styles.content}`}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              {/* <img src={editCampaignHeaderLogo} /> */}
              <h2> Details</h2>
            </div>
            <img src={Close} alt="x" onClick={handleClose} />
          </div>
          <div className={styles.body}>
            {/* <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Campaign Name
              </p>
              <p>{data?.campaignName}</p>
            </div> */}
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Name
              </p>
              <p>{data?.promotorName}</p>
            </div>
            {data?.dop && (
              <div className={styles.form}>
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  Logo
                </p>
                <div className="h-[170px] w-full">
                  <img
                    src={data?.dop}
                    alt={`Image `}
                    className="h-full object-contain"
                  />
                </div>
              </div>
            )}
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Email
              </p>
              <p>{data?.email}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Contact number
              </p>
              <p>{data?.phone}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                Company name
              </p>
              <p>{data?.infOrgName}</p>
            </div>
            <div className={styles.form}>
              <p className="text-description-heading  font-bold whitespace-nowrap ">
                About
              </p>
              <p>{data?.about}</p>
            </div>
            {data?.socialmedialink?.instagram && (
              <div className={styles.form}>
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  Instagram
                </p>
                <p>{data?.socialmedialink?.instagram}</p>
              </div>
            )}
            {data?.socialmedialink?.facebook && (
              <div className={styles.form}>
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  Facebook
                </p>
                <p>{data?.socialmedialink?.facebook}</p>
              </div>
            )}
            {data?.socialmedialink?.website && (
              <div className={styles.form}>
                <p className="text-description-heading  font-bold whitespace-nowrap ">
                  Website
                </p>
                <p>{data?.socialmedialink?.website}</p>
              </div>
            )}
          </div>

          <div className={styles.buttonWrapper}>
            {data?.status == "REQUESTED" && (
              <button
                className={styles.save}
                onClick={handleSubmit}
                disabled={loading}
              >
                Accept
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
