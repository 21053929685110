import styles from "./styles/LuckDrw.module.css";
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import Review from "../../Assets/review.svg";
import ReviewDetails from "./ReviewDetails";
import Context from "../../Context/Context";
import Star from "../../Assets/star.svg";
import usePaginate from "../../Hooks/usePaginate";
import { useUserAuth } from "../../Context/UserAuthContext";
import PendingApprovals from "../PendingApprovals/PendingApprovals";
import Pagination from "../Pagination/Pagination";
import LoadingAnimation from "../Loading/LoadingAnimation";
import AllEntries from "../AllEntries/AllEntries";
import ViewEntry from "./ViewEntry";
import { isEmpty } from "lodash";

function PendingLeads({
  found,
  pendingApprovalCount,
  data,
  dataloading,
  filter,
  pageNumber,
  setPageNumber,
  type,
  judgment,
}) {
  const { currentUser } = useUserAuth();
  const { BaseApiUrl, verifyUser, userIdToken } = useContext(Context);
  const [popUpData, setpopUpData] = useState();
  const [popUp, setpopUp] = useState(false);

  useEffect(() => {
    verifyUser();
  }, [found]);
  const tokenData = userIdToken || currentUser?.accessToken;

  return (
    <div>
      {popUp &&
        (type == "allEntries" ? (
          <ViewEntry setpopUp={setpopUp} popupData={popUpData} popUp={popUp} />
        ) : (
          <ReviewDetails
            setpopUp={setpopUp}
            popupData={popUpData}
            popUp={popUp}
          />
        ))}
      <div className="min-h-[60vh]">
        {dataloading ? (
          <LoadingAnimation />
        ) : isEmpty(data) ? (
          <div className="w-full flex justify-center">
            <h3>No Entries</h3>
          </div>
        ) : (
          <table className=" border-collapse    border border-[#E5EAFF]  w-[100%] min-w-full ">
            {data?.map((obj) =>
              type == "allEntries" ? (
                <AllEntries
                  setpopUp={setpopUp}
                  popUp={popUp}
                  setpopUpData={setpopUpData}
                  obj={obj}
                  filter={filter}
                  judgment={judgment}
                />
              ) : (
                <PendingApprovals
                  setpopUp={setpopUp}
                  popUp={popUp}
                  setpopUpData={setpopUpData}
                  obj={obj}
                />
              )
            )}
          </table>
        )}
      </div>
      <Pagination
        totalData={pendingApprovalCount}
        pageLimit="10"
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
    </div>
  );
}

export default PendingLeads;
