import React from "react";
import CreatePromotion from "../../Components/CreatePromotion/CreatePromotion";

export default function PromotionCreatePage() {
  return (
    <>
      <CreatePromotion />
    </>
  );
}
