import user from "../../Assets/user.svg";
import React from "react";
import styles from "./PendingApproval.module.css";

function PendingApprovals({ setpopUp, popUp, setpopUpData, obj }) {
  const dateShortFormat = (dateString) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day}  ${monthName}  ${year}`;
  };
  const getProfilePicture = (uid) => {
    let url = `https://storage.googleapis.com/goviralprofiledatas/${uid}`;
    return url;
  };
  return (
    <tbody className={styles.pendingApprovalParent} key={obj._id}>
      <tr className={styles.tableBottomLine}>
        <td className={` break-all p-[5px]  ${styles.userId}`}>
          <div className="flexio">
            <p> #GOUS{obj?.userId}</p>
          </div>
        </td>
        <td className=" break-all p-[5px]">
          <div className="flex items-center ">
            <div className={styles.nameDp}>
              <img
                src={getProfilePicture(obj?.uid) || user}
                alt=""
                className="h-[40px] w-[40px] rounded-full overflow-hidden  object-cover"
                onError={(e) => {
                  e.target.src = user;
                }}
              />
            </div>
            <p>{obj?.participantName}</p>
          </div>
        </td>
        <td className=" break-all p-[5px]">
          <div className="flexio">
            <p>{dateShortFormat(obj?.uploadTime)} </p>
          </div>
        </td>
        <td className=" break-all p-[5px]">
          <div className="flexio">
            <p>{obj?.email} </p>
          </div>
        </td>
        <td className=" break-all p-[5px]">
          <div className="flexio">
            <p>{obj?.phone} </p>
          </div>
        </td>
        <td
          className=" break-all p-[5px] cursor-pointer"
          onClick={() => {
            setpopUp(popUp === true ? false : true);
            setpopUpData(obj);
          }}
        >
          <div className={styles.reviewButton}>
            <p>Review</p>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default PendingApprovals;
