import React, { useContext, useRef } from "react";
import styles from "./CreateBrand.module.css";
import Close from "../../Assets/close.svg";
import { useState } from "react";
import axios from "axios";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import { isEmpty } from "lodash";
import AlertPopup from "../AlertPopups/AlertPopup";

export default function CreateBrand({
  setCreateBrand,
  setFormValues,
  formValues,
  creationType,
}) {
  const initialValues = {
    name: "",
  };
  const logoRef = useRef();
  const [formBrandValues, setBrandFormValues] = useState(initialValues);
  const [existingOwner, setExistingOwner] = useState(false);
  const [Logo, setLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedBrands, setSearchedBrands] = useState();
  const [type, setType] = useState(creationType || "");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const { userIdToken, organizationId, setUpdateData, orgDomain } =
    useContext(Context);
  const { currentUser } = useUserAuth();
  const tokenData = userIdToken || currentUser?.accessToken;
  const [brandOwnerValues, setBrandOwnerValues] = useState({
    orgName: orgDomain,
  });
  const [formErrors, setFormErrors] = useState({});
  const [alreadyRequested, setAlreadyRequested] = useState(false);
  console.log("formBrandValues", formBrandValues);
  const handleClose = () => {
    setCreateBrand(false);
    setFormValues({ ...formValues, brandId: "" });
  };
  // LOGO UPLOAD
  const handleObjChange = (e) => {
    setBrandFormValues({
      ...formBrandValues,
      [e.target.id]: e.target.value,
    });
  };
  const handleFileChange = async (event) => {
    console.log("event.target.id", event.target.id);
    const image = new FormData();
    const imagedata = event.target.files[0];
    let url = URL.createObjectURL(imagedata);
    image.append("image", imagedata);

    setLogo(url);
    setLoading(true);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPDATE_BRAND_LOGO}/${organizationId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: image,
    };

    const upload = await axios(config);
    if (upload.status === 200) {
      setBrandFormValues({
        ...formBrandValues,
        logo: upload.data.logoUrl,
      });
    }
    setLoading(false);
  };

  const handleLogoChoose = () => {
    logoRef.current.click();
  };

  const validateEmail = (code) => {
    if (code) {
      let regexEmail =
        // eslint-disable-next-line no-useless-escape
        /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (code.match(regexEmail)) {
        setFormErrors((prevState) => ({ ...prevState, ownerEmail: "" }));
        return true;
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          ownerEmail: "Enter valid email address",
        }));
        return false;
      }
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        ownerEmail: "Enter valid email address",
      }));
    }
  };
  // search owners
  const searchBrandOwners = async () => {
    try {
      setBrandOwnerValues((prevState) => ({
        ...prevState,
        name: "",
        userId: "",
      }));
      setType("");
      let validate = validateEmail(brandOwnerValues?.email);
      if (validate) {
        setLoading(true);
        let config1 = {
          method: "POST",
          url: `${process.env.REACT_APP_SEARCH_BRAND_OWNERS}`,
          data: {
            email: brandOwnerValues?.email,
          },
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
        };
        const setApi = await axios(config1);
        const { name, userId, existingUser } = setApi?.data;
        setExistingOwner(existingUser);
        setBrandOwnerValues((prevState) => ({ ...prevState, name, userId }));
        if (existingUser) {
          setType("ADD");
        } else {
          setType("CREATE");
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const createBrandOwner = async () => {
    try {
      let config2 = {
        method: "POST",
        url: `${process.env.REACT_APP_CREATE_BRAND_OWNERS}/${organizationId}?type=${type}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: brandOwnerValues,
      };
      const response2 = await axios(config2);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChange = (e) => {
    setBrandOwnerValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSelectBrand = (obj) => {
    if (obj == "NEW") {
      setIsDropdownOpen(false);
      setReadOnly(false);
      setBrandFormValues({});
    } else {
      const { logo, name, orgId, phone, email, _id, userId } = obj;
      setBrandFormValues({
        logo,
        name,
        orgId,
        phone,
        email,
        userId,
        brandId: _id,
      });
      setIsDropdownOpen(false);
      setReadOnly(true);
    }
  };
  const searchBrands = async () => {
    try {
      setSearchedBrands(null);
      setLoading(true);
      let config1 = {
        method: "POST",
        url: `${process.env.REACT_APP_SEARCH_BRAND}`,
        data: {
          search: brandOwnerValues?.search,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      if (isEmpty(setApi?.data?.data)) {
        setFormErrors((prevState) => ({
          ...prevState,
          search: "No Result Found, Please Create One",
        }));
        setReadOnly(false);
      } else {
        setIsDropdownOpen(true);
      }
      setSearchedBrands(setApi?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleCreateBrand = async () => {
    setLoading(true);
    let formData = {};
    let url = "";
    if (type == "SELF") {
      if (creationType == "SELF") {
        url = `${process.env.REACT_APP_CREATE_SELF_BRANDS}`;
      } else {
        url = `${process.env.REACT_APP_CREATE_BRAND_DETAILS}/${organizationId}?type=SELF`;
      }
      formData = formBrandValues;
    } else if (readOnly) {
      url = `${process.env.REACT_APP_CREATE_BRAND_DETAILS}/${organizationId}?type=ASSOCIATION`;
      formData = formBrandValues;
    } else {
      url = `${process.env.REACT_APP_CREATE_BRAND_DETAILS}/${organizationId}`;
      formData = {
        ...formBrandValues,
        userId: brandOwnerValues?.userId,
        phone: brandOwnerValues?.phone,
      };
    }

    let config = {
      method: "post",
      url: url,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "application/json",
      },
      data: formData,
    };

    const response = await axios(config);

    if (response.status === 200) {
      console.log("response?.data", response?.data?.data);
      if (response?.data?.alreadyRequested) {
        setAlreadyRequested(true);
        return;
      }
      setUpdateData((prevState) => prevState + 1);
      setLoading(false);
      setCreateBrand(false);
      setFormValues({
        ...formValues,
        brandId: response?.data?.data?._id,
        brandName: response?.data?.data?.name,
      });
    }
  };
  const handleSubmit = async () => {
    if (type == "SELF" || readOnly) {
      await handleCreateBrand();
    } else {
      let validate = validateEmail(brandOwnerValues?.email);
      if (validate) {
        await createBrandOwner();
        await handleCreateBrand();
      }
    }
  };
  const closePopUpValues = {
    Icon: "",
    Heading: "Already Requested",
    Para: "You've already sent a connection request to this brand.",
    Button1: "OK",
  };
  return (
    <>
      {alreadyRequested && (
        <AlertPopup
          visible={alreadyRequested}
          setpopUp={setAlreadyRequested}
          button1Function={() => setAlreadyRequested(false)}
          value={closePopUpValues}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.popup}>
          <div className={`custom-scrollbar ${styles.content}`}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                {/* <img src={editCampaignHeaderLogo} /> */}
                <h2>Create Brand / Associate Brands</h2>
              </div>
              <img src={Close} alt="x" onClick={handleClose} />
            </div>
            <div className={styles.body}>
              <div className={styles.form}>
                <div className="flex items-center relative">
                  <p className={`mt-[10px] `}>Type</p>{" "}
                  <span className="text-red-600">*</span>
                </div>
                <div className="flex items-center gap-[10px]">
                  <label className="flex gap-[5px] items-center">
                    <input
                      type="radio"
                      value="SELF"
                      name="brandType"
                      onChange={() => setType("SELF")}
                      checked={type == "SELF"}
                    />{" "}
                    {creationType == "SELF" ? "My Brand" : "Under Organization"}
                  </label>
                  {creationType !== "SELF" && (
                    <label className="flex gap-[5px] items-center">
                      <input
                        type="radio"
                        value=""
                        name="brandType"
                        onChange={() => setType("")}
                        checked={type == ""}
                      />
                      Associate
                    </label>
                  )}
                </div>
              </div>
              {/* search  */}
              {type !== "SELF" && (
                <div className="relative">
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px]  `}>Search Brands</p>{" "}
                      <span className="text-red-600">*</span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <input
                        type="text"
                        id="search"
                        onChange={handleChange}
                        value={brandOwnerValues?.search}
                        placeholder="email or name"
                      />
                      <button
                        className={styles.search}
                        onClick={searchBrands}
                        disabled={loading}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {formErrors.search && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.search}
                    </span>
                  )}
                  {isDropdownOpen && (
                    <div className={styles.dropdownList}>
                      {searchedBrands?.map((obj) => (
                        <div
                          key={obj?._id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectBrand(obj);
                          }}
                          className="flex items-center gap-[10px]"
                        >
                          <div className="w-[30px] h-[20px]">
                            <img src={obj?.logo} className="h-full w-full " />
                          </div>
                          {obj?.name}
                        </div>
                      ))}
                      <div
                        onClick={() => {
                          handleSelectBrand("NEW");
                        }}
                        className="flex items-center gap-[10px]"
                      >
                        Create New Brand
                      </div>
                    </div>
                  )}
                </div>
              )}
              {(type == "SELF" || searchedBrands) && (
                <>
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}>Brand Name</p>{" "}
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      //   ref={textInputEmail}
                      type="text"
                      id="name"
                      onChange={handleObjChange}
                      value={formBrandValues.name}
                      readOnly={readOnly}
                    />
                  </div>
                  {/* <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}>Email</p>{" "}
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      type="text"
                      id="email"
                      onChange={handleObjChange}
                      value={formBrandValues.email}
                      readOnly={readOnly}
                    />
                  </div> */}
                  <div className="flex flex-col gap-[10px] my-[20px]">
                    <label
                      htmlFor="logo"
                      className="text-[#B5B5B5] align-items-start font-Inter font-[400] text-[17px] leading-[24px] "
                    >
                      Upload Logo
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      ref={logoRef}
                      className="hidden"
                      type="file"
                      onChange={!readOnly && handleFileChange}
                      accept=".jpg,.jpeg,.png,.svg"
                      id="Logo"
                      readOnly={readOnly}
                    />
                    <div
                      className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 px-[15px] py-[18px] font-Inter font-[400] text-[17px] leading-[24px]"
                      onClick={!readOnly && handleLogoChoose}
                    >
                      {Logo || formBrandValues?.logo ? (
                        <div>
                          <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px]">
                            <img
                              src={Logo || formBrandValues?.logo}
                              className="w-auto h-[100%]"
                            />
                          </div>
                          {!readOnly && (
                            <p className=" font-Inter font-[400] text-[17px] leading-[24px] ">
                              Change
                            </p>
                          )}
                        </div>
                      ) : (
                        "Select"
                      )}
                    </div>
                  </div>
                  <hr className={styles.line} />
                  {type !== "SELF" && !readOnly && (
                    <>
                      <h2 className="my-[20px]">Brand Owner Details</h2>
                      {/* number  */}
                      <div>
                        <div className={styles.form}>
                          <div className="flex items-center relative">
                            <p className={`mt-[10px]  `}>Email</p>{" "}
                            <span className="text-red-600">*</span>
                          </div>
                          <div className="flex items-center gap-[10px]">
                            <input
                              type="text"
                              id="email"
                              className={` ${
                                formErrors.email && styles.inputError
                              }`}
                              onChange={handleChange}
                              value={brandOwnerValues?.email}
                              placeholder=""
                            />
                            <button
                              className={styles.search}
                              onClick={searchBrandOwners}
                              disabled={loading}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                        {formErrors.ownerEmail && (
                          <span className={styles.error}>
                            <span className="text-red-600">*</span>
                            {formErrors.ownerEmail}
                          </span>
                        )}
                      </div>
                      {type && (
                        <>
                          <div>
                            <div className={styles.form}>
                              <div className="flex items-center relative">
                                <p className={`mt-[10px] `}>Name</p>{" "}
                                <span className="text-red-600">*</span>
                              </div>
                              <input
                                //   ref={textInputEmail}
                                type="text"
                                id="name"
                                className={` ${
                                  formErrors.name && styles.inputError
                                }`}
                                onChange={handleChange}
                                value={brandOwnerValues?.name}
                                readOnly={existingOwner}
                              />
                            </div>
                            {formErrors.name && (
                              <span className={styles.error}>
                                {formErrors.name}
                              </span>
                            )}
                          </div>
                          {/* email  */}
                          {/* <div>
                            <div className={styles.form}>
                              <div className="flex items-center relative">
                                <p className={`mt-[10px]  `}>Email ID</p>
                              </div>
                              <input
                                type="text"
                                id="email"
                                className={` ${
                                  formErrors.email && styles.inputError
                                }`}
                                onChange={handleChange}
                                value={brandOwnerValues?.email}
                              />
                            </div>
                            {formErrors.email && (
                              <span className={styles.error}>
                                <span className="text-red-600">*</span>
                                {formErrors.email}
                              </span>
                            )}
                          </div> */}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            <div className={styles.buttonWrapper}>
              {(type || readOnly) && (
                <button
                  className={styles.save}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
