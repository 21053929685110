import React from "react";
import styles from "./DynamicInput.module.css";
import ReactPlayer from "react-player";
export default function DynamicInput({
  handleChange,
  handleChooseFile,
  value,
  error,
  field,
  readOnly,
  fileType,
}) {
  switch (field?.type) {
    case "RADIO":
      return (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>{field?.name}</p>
            </div>
            <div className="flex items-center gap-[20px]">
              {field?.options.map((option) => {
                return (
                  <>
                    <input
                      type="radio"
                      value={option?.value}
                      name={field?.id}
                      id={field?.id}
                      checked={value == option?.value}
                      onChange={handleChange}
                    />
                    <label htmlFor={option?.value}>{option?.displayText}</label>
                  </>
                );
              })}
            </div>
          </div>
          {error && <span className={styles.error}>{error}</span>}
        </div>
      );
    case "CHECKBOX":
      return (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>{field?.name}</p>
            </div>
            <div className="flex items-center gap-[20px]">
              {field?.options.map((option) => {
                return (
                  <>
                    <input
                      type="checkbox"
                      value={option?.value}
                      name={option?.value}
                      id={field?.id}
                      onChange={handleChange}
                      checked={value}
                    />
                    <label htmlFor={option?.value}>{option?.displayText}</label>
                  </>
                );
              })}
            </div>
          </div>
          {error && <span className={styles.error}>{error}</span>}
        </div>
      );
    case "DATE":
      return (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>{field?.name}</p>
            </div>
            <input
              type="date"
              id={field?.id}
              className={` ${error && styles.inputError}`}
              onChange={handleChange}
              value={value}
            />
          </div>
          {error && <span className={styles.error}>{error}</span>}
        </div>
      );
    case "MEDIA":
      return (
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>{field?.name}</p>
          </div>
          <div className="relative h-[200px] bg-[] border-2 border-dashed">
            <input
              className="hidden"
              data-custom-info={field?.mainKey}
              type="file"
              id={field?.id}
              onChange={handleChange}
              accept={field.accept}
            />
            <div
              className="w-[100%] min-h-[60px]  h-full overflow-hidden  cursor-pointer  "
              onClick={() => !readOnly && handleChooseFile(field?.id)}
            >
              {value ? (
                <>
                  {fileType?.startsWith("video") ||
                  fileType?.startsWith("audio") ? (
                    <div className="h-[200px] h-auto w-[100%] overflow-hidden flex items-center justify-center mb-[15px] bg-[#1c1c1c]">
                      <ReactPlayer
                        controls={true}
                        height="100%"
                        url={value}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  ) : (
                    <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                      <img src={value} className="object-contain w-full h-full" />
                    </div>
                  )}
                  {!readOnly && (
                    <div className={styles.changeImageButton}>
                      Change {field?.name}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center h-full">
                  Upload {field?.name}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case "DOC":
      return (
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>{field?.name}</p>
          </div>
          <div className="relative">
            <input
              data-custom-info={field?.mainKey}
              type="file"
              id={field?.id}
              onChange={handleChange}
              accept={field.accept}
            />
          </div>
        </div>
      );
    case "TEXTAREA":
      return (
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>{field?.name}</p>
          </div>
          <textarea
            type="text"
            data-custom-info={field?.mainKey}
            id={field?.id}
            className={` ${error && styles.inputError}`}
            onChange={handleChange}
            value={value}
            readOnly={readOnly}
          />
        </div>
      );
    case "COLOR":
      return (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>{field?.name}</p>
            </div>
            <input
              type="color"
              data-custom-info={field?.mainKey}
              id={field?.id}
              className={` ${error && styles.inputError}`}
              onChange={handleChange}
              value={value}
              readOnly={readOnly}
            />
          </div>
          {error && <span className={styles.error}>{error}</span>}
        </div>
      );
      break;
    default:
      return (
        <div>
          <div className={styles.form}>
            <div className="flex items-center relative">
              <p className={`mt-[10px] `}>{field?.name}</p>
            </div>
            <input
              type="text"
              data-custom-info={field?.mainKey}
              id={field?.id}
              className={` ${error && styles.inputError}`}
              onChange={handleChange}
              value={value}
              readOnly={readOnly}
            />
          </div>
          {error && <span className={styles.error}>{error}</span>}
        </div>
      );
  }
}
