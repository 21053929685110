import { useState, useContext, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import PendingLeads from "./PendingLeads";
import Context from "../../Context/Context";
import { useUserAuth } from "../../Context/UserAuthContext";
import LoadingAnimation from "../Loading/LoadingAnimation";
import TopBanner from "../TopBanner/TopBanner";
import RightPanel from "../RightPanel/RightPanel";
import SortByFilter from "../Filters/SortByFilter";
import sortingJson from "../../Json/allEntriesSorting.json";
import filterJson from "../../Json/allEntriesFilter.json";
import RefreshButton from "../RefreshButton/RefreshButton";
import Filter from "../Filters/Filter";
import useGetAllEntriesCampaign from "../../Hooks/useGetAllEntriesCampaign";
import ColorIndicator from "../ColorIndicator/ColorIndicator";
import useGetDeclinedEntriesCampaign from "../../Hooks/useGetDeclinedEntriesCampaign";
import useGetDeletedEntries from "../../Hooks/useGetDeletedEntries";

function ContestDeletedEntriesDetailPage() {
  const { currentUser } = useUserAuth();
  const {
    verifyUser,
    userIdToken,
    setUpdateData,
    updateData,
    setCampaignIdToPersist,
  } = useContext(Context);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { orgId, campaignId, contestId } = useContext(Context);
  const [headData, setHeadData] = useState({});
  const [loading, setLoading] = useState(false);
  const [participantCount, setParticipantCount] = useState("");
  const [sorting, setSorting] = useState("created");
  const [filter, setFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignContestData();
  }, [updateData]);
  const getCampaignContestData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CONTEST_DATA}/${orgId}/${campaignId}/${contestId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const data = setApi?.data?.data;
      setCampaignIdToPersist(data?.campaignId);
      setHeadData(data);
      const participantCounter = _.get(data, "participantCounter", 0);
      setParticipantCount(participantCounter);

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const { dataloadingEntries, entries, entryCount } = useGetDeletedEntries(
    pageNumber,
    10,
    tokenData,
    orgId,
    campaignId,
    contestId,
    "CONTEST",
    sorting
  );
  const handleRefresh = () => {
    setPageNumber(1);
    setUpdateData((prevState) => prevState + 1);
  };

  return (
    <div className="content-wrapper ">
      <div className="center">
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className={`mobWidth paddingx`}>
            {/* <ContestHeadData found={headData} openNav={openNav} /> */}
            <TopBanner
              backgroundImage={headData?.contestPoster}
              heading={headData?.contestName}
              data={headData}
              breadCrumbItem={[
                { label: "Campaigns", url: "/campaigns" },
                {
                  label: "Contests",
                  url: "/campaigns/contests",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                  },
                },
                {
                  label: headData?.contestName,
                  url: "/campaigns/contests/contest",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                    conId: headData?.contestId,
                  },
                },
                {
                  label: "Deleted Entries",
                  url: "/campaigns/contests/contest/deleted-entries",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                    conId: headData?.contestId,
                  },
                },
              ]}
            />
            <div className="w-full flex items-center justify-between my-[20px]">
              <div className="flex gap-[10px] items-center">
                <SortByFilter
                  sorting={sorting}
                  setSorting={setSorting}
                  options={sortingJson.options}
                />
              </div>
              <RefreshButton handleRefresh={handleRefresh} />
            </div>

            <div className="mt-[45px]">
              <PendingLeads
                found={headData}
                pendingApprovalCount={entryCount}
                sorting={sorting}
                filter={filter}
                data={entries}
                dataloading={dataloadingEntries}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                type="allEntries"
              />
            </div>
          </div>
        )}
      </div>
      <div className=" right-sidebar">
        <RightPanel
          cardTitile="Contest details"
          title={headData?.contestName}
          type="Contest"
          description={headData.description}
          rightPanelData={headData}
          status={headData.contestStatus}
          likeCount={headData?.contestEntryLikeCount}
          viewCount={headData?.contestEntryViewCount}
          userCount={headData?.contestVisitCount}
          participantCounter={headData?.participantCounter}
        />
      </div>
    </div>
  );
}

export default ContestDeletedEntriesDetailPage;
