import styles from "./ContestDetailsPage.module.css";
import { useState, useContext, useEffect } from "react";
// import Timer from './Timer'
import Pending from "../../Assets/pending.svg";
import rytArow from "../../Assets/rytArow.svg";
import axios from "axios";
import _, { isEmpty } from "lodash";
import { ExportCSV } from "../../utils/ExportCSV";
// import PendingLeads from './PendingLeads'
// import ReviewDetails from './ReviewDetails'
// import PdfDownloder from './PdfDownloder.js/PdfDownloder'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Context from "../../Context/Context";

import { useUserAuth } from "../../Context/UserAuthContext";
import closeCampaignLogo from "../../Assets/closeCampaignIcon.svg";
import LoadingAnimation from "../../Components/Loading/LoadingAnimation";
import TopBanner from "../../Components/TopBanner/TopBanner";
import RightPanel from "../../Components/RightPanel/RightPanel";
import AlertPopup from "../../Components/AlertPopups/AlertPopup";
import { closeContest, openContest } from "../../utils/StatusChanger";
import PendingOnContestPage from "../../Components/PendingApprovals/PendingOnContestPage";
import usePaginate from "../../Hooks/usePaginate";
import SelectedWinners from "../../Components/SelectedWinners/SelectedWinners";
import RefreshButton from "../../Components/RefreshButton/RefreshButton";
import AllEntriesOnContestPage from "../../Components/AllEntries/AllEntriesOnContestPage";
import useGetAllEntries from "../../Hooks/useGetAllEntries";
import Insight from "../../Components/RightPanel/Insight";

function ContestDetailsPage() {
  // const campaignId = window.location.href.split("/")[4];
  // const orgId = window.location.href.split("/")[5];
  // const contestId = window.location.href.split("/")[6];

  const { currentUser } = useUserAuth();
  const {
    verifyUser,
    userIdToken,
    setStatusChangeLoading,
    orgId,
    campaignId,
    contestId,
    setUpdateData,
    updateData,
    setNotAdmin,
    setCampaignIdToPersist,
  } = useContext(Context);
  const [singleContestData, setSingleContest] = useState([]);

  const [loading, setLoading] = useState(false);
  const [headData, setHeadData] = useState({});
  const [contestStatus, setContestStatus] = useState("");
  const [participantCount, setParticipantCount] = useState("");
  const [winnersPrepared, setWinnersPrepared] = useState(false);
  const [pendingApprovalCount, setPendingApprovalCount] = useState("");
  const [winnerSelectedStatus, setWinnerSelectedStatus] = useState("");
  const [winnersList, setWinnersList] = useState([]);

  const [showCloseContestPopup, setShowCloseContestPopup] = useState(false);
  const [showOpenContestPopup, setShowOpenContestPopup] = useState(false);
  const [showCloseLikePopup, setShowCloseLikePopup] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    verifyUser();
  }, []);
  useEffect(() => {
    getCampaignContestData();
  }, [updateData]);
  const tokenData = userIdToken || currentUser?.accessToken;

  const getCampaignContestData = async () => {
    try {
      setLoading(true);
      let config1 = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_CONTEST_DATA}/${orgId}/${campaignId}/${contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const setApi = await axios(config1);
      const contestDetails = setApi?.data?.data;
      setCampaignIdToPersist(contestDetails?.campaignId);
      const participantCount = _.get(
        contestDetails && contestDetails,
        "participantCounter",
        0
      );
      setParticipantCount(participantCount);
      const pendingApprovalCount = _.get(
        contestDetails && contestDetails,
        "pendingApproval",
        0
      );
      setPendingApprovalCount(pendingApprovalCount);

      const contestStatus = _.get(contestDetails, "contestStatus", "");
      setContestStatus(contestStatus);
      const winnersPrepared = _.get(
        contestDetails && contestDetails,
        "winnersPrepared",
        false
      );
      setWinnersPrepared(winnersPrepared);
      const winnerSelectedStatus = _.get(
        contestDetails && contestDetails,
        "winnerSelected",
        ""
      );

      setWinnerSelectedStatus(winnerSelectedStatus);
      const winnersList = _.get(
        contestDetails && contestDetails,
        "winnersList",
        []
      );

      setWinnersList(winnersList);
      setHeadData(contestDetails);
      setSingleContest(contestDetails);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const handleOpenContest = () => {
    setShowOpenContestPopup(true);

    // const { orgId, campaignId, contestId, orgDomain, campaignUniqueText } =
    //   singleContestData;
    // openContest(
    //   orgId,
    //   campaignId,
    //   contestId,
    //   orgDomain,
    //   campaignUniqueText,
    //   tokenData,
    //   setStatusChangeLoading
    // );
  };
  const handleCloseContet = () => {
    const { orgId, campaignId, contestId, contestType } = singleContestData;
    closeContest(
      orgId,
      campaignId,
      contestId,
      contestType,
      tokenData,
      setStatusChangeLoading,
      setNotAdmin
    );
  };
  const hanldeCloseStatusChangerPopup = () => {
    setShowCloseContestPopup(false);
  };
  const handleCloseOpenContentPopup = () => {
    setShowOpenContestPopup(false);
  };
  const { data, dataloadingPending } = usePaginate(
    1,
    6,
    tokenData,
    singleContestData?.orgId,
    singleContestData?.campaignId,
    singleContestData?.contestId,
    [singleContestData?.contestId],
    "created",
    singleContestData?.pendingApproval
  );
  const { entries, dataloadingEntries } = useGetAllEntries(
    1,
    6,
    tokenData,
    singleContestData?.orgId,
    singleContestData?.campaignId,
    singleContestData?.contestId,
    [singleContestData?.contestId],
    "created",
    singleContestData?.pendingApproval
  );
  const closePopUpValues = {
    Icon: "",
    Heading: "Close the Contest",
    Para: "Are you sure you want to close this Contest? This action cannot be undone.",
    Button1: "Cancel",
    Button2: "Close this Contest",
    Button2Color: "#F5BF00",
  };
  const openPopUpValues = {
    Icon: "",
    Heading: "Open the Contest",
    Para: "Contact admin for opening this contest! ",
    Button1: "Cancel",
    Button2: "",
    Button2Color: "#06B818",
  };
  const closeLikeValues = {
    Icon: "",
    Heading: "Close Accepting Like",
    Para: "Are you sure you want to close accepting likes? ",
    Button1: "Cancel",
    Button2: "Close Like",
    Button2Color: "#06B818",
  };
  const handleRefresh = () => {
    setUpdateData((prevState) => prevState + 1);
  };
  const handleCloseLikePopup = () => {
    setShowCloseLikePopup(false);
  };
  const navigateWinnerSlection = () => {
    const params = {
      orgId: singleContestData?.orgId,
      cmpId: singleContestData?.campaignId,
      conId: singleContestData?.contestId,
    };
    navigate({
      pathname: `/campaigns/contests/contest/winner-selection`,
      search: `?${createSearchParams(params)}`,
    });
  };
  const handleCloseLike = async () => {
    setLoading(true);
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_UPDATE_GIFT_OPTIONS}/${singleContestData?.orgId}/${singleContestData?.campaignId}/${singleContestData?.contestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          likeClosed: true,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        handleCloseLikePopup();
        setUpdateData((prevState) => prevState + 1);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const params = { orgId: orgId, cmpId: campaignId, conId: contestId };
  const handleViewDeclinedEntries = () => {
    navigate({
      pathname: "/campaigns/contests/contest/declined-entries",
      search: `?${createSearchParams(params)}`,
    });
  };
  const handleViewDeletedEntries = () => {
    navigate({
      pathname: "/campaigns/contests/contest/deleted-entries",
      search: `?${createSearchParams(params)}`,
    });
  };

  const handleViewAllEntries = () => {
    navigate({
      pathname: "/campaigns/contests/contest/contest-all-entries",
      search: `?${createSearchParams(params)}`,
    });
  };
  return (
    <div className={`content-wrapper ${styles.contestDetails}`}>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className={`mobWidth paddingx center`}>
            {showCloseContestPopup && (
              <AlertPopup
                visible={showCloseContestPopup}
                setpopUp={setShowCloseContestPopup}
                button1Function={hanldeCloseStatusChangerPopup}
                button2Function={handleCloseContet}
                value={closePopUpValues}
              />
            )}
            {showOpenContestPopup && (
              <AlertPopup
                visible={showOpenContestPopup}
                setpopUp={setShowOpenContestPopup}
                button1Function={handleCloseOpenContentPopup}
                button2Function={handleOpenContest}
                value={openPopUpValues}
              />
            )}
            {showCloseLikePopup && (
              <AlertPopup
                visible={showCloseLikePopup}
                setpopUp={setShowCloseLikePopup}
                button1Function={handleCloseLikePopup}
                button2Function={handleCloseLike}
                value={closeLikeValues}
              />
            )}
            <TopBanner
              backgroundImage={headData?.contestPoster}
              heading={headData?.contestName}
              data={headData}
              breadCrumbItem={[
                { label: "Campaigns", url: "/campaigns" },
                {
                  label: "Contests",
                  url: "/campaigns/contests",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                  },
                },
                {
                  label: headData?.contestName,
                  url: "/campaigns/contests/contest",
                  params: {
                    orgId: headData?.orgId,
                    cmpId: headData?.campaignId,
                    conId: headData?.contestId,
                  },
                },
              ]}
            />
            <Insight
              type="Contest"
              userCount={singleContestData?.contestVisitCount}
              likeCount={singleContestData?.contestEntryLikeCount}
              viewCount={singleContestData?.contestEntryViewCount}
              participantCounter={singleContestData?.participantCounter}
            />
            <div className="w-full flex items-center justify-end my-[20px] gap-x-[5px] gap-y-[10px] flex-wrap">
              {singleContestData.contestStatus == "CLOSED" && (
                <>
                  {singleContestData?.likeClosed ? (
                    <button
                      className={styles.button}
                      onClick={navigateWinnerSlection}
                    >
                      Winners
                    </button>
                  ) : (
                    <button
                      className={styles.button}
                      onClick={setShowCloseLikePopup}
                    >
                      Close Like
                    </button>
                  )}
                </>
              )}
              <button className={styles.button} onClick={handleViewAllEntries}>
                All Entries
              </button>
              <button
                className={styles.button}
                onClick={handleViewDeletedEntries}
              >
                Deleted Entries
              </button>
              <button
                className={styles.button}
                onClick={handleViewDeclinedEntries}
              >
                Declined Entries
              </button>
              <RefreshButton handleRefresh={handleRefresh} />
            </div>
            {singleContestData?.crowdWinners && (
              <SelectedWinners
                data={singleContestData?.crowdWinners}
                type="Crowd Winners"
              />
            )}
            {singleContestData?.judgeWinners && (
              <SelectedWinners
                data={singleContestData?.judgeWinners}
                type="Judge Winners"
              />
            )}
            {singleContestData?.specialWinners && (
              <SelectedWinners
                data={singleContestData?.specialWinners}
                type="Special Winners"
              />
            )}
            <div className="mt-[30px]">
              <h3 className="">
                Pending Approvals ({singleContestData.pendingApproval})
              </h3>
              {dataloadingPending ? (
                <LoadingAnimation />
              ) : isEmpty(data) ? (
                <div className="flex items-center justify-center w-[100%] h-[100px]">
                  No pending Entries
                </div>
              ) : (
                <PendingOnContestPage
                  pendingApprovalList={data}
                  pendingArrovalCount={singleContestData.pendingApproval}
                />
              )}
            </div>
            <div className="mt-[30px]">
              <h3 className="">
                All Entries ({singleContestData?.participantCounter})
              </h3>
              {dataloadingEntries ? (
                <LoadingAnimation />
              ) : isEmpty(entries) ? (
                <div className="flex items-center justify-center w-[100%] h-[100px]">
                  No Entries
                </div>
              ) : (
                <AllEntriesOnContestPage
                  allEntriesList={entries}
                  totalCount={singleContestData?.participantCounter}
                />
              )}
            </div>
          </div>
          <div className=" right-sidebar">
            <RightPanel
              cardTitile="contest details"
              title={singleContestData.contestName}
              type="Contest"
              description={singleContestData.description}
              rightPanelData={singleContestData}
              handleOpenClick={setShowOpenContestPopup}
              handleCloseClick={setShowCloseContestPopup}
              handleCloseLike={setShowCloseLikePopup}
              //   pendingApprovalList={data}
              status={singleContestData.contestStatus}
              pendingArrovalCount={singleContestData.pendingApproval}
              likeCount={singleContestData?.contestEntryLikeCount}
              viewCount={singleContestData?.contestEntryViewCount}
              userCount={singleContestData?.contestVisitCount}
              participantCounter={singleContestData?.participantCounter}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ContestDetailsPage;
