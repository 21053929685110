import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styles from "./MoreOptionDropDown.module.css";
import Union from "../../../src/Assets/Union.svg";
import ShareTube from "../../../src/Assets/sharetube.svg";

function LeadsMoreOptionDropDown({
  setShowDeleteCampaignPopup,
  setShowCommentPopup,
  setLeadsId,
  obj,
  handleStatusMarker,
  setCommentsObj
}) {
  const [dropDown, setDropDown] = useState(false);
  const modalRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropDown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown]);
  return (
    <div
      onClick={() => {
        setDropDown(!dropDown);
      }}
      className={styles.dotInLeads}
      ref={modalRef}
    >
      <img src={Union} alt="" />
      {dropDown === true && (
        <div className={styles.bigDropme}>
          <p>More Options</p>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setLeadsId(obj._id);
              setShowDeleteCampaignPopup(true);
            }}
          >
            <img src={ShareTube} alt="" />
            <p>Delete Lead</p>
          </div>
          <div
            className="flex gap-4 mt-[20px] mb-[24px]"
            onClick={() => {
              setLeadsId(obj._id);
              setCommentsObj(obj?.comments);
              setShowCommentPopup(true);
            }}
          >
            <img src={ShareTube} alt="" />
            <p>Add Comments</p>
          </div>
          {obj?.contacted ? (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => handleStatusMarker(obj._id, false)}
            >
              <img src={ShareTube} alt="" />
              <p>Mark as Not Contacted</p>
            </div>
          ) : (
            <div
              className="flex gap-4 mt-[20px] mb-[24px]"
              onClick={() => handleStatusMarker(obj._id, true)}
            >
              <img src={ShareTube} alt="" />
              <p>Mark as Contacted</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default LeadsMoreOptionDropDown;
