import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import HelpPopup from "../HelpPopup/HelpPopup";
import CommonRichTextEditor from "../RichtextEditor/CommonRichTextEditor";
import styles from "./EditCampaign.module.css";
import FormAttributes from "./FormAttributes/FormAttributes";

export default function ContestFormInputs({
  contestFormValue,
  setContestFormValues,
  contestFormErrors,
  helpData,
  tokenData,
  editCampaignData,
  formValues,
}) {
  const inputRef = useRef();
  const [source, setSource] = useState("");
  const [showAddFormAttributes, setShowAddFormAttributes] = useState(false);
  const [formattributesValues, setFormAttributesValues] = useState({});

  useEffect(() => {
    setSource(contestFormValue.contestPoster);
  }, [contestFormValue]);
  const handleFileChange = async (event) => {
    event.preventDefault();
    const thumbnail = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    let url = URL.createObjectURL(imagedata);
    thumbnail.append("image", imagedata);
    setSource(url);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_UPDATE_CONSTEST_POSTER}/${editCampaignData?.orgId}/${editCampaignData?._id}/${contestFormValue?.contestId}`,
      headers: {
        Authorization: `Bearer ${tokenData}`,
        "Content-Type": "multipart/form-data",
      },
      data: thumbnail,
    };

    const coverUpdate = await axios(config);
    if (coverUpdate.status === 200) {
      setContestFormValues((prevState) => ({
        ...prevState,
        contestPoster: coverUpdate.data.posterUrl,
      }));
    }
  };
  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleChangeInput = (e) => {
    setContestFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <div>
      {showAddFormAttributes && (
        <FormAttributes
          contestFormValue={contestFormValue}
          setContestFormValues={setContestFormValues}
          showAddFormAttributes={showAddFormAttributes}
          setShowAddFormAttributes={setShowAddFormAttributes}
          formattributesValues={formattributesValues}
          setFormAttributesValues={setFormAttributesValues}
        />
      )}
      <h3>Contest Details</h3>
      {/* cover image  */}
      <div className="relative h-[200px]">
        <input
          ref={inputRef}
          className="hidden"
          type="file"
          onChange={handleFileChange}
          accept=".jpg,.jpeg"
        />
        <div
          className="w-[100%] min-h-[60px]   overflow-hidden  cursor-pointer  "
          onClick={handleChoose}
        >
          {source ? (
            <div>
              <div className=" h-[200px] overflow-hidden flex items-center justify-center">
                <img src={source} className="" />
              </div>
            </div>
          ) : (
            "Add Cover Image"
          )}
          <div className={styles.changeImageButton}>Change Cover Image</div>
        </div>
      </div>
      {/* contest name  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Name<span className="text-red-600">*</span>
            </p>
            {helpData?.contestName && (
              <HelpPopup content={helpData?.contestName} />
            )}
          </div>
          <input
            type="text"
            id="contestName"
            className={` ${
              contestFormErrors?.contestName && styles.inputError
            }`}
            onChange={handleChangeInput}
            value={contestFormValue?.contestName}
          />
        </div>
        {contestFormErrors?.contestName && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.contestName}
          </span>
        )}
      </div>
      {/* tagline */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Tag line<span className="text-red-600">*</span>
            </p>
            {helpData?.contestTagLine && (
              <HelpPopup content={helpData?.contestTagLine} />
            )}
          </div>
          <input
            type="text"
            className={` ${
              contestFormErrors?.contestTagLine && styles.inputError
            }`}
            id="contestTagLine"
            onChange={handleChangeInput}
            value={contestFormValue?.contestTagLine}
          />
        </div>
        {contestFormErrors?.contestTagLine && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.contestTagLine}
          </span>
        )}
      </div>
      {/* contest description */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>
              Description<span className="text-red-600">*</span>
            </p>
            {helpData?.contestDescription && (
              <HelpPopup content={helpData?.description} />
            )}
          </div>
          <textarea
            //   ref={textInputEmail}
            type="text"
            className={` ${
              contestFormErrors?.description && styles.inputError
            } scrollbar-hide `}
            id="description"
            onChange={handleChangeInput}
            value={contestFormValue.description}
          />
        </div>
        {contestFormErrors?.description && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors?.description}
          </span>
        )}
      </div>
      {/* priceDescription */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              Gift Details<span className="text-red-600">*</span>
            </p>
            {helpData?.priceDescription && (
              <HelpPopup content={helpData?.priceDescription} />
            )}
          </div>
        </div>
        <div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${contestFormErrors?.priceDescription && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setContestFormValues}
              formValues={contestFormValue}
              keyToEdit="priceDescription"
            />
          </div>
          {contestFormErrors?.priceDescription && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.priceDescription}
            </span>
          )}
        </div>
      </div>
      {/* contestRules */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              Contest Rules<span className="text-red-600">*</span>
            </p>
            {helpData?.contestRules && (
              <HelpPopup content={helpData?.contestRules} />
            )}
          </div>
        </div>
        <div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${contestFormErrors?.contestRules && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setContestFormValues}
              formValues={contestFormValue}
              keyToEdit="contestRules"
            />
          </div>
          {contestFormErrors?.contestRules && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.contestRules}
            </span>
          )}
        </div>
      </div>
      {/* howToParticipate */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              How To Participate<span className="text-red-600">*</span>
            </p>
            {helpData?.howToParticipate && (
              <HelpPopup content={helpData?.howToParticipate} />
            )}
          </div>
        </div>
        <div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${contestFormErrors?.howToParticipate && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setContestFormValues}
              formValues={contestFormValue}
              keyToEdit="howToParticipate"
            />
          </div>
          {contestFormErrors?.howToParticipate && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.howToParticipate}
            </span>
          )}
        </div>
      </div>
      {/* howIt WOrks */}
      <div className={styles.form}>
        <div className="flex flex-col">
          <div className="flex items-center relative">
            <p className={`mt-[10px]  `}>
              How It Works<span className="text-red-600">*</span>
            </p>
            {helpData?.howToParticipate && (
              <HelpPopup content={helpData?.howItsWorks} />
            )}
          </div>
        </div>
        <div>
          <div
            className={`outline-none h-[auto] w-[100%] border-2 rounded-[5px] p-[16px] 
              ${contestFormErrors?.howItsWorks && styles.inputError}`}
            // ref={textInputTandc}
          >
            <CommonRichTextEditor
              setFormValues={setContestFormValues}
              formValues={contestFormValue}
              keyToEdit="howItsWorks"
            />
          </div>
          {contestFormErrors?.howItsWorks && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {contestFormErrors.howItsWorks}
            </span>
          )}
        </div>
      </div>
      {/* start date  */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Start Date<span className="text-red-600">*</span>
            </p>
            {helpData?.startDate && <HelpPopup content={helpData?.startDate} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${contestFormErrors.startDate && styles.inputError}`}
            id="startDate"
            onChange={handleChangeInput}
            value={contestFormValue?.startDate}
            min={formValues?.startDate}
            max={formValues?.endDate}
          />
        </div>
        {contestFormErrors.startDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.startDate}
          </span>
        )}
      </div>
      {/* endDate   */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              End Date<span className="text-red-600">*</span>
            </p>
            {helpData?.endDate && <HelpPopup content={helpData?.endDate} />}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${contestFormErrors.endDate && styles.inputError}`}
            id="endDate"
            onChange={handleChangeInput}
            value={contestFormValue?.endDate}
            min={contestFormValue?.startDate}
            max={formValues?.endDate}
          />
        </div>
        {contestFormErrors.endDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.endDate}
          </span>
        )}
      </div>
      {/* winner annoncement date   */}
      <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
              Winner Anounce Date<span className="text-red-600">*</span>
            </p>
            {helpData?.winnerAnounceDate && (
              <HelpPopup content={helpData?.winnerAnounceDate} />
            )}
          </div>
          <input
            //   ref={textInputEmail}
            type="datetime-local"
            className={` ${
              contestFormErrors.winnerAnounceDate && styles.inputError
            }`}
            id="winnerAnounceDate"
            onChange={handleChangeInput}
            value={contestFormValue?.winnerAnounceDate}
            min={contestFormValue?.startDate}
          />
        </div>
        {contestFormErrors.winnerAnounceDate && (
          <span className={styles.error}>
            <span className="text-red-600">*</span>
            {contestFormErrors.winnerAnounceDate}
          </span>
        )}
      </div>

      {/* FORM ATTRIBUTES */}
      {/* <div>
        <div className={styles.form}>
          <div className="flex items-center relative">
            <p className={`mt-[10px] `}>Form Attributes</p>
            {helpData?.contestTagLine && (
              <HelpPopup content={helpData?.contestTagLine} />
            )}
          </div>
          {contestFormValue?.formAttributes?.map((obj) => (
            <p
              onClick={() => {
                setShowAddFormAttributes(true);
                setFormAttributesValues(obj);
              }}
            >
              {obj.title}
            </p>
          ))}
        </div>
      </div> */}
    </div>
  );
}
